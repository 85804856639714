/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState } from 'react';
import { Box, Container, Flex, Spacer, Button, Text } from '@chakra-ui/core';
import { CheckIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useMeetContext } from './MeetContext';
import { LocalVideoPreview } from './LocalVideoPreview';
import { VideoToggleButton } from './twilio/VideoToggleButton';
import { MeetParticipantVerification } from './MeetParticipantVerification';
import { greenRoomPage, greenRoomVideoBlock, greenRoomVideoControls } from './MeetVide.styles';
import { ParticipantData, ValidMeetTokenResponse } from './MeetTokenResponse';
import { GreenRoomTestOutputDevice } from './GreenRoomTestOutputDevice';
import { MeetGuestVerification } from '../guestMeet/MeetGuestVerification';
import { MeetTypeEnum } from '../../routes/constants';
import { AudioToggleButtonGreenRoom } from './twilio/AudioToggleButtonGreenRoom';
import { AppointmentType } from '../../firebase/firestore/documents/appointmentAvaiability';

type GreenRoomProps = {
  isLoading: boolean;
  joinGroupEventRoom: (participantInfo: ParticipantData) => void;
  groupEventInfo: ValidMeetTokenResponse | null;
  joinRoom: () => void;
  meetType: MeetTypeEnum;
  isCollaboratorFeatureEnabled: boolean;
  selectedAppointmentType: AppointmentType | null;
  setSelectedAppointmentType: (appointmentType: AppointmentType) => void;
};

export const GreenRoom = ({
  isLoading,
  joinGroupEventRoom,
  groupEventInfo,
  joinRoom,
  meetType,
  isCollaboratorFeatureEnabled,
  selectedAppointmentType,
  setSelectedAppointmentType,
}: GreenRoomProps): JSX.Element => {
  const { t } = useTranslation('meet');
  const { getAudioAndVideoTracks } = useMeetContext();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    getAudioAndVideoTracks();
  }, [getAudioAndVideoTracks]);

  return (
    <Box className="green-room-page">
      <Container maxW="container.xl">
        <Flex css={greenRoomPage} justify="center" align="center" p={6}>
          {isActive ? (
            // istanbul ignore next
            <GreenRoomTestOutputDevice onClose={/* istanbul ignore next */ () => setIsActive(!isActive)} />
          ) : (
            <Fragment>
              {MeetTypeEnum.EVENT === meetType && (
                <MeetParticipantVerification
                  groupEventInfo={groupEventInfo}
                  joinGroupEventRoom={joinGroupEventRoom}
                  isLoading={isLoading}
                />
              )}
              {MeetTypeEnum.INTERVIEW === meetType &&
                (isCollaboratorFeatureEnabled ? (
                  <MeetGuestVerification
                    joinRoom={joinRoom}
                    isLoading={isLoading}
                    setSelectedAppointmentType={setSelectedAppointmentType}
                  />
                ) : (
                  <Text fontSize="lg">{t('collaboratorFeatureNotAvailable')}</Text>
                ))}
            </Fragment>
          )}
          <Spacer />

          {
            // istanbul ignore next
            selectedAppointmentType === AppointmentType.video && (
              <Box css={greenRoomVideoBlock} className="green-room-video-block">
                <LocalVideoPreview />
                <Box css={greenRoomVideoControls} className="green-room-video-controls">
                  <AudioToggleButtonGreenRoom isLoading={isLoading} />
                  <VideoToggleButton isLoading={isLoading} />
                </Box>
                <Box mt="3" d="flex" alignItems="center" justifyContent="center">
                  {!isActive && (
                    <Button
                      size="sm"
                      leftIcon={<CheckIcon />}
                      colorScheme="blue"
                      variant="solid"
                      data-testid="activeBtn"
                      onClick={/* istanbul ignore next */ () => setIsActive(!isActive)}
                    >
                      {t('checkYourDevice')}
                    </Button>
                  )}
                </Box>
              </Box>
            )
          }
        </Flex>
      </Container>
    </Box>
  );
};
