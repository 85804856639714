/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { Fragment, ChangeEvent, useState, useEffect } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Stack,
  Text,
  Tooltip,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Divider,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
} from '@chakra-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { css } from '@emotion/core';
import { GoKebabVertical } from 'react-icons/go';
import { BiArrowBack, BiUserCircle } from 'react-icons/bi';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { AiOutlineUser, AiOutlineMinusCircle } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import { BsFunnel } from 'react-icons/bs';
import { IoArrowBackOutline } from 'react-icons/io5';
import SeekerListSortType from './seekerListSortType';
import { DropDown, Label, SearchInput } from '../../../common';
import SeekerListGroupType from './seekerListGroupType';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { useSeekersFilters, SESSION_KEY_CANDIDATE_LIST_TYPE } from './SeekersFiltersContext';
import Star5 from '../../../../assets/img/star5.png';
import Star4 from '../../../../assets/img/star4.png';
import Star3 from '../../../../assets/img/star3.png';
import Star2 from '../../../../assets/img/star2.png';
import Star1 from '../../../../assets/img/star1.png';
import colors from '../../../../styles/colors';
import { CandidateListType } from './seekersFiltersContextValueType';
import { isNewSeekerListPage, isPositionCandidatesPage } from '../../../../utils/candidatesUtils';

const filterBlock = css`
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  position: relative;
`;

export type FilterProps = BoxProps & {
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isUnresponsiveCandidatesEnabled: boolean;
  isArchiveListEnabled: boolean;
  isDismissedCandidateEnabled: boolean;
  onOpenSearch: () => void;
  showSearchSort: boolean;
};

const SeekerListFilters = ({
  isArchiveListEnabled,
  isDismissedCandidateEnabled,
  isUnresponsiveCandidatesEnabled,
  isCertnEnabled,
  isCertnFeatureEnabled,
  onOpenSearch,
  showSearchSort,
  ...rest
}: FilterProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    sort,
    updateSort,
    group,
    updateGroup,
    searchQuery,
    setSearchQuery,
    setGhostedListView,
    ghostedListView,
    favoriteCandidateView,
    setFavoriteCandidateView,
    isFromSearchAndSort,
    setSearchAndSortTypes,
    searchAndSortTypes,
    setIsFromSearchAndSort,
    archiveListView,
    setArchiveListView,
    dismissListView,
    setDismissListView,
    setListType,
  } = useSeekersFilters();
  const [showItemsOfMenu, setShowItemsOfMenu] = useState<boolean>(false);
  const [searchText, setSearchText] = useState(searchQuery);
  const [filterCount, setFilterCount] = useState<number>(0);
  const debounced = useDebouncedCallback(setSearchQuery, 500);
  const isSearchAndSearchFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE];
  });

  const handleGhostedList = () => {
    setShowItemsOfMenu(!showItemsOfMenu);
  };

  const location = useLocation();

  const handleFavoriteList = () => {
    const listTypeValue = favoriteCandidateView ? CandidateListType.NONE : CandidateListType.FAVOURITE_CANDIDATE_LIST;
    setListType(listTypeValue);
    sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, listTypeValue);
    setFavoriteCandidateView(!favoriteCandidateView);
  };

  const handleGhostedCandidatesView = () => {
    setListType(CandidateListType.GHOSTED_CANDIDATE_LIST);
    sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.GHOSTED_CANDIDATE_LIST);
    setGhostedListView(!ghostedListView);
  };

  const handleArchiveListView = () => {
    setListType(CandidateListType.ARCHIVED_CANDIDATE_LIST);
    sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.ARCHIVED_CANDIDATE_LIST);
    setArchiveListView(!archiveListView);
  };

  const handleDismissListView = () => {
    setListType(CandidateListType.DISMISS_CANDIDATE_LIST);
    sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.DISMISS_CANDIDATE_LIST);
    setDismissListView(!dismissListView);
  };

  const goBackToActiveListHandler = () => {
    // istanbul ignore else
    if (archiveListView) {
      handleArchiveListView();
    } else if (ghostedListView) {
      handleGhostedCandidatesView();
    } else if (dismissListView) {
      setDismissListView(!dismissListView);
    }
    setListType(CandidateListType.NONE);
    sessionStorage.removeItem(SESSION_KEY_CANDIDATE_LIST_TYPE);

    setShowItemsOfMenu(false);
  };

  const backDropClickHandler = () => {
    setShowItemsOfMenu(false);
  };

  const handleSearchBtn = () => {
    if (favoriteCandidateView) {
      setFavoriteCandidateView(false);
    }
    onOpenSearch();
  };

  const onClearSearchAndSort = () => {
    searchAndSortTypes.isInterventionEnabled = false;
    searchAndSortTypes.isFavouriteApplied = false;
    searchAndSortTypes.isPriorityCandidates = false;
    searchAndSortTypes.kioskEnabled = false;
    searchAndSortTypes.backgroundCheck = [];
    searchAndSortTypes.hireStatus = [];
    searchAndSortTypes.modernHireStatus = [];
    searchAndSortTypes.languageEvaluation = [];
    searchAndSortTypes.availabilityFit = [];
    searchAndSortTypes.requisitionList = [];
    searchAndSortTypes.candidateEngagement.apointmentRequested = '';
    searchAndSortTypes.candidateEngagement.Hired = '';
    searchAndSortTypes.candidateEngagement.offerMade = '';
    searchAndSortTypes.candidateEngagement.offerRejected = '';
    searchAndSortTypes.candidateEngagement.offerAccepted = '';
    searchAndSortTypes.candidateEngagement.appoinmentAccepted = '';
    searchAndSortTypes.candidateEngagement.appoinmentDeclined = '';
    searchAndSortTypes.candidateEngagement.appoinmentCancled = '';
    searchAndSortTypes.candidateEngagement.noAppointmentRequest = '';
    searchAndSortTypes.candidateEngagement.interestRevoked = '';
    searchAndSortTypes.candidateEngagement.contactRequestDeclined = '';
    searchAndSortTypes.rating = [0, 0, 0, 0, 0];
    searchAndSortTypes.availability.day = false;
    searchAndSortTypes.availability.night = false;
    searchAndSortTypes.availability.evening = false;
    setIsFromSearchAndSort(false);
    setSearchAndSortTypes({ ...searchAndSortTypes });
  };

  const onGetFilterUI = (backgroundValue: string) => {
    let value = '';
    if (backgroundValue === 'COMPLETE') {
      value = t('backgroundCheck.done');
    }
    if (backgroundValue === 'SENT') {
      value = t('backgroundCheck.Sent');
    }
    if (backgroundValue === 'IN_PROGRESS') {
      value = t('backgroundCheck.IN_PROGRESS');
    }
    if (backgroundValue === 'PARTIAL') {
      value = t('backgroundCheck.partiallyDone');
    }
    if (backgroundValue === 'DISPUTE') {
      value = t('backgroundCheck.DISPUTE');
    }
    if (backgroundValue === 'NONE') {
      value = t('backgroundCheck.notDone');
    }
    return value;
  };

  const onGetFilterCount = () => {
    let count = 0;
    if (searchAndSortTypes.isInterventionEnabled) {
      count += 1;
    }
    if (searchAndSortTypes.isFavouriteApplied) {
      count += 1;
    }
    if (searchAndSortTypes.isPriorityCandidates) {
      count += 1;
    }
    if (searchAndSortTypes.kioskEnabled) {
      count += 1;
    }
    if (
      searchAndSortTypes.candidateEngagement.apointmentRequested !== '' ||
      searchAndSortTypes.candidateEngagement.offerMade !== '' ||
      searchAndSortTypes.candidateEngagement.Hired !== '' ||
      searchAndSortTypes.candidateEngagement.offerRejected !== '' ||
      searchAndSortTypes.candidateEngagement.offerAccepted !== '' ||
      searchAndSortTypes.candidateEngagement.appoinmentAccepted !== '' ||
      searchAndSortTypes.candidateEngagement.appoinmentDeclined !== '' ||
      searchAndSortTypes.candidateEngagement.appoinmentCancled !== '' ||
      searchAndSortTypes.candidateEngagement.interestRevoked !== '' ||
      searchAndSortTypes.candidateEngagement.contactRequestDeclined !== '' ||
      searchAndSortTypes.candidateEngagement.noAppointmentRequest !== ''
    ) {
      count += 1;
    }
    if (searchAndSortTypes.backgroundCheck.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.hireStatus.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.languageEvaluation.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.availabilityFit.length > 0) {
      count += 1;
    }
    /* istanbul ignore next */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (searchAndSortTypes.requisitionList.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.modernHireStatus.length > 0) {
      count += 1;
    }
    if (
      searchAndSortTypes.rating.includes(1) ||
      searchAndSortTypes.rating.includes(2) ||
      searchAndSortTypes.rating.includes(3) ||
      searchAndSortTypes.rating.includes(4) ||
      searchAndSortTypes.rating.includes(5)
    ) {
      count += 1;
    }
    if (searchAndSortTypes.availability.day || searchAndSortTypes.availability.evening || searchAndSortTypes.availability.night) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    onGetFilterCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAndSortTypes]);

  const getTextByListType = () => {
    if (ghostedListView) return t('common:candidates.unresponsiveList');
    if (archiveListView) return t('common:candidates.archivedList');
    return t('common:candidates.dismissedList');
  };

  return (
    <Fragment>
      {!(ghostedListView || archiveListView || dismissListView) &&
        /* istanbul ignore next */ ((isCertnEnabled && isCertnFeatureEnabled) || isUnresponsiveCandidatesEnabled) && (
          <Box
            data-testid="CandidatesListBox"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={/* istanbul ignore next */ isCertnFeatureEnabled && isCertnEnabled ? 2 : 1}
            mt={/* istanbul ignore next */ isCertnFeatureEnabled && isCertnEnabled ? 1 : 3}
            style={{ position: 'relative' }}
          >
            <Flex alignItems="center">
              {isPositionCandidatesPage() && /* istanbul ignore next */ location.state && (
                // istanbul ignore next
                <Button
                  onClick={
                    // istanbul ignore next
                    () => window.history.back()
                  }
                  variant="ghost"
                  colorScheme="blue"
                  style={{ backgroundColor: '#fff' }}
                  px={2}
                  py={0}
                  mr={2}
                  height="unset"
                  data-testid="backBtn"
                  leftIcon={<IoArrowBackOutline size={24} color={colors.blue[500]} />}
                >
                  <Text color={colors.blue[500]}>{t('createJobPosition:stepsNav.back')}</Text>
                </Button>
              )}
              <Text
                style={{
                  fontWeight: 'bolder',
                }}
                fontSize={{ base: '16px', md: '12px', sm: '8px', lg: '15.5px' }}
              >
                {/* istanbul ignore next */}
                {favoriteCandidateView ? t('common:candidates.favoriteCandidatesList') : t('common:candidates.candidatesList')}
              </Text>
            </Flex>
            <Box
              display="flex"
              data-testid="GhostingListAlertContainer"
              flexDirection="row"
              onBlur={() => backDropClickHandler()}
              tabIndex={0}
            >
              {isSearchAndSearchFeatureEnabled && (
                <Box>
                  {isFromSearchAndSort && filterCount !== 0 ? (
                    <Popover>
                      <PopoverTrigger>
                        <Box position="relative">
                          <IconButton
                            data-testid="numberOfsearchBtn"
                            onClick={handleSearchBtn}
                            fontSize="16px"
                            colorScheme="blue"
                            style={{ borderRadius: '50%', marginTop: '-4px' }}
                            aria-label="Search And Sort"
                            height="30px"
                            width="30px"
                            minWidth="30px"
                            icon={<BsFunnel />}
                            mr={3}
                          />
                          <span
                            style={{
                              background: colors.blue[500],
                              color: colors.white,
                              boxShadow: `0px 1px 1px ${colors.white}`,
                              height: '18px',
                              width: '18px',
                              display: 'inline-block',
                              textAlign: 'center',
                              lineHeight: '1.3',
                              borderRadius: '50%',
                              position: 'absolute',
                              top: '-9px',
                              right: '1px',
                              fontSize: '14px',
                            }}
                          >
                            {filterCount}
                          </span>
                        </Box>
                      </PopoverTrigger>
                      <PopoverContent bg="#1A202C" color="white" maxH="85vh" overflowY="auto">
                        <PopoverArrow bg="#1A202C" />
                        <PopoverCloseButton />
                        <PopoverBody style={{ padding: 0 }}>
                          {searchAndSortTypes.isInterventionEnabled && (
                            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('searchAndSort.actionRequiredCandidates')}
                                </Text>
                              </Box>
                              <Flex color="white" style={{ paddingLeft: 10 }}>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                  <TiTick />
                                </Box>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {t('searchAndSort.actionRequiredCandidates')}
                                  </Text>
                                </Box>
                              </Flex>
                              <Divider pt="10px" />
                            </Box>
                          )}
                          {searchAndSortTypes.isFavouriteApplied && (
                            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('favoriteCandidate.favoriteCandidate')}
                                </Text>
                              </Box>
                              <Flex color="white" style={{ paddingLeft: 10 }}>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                  <TiTick />
                                </Box>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {t('favoriteCandidate.favoriteCandidate')}
                                  </Text>
                                </Box>
                              </Flex>
                              <Divider pt="10px" />
                            </Box>
                          )}
                          {searchAndSortTypes.isPriorityCandidates && (
                            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('searchAndSort.priority')}
                                </Text>
                              </Box>
                              <Flex color="white" style={{ paddingLeft: 10 }}>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                  <TiTick />
                                </Box>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {t('searchAndSort.priority')}
                                  </Text>
                                </Box>
                              </Flex>
                              <Divider pt="10px" />
                            </Box>
                          )}
                          {searchAndSortTypes.kioskEnabled && (
                            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('searchAndSort.kiosk')}
                                </Text>
                              </Box>
                              <Flex color="white" style={{ paddingLeft: 10 }}>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                  <TiTick />
                                </Box>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {t('searchAndSort.kiosk')}
                                  </Text>
                                </Box>
                              </Flex>
                              <Divider pt="10px" />
                            </Box>
                          )}
                          {(searchAndSortTypes.candidateEngagement.apointmentRequested !== '' ||
                            searchAndSortTypes.candidateEngagement.offerMade !== '' ||
                            searchAndSortTypes.candidateEngagement.interestRevoked !== '' ||
                            searchAndSortTypes.candidateEngagement.contactRequestDeclined !== '' ||
                            searchAndSortTypes.candidateEngagement.Hired !== '' ||
                            searchAndSortTypes.candidateEngagement.offerRejected !== '' ||
                            searchAndSortTypes.candidateEngagement.offerAccepted !== '' ||
                            searchAndSortTypes.candidateEngagement.appoinmentAccepted !== '' ||
                            searchAndSortTypes.candidateEngagement.appoinmentDeclined !== '' ||
                            searchAndSortTypes.candidateEngagement.appoinmentCancled !== '' ||
                            searchAndSortTypes.candidateEngagement.noAppointmentRequest !== '') && (
                            <Box>
                              <Box style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('searchAndSort.candidateEngagement')}
                                  </Text>
                                </Box>
                                {searchAndSortTypes.candidateEngagement.apointmentRequested !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.appointmentRequested')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.noAppointmentRequest !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.noAppointmentRequest')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.appoinmentAccepted !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.appoinmentAccepted')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.appoinmentDeclined !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.appoinmentDeclined')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.appoinmentCancled !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.appoinmentCancled')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.Hired !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.hired')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}

                                {searchAndSortTypes.candidateEngagement.offerMade !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.OfferMade')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}

                                {searchAndSortTypes.candidateEngagement.offerRejected !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.offerRejected')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}

                                {searchAndSortTypes.candidateEngagement.offerAccepted !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.offerAccepted')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.contactRequestDeclined !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.contactRequestDeclined')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.candidateEngagement.interestRevoked !== '' && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('cadidateEngagementView.interestRevoked')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                              </Box>
                              <Divider />
                            </Box>
                          )}

                          {(searchAndSortTypes.rating.includes(1) ||
                            searchAndSortTypes.rating.includes(2) ||
                            searchAndSortTypes.rating.includes(3) ||
                            searchAndSortTypes.rating.includes(4) ||
                            searchAndSortTypes.rating.includes(5)) && (
                            <Box>
                              <Box style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('searchAndSort.ratings')}
                                  </Text>
                                </Box>
                                {searchAndSortTypes.rating.includes(1) && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          <img src={Star1} alt="star1" />
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.rating.includes(2) && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          <img src={Star2} alt="star5" />
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.rating.includes(3) && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          <img src={Star3} alt="star5" />
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.rating.includes(4) && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          <img src={Star4} alt="star5" />
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.rating.includes(5) && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          <img src={Star5} alt="star5" />
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                              </Box>
                              <Divider />
                            </Box>
                          )}
                          {searchAndSortTypes.backgroundCheck.length > 0 && (
                            <Box style={{ paddingTop: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('searchAndSort.backgroundCheck')}
                                </Text>
                              </Box>
                              {searchAndSortTypes.backgroundCheck.map((item) => (
                                <Box key={item}>
                                  <Flex color="white" style={{ paddingLeft: 10 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                      <TiTick />
                                    </Box>
                                    <Box py={1}>
                                      <Text
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: 'normal',
                                        }}
                                      >
                                        {onGetFilterUI(item)}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              ))}
                              <Divider />
                            </Box>
                          )}

                          {searchAndSortTypes.hireStatus.length > 0 && (
                            <Box style={{ paddingTop: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('searchAndSort.hireStatus')}
                                </Text>
                              </Box>
                              {searchAndSortTypes.hireStatus.map((item) => (
                                <Box key={item}>
                                  <Flex color="white" style={{ paddingLeft: 10 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                      <TiTick />
                                    </Box>
                                    <Box py={1}>
                                      <Text
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: 'normal',
                                        }}
                                      >
                                        {t(`hireDetail:status.${item}`)}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              ))}
                              <Divider />
                            </Box>
                          )}

                          {searchAndSortTypes.modernHireStatus.length > 0 && (
                            <Box style={{ paddingTop: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('searchAndSort.modernHireStatus')}
                                </Text>
                              </Box>
                              {searchAndSortTypes.modernHireStatus.map((item) => (
                                <Box key={item}>
                                  <Flex color="white" style={{ paddingLeft: 10 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                      <TiTick />
                                    </Box>
                                    <Box py={1}>
                                      <Text
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: 'normal',
                                        }}
                                      >
                                        {t(`modernHire:status.${item.toUpperCase()}`)}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              ))}
                              <Divider />
                            </Box>
                          )}

                          {(searchAndSortTypes.availability.day ||
                            searchAndSortTypes.availability.evening ||
                            searchAndSortTypes.availability.night) && (
                            <Box>
                              <Box style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                <Box py={1}>
                                  <Text
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('searchAndSort.availability')}
                                  </Text>
                                </Box>
                                {searchAndSortTypes.availability.day && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('availability.day')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.availability.night && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('availability.night')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                                {searchAndSortTypes.availability.evening && (
                                  <Box>
                                    <Flex color="white">
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {t('availability.evening')}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                )}
                              </Box>
                              <Divider />
                            </Box>
                          )}

                          {searchAndSortTypes.languageEvaluation.length > 0 && (
                            <Box style={{ paddingTop: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('audioEvaluation:languageEvaluation')}
                                </Text>
                              </Box>
                              {searchAndSortTypes.languageEvaluation.map((item) => (
                                <Box key={item}>
                                  <Flex color="white" style={{ paddingLeft: 10 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                      <TiTick />
                                    </Box>
                                    <Box py={1}>
                                      <Text
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: 'normal',
                                        }}
                                      >
                                        {t(`audioEvaluation:status.${item.toUpperCase()}`)}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              ))}
                            </Box>
                          )}
                          {searchAndSortTypes.requisitionList.length > 0 && (
                            // istanbul ignore next
                            <Box style={{ paddingTop: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('common:searchAndSort.requisitionsList')}
                                </Text>
                              </Box>
                              {searchAndSortTypes.requisitionList.map(
                                /* istanbul ignore next */ ({ requisitionId }) => (
                                  // istanul ignore next
                                  <Box key={requisitionId}>
                                    <Flex color="white" style={{ paddingLeft: 10 }}>
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <TiTick />
                                      </Box>
                                      <Box py={1}>
                                        <Text
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          {requisitionId.toLowerCase()}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                ),
                              )}
                            </Box>
                          )}

                          {searchAndSortTypes.availabilityFit.length > 0 && (
                            <Box style={{ paddingTop: 10 }}>
                              <Box px={4} py={0}>
                                <Text
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('common:searchAndSort.availabilityFit')}
                                </Text>
                              </Box>
                              {searchAndSortTypes.availabilityFit.map((item) => (
                                <Box key={item}>
                                  <Flex color="white" style={{ paddingLeft: 10 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                      <TiTick />
                                    </Box>
                                    <Box py={1}>
                                      <Text
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: 'normal',
                                        }}
                                      >
                                        {t(`common:profile.matchingRequisition.${item.toLowerCase()}Fit`)}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              ))}
                            </Box>
                          )}

                          <Box px={4} py={4} onClick={onClearSearchAndSort} data-testid="clearAllBtn">
                            <Text
                              style={{
                                fontSize: '14px',
                                fontWeight: 'normal',
                                float: 'right',
                                cursor: 'pointer',
                                paddingBottom: 10,
                              }}
                            >
                              {t('searchAndSort.clearAll')}
                            </Text>
                          </Box>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  ) : (
                    <Tooltip hasArrow label={t('common:searchAndSort.advanceSearch')}>
                      <IconButton
                        onClick={handleSearchBtn}
                        fontSize="16px"
                        data-testid="searchIcon"
                        background="#F3F3F3"
                        style={{ borderRadius: '50%', marginTop: '-4px' }}
                        aria-label="Search And Sort"
                        height="30px"
                        width="30px"
                        minWidth="30px"
                        icon={<BsFunnel />}
                        mr={1}
                      />
                    </Tooltip>
                  )}
                </Box>
              )}

              <Box display="flex" flexDirection="row" data-testid="IconsBox" mt={-1}>
                {!showSearchSort && (
                  <Box onClick={handleFavoriteList} data-testid="FavoriteCandidateBox">
                    <Tooltip hasArrow label={t('common:tooltip.favoriteCandidates')}>
                      <span>
                        <AiOutlineUser
                          style={{
                            background: favoriteCandidateView ? 'rgb(38 61 186)' : '#F3F3F3',
                            marginRight: '4px',
                            padding: '5px',
                            borderRadius: '50%',
                            color: favoriteCandidateView ? '#FFFFFF' : '',
                          }}
                          size={30}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                )}

                {isUnresponsiveCandidatesEnabled && !showSearchSort && (
                  <Tooltip hasArrow label={t('common:tooltip.ghostedCandidatesList')}>
                    <Menu autoSelect={false} isLazy placement="bottom-end">
                      <MenuButton alignItems="baseline">
                        <span>
                          <GoKebabVertical
                            size={30}
                            data-testid="KebabVerticalIcon"
                            onClick={handleGhostedList}
                            style={{ background: '#F3F3F3', padding: '5px', borderRadius: '50%' }}
                          />
                        </span>
                      </MenuButton>

                      <MenuList>
                        <MenuItem data-testid="GhostingListAlert" onClick={handleGhostedCandidatesView}>
                          <BiUserCircle size={20} style={{ marginRight: '10px' }} />
                          <Text>{t('common:candidates.ghostingList')}</Text>
                        </MenuItem>
                        {isArchiveListEnabled && (
                          <MenuItem onClick={handleArchiveListView} data-testid="ArchivedCandidatesBox">
                            <RiDeleteBin4Line size={20} style={{ marginRight: '10px' }} />
                            <Text>{t('common:candidates.archiveList')}</Text>
                          </MenuItem>
                        )}
                        {isDismissedCandidateEnabled && (
                          <MenuItem data-testid="dismissedListBtn" onClick={handleDismissListView}>
                            <AiOutlineMinusCircle size={20} style={{ marginRight: '10px' }} />
                            <Text>{t('common:candidates.dismissedList')}</Text>
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        )}

      {(ghostedListView || archiveListView || dismissListView) && (
        <Box
          data-testid="GhostingList"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          onClick={goBackToActiveListHandler}
          mb={1}
          mt={/* istanbul ignore next */ isCertnFeatureEnabled && isCertnEnabled ? 1 : 2}
        >
          <BiArrowBack size={16} style={{ marginRight: '5px', marginTop: '3px' }} />
          <Text style={{ fontSize: '16px', fontWeight: 'bolder' }}>{getTextByListType()}</Text>
        </Box>
      )}

      <Box
        mb={2}
        mt={/* istanbul ignore next */ !((isCertnEnabled && isCertnFeatureEnabled) || isUnresponsiveCandidatesEnabled) ? 9 : 0}
        {...rest}
      >
        <Stack spacing={4}>
          <Box mb={/* istanbul ignore next */ isCertnFeatureEnabled && isCertnEnabled ? 2 : 0}>
            <SearchInput
              placeholder={t('search.label')}
              onChange={(evt: React.FormEvent) => {
                const target = evt.target as HTMLInputElement;
                setSearchText(target.value);
                debounced.callback(target.value);
              }}
              value={searchText}
            />
          </Box>
          <Flex justify="flex-start" css={filterBlock}>
            {!isPositionCandidatesPage() && (
              <Flex align="baseline" justify="flex-start" marginRight={2} flexWrap="wrap">
                <Label size="xs" bold>
                  {t('grouping.label')}
                </Label>
                <DropDown
                  className="groupby-select"
                  data-testid="GroupDropDown"
                  options={[
                    { value: SeekerListGroupType.NONE, label: t('grouping.none') },
                    ...(!isNewSeekerListPage()
                      ? [{ value: SeekerListGroupType.POSITION, label: t('grouping.position') }]
                      : /* istanbul ignore next */ []),
                    { value: SeekerListGroupType.BUSINESS_LOCATION, label: t('grouping.businessLocation') },
                  ]}
                  onChange={(evt: ChangeEvent<EventTarget>) => {
                    const target = evt.currentTarget as HTMLSelectElement;
                    updateGroup(target.value as SeekerListGroupType);
                  }}
                  value={group}
                  fontSize="xs"
                />
              </Flex>
            )}
            <Flex align="baseline" justify="flex-start" flexWrap="wrap">
              <Label size="xs" bold>
                {t('sorting.label')}
              </Label>
              <DropDown
                className="groupby-select"
                data-testid="SortDropDown"
                options={[
                  { value: SeekerListSortType.ALPHABETICAL, label: t('sorting.alphabetical') },
                  { value: SeekerListSortType.CHRONOLOGICAL, label: t('sorting.chronological') },
                ]}
                onChange={(evt: ChangeEvent<EventTarget>) => {
                  const target = evt.currentTarget as HTMLSelectElement;
                  updateSort(target.value as SeekerListSortType);
                }}
                value={sort}
                fontSize="xs"
              />
            </Flex>
          </Flex>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default SeekerListFilters;
