import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { HStack } from '@chakra-ui/core';
import FormElementTextBuilder from './FormElementTextBuilder';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';

export default function CheckBoxSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <HStack align="flex-start">
      <MdOutlineCheckBoxOutlineBlank fontSize={20} />
      <FormElementTextBuilder {...props} placeholder="Enter text here..." />
    </HStack>
  );
}
