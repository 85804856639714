import { useTranslation } from 'react-i18next';
import _isArray from 'lodash/isArray';
import _concat from 'lodash/concat';
import MessagesPresenter from './messagesPresenter';
import { useQuerySnapshot } from '../../../../../firebase/firestore/hooks';
import { Collections } from '../../../../../firebase/firestore/collections';
import { Query } from '../../../../../firebase/firestore/query/query';
import { CandidateAudit } from '../../../../../firebase/firestore/documents/candidateAudit';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import {
  CandidateHistoryActionName,
  CandidateMessagingActionName,
} from '../../../../../firebase/firestore/documents/candidateAuditActionNames';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

export enum MessageStateEnum {
  UNDEFINED,
  LOADING,
  READY,
}

export type MessageState = {
  state: MessageStateEnum;
  presenter: MessagesPresenter;
};

// istanbul ignore next
const mergeArrays = (arr1: CandidateAudit[] | undefined, arr2: CandidateAudit[] | undefined): CandidateAudit[] => {
  if (_isArray(arr1) && _isArray(arr2)) {
    return _concat(arr1, arr2);
  }
  if (_isArray(arr1)) {
    return arr1;
  }
  if (_isArray(arr2)) {
    return arr2;
  }
  return [];
};

const useMessages = (): MessageState => {
  const { i18n } = useTranslation();
  const { currentUserProfile } = useCurrentUserProfile();
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate;

  const candidateAuditMessagesOne = useQuerySnapshot<CandidateAudit>(Collections.CANDIDATE_AUDIT, [
    Query.field<CandidateAudit>('candidateId').equals(candidate?.id || ''),
    Query.field<CandidateAudit>('account').equals(currentUserProfile?.account),
    Query.field<CandidateAudit>('action').in([
      CandidateMessagingActionName.EMPLOYER_MESSAGE,
      CandidateMessagingActionName.SEEKER_MESSAGE,
      CandidateMessagingActionName.APPOINTMENT_PERIOD_CANCELLED,
      CandidateMessagingActionName.APPOINTMENT_REQUEST_ACCEPTED,
      CandidateMessagingActionName.APPOINTMENT_REQUEST_CANCELLED,
      CandidateMessagingActionName.APPOINTMENT_REQUEST_CREATED,
      CandidateHistoryActionName.OFFER_MADE,
      CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER,
      CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER,
      CandidateHistoryActionName.INTEREST_REVOKED,
    ]),
    Query.limit(500),
  ]);
  const candidateAuditMessagesTwo = useQuerySnapshot<CandidateAudit>(Collections.CANDIDATE_AUDIT, [
    Query.field<CandidateAudit>('candidateId').equals(candidate?.id || ''),
    Query.field<CandidateAudit>('account').equals(currentUserProfile?.account),
    Query.field<CandidateAudit>('action').in([
      CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED,
      CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED,
      CandidateHistoryActionName.OFFER_REVOKE,
      CandidateHistoryActionName.OFFER_REJECTED_BY_RECRUITER,
      CandidateHistoryActionName.HIRE_FORM_REQUESTED,
      CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS,
      CandidateHistoryActionName.HIRE_FORM_COMPLETED,
      CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED,
      CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS,
      CandidateHistoryActionName.CANDIDATE_REJECTED,
    ]),
    Query.limit(500),
  ]);

  const candidateAuditMessagesThree = useQuerySnapshot<CandidateAudit>(Collections.CANDIDATE_AUDIT, [
    Query.field<CandidateAudit>('candidateId').equals(candidate?.id || ''),
    Query.field<CandidateAudit>('account').equals(currentUserProfile?.account),
    Query.field<CandidateAudit>('action').in([
      CandidateHistoryActionName.CANDIDATE_UNHIRED,
      CandidateHistoryActionName.CANDIDATE_APPROVED,
      CandidateHistoryActionName.OFFBOARDED,
    ]),
    Query.limit(500),
  ]);

  const finalCandidateAuditMessages: CandidateAudit[] = mergeArrays(
    mergeArrays(candidateAuditMessagesOne, candidateAuditMessagesTwo),
    candidateAuditMessagesThree,
  );

  if (currentUserProfile && candidate && finalCandidateAuditMessages) {
    if (finalCandidateAuditMessages.length === 0) {
      return { state: MessageStateEnum.UNDEFINED, presenter: new MessagesPresenter([], i18n.language) };
    }

    return { state: MessageStateEnum.READY, presenter: new MessagesPresenter(finalCandidateAuditMessages, i18n.language) };
  }

  return { state: MessageStateEnum.LOADING, presenter: new MessagesPresenter([], i18n.language) };
};

export default useMessages;
