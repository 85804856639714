/** @jsx jsx */
/* istanbul ignore file */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useState } from 'react';
import { Box, Flex, Image, Spinner, Stack, Text } from '@chakra-ui/core';
import { useParams } from 'react-router';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import SeekerListFilters from '../filters/SeekerListFilters';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { seekersListContainer, seekersListItems } from './SeekerListContainer.styles';
import { MessagingTabsParams, MESSAGING_CANDIDATE_TABS, MESSAGING_TABS_NEW } from '../../../../routes/constants';
import { CandidateCategory, CandidateStatus, EngagedCandidate } from '../../../../firebase/firestore/documents/candidate';
import { SeekersPresenter } from './seekersPresenter';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { SeekerContainerTabItems } from '../../seeker/SeekerContainerContext';
import { getConfig } from './seekerListContainerConfig';
import { useStoreState } from '../../../../models/hooks';
import { seekersListFilters } from '../filters/SeekerListFilters.styles';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { FavouriteSeeker } from '../../../../firebase/firestore/documents/user';
import { useUserDocumentSnapshot } from '../../../../firebase/firestore/hooks';
import { PositionWiseSearchModal } from '../searchAndSort/PositionWiseSearchModal';
import { CandidateListType } from '../filters/seekersFiltersContextValueType';
import useCandidate from '../../../../app/components/useCandidate';
import { NotAuthorizedView } from '../../../administration/NotAuthorizedView';
import BusinessCandidateList from './BusinessCandidateList';
import SeekerListGroupType from '../filters/seekerListGroupType';
import NewSeekerList from './NewSeekerList';

const MotionBox = motion.custom(Box);

type ToggleIconProp = {
  on: boolean;
};

export type SeekerListContainerProps = {
  showSidebar: boolean;
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isUnresponsiveCandidatesEnabled: boolean;
  isArchiveListEnabled: boolean;
  isDismissedCandidateEnabled: boolean;
  onOpenSearch: () => void;
  showSearchSort: boolean;
  shouldRedirect?: boolean;
  givenCandidateId?: string;
};

export const NewSeekerListContainer = ({
  showSidebar,
  isCertnFeatureEnabled,
  isCertnEnabled,
  isArchiveListEnabled,
  isDismissedCandidateEnabled,
  isUnresponsiveCandidatesEnabled,
  onOpenSearch,
  showSearchSort,
  shouldRedirect,
  givenCandidateId,
}: SeekerListContainerProps): JSX.Element => {
  const { t } = useTranslation('noSeekerMsg');
  const seekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);

  const [visibleSeekerCount, setVisibleSeekerCount] = useState(getConfig().defaultVisibleSeekerCount);

  const { candidateId: candidateIdFromParams } = useParams<MessagingTabsParams>();
  const candidateId = givenCandidateId || candidateIdFromParams;
  const { currentUserProfile } = useCurrentUserProfile();
  const user = useUserDocumentSnapshot(currentUserProfile?.id);

  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();

  const { setIsPositionFilterApplied, isPositionFilterApplied, listType, changeCandidate, group } = useSeekersFilters();
  const candidate = useCandidate(candidateId);
  // istanbul ignore next

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const top = event.target.scrollTop === 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const bottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 1;

    if (bottom) {
      if (visibleSeekerCount < getConfig().maxVisibleSeekerCount) {
        setVisibleSeekerCount(visibleSeekerCount + getConfig().visibleSeekerCountIncrement);
      }
    }

    if (top) {
      setVisibleSeekerCount(getConfig().defaultVisibleSeekerCount);
    }
  };

  useEffect(() => {
    if (changeCandidate) {
      if (CandidateListType.DISMISS_CANDIDATE_LIST === listType) {
        if (seekerList?.dismissedCandidate && seekerList?.dismissedCandidate.length > 0) {
          const nonBlacklistedCandidates = seekerList?.dismissedCandidate.filter((value) => !value.blacklisted);

          setSelectedEngagedCandidate(nonBlacklistedCandidates[0]);
          setSelectedEngagedSeeker(
            nonBlacklistedCandidates[0] && {
              seekerId: nonBlacklistedCandidates[0].seeker,
              candidateId: nonBlacklistedCandidates[0].id,
            },
          );
          if (shouldRedirect) {
            window.history.pushState(
              null,
              '',
              MESSAGING_TABS_NEW(seekerList?.dismissedCandidate[0].id, SeekerContainerTabItems.Messaging),
            );
          } else {
            window.history.pushState(
              null,
              '',
              MESSAGING_CANDIDATE_TABS(seekerList?.dismissedCandidate[0].id, SeekerContainerTabItems.Messaging),
            );
          }
        }
      } else if (CandidateListType.ARCHIVED_CANDIDATE_LIST === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) =>
            seeker.computedStatus?.category === CandidateCategory.Other &&
            seeker.computedStatus.status !== CandidateStatus.Dismissed,
        );

        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          window.history.pushState(
            null,
            '',
            shouldRedirect
              ? MESSAGING_TABS_NEW(newSeeker[0].id, SeekerContainerTabItems.Messaging)
              : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
          );
        }
      } else if (CandidateListType.GHOSTED_CANDIDATE_LIST === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) => seeker.ghostingStatus?.status === 'GHOSTED',
        );

        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          window.history.pushState(
            null,
            '',
            shouldRedirect
              ? MESSAGING_TABS_NEW(newSeeker[0].id, SeekerContainerTabItems.Messaging)
              : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
          );
        }
      } else if (CandidateListType.FAVOURITE_CANDIDATE_LIST === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) =>
            user?.favouriteSeekers?.some((item: FavouriteSeeker) => item.id === seeker.seeker) &&
            seeker.ghostingStatus?.status !== 'GHOSTED' &&
            seeker.computedStatus?.category !== CandidateCategory.Other,
        );

        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          window.history.pushState(
            null,
            '',
            shouldRedirect
              ? MESSAGING_TABS_NEW(newSeeker[0].id, SeekerContainerTabItems.Messaging)
              : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
          );
        }
      } else if (CandidateListType.NONE === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) =>
            seeker.computedStatus?.category !== CandidateCategory.Other && seeker.ghostingStatus?.status !== 'GHOSTED',
        );
        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          window.history.pushState(
            null,
            '',
            shouldRedirect
              ? MESSAGING_TABS_NEW(newSeeker[0].id, SeekerContainerTabItems.Messaging)
              : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listType]);

  if (!shouldRedirect) {
    return (
      <Box className="super-centered" height="80vh">
        <Stack direction="column" alignItems="center" spacing={4} data-testid="Loading">
          <Spinner color="#1F3CBA" data-testid="Spinner" />
          <Box>{t('app:loading')}</Box>
        </Stack>
      </Box>
    );
  }
  // istanbul ignore else
  if (shouldRedirect) {
    return (
      <Box data-testid="SeekerListContainer" css={seekersListContainer} d={showSidebar ? 'contents' : 'none'}>
        {shouldRedirect && (
          <Box py={/* istanbul ignore next */ isCertnFeatureEnabled && isCertnEnabled ? 3 : ''} px={3} css={seekersListFilters}>
            <SeekerListFilters
              isCertnFeatureEnabled={isCertnFeatureEnabled}
              isCertnEnabled={isCertnEnabled}
              isUnresponsiveCandidatesEnabled={isUnresponsiveCandidatesEnabled}
              isArchiveListEnabled={isArchiveListEnabled}
              isDismissedCandidateEnabled={isDismissedCandidateEnabled}
              onOpenSearch={onOpenSearch}
              showSearchSort={showSearchSort}
            />
          </Box>
        )}
        {isPositionFilterApplied && (
          <PositionWiseSearchModal
            onClose={
              /* istanbul ignore next */ () => {
                setIsPositionFilterApplied(false);
              }
            }
          />
        )}

        <MotionBox data-testid="SidebarView" css={seekersListItems} onScroll={handleScroll}>
          {
            /* istanbul ignore next */ seekerList && !shouldRedirect && (
              <Box height="80%" className="noCandidateMsg">
                <Box>
                  <Flex direction="column" data-testid="NoSearchCandidates" align="center">
                    <Image src="/search.svg" w="108px" h="76px" alt="No Search Candidates" />
                    <Text color="#90A4AE" fontSize="14px" mt="5">
                      <b>{t('searchResultMsg')}</b>
                    </Text>
                  </Flex>
                </Box>
              </Box>
            )
          }
          {group === SeekerListGroupType.BUSINESS_LOCATION ? <BusinessCandidateList /> : <NewSeekerList />}
        </MotionBox>
      </Box>
    );
  }
  // istanbul ignore next
  if (!candidate) {
    return <NotAuthorizedView isAuthorizationStateLoaded />;
  }
  // istanbul ignore next
  return <Box />;
};
