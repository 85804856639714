import { Stack, HStack, Button, Box } from '@chakra-ui/core';
import React from 'react';
import { HiTrash } from 'react-icons/hi';
import { formBuilderSchemas } from '../../formBuilderSchemas/formBuilder.schema';
import DeletePopover from '../advancedElements/DeletePopover';
import { SchemaBuilderProps, FieldSchema } from '../formBuilderSchema';
import FollowUpOptionSelect from './FollowUpOptionSelect';
import FollowUpEmptyComponent from './FollowUpEmptyComponent';
import theme from '../../../../../styles/customTheme';

interface FollowUpQuestionSchemaBuilderProps extends Omit<SchemaBuilderProps, 'onChange'> {
  followUpOptionId: string;
  followUpOptions: string[];
  setFollowUpOptions: (followUps: string[]) => void;
  index: number;
  onChange: (field: FieldSchema) => void;
}

export default function FollowUpQuestionSchemaBuilder({
  defaultValue: parentDefaultValue,
  onChange,
  language,
  followUpDragOver,
  followUpOptionId,
  followUpOptions,
  setFollowUpOptions,
  path: parentPath,
  index,
  isAdvanced,
  sectionIndex,
  followUpLabel,
}: FollowUpQuestionSchemaBuilderProps) {
  const followUpQuestions = parentDefaultValue?.followUpQuestionList?.filter((value) => value?.optionId === followUpOptionId);

  const path = [...parentPath, index];
  return (
    <Stack
      key={followUpOptionId}
      sx={{
        '&:hover .follow-up-option': {
          display: 'inline-flex',
        },
        backgroundColor: '#f9fcff99',
        border: `1px solid #e5eef7`,
        padding: theme.space[4],
        borderRadius: theme.radii.md,
      }}
    >
      <HStack justify="space-between">
        <HStack>
          <Button variant="link" fontSize={12} fontWeight={'normal'}>
            Show next question on:
          </Button>
          <FollowUpOptionSelect
            followUpOptions={followUpOptions}
            parentDefaultValue={parentDefaultValue}
            onChange={onChange}
            followUpOptionId={followUpOptionId}
            setFollowUpOptions={setFollowUpOptions}
            isAdvanced={isAdvanced ?? false}
          />
        </HStack>
        {!isAdvanced && (
          <DeletePopover
            popoverTrigger={
              <Button
                colorScheme="red"
                style={{ aspectRatio: '1/1' }}
                padding={1}
                size="sm"
                variant="ghost"
                className="follow-up-option"
              >
                <HiTrash />
              </Button>
            }
            onYes={() => {
              const newFormSchema = { ...parentDefaultValue };
              newFormSchema.followUpQuestionList?.splice(index, 1);
              onChange(newFormSchema);

              setFollowUpOptions([...followUpOptions].filter((_, i) => i !== index));
            }}
          />
        )}
      </HStack>
      {followUpQuestions?.map((item) => (
        <FollowupElements
          key={item.id}
          item={item}
          onChange={onChange}
          parentDefaultValue={parentDefaultValue}
          index={index}
          followUpOptionId={followUpOptionId}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
          sectionIndex={sectionIndex}
          fieldIndex={0}
          followUpLabel={followUpLabel}
          isAdvanced={isAdvanced}
        />
      ))}
      {!isAdvanced && (
        <FollowUpEmptyComponent
          followUpOptionId={followUpOptionId}
          defaultValue={parentDefaultValue}
          onChange={onChange}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
          sectionIndex={sectionIndex}
          fieldIndex={index}
          followUpLabel={followUpLabel}
        />
      )}
    </Stack>
  );
}

interface FollowupElementsProps extends Omit<SchemaBuilderProps, 'defaultValue'> {
  item: FieldSchema;
  parentDefaultValue: FieldSchema;
  index: number;
  followUpOptionId: string;
}
function FollowupElements({
  item,
  onChange,
  parentDefaultValue,
  index,
  followUpOptionId,
  language,
  followUpDragOver,
  path,
  sectionIndex,
  followUpLabel,
  isAdvanced,
}: FollowupElementsProps) {
  const Component = formBuilderSchemas?.[item?.inputType as keyof typeof formBuilderSchemas]?.component;
  if (!Component) return null;
  return (
    <Box position="relative">
      {!isAdvanced && (
        <DeletePopover
          popoverTrigger={
            <Button
              colorScheme="red"
              style={{ aspectRatio: '1/1' }}
              padding={1}
              size="sm"
              variant="ghost"
              className="follow-up-option"
              // display="none"
              position="absolute"
              right={0}
              zIndex={1}
            >
              <HiTrash />
            </Button>
          }
          onYes={() => {
            const newFormSchema = { ...parentDefaultValue };
            const ind = newFormSchema.followUpQuestionList?.findIndex((v) => v.id === item.id) ?? -1;
            newFormSchema.followUpQuestionList?.splice(ind, 1);

            onChange(newFormSchema);

            // setFollowUpOptions([...followUpOptions].filter((_, i) => i !== index));
          }}
        />
      )}

      <Component
        defaultValue={item!}
        onChange={(value) => {
          const newFollowUpQuestion = [...(parentDefaultValue?.followUpQuestionList ?? [])];
          const index = newFollowUpQuestion?.findIndex((v) => v.id === item.id) ?? -1;
          if (index >= 0) {
            newFollowUpQuestion[index] = { ...newFollowUpQuestion[index], ...value };
          }

          const result = {
            ...parentDefaultValue,
            followUpQuestionList: newFollowUpQuestion,
          };
          onChange(result);
        }}
        language={language}
        followUpDragOver={followUpDragOver}
        path={path}
        sectionIndex={sectionIndex}
        fieldIndex={index}
        followUpLabel={followUpLabel}
      />
    </Box>
  );
}
