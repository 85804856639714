import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import TextSchemaBuilder from './TextSchemaBuilder';

interface FormElementTextBuilderProps extends SchemaBuilderProps {
  placeholder?: string;
  startWithEditView?: boolean;
}

export default function FormElementTextBuilder({
  onChange,
  defaultValue,
  language,
  placeholder,
  startWithEditView,
  sectionIndex,
  fieldIndex,
  ...props
}: FormElementTextBuilderProps) {
  const path = [...props.path, 'text'];
  const handleChange = (value: string) => {
    const changedValue = { ...defaultValue, text: { ...defaultValue?.text!, [language]: value } };
    onChange(changedValue);
  };

  return (
    <TextSchemaBuilder
      label={defaultValue?.text?.[language]!}
      placeholder={placeholder}
      onLabelChange={handleChange}
      startWithEditView={startWithEditView}
      language={language}
      path={path}
    />
  );
}
