import { HStack, Text, useToast } from '@chakra-ui/core';
import { IoMoveSharp } from 'react-icons/io5';
import { formBuilderSchemas } from '../../formBuilderSchemas/formBuilder.schema';
import { EmptyComponent } from '../EmptyComponent';
import { SchemaBuilderProps, FieldSchema, formBuilderType, MAX_FOLLOW_UP_DEPTH } from '../formBuilderSchema';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import isArray from 'lodash/isArray';

interface FollowUpEmptyComponentProps extends Omit<SchemaBuilderProps, 'onChange'> {
  followUpOptionId: string;
  onChange: (field: FieldSchema) => void;
}

export default function FollowUpEmptyComponent({
  defaultValue: parentDefaultValue,
  onChange,
  followUpDragOver,
  followUpOptionId,
  followUpLabel,
}: FollowUpEmptyComponentProps) {
  const followUpQuestions = parentDefaultValue.followUpQuestionList || [];
  const toast = useToast();
  return (
    <EmptyComponent
      onDrop={(event) => {
        const type = event.dataTransfer.getData('type');
        if (followUpLabel >= MAX_FOLLOW_UP_DEPTH && type === formBuilderType.yesNo) {
          toast({
            description: `Only ${MAX_FOLLOW_UP_DEPTH} nested level of follow-ups question is allowed`,
            status: 'error',
            isClosable: true,
          });
          return;
        }
        const schema = formBuilderSchemas[type as keyof typeof formBuilderSchemas];

        if (schema.panel === 'advanced' || isArray(schema.defaultValue)) return;
        const defaultValue = schema.defaultValue;

        // const index = followUpQuestions?.findIndex((v) => v.optionId === followUpOptionId) ?? -1;

        const newFollowUpQuestion = [
          ...followUpQuestions,
          {
            ...defaultValue,
            id: uuidv4(),
            parent: parentDefaultValue.id,
            optionId: followUpOptionId,
            timestamp: Date.now(),
            isFollowUp: true,
          },
        ];
        //   newFollowUpQuestion[index] = {
        //     ...defaultValue,
        //     id: uuidv4(),
        //     parent: parentDefaultValue.id,
        //     optionId: followUpOptionId,
        //     timestamp: Date.now(),
        //     isFollowUp: true,
        //   };
        const newSchema = {
          ...parentDefaultValue,
          followUpQuestionList: newFollowUpQuestion,
        };

        onChange(newSchema);
        // if (index >= 0) {
        //   const newFollowUpQuestion = [
        //     ...followUpQuestions,
        //     {
        //       ...defaultValue,
        //       id: uuidv4(),
        //       parent: parentDefaultValue.id,
        //       optionId: followUpOptionId,
        //       timestamp: Date.now(),
        //       isFollowUp: true,
        //     },
        //   ];
        //   //   newFollowUpQuestion[index] = {
        //   //     ...defaultValue,
        //   //     id: uuidv4(),
        //   //     parent: parentDefaultValue.id,
        //   //     optionId: followUpOptionId,
        //   //     timestamp: Date.now(),
        //   //     isFollowUp: true,
        //   //   };
        //   const schema = {
        //     ...parentDefaultValue,
        //     followUpQuestion: newFollowUpQuestion,
        //   };

        //   onChange(schema);
        // } else {
        //   const schema = {
        //     ...parentDefaultValue,
        //     followUpQuestionList: [
        //       ...followUpQuestions,
        //       { ...defaultValue, id: uuidv4(), parent: parentDefaultValue.id, optionId: followUpOptionId, timestamp: Date.now() },
        //     ],
        //   };
        //   onChange(schema);
        // }
      }}
      onDragOver={followUpDragOver}
    >
      <HStack
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#586073"
        align="center"
        justify="center"
        flexGrow={1}
      >
        <IoMoveSharp size={24} />
        <Text fontSize="sm" fontWeight="bold">
          Drag or Click on the items on your left to add a follow up.
        </Text>
      </HStack>
    </EmptyComponent>
  );
}
