import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { BsClipboardCheck } from 'react-icons/bs';

import theme from '../../../../styles/customTheme';

export const tags = [
  '[first_name]',
  '[last_name]',
  '[email_address]',
  '[business_name]',
  '[position_name]',
  '[position_start_date]',
  '[phone_number]',
];

interface FormBuilderTagsProps {
  search: string;
}

export default function FormBuilderTags({ search }: FormBuilderTagsProps) {
  return (
    <Box>
      <Stack>
        {tags
          ?.filter((tag) => tag.toLowerCase().includes(search.toLowerCase()))
          .map((tag) => (
            <CopyTooltip key={tag} copyText={tag} gutter={45} placement="bottom-end">
              <Box
                cursor="copy"
                key={tag}
                title="Copy to clipboard"
                background={'#F3F6F9'}
                borderRadius={theme.radii.md}
                paddingX={theme.space[4]}
                paddingY={theme.space[2]}
                _hover={{ backgroundColor: '#ebeef1' }}
              >
                <HStack justify="space-between">
                  <Box>
                    <Text fontSize="sm" fontWeight="bold">
                      {tag}
                    </Text>
                  </Box>
                  <MdContentCopy />
                </HStack>
              </Box>
            </CopyTooltip>
          ))}
      </Stack>
    </Box>
  );
}

interface CopyTooltipProps extends PopoverProps {
  copyText: string;
}

const CopyTooltip = ({ children, copyText, ...props }: CopyTooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      copyToClipboard(copyText);
      setTimeout(() => {
        close();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Box position="relative">
      <Popover autoFocus={false} size="sm" placement="bottom" {...props} isOpen={isOpen} onOpen={open} onClose={close}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent borderRadius={theme.radii.md} color={theme.colors.green[800]} maxWidth={'fit-content'}>
          <PopoverArrow />
          <PopoverBody>
            <HStack>
              <BsClipboardCheck size={20} />
              <Text fontWeight="bold" fontSize="sm">
                Copied to clipboard
              </Text>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

function copyToClipboard(text: string) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.style.position = 'absolute';
  textarea.style.left = '-1000px';
  textarea.style.top = '-1000px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}
