import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreState } from '../../../models/hooks';
import { PATH_MAIN } from '../../../routes/constants';

const useCheckIfSmbScreen = () => {
  const history = useHistory();
  const account = useStoreState((state) => state.app.accounts);
  const isSMB = useStoreState((state) => state.app.accounts?.smb);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (account === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
      if (!isSMB) {
        history.push(PATH_MAIN);
      }
    }
  }, [account, isSMB, history]);

  return { loading };
};

export default useCheckIfSmbScreen;
