export const isCandidatePage = () => {
  const location = window.location.href;
  return location.includes('/candidate/');
};
export const isCandidatesPage = () => {
  const location = window.location.href;
  return location.includes('/candidates/');
};

export const isCandidateOnDashboard = () => {
  const location = window.location.href;
  return location.includes('/dashboard');
};

export const isPositionCandidatesPage = () => {
  const location = window.location.href;
  return location.includes('/positionCandidates/');
};

export const isNewSeekerListPage = () => {
  const location = window.location.href;
  const pages = ['/newMessaging', '/newCandidates'];
  return pages.some((page) => location.includes(page));
};
