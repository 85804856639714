import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  HStack,
  Button,
  ModalFooter,
  Box,
} from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ImageView from './ImageView';
import colors from '../../styles/colors';
import DraggableScroll from './DraggableScroll';

interface ImagePreviewModalBaseProps<T> {
  images?: T[];
  selectedImage?: T | null;
  setSelectedImage?: (image: T | null) => void;
  getImageKey: (image: T | null) => string;
  getImageSrc: (image: T | null) => string;
  getImageAlt?: (image: T | null) => string;
  currentActive?: T | null;
}

interface ImagePreviewModalWithNodeTrigger<T> extends ImagePreviewModalBaseProps<T> {
  setTrigger: JSX.Element;
  onModalClose?: () => void;
}

interface ImagePreviewModalWithBooleanTrigger<T> extends ImagePreviewModalBaseProps<T> {
  setTrigger: boolean;
  onModalClose: () => void;
}
export type ImagePreviewModalProps<T> = ImagePreviewModalWithNodeTrigger<T> | ImagePreviewModalWithBooleanTrigger<T>;

/**
 * A modal for previewing images.
 *
 * The modal shows an image gallery, where the user can select an image by clicking on the image.
 * The selected image is highlighted with a border.
 * The modal also shows a close button on the top right corner.
 * The modal can be triggered by a boolean, or a node element.
 *
 * @param {ImagePreviewModalProps<T>} props The props for the modal.
 * @param {T[]} props.images The images to be displayed.
 * @param {(image: T | null) => string} props.getImageKey A function that returns the key of the image.
 * @param {(image: T | null) => string} props.getImageSrc A function that returns the src of the image.
 * @param {(image: T | null) => string} [props.getImageAlt] A function that returns the alt of the image.
 * @param {() => void} [props.onModalClose] A callback function invoked when the modal is closed.
 * @param {T | null} [props.selectedImage] The currently selected image.
 * @param {(image: T | null) => void} [props.setSelectedImage] The callback function invoked when an image is selected.
 * @param {T | null} [props.currentActive] The currently active image.
 * @returns {React.ReactElement} The React component.
 */
export function ImagePreviewModal<T extends unknown | any>({
  setTrigger,
  images,
  getImageKey,
  getImageSrc,
  getImageAlt,
  onModalClose,
  selectedImage,
  setSelectedImage,
  currentActive,
}: ImagePreviewModalProps<T>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentActiveImage, setCurrentActiveImage] = React.useState<T | null>(null);
  const [error, setError] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const isSingleImage = images?.length === 1;
  const isTriggerElement = React.isValidElement(setTrigger);
  const isSelectedImage = getImageKey(selectedImage ?? null) === getImageKey(currentActiveImage);

  useEffect(() => {
    if (currentActive) {
      setCurrentActiveImage(currentActive);
    }
  }, [currentActive]);

  function handleTirggerClick() {
    if (!error) {
      onOpen();
    }
  }

  useEffect(() => {
    if (!isTriggerElement && setTrigger) {
      handleTirggerClick();
    }
    // eslint-disable-next-line
  }, [isTriggerElement, setTrigger]);

  useEffect(() => {
    setCurrentActiveImage(selectedImage ?? images?.[0] ?? null);
    // eslint-disable-next-line
  }, [images, selectedImage]);

  function handleModalClose() {
    onClose();
    if (onModalClose && !isTriggerElement) onModalClose();
  }

  function handleImageSelection(image: T) {
    setSelectedImage?.(getImageKey(image) === getImageKey(selectedImage ?? null) ? null : image);
  }

  const triggerElement =
    isTriggerElement &&
    React.cloneElement(setTrigger as React.ReactElement<any>, {
      onError: () => {
        setError(true);
      },
      onLoad: () => {
        setError(false);
      },
    });

  if (images?.length === 0) {
    return null;
  }

  return (
    <>
      {isTriggerElement && (
        <Center onClick={handleTirggerClick} data-testid="open-modal-button" width="fit-content">
          {triggerElement}
        </Center>
      )}

      <Modal isCentered isOpen={isOpen} onClose={handleModalClose} size="3xl" autoFocus={false} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent h="auto" w="auto" paddingBottom={2} data-testid="image-preview-modal">
          <ModalHeader display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box>{t('administration:imageGallery:imagePreviewTitle')}</Box>
            <HStack spacing={2}>
              {setSelectedImage && (
                <Button
                  variant={isSelectedImage ? 'solid' : 'outline'}
                  size="sm"
                  colorScheme={isSelectedImage ? 'green' : 'gray'}
                  onClick={() => handleImageSelection(currentActiveImage as T)}
                  leftIcon={isSelectedImage ? <FaCheck /> : undefined}
                  data-testid="select-image"
                >
                  {isSelectedImage ? 'Selected' : 'Select'}
                </Button>
              )}
              <ModalCloseButton position="relative" top={0} right={0} data-testid="close-modal-button" />
            </HStack>
          </ModalHeader>
          <ModalBody padding={0}>
            <Center>
              <ImageView
                src={getImageSrc(currentActiveImage)}
                alt={getImageAlt?.(currentActiveImage)}
                height="500px"
                objectFit="contain"
                wrapperProps={{ style: { aspectRatio: 'unset' } }}
                dataTestId="preview-image"
              />
            </Center>
          </ModalBody>
          {!isSingleImage && (
            <ModalFooter justifyContent="center">
              <DraggableScroll scrollToIndex={images?.indexOf(currentActiveImage as T)}>
                <HStack spacing={2}>
                  {images?.map((image) => {
                    const isActive = getImageKey(image) === getImageKey(currentActiveImage);
                    const isSelected = getImageKey(image) === getImageKey(selectedImage ?? null);
                    return (
                      <Box
                        key={getImageKey(image)}
                        padding={0.5}
                        borderRadius="lg"
                        position="relative"
                        border={isActive ? '1px solid' : undefined}
                        borderColor={isActive ? colors.blue[400] : undefined}
                      >
                        {isSelected && (
                          <FaCheckCircle color={colors.green[400]} style={{ position: 'absolute', top: 5, left: 5, zIndex: 1 }} />
                        )}
                        <ImageView
                          onClick={() => {
                            setCurrentActiveImage(image);
                          }}
                          src={getImageSrc(image)}
                          alt={getImageAlt?.(image)}
                          height={100}
                          dataTestId="image-thumbnail"
                        />
                      </Box>
                    );
                  })}
                </HStack>
              </DraggableScroll>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
