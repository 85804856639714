import { Input, InputProps } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';

interface InputFieldProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: string) => void;
}
const TextField = React.forwardRef<HTMLInputElement, InputFieldProps>(function ({ onChange, ...props }, ref) {
  return (
    <Input
      ref={ref}
      variant="filled"
      backgroundColor="#F3F6F9"
      _hover={{ backgroundColor: '#ebeef1' }}
      fontSize="sm"
      size="md"
      borderRadius={theme.radii.md}
      {...props}
      onChange={(e) => onChange?.(e.target.value)}
      _placeholder={{ color: '#4A5568' }}
    />
  );
});

export default TextField;
