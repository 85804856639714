import React, { useContext } from 'react';
import { LocalAudioTrack, LocalVideoTrack, Room } from 'twilio-video';
import _noop from 'lodash/noop';
import { useParams } from 'react-router-dom';
import { useRoom } from './twilio/hooks/useRoom';
import { useLocalTracks } from './twilio/hooks/useLocalTracks';
import { useRoomDisconnect } from './twilio/hooks/useRoomDisconnect';
import { usePublicationFailed } from './twilio/hooks/usePublicationFailed';
import { useActiveSinkId } from './twilio/hooks/useActiveSinkId';
import { useScreenShareToggle } from './twilio/hooks/useScreenShareToggle';
import { useMeet } from './twilio/hooks/useMeet';
import { GroupEventMeetParams, MeetTypeEnum, MessagingTabsParams } from '../../routes/constants';
import { useAudioReconnect } from './twilio/hooks/useAudioReconnect';
import { useConnectionOptions } from './twilio/hooks/useConnectionOptions';
import { ParticipantData } from './MeetTokenResponse';

export type MeetContextValueType = {
  room: Room | null;
  isConnecting: boolean;
  connect: (token: string) => void;
  disconnect: () => void;
  activeSinkId: string | null;
  setActiveSinkId: (sinkId: string) => void;
  isLoadingLocalTracks: boolean;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  getLocalAudioTrack: () => Promise<LocalAudioTrack>;
  getLocalVideoTrack: () => Promise<LocalVideoTrack>;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  getAudioAndVideoTracks: () => void;
  isSharingScreen: boolean;
  toggleScreenSharing: () => void;
  joinRoom: () => void;
  quitRoom: () => void;
  joinGroupEventRoom: (participantInfo: ParticipantData) => void;
  quitGroupEventRoom: () => void;
  joinedByAnotherRecruiterName: string | undefined;
};

// istanbul ignore next
const DEFAULT_VIDEO_CONTEXT: MeetContextValueType = {
  room: null,
  isConnecting: false,
  connect: _noop,
  disconnect: () => _noop,
  activeSinkId: null,
  setActiveSinkId: _noop,
  localTracks: [],
  isLoadingLocalTracks: false,
  getLocalAudioTrack: () => Promise.resolve({} as LocalAudioTrack),
  getLocalVideoTrack: () => Promise.resolve({} as LocalVideoTrack),
  removeLocalVideoTrack: _noop,
  removeLocalAudioTrack: _noop,
  getAudioAndVideoTracks: () => _noop,
  isSharingScreen: false,
  toggleScreenSharing: _noop,
  joinRoom: () => _noop,
  quitRoom: () => _noop,
  joinGroupEventRoom: () => _noop,
  quitGroupEventRoom: () => _noop,
  joinedByAnotherRecruiterName: '',
};

const MeetContext = React.createContext<MeetContextValueType>(DEFAULT_VIDEO_CONTEXT);

export const MeetContextProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let token: string;
  let type: MeetTypeEnum;

  const paramsData = useParams<GroupEventMeetParams & MessagingTabsParams>();

  if (paramsData.token) {
    token = paramsData.token;
    type = MeetTypeEnum.EVENT;
  } else {
    token = paramsData.candidateId;
    type = MeetTypeEnum.INTERVIEW;
  }

  const { activeSinkId, setActiveSinkId } = useActiveSinkId();
  const {
    localTracks,
    isLoadingLocalTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, connect, disconnect, isConnecting } = useRoom(localTracks, useConnectionOptions());
  const { isSharingScreen, toggleScreenSharing } = useScreenShareToggle(room);

  useRoomDisconnect(room, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenSharing);
  usePublicationFailed(room);
  useAudioReconnect(localTracks);

  const { joinRoom, quitRoom, joinGroupEventRoom, quitGroupEventRoom, joinedByAnotherRecruiterName } = useMeet({
    connect,
    disconnect,
    meetToken: token,
    meetType: type,
  });

  return (
    <MeetContext.Provider
      value={{
        room,
        isConnecting,
        connect,
        disconnect,
        activeSinkId,
        setActiveSinkId,
        localTracks,
        isLoadingLocalTracks,
        getLocalAudioTrack,
        getLocalVideoTrack,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        getAudioAndVideoTracks,
        isSharingScreen,
        toggleScreenSharing,
        joinRoom,
        quitRoom,
        joinGroupEventRoom,
        quitGroupEventRoom,
        joinedByAnotherRecruiterName,
      }}
    >
      {children}
    </MeetContext.Provider>
  );
};

export const useMeetContext = (): MeetContextValueType => useContext(MeetContext);
