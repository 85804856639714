import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../../styles/colors';
import { JobPosition, JobType } from '../PositionType';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

export type AdvanceFieldsProps = {
  jobPositionData: JobPosition;
  jobList: JobType[];
  handleSkillChange: (value: number, id: number) => void;
};

export const AdvanceFieldsCard = ({ jobPositionData, jobList, handleSkillChange }: AdvanceFieldsProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const listSkills = () => {
    // eslint-disable-next-line radix
    const filterJob = jobList?.filter((job) => job.id === parseInt(jobPositionData.job))[0];
    return (
      filterJob.skills.length > 0 &&
      filterJob.skills.map((skill, index) => (
        <Box w="50%" pr={index % 2 === 0 ? 8 : 0} key={skill.id} mb={4}>
          <FormControl>
            <FormLabel htmlFor={`skill-${skill.id}`}>{getTranslatedValue(skill.name, i18n.language)}</FormLabel>
            <Slider
              value={
                jobPositionData?.skills?.length
                  ? jobPositionData?.skills?.filter((skl) => skl?.id === skill?.id)?.[0]?.value
                  : 0.5
              }
              min={0}
              max={1}
              step={0.1}
              data-testid={`SkillSlider-${skill.id}`}
              id={`skill-${skill.id}`}
              onChange={/* istanbul ignore next */ (value: number) => handleSkillChange(value, skill.id)}
            >
              <SliderTrack bg="rgba(48, 127, 226, 0.3)" h="8px" borderRadius="3px">
                <Box position="relative" right={10} />
                <SliderFilledTrack bg="blue.500" />
              </SliderTrack>
              <SliderThumb bg="blue.500" boxSize={6} />
            </Slider>
          </FormControl>
        </Box>
      ))
    );
  };
  const enableNewUiForCreatePosition: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION];
  });

  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      <Accordion borderWidth={0} defaultIndex={[0]} allowToggle>
        <AccordionItem borderWidth={0} borderColor="#fff" isDisabled={!jobPositionData.job}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {t('createJobPosition:advanceAccordionLabel')}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0} borderWidth={0}>
            <Divider borderColor="#ECEFF1" />
            <Box px={6} py={4}>
              <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
                {t('createJobPosition:skillsCardHeader')}
              </Heading>
              <Text fontSize="xs" color="gray.500">
                {t('createJobPosition:skillsCardHint')}
                <span style={{ color: colors.blue['500'], marginLeft: '5px' }}>
                  {t('createJobPosition:skillsCardHintSlideText')}
                </span>
              </Text>
            </Box>
            <Divider borderColor="#ECEFF1" />
            <Box p={/* istanbul ignore next */ enableNewUiForCreatePosition ? 3 : 6}>
              <Flex flexWrap="wrap">{jobList && jobPositionData?.job && listSkills()}</Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
