import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { HStack, Stack } from '@chakra-ui/core';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function AddressSchemaBuilder(props: SchemaBuilderProps) {
  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder="Enter address text here..." />
      <InputPreview value={props?.defaultValue?.previewPlaceholders?.placeholder ?? 'Address'} />
    </Stack>
  );
}
