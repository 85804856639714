/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/core';
import { StarIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import _includes from 'lodash/includes';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { MdOutlineViewAgenda, MdTimeline } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import isUndefined from 'lodash/isUndefined';
import useBackgroundChecks from '../../../../../app/hooks/useBackgroundChecks';
import {
  EngagedCandidate,
  OffboardingReason,
  ResubmissionRequestFields,
} from '../../../../../firebase/firestore/documents/candidate';
import { CandidateHistoryActionName } from '../../../../../firebase/firestore/documents/candidateAuditActionNames';
import { Lookups } from '../../../../../firebase/firestore/documents/lookups';
import { Requisition, Shifts } from '../../../../../firebase/firestore/documents/requisition';
import { useStoreState } from '../../../../../models/hooks';
import colors from '../../../../../styles/colors';
import { Label } from '../../../../common';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { tableStyle } from '../../header/actionsDropdown/HireWithRequisitionStyle';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { CandidateAuditStatusIcon, CandidateAuditStatusIconAutomated } from './CandidateAuditStatusIcon';
import { CollaboratorInterviewRatingView } from './CollaboratorInterviewRatingView';
import { historyViewWrapper } from './History.styles';
import { HistoryPresenter } from './historyPresenter';
import { HistoryTypes, HistoryViewTypes } from './HistoryTypes';
import useContactRequestDeclineReasons from './useContactRequestDeclinedReasons';
import useHistory, { HistoryStateEnum } from './useHistory';
import { ViewRequisitionDetail } from './ViewRequisitionDetail';
import useLookupsByType from '../../../../../app/hooks/useLookupsByType';
import { getGradientBackground } from '../../../../../utils/uiUtils';

const MotionStack = motion.custom(Stack);
const MotionBox = motion.custom(Box);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};

const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export type HistoryProps = BoxProps & {
  isCertnFeatureEnabled?: boolean;
  isCertnEnabled?: boolean;
  candidateFromTopCandidate?: EngagedCandidate | undefined;
};

enum AvailabilityTypes {
  DAY = 'Day',
  NIGHT = 'Night',
}

export const History = ({
  className = '',
  padding = 2,
  isCertnFeatureEnabled,
  isCertnEnabled,
  candidateFromTopCandidate,
}: HistoryProps): JSX.Element | null => {
  const { t, i18n } = useTranslation('candidateHistory');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCollaboratorFeedback,
    onOpen: onOpenCollaboratorFeedback,
    onClose: onCloseCollaboratorFeedback,
  } = useDisclosure();

  const { isOpen: isAvailabilityViewOpen, onOpen: onOpenAvailabilityView, onClose: onCloseAvailabilityView } = useDisclosure();

  const { initialized, histories, state } = useHistory(candidateFromTopCandidate);
  const [historyViewType, setHistoryViewType] = React.useState<HistoryViewTypes>(HistoryViewTypes.COMPACT);
  const [activeHistoryActions, setActiveHistoryActions] = React.useState<string>();
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [selectedHistory, setSelectedHistory] = React.useState<string>('');
  const [hireDetailsResubmissionRequest, setHireDetailsResubmissionRequest] = React.useState<
    ResubmissionRequestFields[] | undefined
  >([]);

  const [availabilityType, setAvailabilityType] = useState<AvailabilityTypes>(AvailabilityTypes.DAY);
  const [availability, setAvailability] = useState<{ [key: string]: { start_1: string; end_1: string } } | undefined>(undefined);
  const [nightAvailability, setNightAvailability] = useState<{ [key: string]: { start_1: string; end_1: string } } | undefined>(
    undefined,
  );

  const [expectedWorkHour, setExpectedWorkHour] = useState<{ unit: string; value: string } | undefined>(undefined);
  const [offeredSalary, setOfferedSalary] = useState<{ salaryUnit: string; salary: string } | undefined>(undefined);
  const [requisition, setRequisition] = React.useState<Requisition | undefined>(undefined);
  const [shifts, setShifts] = React.useState<Shifts | undefined>(undefined);

  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();

  const backgroundChecks = useBackgroundChecks();
  const declinedReasonsTypes = useContactRequestDeclineReasons();
  const offerRejectedReasons = useLookupsByType('offerRejectedReason');
  const offboardedReasons = useLookupsByType('offboardReason');
  const offboardedTypes = useLookupsByType('offboardingType');
  const isHireFormEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabled);
  const isModernHireEnable = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);
  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);

  const { selectedCandidate } = useSeekerContainerContext();
  const { selectedEngagedCandidate } = useMessagingViewContext();

  const candidate = selectedCandidate || selectedEngagedCandidate;

  const soquijBackgroundCheck = {
    active: true,
    description: {
      en: `This check isn't available by default. Contact to apisupport@certn.co`,
      fr: `Ce contrôle n'est pas disponible par défaut. Contact à apisupport@certn.co`,
    },
    id: 'request_soquij',
    label: {
      fr: 'SOQUIJ',
      en: 'SOQUIJ',
    },
    order: 90,
    type: 'background_check',
  } as Lookups;

  const handleShowDropDown = (action: any, index: number) => {
    setShowDropdown(!showDropdown);
    setSelectedHistory(action);
    setSelectedIndex(index);
  };

  const handleShowDropDownBlur = () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  };

  const revokeReason = (reasonValue: string | undefined) => {
    const reasonText =
      declinedReasonsTypes.find((reason) => reason?.reasonType === reasonValue)?.text ||
      offerRejectedReasons.find((reason) => reason.id === reasonValue)?.label;

    return reasonText && getTranslatedValue(reasonText, i18n.language);
  };

  const offboardingReason = (reasonValue: string | undefined) => {
    const reasonText = offboardedReasons.find((reason) => reason.id === reasonValue)?.label;
    return reasonText && getTranslatedValue(reasonText, i18n.language);
  };

  const offboardingType = (typeValue: string | undefined) => {
    const typeReason = offboardedTypes.find((type) => type.id === typeValue)?.label;
    return typeReason && getTranslatedValue(typeReason, i18n.language);
  };

  const onGetHistoryBody = (historyItem: HistoryPresenter) => {
    if (historyItem.action === CandidateHistoryActionName.APPOINTMENT_REQUEST_CANCELLED) {
      return (
        <Text fontSize="sm" style={{ width: '190px', color: '#000' }}>
          {t('APPOINTMENT_REQUEST_DECLINED', {
            appointmentType: historyItem.appointmentType ? historyItem.appointmentType.toUpperCase() : ' ',
          })}
        </Text>
      );
    }

    if (historyItem.action === CandidateHistoryActionName.INTEREST_REVOKED) {
      return (
        <Text fontSize="sm" style={{ width: '190px', color: '#000' }}>
          {t(`${CandidateHistoryActionName.INTEREST_REVOKED}`)}
          <Box fontSize="sm" color="gray.400">
            {revokeReason(historyItem.interestRevokeReason)}
          </Box>
          <Box fontSize="xs" color="gray.400" fontWeight="normal">
            {historyItem.interestRevokeReason === 'OTHER' && historyItem.interestRevokeReasonComment}
          </Box>
        </Text>
      );
    }

    if (historyItem.action === CandidateHistoryActionName.OFFER_REVOKE) {
      return (
        <Text fontSize="sm" style={{ width: '190px', color: '#000' }}>
          {t(`${CandidateHistoryActionName.OFFER_REVOKE}`)}
          <Text fontSize="sm" color="gray.400">
            {revokeReason(historyItem.rejectedReasonId)}
          </Text>
          <Text fontSize="xs" color="gray.400" fontWeight="normal" noOfLines={1}>
            {historyItem.rejectedReasonId === 'OTHER' && historyItem.reasonComment}
          </Text>
        </Text>
      );
    }

    if (historyItem.action === CandidateHistoryActionName.CANDIDATE_APPROVED) {
      return (
        <Text fontSize="sm" style={{ width: '190px', color: '#000' }}>
          {t(`${CandidateHistoryActionName.CANDIDATE_HIRED}`)}
          <Box fontSize="sm" color="gray.400">
            {t(`${historyItem.action}`)}
          </Box>
        </Text>
      );
    }

    if (historyItem.action === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER) {
      return (
        <Text fontSize="sm" style={{ width: '190px', color: '#000' }}>
          {t(`${CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER}`)}
          <Text fontSize="sm" color="gray.400">
            {revokeReason(historyItem.rejectedReasonId)}
          </Text>
          <Text fontSize="xs" color="gray.400" fontWeight="normal" noOfLines={1}>
            {historyItem.rejectedReasonId === 'OTHER' && historyItem.reasonComment}
          </Text>
        </Text>
      );
    }

    return (
      <Text fontSize="sm" style={{ width: '190px', color: '#000' }}>
        {t(`${historyItem.action}`)}
      </Text>
    );
  };

  const onGetAverageRating = (): number => {
    let avgRating = 0;

    selectedEngagedCandidate?.anonymousRecruiterInterviewReview?.forEach((e) => {
      if (e.rating) {
        avgRating += e.rating;
      }
    });

    const reviewCount = selectedEngagedCandidate?.anonymousRecruiterInterviewReview?.length || 1;
    const average = avgRating / reviewCount;
    return Math.round(average);
  };

  const filteredMessages = (type: HistoryTypes, history: string) => {
    let filteredHistory = true;
    if (HistoryTypes.BACKGROUND_CHECK === type && isCertnFeatureEnabled && isCertnEnabled) {
      filteredHistory =
        history === CandidateHistoryActionName.SCREENING_COMPLETED ||
        history === CandidateHistoryActionName.INVITED_FOR_SCREENING;
    } else if (HistoryTypes.HIRE_FORM === type) {
      filteredHistory =
        history === CandidateHistoryActionName.HIRE_FORM_COMPLETED ||
        history === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED ||
        history === CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED ||
        history === CandidateHistoryActionName.HIRE_FORM_REQUESTED ||
        history === CandidateHistoryActionName.CANDIDATE_APPROVED ||
        history === CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS ||
        history === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS ||
        history === CandidateHistoryActionName.CANDIDATE_REJECTED;
    } else if (HistoryTypes.MODERN_HIRE === type) {
      filteredHistory =
        history === CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_STARTED ||
        history === CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_IN_PROGRESS ||
        history === CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_PASSED ||
        history === CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_FAILED;
    } else if (HistoryTypes.APPOINTMENT === type) {
      filteredHistory =
        history === CandidateHistoryActionName.APPOINTMENT_ASSESSMENT_POSITIVE ||
        history === CandidateHistoryActionName.APPOINTMENT_ASSESSMENT_NEGATIVE ||
        history === CandidateHistoryActionName.SEEKER_APPOINTMENT_REMINDER_SENT ||
        history === CandidateHistoryActionName.APPOINTMENT_REQUEST_CANCELLED ||
        history === CandidateHistoryActionName.APPOINTMENT_REQUEST_CREATED ||
        history === CandidateHistoryActionName.APPOINTMENT_PERIOD_CANCELLED ||
        history === CandidateHistoryActionName.APPOINTMENT_REQUEST_ACCEPTED ||
        history === CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW;
    } else if (HistoryTypes.CANDIDATE === type) {
      filteredHistory =
        history === CandidateHistoryActionName.CANDIDATE_BOOKMARKED ||
        history === CandidateHistoryActionName.CANDIDATE_CALLED ||
        history === CandidateHistoryActionName.CANDIDATE_DISMISSED ||
        history === CandidateHistoryActionName.CANDIDATE_HIRED ||
        history === CandidateHistoryActionName.CANDIDATE_UNHIRED ||
        history === CandidateHistoryActionName.CANDIDATE_UNLOCKED;
    } else if (HistoryTypes.OFFER === type) {
      filteredHistory =
        history === CandidateHistoryActionName.OFFER_MADE ||
        history === CandidateHistoryActionName.OFFER_REJECTED ||
        history === CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER ||
        history === CandidateHistoryActionName.OFFER_REVOKE ||
        history === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER;
    } else if (HistoryTypes.AUDIO_LANGUAGE_EVALUATION === type) {
      filteredHistory =
        history === CandidateHistoryActionName.CANDIDATE_RECORDING_UPLOADED ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_COMPETENT ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_LIMITED ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_REQUESTED ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED ||
        history === CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT ||
        history === CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT ||
        history === CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED ||
        history === CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL ||
        history === CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW ||
        history === CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_EXPERT;
    } else if (isCertnFeatureEnabled && isCertnEnabled) {
      filteredHistory = true;
    } else {
      filteredHistory = !(
        history === CandidateHistoryActionName.SCREENING_COMPLETED || history === CandidateHistoryActionName.INVITED_FOR_SCREENING
      );
    }
    return filteredHistory;
  };

  const onGetNoHistoryMessage = () => {
    return (
      <Center data-testid="noHistory">
        <Flex direction="column" p={3} align="center" textAlign="center">
          <Image src="/checks.svg" w="180px" h="auto" alt="No Background History" />
          <Text mt="8">
            <b>{t('no_history')}</b>
          </Text>
        </Flex>
      </Center>
    );
  };

  // helper function to get day range
  const getDayRange = (day: string) => {
    const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const currentIndex = daysOfWeek.indexOf(day);
    const nextDay = currentIndex < daysOfWeek.length - 1 ? daysOfWeek[currentIndex + 1] : daysOfWeek[0];
    return `${t(`common:days.${day}`)} - ${t(`common:days.${nextDay}`)}`;
  };

  const renderHistoryHeaderBlock = (history: HistoryPresenter) => {
    return (
      <MotionBox
        key={history.id}
        data-testid={history.id}
        variants={childrenVariants}
        onClick={() => {
          if (
            history.actionCategory === activeHistoryActions ||
            history.actionCategory === HistoryTypes.NOT_LOOKING_FOR_WORK ||
            history.actionCategory === HistoryTypes.DEACTIVATED
          ) {
            setActiveHistoryActions(undefined);
          } else {
            setActiveHistoryActions(history.actionCategory);
          }
        }}
      >
        <Flex w="100%" justifyContent="space-between" className="history-block-item">
          <Flex>
            <Box className="icon-wrapper" mr={3} lineHeight={0}>
              <span className="icon-block">
                {history.automation ? (
                  <CandidateAuditStatusIconAutomated status={history.action} />
                ) : (
                  <CandidateAuditStatusIcon status={history.action} />
                )}
              </span>
            </Box>
            <Box>
              <Box fontSize="14px" fontWeight="bold">
                {onGetHistoryBody(history)}
              </Box>
              <Flex pt={1} lineHeight={1.4}>
                <Flex className="history-info-date" justify="flex-end" mr={3}>
                  <Text fontSize="sm">{moment(history.timestamp).locale(i18n.language).format('ll')}</Text>
                </Flex>
                <Flex fontSize="sm" className="history-info-time" justify="flex-end">
                  <Text>{moment(history.timestamp).locale(i18n.language).format('LT')}</Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Box fontSize="xl">
            {!(
              history.actionCategory === HistoryTypes.NOT_LOOKING_FOR_WORK || history.actionCategory === HistoryTypes.DEACTIVATED
            ) && (
              <RiArrowRightSLine
                style={{
                  transform: activeHistoryActions === history.actionCategory ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: '.2s ease all',
                }}
              />
            )}
          </Box>
        </Flex>
      </MotionBox>
    );
  };

  const renderHistoryBlock = (history: HistoryPresenter, idx: number) => {
    return (
      <MotionBox key={history.id} data-testid={history.id} variants={childrenVariants}>
        <Box className={history?.screeningOptions && showDropdown && selectedIndex === idx ? 'cardsmall' : ''}>
          <Flex direction="row" justify="space-between" data-testid="HistoryGrid" className="history-block-item">
            <Box>
              <Box d="flex" alignItems="center">
                <Box className="icon-wrapper" lineHeight={0}>
                  <span className="icon-block">
                    {history.automation ? (
                      <CandidateAuditStatusIconAutomated status={history.action} />
                    ) : (
                      <CandidateAuditStatusIcon status={history.action} />
                    )}
                  </span>
                </Box>
                <Box className="history-info" pl="2">
                  <Heading as="h6" size="sm" lineHeight="1.3">
                    {(history.action === CandidateHistoryActionName.SCREENING_COMPLETED ||
                      history.action === CandidateHistoryActionName.INVITED_FOR_SCREENING) &&
                    isCertnFeatureEnabled &&
                    isCertnEnabled &&
                    history?.screeningOptions ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}
                        justifyContent="center"
                        data-testid="AccordionButtonTest"
                        onClick={() => handleShowDropDown(history.action, idx)}
                        onBlur={() => handleShowDropDownBlur()}
                        tabIndex={0}
                      >
                        {t(`${history.action}`)}
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <RiArrowRightSLine size={24} className="info-bgCheckInitial" style={{ marginLeft: '24px' }} />
                          <RiArrowRightSLine size={24} className="info-bgCheckInitial" style={{ marginLeft: '-18px' }} />
                        </Box>
                      </Box>
                    ) : (
                      onGetHistoryBody(history)
                    )}
                    {!history.automation && (
                      <Text fontSize="sm" style={{ width: '190px', color: 'gray.400' }}>
                        {t(`${history.userFullName}`)}
                      </Text>
                    )}
                    {(history.action === CandidateHistoryActionName.HIRE_FORM_REQUESTED ||
                      history.action === CandidateHistoryActionName.CANDIDATE_APPROVED ||
                      history.action === CandidateHistoryActionName.CANDIDATE_HIRED ||
                      history.action === CandidateHistoryActionName.OFFER_MADE ||
                      history.action === CandidateHistoryActionName.HIRE_FORM_COMPLETED) && (
                      <Box>
                        {history.businessName && (
                          <Text fontSize="sm" style={{ width: '190px', color: 'gray.400' }}>
                            {t(`${history.businessName} `)}
                          </Text>
                        )}
                        {history.positionName && (
                          <Text fontSize="sm" style={{ width: '190px', color: 'gray.400' }}>
                            {t(`${history.positionName}`)}
                          </Text>
                        )}
                        {history.requisitionId && (
                          <Box
                            as="text"
                            _hover={{ color: colors.blue[400], textDecoration: 'underline' }}
                            cursor="pointer"
                            fontSize="sm"
                            color={colors.blue[200]}
                            data-testid="viewRequisitionBtn"
                            width="190px"
                            onClick={() => {
                              setExpectedWorkHour(history?.expectedWorkHour);
                              setOfferedSalary(history?.offeredSalary);
                              setRequisition(history?.requisition);
                              setShifts(history?.shiftIntersection);
                              setAvailability(history?.availability);
                              setNightAvailability(history?.nightAvailabilities);
                              onOpenRequisition();
                            }}
                          >
                            {t(`${history.requisitionId}`)}
                          </Box>
                        )}
                      </Box>
                    )}
                    {history.action === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED &&
                      history.hireDetailsResubmissionRequest &&
                      history.hireDetailsResubmissionRequest.length > 0 && (
                        <Box
                          as="text"
                          _hover={{ color: colors.blue[400], textDecoration: 'underline' }}
                          cursor="pointer"
                          fontSize="sm"
                          color={colors.blue[200]}
                          data-testid="hireDetailsResubmissionRequestBtn"
                          width="190px"
                          onClick={() => {
                            setHireDetailsResubmissionRequest(history?.hireDetailsResubmissionRequest);
                            onOpen();
                          }}
                        >
                          {t('view')}
                        </Box>
                      )}
                    {history.action === CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER &&
                      (history.availability || history.nightAvailabilities) && (
                        <Box
                          as="text"
                          _hover={{ color: colors.blue[400], textDecoration: 'underline' }}
                          cursor="pointer"
                          fontSize="sm"
                          color={colors.blue[200]}
                          data-testid="availabilityViewBtn"
                          width="190px"
                          onClick={() => {
                            setAvailability(history?.availability);
                            setNightAvailability(history?.nightAvailabilities);
                            setExpectedWorkHour(history?.expectedWorkHour);
                            onOpenAvailabilityView();
                          }}
                        >
                          {t('availability')}
                        </Box>
                      )}
                    {history.action === CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW && (
                      <Box>
                        {Array(onGetAverageRating())
                          .fill(null)
                          .map((i: number) => (
                            <StarIcon
                              key={i}
                              mb="0.1rem"
                              boxSize={3}
                              color="#FF6600"
                              style={{ marginRight: '6px', cursor: 'pointer', stroke: '#FF6600' }}
                            />
                          ))}
                      </Box>
                    )}

                    {history.action === CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW && (
                      <Box
                        as="text"
                        _hover={{ color: colors.blue[400], textDecoration: 'underline' }}
                        cursor="pointer"
                        fontSize="sm"
                        color={colors.blue[200]}
                        data-testid="collaboratorRatingBtn"
                        width="190px"
                        onClick={() => {
                          onOpenCollaboratorFeedback();
                        }}
                      >
                        {t('viewReview')}
                      </Box>
                    )}

                    {(history.automation || history.action === CandidateHistoryActionName.OFFBOARDED) && (
                      <Text fontSize="sm" style={{ width: '190px', color: 'gray.400' }}>
                        {t('automation')}
                      </Text>
                    )}
                    {history.action === CandidateHistoryActionName.CANDIDATE_DISMISSED && history.content && (
                      <Text fontSize="xs" style={{ width: '190px', color: 'gray.400' }} fontWeight="400">
                        {history.content}
                      </Text>
                    )}
                    {history.action === CandidateHistoryActionName.OFFER_REJECTED && (
                      <Text fontSize="xs" style={{ width: '190px', color: 'gray.400' }} fontWeight="400">
                        {history.rejectedReason}
                      </Text>
                    )}

                    {history.action === CandidateHistoryActionName.OFFBOARDED && !isUndefined(history.offboardingDetails) && (
                      <Text fontSize="xs" style={{ width: '190px', color: 'gray.400' }} fontWeight="400">
                        {history?.offboardingDetails?.reason === OffboardingReason.OTHER
                          ? history?.offboardingDetails?.customReason
                          : `${offboardingType(history?.offboardingDetails?.type)} - ${offboardingReason(
                              history?.offboardingDetails?.reason,
                            )}`}
                      </Text>
                    )}
                  </Heading>
                </Box>
              </Box>
            </Box>
            <Flex direction="column" minW="90px" pt={1} lineHeight={1.4}>
              <Flex className="history-info-date" justify="flex-end">
                <Text fontSize="sm">{moment(history.timestamp).locale(i18n.language).format('ll')}</Text>
              </Flex>
              <Flex fontSize="sm" className="history-info-time" justify="flex-end">
                <Text>{moment(history.timestamp).locale(i18n.language).format('LT')}</Text>
              </Flex>
            </Flex>
          </Flex>
          {showDropdown &&
            isCertnFeatureEnabled &&
            isCertnEnabled &&
            history?.screeningOptions &&
            selectedHistory === history.action &&
            (history.action === CandidateHistoryActionName.SCREENING_COMPLETED ||
              history.action === CandidateHistoryActionName.INVITED_FOR_SCREENING) && (
              <Box className="cardsmall-content">
                {[...backgroundChecks, soquijBackgroundCheck].map((bc) =>
                  history?.screeningOptions[bc.id] === true && selectedIndex === idx ? (
                    <Box className="cardsmall" key={bc.id}>
                      <div style={{ marginLeft: '32px' }}>{getTranslatedValue(bc.label, i18n.language)}</div>
                    </Box>
                  ) : null,
                )}
              </Box>
            )}
        </Box>
      </MotionBox>
    );
  };

  return (
    <Fragment>
      {state === HistoryStateEnum.LOADING && (
        <Center>
          <Spinner color={colors.blue.default} data-testid="Spinner" />
        </Center>
      )}

      {state === HistoryStateEnum.READY && (
        <MotionStack
          data-testid="History"
          initial="hidden"
          animate="visible"
          variants={parentVariants}
          css={historyViewWrapper}
          py={padding}
          className={['user-selectable', className].join(' ')}
        >
          <Flex alignItems="center" border="1px solid #E5E7EB" borderRadius="4px" mb={2}>
            <Flex
              alignItems="center"
              justifyContent="center"
              fontWeight="700"
              p={1}
              w="50%"
              cursor="pointer"
              onClick={() => setHistoryViewType(HistoryViewTypes.COMPACT)}
              bg={historyViewType === HistoryViewTypes.COMPACT ? '#E9ECF8' : '#fff'}
              color={historyViewType === HistoryViewTypes.COMPACT ? 'blue.500' : 'gray.800'}
            >
              <MdOutlineViewAgenda style={{ marginRight: '5px' }} />
              {t(`compact`)}
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              fontWeight="700"
              p={1}
              w="50%"
              cursor="pointer"
              onClick={() => setHistoryViewType(HistoryViewTypes.TIMELINE)}
              bg={historyViewType === HistoryViewTypes.TIMELINE ? '#E9ECF8' : '#fff'}
              color={historyViewType === HistoryViewTypes.TIMELINE ? 'blue.500' : 'gray.800'}
            >
              <MdTimeline style={{ marginRight: '5px' }} />
              {t(`detailed`)}
            </Flex>
          </Flex>
          {/**/}
          <Box className="history-list-wrapper">
            {histories?.messages.length === 0 && onGetNoHistoryMessage()}
            {initialized &&
              historyViewType === HistoryViewTypes.COMPACT &&
              histories
                ?.getRecentOneHistoryOfEachStatus()
                .filter((historyChild) => {
                  return isModernHireEnable
                    ? filteredMessages(HistoryTypes.ALL_HISTORY, historyChild.action)
                    : !filteredMessages(HistoryTypes.MODERN_HIRE, historyChild.action);
                })
                .filter((historyChild) => {
                  return isHireFormEnabled && _includes(hireFormEnabledPositions, candidate?.position)
                    ? filteredMessages(HistoryTypes.ALL_HISTORY, historyChild.action)
                    : !filteredMessages(HistoryTypes.HIRE_FORM, historyChild.action);
                })
                .map((history) => {
                  const status = history.action in CandidateHistoryActionName;
                  if (!status) {
                    return null;
                  }
                  return (
                    <Fragment key={`parent-${history.id}`}>
                      {renderHistoryHeaderBlock(history)}
                      {activeHistoryActions === history.actionCategory && (
                        <Box p={3} className="sub-cat-histories">
                          {histories?.messages
                            .filter((historyChild) => {
                              return filteredMessages(history.actionCategory as HistoryTypes, historyChild.action);
                            })
                            ?.map((historyChild, idxChild) => {
                              const statusChild = history.action in CandidateHistoryActionName;
                              if (!statusChild) {
                                return null;
                              }
                              return renderHistoryBlock(historyChild, idxChild);
                            })}
                        </Box>
                      )}
                    </Fragment>
                  );
                })}

            {initialized &&
              historyViewType === HistoryViewTypes.TIMELINE &&
              histories?.messages
                .filter((historyChild) => {
                  return isModernHireEnable
                    ? filteredMessages(HistoryTypes.ALL_HISTORY, historyChild.action)
                    : !filteredMessages(HistoryTypes.MODERN_HIRE, historyChild.action);
                })
                .filter((historyChild) => {
                  return isHireFormEnabled && _includes(hireFormEnabledPositions, candidate?.position)
                    ? filteredMessages(HistoryTypes.ALL_HISTORY, historyChild.action)
                    : !filteredMessages(HistoryTypes.HIRE_FORM, historyChild.action);
                })
                .map((history, idx) => {
                  const status = history.action in CandidateHistoryActionName;
                  if (!status) {
                    return null;
                  }
                  return <Fragment key={`parent-${history.id}`}>{renderHistoryBlock(history, idx)}</Fragment>;
                })}
          </Box>
        </MotionStack>
      )}
      {isOpenRequisition && (
        <ViewRequisitionDetail
          isOpen={isOpenRequisition}
          onClose={onCloseRequisition}
          requisition={requisition}
          shouldCompare={!!shifts}
          shiftIntersection={shifts}
          availability={availability}
          expectedWorkHour={expectedWorkHour}
          offeredSalary={offeredSalary}
        />
      )}

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('hireDetailsResubmissionRequestTitle')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text>{t('hireDetailsResubmissionRequestSubTitle')}</Text>
                <UnorderedList mt="10px">
                  {hireDetailsResubmissionRequest?.map((item) => (
                    <ListItem key={item.field}>
                      <Box>
                        <Text fontWeight="700" fontSize="16px">
                          {item.field}
                        </Text>
                        <Text fontWeight="400" fontSize="14px" fontStyle="italic" color={colors.red[500]}>
                          {item.comment}
                        </Text>
                      </Box>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                {t('close')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {isOpenCollaboratorFeedback && (
        <Modal isOpen={isOpenCollaboratorFeedback} onClose={onCloseCollaboratorFeedback} size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="lg">{t('coInterviewerCandidateAssessment')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
              <CollaboratorInterviewRatingView />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onCloseCollaboratorFeedback}>
                {t('close')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isAvailabilityViewOpen && (
        <Modal isOpen={isAvailabilityViewOpen} onClose={onCloseAvailabilityView} size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="lg">{t('availability')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
              <Flex
                alignItems="center"
                justifyContent="center"
                border="1px solid #E5E7EB"
                borderRadius="4px"
                mb={2}
                p={1}
                marginLeft="10px"
                marginRight="10px"
                position="relative"
                height="42px"
                background={getGradientBackground(availabilityType, '4.6')}
                overflow="hidden"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="700"
                  cursor="pointer"
                  flex="1"
                  mr="50px"
                  mt="8px"
                  onClick={() => setAvailabilityType(AvailabilityTypes.DAY)}
                  data-testid="dayBtn"
                  color={availabilityType === AvailabilityTypes.DAY ? 'blue.500' : 'gray.800'}
                >
                  <Text fontSize="14px" fontWeight={700}>
                    {t('common:availability.day')}
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="700"
                  cursor="pointer"
                  flex="1"
                  ml="50px"
                  mb="8px"
                  onClick={() => setAvailabilityType(AvailabilityTypes.NIGHT)}
                  data-testid="nightBtn"
                  color={availabilityType === AvailabilityTypes.NIGHT ? 'blue.500' : 'gray.800'}
                >
                  <Text fontSize="14px" fontWeight={700}>
                    {t('common:availability.night')}
                  </Text>
                </Flex>
              </Flex>

              {/* eslint-disable no-nested-ternary */}
              {availabilityType === AvailabilityTypes.DAY && availability ? (
                <Flex alignItems="baseline" css={tableStyle} style={{ display: 'flex' }} p="12px">
                  <Box w="50%">
                    <table>
                      {['mon', 'tue', 'wed', 'thu', 'fri'].map((day) => {
                        return (
                          <tr key={day}>
                            <td>{t(`common:days.${day}`)}</td>
                            <td style={{ float: 'right' }}>
                              <Flex>
                                {availability[day] ? (
                                  <Text>
                                    {`${moment(availability[day].start_1, ['HH:mm']).format('LT')} - ${moment(
                                      availability[day].end_1,
                                      ['HH:mm'],
                                    ).format('LT')}`}
                                  </Text>
                                ) : (
                                  /* istanbul ignore next */ <Text>---</Text>
                                )}
                              </Flex>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Box>

                  <Box w="50%" ml="6px">
                    <table>
                      {['sun', 'sat'].map((day) => {
                        return (
                          <tr key={day}>
                            <td>{t(`common:days.${day}`)}</td>
                            <td style={{ float: 'right' }}>
                              <Flex>
                                {availability[day] ? (
                                  <Text>
                                    {`${moment(availability[day].start_1, ['HH:mm']).format('LT')} - ${moment(
                                      availability[day].end_1,
                                      ['HH:mm'],
                                    ).format('LT')}`}
                                  </Text>
                                ) : (
                                  /* istanbul ignore next */ <Text>----</Text>
                                )}
                              </Flex>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Box>
                </Flex>
              ) : availabilityType === AvailabilityTypes.NIGHT && nightAvailability ? (
                <Flex alignItems="baseline" css={tableStyle} style={{ display: 'flex' }} p="12px">
                  <Box w="50%">
                    <table>
                      {['mon', 'tue', 'wed', 'thu', 'fri'].map((day) => {
                        return (
                          <tr key={day}>
                            <td>{getDayRange(day)}</td>
                            <td style={{ float: 'right' }}>
                              <Flex>
                                {nightAvailability[day] ? (
                                  <Text>
                                    {`${moment(nightAvailability[day].start_1, ['HH:mm']).format(
                                      'LT',
                                    )} - ${moment(nightAvailability[day].end_1, ['HH:mm']).format('LT')}`}
                                  </Text>
                                ) : (
                                  /* istanbul ignore next */ <Text>---</Text>
                                )}
                              </Flex>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Box>

                  <Box w="50%" ml="6px">
                    <table>
                      {['sun', 'sat'].map((day) => {
                        return (
                          <tr key={day}>
                            <td>{getDayRange(day)}</td>
                            <td style={{ float: 'right' }}>
                              <Flex>
                                {nightAvailability[day] ? (
                                  <Text>
                                    {`${moment(nightAvailability[day].start_1, ['HH:mm']).format(
                                      'LT',
                                    )} - ${moment(nightAvailability[day].end_1, ['HH:mm']).format('LT')}`}
                                  </Text>
                                ) : (
                                  /* istanbul ignore next */ <Text>---</Text>
                                )}
                              </Flex>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Box>
                </Flex>
              ) : (
                <Label p={4} size="sm" color={colors.gray[500]} colorDarkMode={colors.gray['600']} border="1px solid #eceff1">
                  ---
                </Label>
              )}

              {expectedWorkHour && expectedWorkHour.value && (
                <Flex mt="12px" ml={2}>
                  <Text fontSize="14px" fontWeight="700" mr={2}>
                    {t('candidateHistory:expectedWorkHours')}
                  </Text>
                  <Text fontSize="14px" fontWeight="400">
                    {`${expectedWorkHour.value} ${t('common:hrsWeek')}`}
                  </Text>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Fragment>
  );
};
