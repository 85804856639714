import { Checkbox, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { useHireFormContext } from '../HireForm.context';

export default function CheckboxDisable({ defaultValue, language, sectionIndex, fieldIndex }: SchemaBuilderProps) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const [value, setValue] = React.useState(true);

  React.useEffect(() => {
    const currentSection = formSchema[sectionIndex];
    const currentField = currentSection.fields[fieldIndex];
    const disableId = currentField.disabledId;
    const updatedFields = formSchema.map((section, index) => {
      let fields = section.fields;
      if (index === sectionIndex) {
        fields = fields.map((field) => {
          if (currentField.id !== field.id && disableId && disableId === field.disabledId) {
            return { ...field, disabled: !value };
          }
          return field;
        });
      }
      return { ...section, fields };
    });
    setFormSchema(updatedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Stack>
      <Checkbox isChecked={value} onChange={(e) => setValue(e.target.checked)}>
        <Text fontSize="sm">{defaultValue.text?.[language]}</Text>
      </Checkbox>
    </Stack>
  );
}
