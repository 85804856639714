import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import { Divider, HStack, Stack } from '@chakra-ui/core';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import InputPreview from './InputPreview';
import InputMinMaxSelector from './InputMinMaxSelector';

export default function TextFieldSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const textField = props?.defaultValue?.isInfo ?? false;
  return (
    <Stack>
      {!textField && (
        <HStack justify="space-between">
          {!textField && <LabelSchemaBuilder {...props} />}
          <HStack flexShrink={0}>
            <InputMinMaxSelector {...props} />
            <Divider orientation="vertical" />
            {props?.labelRightRenderer}
          </HStack>
        </HStack>
      )}

      <FormElementTextBuilder {...props} />
      <InputPreview value={props?.defaultValue?.previewPlaceholders?.placeholder ?? 'Input field'} />
    </Stack>
  );
}
