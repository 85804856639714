import { action, Action, thunk, Thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { HRFormTemplateController } from '../../../controllers/HRFormTemplateController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { FieldSchema, HrFromTemplate } from '../hireForm/formElements/formBuilderSchema';
import { ValidationErrorItem } from 'joi';
import { AxiosError } from 'axios';

export interface CurrentSchema {
  id?: string;
  name?: string;
  questions: FieldSchema[];
}
export type HrFormTemplateStoreModel = {
  hrFormTemplate: HRFormTemplateModel;
};
export interface HRFormTemplateModel {
  isLoading: boolean;
  setLoader: Action<HRFormTemplateModel, boolean>;
  isUpdating: boolean;
  setIsUpdating: Action<HRFormTemplateModel, boolean>;
  templates: HrFromTemplate[];
  errors: ValidationErrorItem[];
  setErrors: Action<HRFormTemplateModel, ValidationErrorItem[]>;
  setTemplates: Action<HRFormTemplateModel, HrFromTemplate[]>;
  currentTemplate?: CurrentSchema;
  setCurrentTemplate: Action<HRFormTemplateModel, CurrentSchema | undefined>;
  getTemplates: Thunk<HRFormTemplateModel, { account: string }, void, HttpStoreModel, Promise<any>>;
  createTemplate: Thunk<
    HRFormTemplateModel,
    { account: string; payload: { name: string; questions: any } },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  getTemplateById: Thunk<HRFormTemplateModel, { account: string; id: string }, void, HttpStoreModel, Promise<any>>;
  updateTemplate: Thunk<
    HRFormTemplateModel,
    { account: string; id: string; payload: { name: string; questions: any } },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  deleteTemplate: Thunk<HRFormTemplateModel, { account: string; id: string }, void, HttpStoreModel, Promise<any>>;
  removeTemplateFromList: Action<HRFormTemplateModel, string>;
  uploadingDocument: boolean;
  setUploadingDocument: Action<HRFormTemplateModel, boolean>;
  uploadDocument: Thunk<HRFormTemplateModel, { account: string; payload: FormData }, void, HttpStoreModel, Promise<string>>;
}

export const hrFormTemplateStoreModel: HRFormTemplateModel = {
  isLoading: false,
  setLoader: action((state, payload) => {
    state.isLoading = payload;
  }),
  isUpdating: false,
  setIsUpdating: action((state, payload) => {
    state.isUpdating = payload;
  }),
  templates: [],
  setTemplates: action((state, payload) => {
    state.templates = payload;
  }),
  errors: [],
  setErrors: action((state, payload) => {
    state.errors = payload;
  }),
  currentTemplate: undefined,
  setCurrentTemplate: action((state, payload) => {
    state.currentTemplate = payload;
  }),
  getTemplates: thunk(async (actions, { account }, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const response = await new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry).getTemplates(
        account,
      );
      actions.setTemplates(response?.data?.templates);
      return response?.data;
    } finally {
      actions.setLoader(false);
    }
  }),
  createTemplate: thunk(async (actions, { account, payload }, { getStoreState }) => {
    try {
      actions.setIsUpdating(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.createTemplate(account, payload);
      actions.setErrors([]);
      return response?.data;
    } catch (error) {
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      actions.setErrors(newErr.response?.data?.errors ?? []);
      throw newErr;
    } finally {
      actions.setIsUpdating(false);
    }
  }),
  getTemplateById: thunk(async (actions, payload, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.getTemplateById(payload.account, payload.id);
      actions.setCurrentTemplate(response?.data as any);
      return response?.data;
    } finally {
      actions.setLoader(false);
    }
  }),
  updateTemplate: thunk(async (actions, { account, id, payload }, { getStoreState }) => {
    try {
      actions.setIsUpdating(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.updateTemplate(account, id, payload);
      actions.setErrors([]);
      return response?.data;
    } catch (error) {
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      actions.setErrors(newErr.response?.data?.errors ?? []);
      throw newErr;
    } finally {
      actions.setIsUpdating(false);
    }
  }),
  deleteTemplate: thunk(async (actions, { account, id }, { getStoreState }) => {
    try {
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.deleteTemplate(account, id);
      actions.removeTemplateFromList(id);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }),
  removeTemplateFromList: action((state, payload) => {
    const templates = [...state.templates]?.filter((template) => template.id !== payload);
    state.templates = templates;
  }),
  uploadingDocument: false,
  setUploadingDocument: action((state, payload) => {
    state.uploadingDocument = payload;
  }),

  uploadDocument: thunk(async (actions, { account, payload }, { getStoreState }) => {
    try {
      actions.setUploadingDocument(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.uploadDocument(account, payload);
      return response?.data?.data?.url;
    } catch (error) {
      throw error;
    } finally {
      actions.setUploadingDocument(false);
    }
  }),
};
