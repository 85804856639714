import { Action, action, thunk, Thunk } from 'easy-peasy';

import {
  Candidate,
  CandidateHireFormDetailResponse,
  CandidateHireFormDetailResponseWithError,
  EngagedCandidate,
  EngagedCandidateNotification,
  LanguageEvaluationRating,
  LanguageEvaluationStatus,
} from '../../../firebase/firestore/documents/candidate';
import { SeekersPresenter } from '../sidebar/seekerList/seekersPresenter';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { CandidateController, CandidateSearchResponse } from '../../../controllers/candidateController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { CandidateListType, SearchAndSortTypes } from '../sidebar/filters/seekersFiltersContextValueType';

export type CandidateStoreModel = {
  candidate: CandidateModel;
};

/* istanbul ignore next */
function cacheForAdvancedFilters(params: SearchAndSortTypes): string {
  return JSON.stringify(params);
}

// This is not being used currently
/* istanbul ignore next */
function generateApiCacheKey(params: {
  searchQuery?: string;
  sortBy?: 'name' | 'createdAt';
  sortOrder?: 'asc' | 'desc';
  advancedFilters: SearchAndSortTypes;
}): string {
  return `sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&searchQuery=${
    params.searchQuery
  }&advancedFilters=${cacheForAdvancedFilters(params.advancedFilters)}`;
}

export interface CandidateModel {
  paginatedCandidates: Omit<CandidateSearchResponse, 'data'> & {
    isFetching: boolean;
    cacheKey: string;
    data: Map<string, CandidateSearchResponse['data'][0]>;
    requestedAt?: Date;
    lastSetDate?: Date;
  };
  allCandidates: SeekersPresenter | undefined;
  allPositionCandidates: SeekersPresenter | undefined;
  selectedCandidates: Candidate[];
  setAllCandidates: Action<CandidateModel, SeekersPresenter>;
  setAllPositionCandidates: Action<CandidateModel, SeekersPresenter | undefined>;
  setSelectedCandidates: Action<CandidateModel, Candidate[]>;
  loadHireDetailOfCandidate: Thunk<
    CandidateModel,
    { candidateId: string },
    void,
    HttpStoreModel,
    Promise<CandidateHireFormDetailResponse | CandidateHireFormDetailResponseWithError>
  >;
  reviewLanguageEvaluation: Thunk<
    CandidateModel,
    {
      positionId: string;
      seekerId: string;
      language: string;
      phraseId: string;
      rating: LanguageEvaluationRating;
      fluency: number;
      pronunciation: number;
      accuracy: number;
      status: LanguageEvaluationStatus;
    },
    void,
    HttpStoreModel
  >;
  requestLanguageEvaluationResubmit: Thunk<
    CandidateModel,
    {
      candidateId: string;
      phraseId: string;
      recruiterId: string;
    },
    void,
    HttpStoreModel
  >;
  loadCandidate: Thunk<CandidateModel, { accountId: string }, void, HttpStoreModel, Promise<EngagedCandidate[]>>;
  loadCandidatesOfPositions: Thunk<
    CandidateModel,
    { accountId: string; positionIds: string[] },
    void,
    HttpStoreModel,
    Promise<EngagedCandidate[]>
  >;
  loadCandidatesForNotifications: Thunk<
    CandidateModel,
    { accountId: string; after: string; limit: number },
    void,
    HttpStoreModel,
    Promise<EngagedCandidateNotification>
  >;
  getPositionCandidates: Thunk<CandidateModel, { positionId: string }, void, HttpStoreModel, Promise<EngagedCandidate[]>>;
  saveFromDismissal: Thunk<CandidateModel, { positionId: string; seekerId: string }, void, HttpStoreModel>;
  searchCandidates: Thunk<
    CandidateModel,
    {
      accountId: string;
      filters: {
        searchQuery?: string;
        page: number;
        limit: number;
        sortBy: 'name' | 'createdAt';
        sortOrder: 'asc' | 'desc';
        listType: CandidateListType;
        advancedFilters: SearchAndSortTypes;
      };
    },
    void,
    HttpStoreModel
  >;
  setPaginatedCandidates: Action<
    CandidateModel,
    Omit<CandidateSearchResponse, 'data'> & {
      cacheKey: string;
      isFetching: boolean;
      data: CandidateSearchResponse['data'];
      requestedAt?: Date;
      lastSetDate?: Date;
    }
  >;
  setCandidatesFetching: Action<CandidateModel, boolean>;
  resetPaginatedCandidates: Action<CandidateModel, { resetFetching?: boolean }>;
}

export const candidateModel: CandidateModel = {
  paginatedCandidates: {
    data: new Map<string, CandidateSearchResponse['data'][0]>(),
    from: 0,
    page: 0,
    size: 0,
    to: 0,
    total: 0,
    cacheKey: '',
    isFetching: false,
  },
  allCandidates: undefined,
  allPositionCandidates: undefined,
  selectedCandidates: [],

  setAllCandidates: action((state, payload) => {
    state.allCandidates = payload;
  }),

  setAllPositionCandidates: action((state, payload) => {
    state.allPositionCandidates = payload;
  }),

  setSelectedCandidates: action((state, payload) => {
    state.selectedCandidates = payload;
  }),

  loadHireDetailOfCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadHireDetailOfCandidate(payload.candidateId);
    return response?.data as CandidateHireFormDetailResponse;
  }),

  loadCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadCandidates(payload.accountId);
    return response?.data as EngagedCandidate[];
  }),

  loadCandidatesOfPositions: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadCandidatesOfPositions(payload.accountId, payload.positionIds);
    return response?.data as EngagedCandidate[];
  }),

  loadCandidatesForNotifications: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadCandidatesForNotifications(payload.accountId, payload.after, payload.limit);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response?.data as EngagedCandidateNotification;
  }),

  requestLanguageEvaluationResubmit: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.requestLanguageEvaluationResubmit(payload.phraseId, payload.candidateId, payload.recruiterId);
  }),

  reviewLanguageEvaluation: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.reviewLanguageEvaluation(
      payload.positionId,
      payload.seekerId,
      payload.language,
      payload.phraseId,
      payload.rating,
      payload.fluency,
      payload.pronunciation,
      payload.accuracy,
      payload.status,
    );
  }),

  getPositionCandidates: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getPositionCandidates(payload.positionId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.candidates as EngagedCandidate[];
  }),
  saveFromDismissal: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.saveFromDismissal(payload.positionId, payload.seekerId);
  }),

  searchCandidates: /* istanbul ignore next */ thunk(
    /* istanbul ignore next */
    async (_actions, payload, { getStoreState, getState }) => {
      try {
        _actions.setCandidatesFetching(true);
        const requestedAt = new Date();
        const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);

        const cacheKey = generateApiCacheKey(payload.filters);
        const currentCacheKey = getState().paginatedCandidates.cacheKey;
        if (currentCacheKey !== cacheKey) {
          _actions.resetPaginatedCandidates({ resetFetching: false });
        }
        const response = await controller.searchCandidates(payload.accountId, payload.filters);
        const data = response?.data as CandidateSearchResponse;
        _actions.setPaginatedCandidates({
          ...data,
          cacheKey,
          requestedAt,
          isFetching: false,
          data: data.data,
        });
        return data;
      } finally {
        _actions.setCandidatesFetching(false);
      }
    },
  ),
  setPaginatedCandidates: /* istanbul ignore next */ action(
    /* istanbul ignore next */
    (state, payload) => {
      // eslint-disable-next-line no-param-reassign
      payload.isFetching = false;
      const prevData = state.paginatedCandidates;
      // check if the requestedAt date is older than current data, if not then ignore the data
      if (payload.requestedAt && prevData.requestedAt && prevData.requestedAt.getTime() > payload.requestedAt.getTime()) {
        return;
      }
      const { data, ...rest } = payload;
      data.forEach((candidate) => {
        const [, seeker] = candidate.id.split('_');
        const previousData = state.paginatedCandidates.data.get(seeker);
        if (!previousData) {
          // Setting seeker here for now as elastic search does not have seeker in the index
          state.paginatedCandidates.data.set(seeker, { ...candidate, seeker });
        }
      });
      state.paginatedCandidates.from = rest.from + 50;
      state.paginatedCandidates.page = rest.page;
      state.paginatedCandidates.size = prevData.size + rest.size;
      state.paginatedCandidates.to = rest.to;
      state.paginatedCandidates.total = rest.total;
      state.paginatedCandidates.cacheKey = rest.cacheKey;
      state.paginatedCandidates.lastSetDate = new Date();
      state.paginatedCandidates.requestedAt = rest.requestedAt;
    },
  ),
  setCandidatesFetching: /* istanbul ignore next */ action(
    /* istanbul ignore next */
    (state, payload) => {
      state.paginatedCandidates.isFetching = payload;
    },
  ),
  resetPaginatedCandidates: /* istanbul ignore next */ action(
    /* istanbul ignore next */
    (state, payload) => {
      const { resetFetching = false } = payload;
      state.paginatedCandidates = {
        size: 0,
        data: new Map(),
        from: 0,
        page: 0,
        to: 0,
        total: 0,
        isFetching: resetFetching ? false : state.paginatedCandidates.isFetching,
        cacheKey: '',
      };
    },
  ),
};
