import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  useDisclosure,
  Text,
} from '@chakra-ui/core';
import React from 'react';

interface DeletePopoverProps {
  popoverTrigger: React.ReactNode;
  onYes: () => void;
}

export default function DeletePopover({ popoverTrigger, onYes }: DeletePopoverProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur closeOnEsc autoFocus={false} placement="left-start">
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverCloseButton />
          <PopoverBody>
            <Text>Are you sure you want to delete?</Text>
          </PopoverBody>
          <PopoverFooter display="flex" alignItems="center" justifyContent="flex-end">
            <ButtonGroup size="sm" variant="outline">
              <Button
                colorScheme="red"
                onClick={() => {
                  onYes();
                  onClose();
                }}
              >
                Yes
              </Button>
              <Button onClick={onClose}>No</Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
