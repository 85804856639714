import { CandidateAuditActionNames } from './candidateAuditActionNames';
import { Requisition } from './requisition';
// eslint-disable-next-line import/no-cycle
import { OffboardingReason } from './candidate';

export type CandidateAudit = {
  id: string;
  account: string;
  candidateId: string;
  action: CandidateAuditActionNames;
  timestamp: Date;
  seekerId: string;
  screeningOptions: { [key: string]: boolean };
  seekerFirstName: string;
  userId: string;
  recruiterFirstName: string;
  message: string;
  actionCategory?: string;
  automation?: boolean;
  appointment?: AppointmentAudit;
  question?: Question;
  userFullName?: string;
  businessName?: string;
  requisitionId?: string;
  requisition?: Requisition;
  positionId?: string;
  positionName?: string;
  candidateFullName?: string;
  reasonId?: string;
  reasonComment?: string;
  dialogflow?: boolean;
  hireDetailsResubmissionRequest?: [
    {
      comment: string;
      field: string;
    },
  ];
  availability?: { [key: string]: { start_1: string; end_1: string } };
  nightAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  shiftIntersection?: { [key: string]: { start_1: string; end_1: string; start_2: string; end_2: string } };
  expectedWorkHour?: { unit: string; value: string };
  offeredSalary?: { salary: string; salaryUnit: string };
  offboardingDetails?: OffboardingDetails;
};

export type OffboardingDetails = {
  offboardedTime: Date;
  reason: OffboardingReason;
  type: string;
  customReason?: string;
};

export type AppointmentAudit = {
  date: Date;
  appointmentType: AppointmentType;
  duration: number;
};

export enum AppointmentType {
  inPerson = 'inPerson',
  phone = 'phone',
  video = 'video',
}

export type Question = {
  answer: boolean | string;
  id: string;
  knockout: boolean;
  preferredAnswer: boolean;
  text: string;
};
