import { Select } from '@chakra-ui/core';
import { useHireFormContext } from '../HireForm.context';
import { FieldSchema, SectionSchema } from './formBuilderSchema';
import React from 'react';
import theme from '../../../../styles/customTheme';

export default function ElementSelectField({
  defaultValue,
  sectionIndex,
  fieldIndex,
  options = [],
}: {
  defaultValue: FieldSchema;
  sectionIndex: number;
  fieldIndex: number;
  options: string[];
}) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const [fields, setFields] = React.useState<string[]>(options);

  React.useEffect(() => {
    const baseFieldName = defaultValue.field?.split('_')[0];

    const newOptions = [...options];
    const optionIndex = newOptions.indexOf(baseFieldName);

    newOptions.splice(optionIndex, 1, defaultValue.field);
    setFields(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchema]);

  const updateFieldsWithIndices = (fieldsToUpdate: string[], formSchema: SectionSchema[], fieldName?: string) => {
    const baseFieldName = (fieldName ?? defaultValue.field)?.split('_')[0];
    const newFormSchema = [...formSchema];

    const existingFields = newFormSchema
      .flatMap((section) => section.fields.map((field) => field))
      ?.reduce((acc, field) => {
        if (field?.field?.startsWith(baseFieldName)) {
          acc.push(field);
        }
        return acc;
      }, [] as FieldSchema[]);

    const updatedOptionFields = existingFields.reduce(
      (acc, field, index) => {
        let currentField = baseFieldName;
        const newOptions = [...fieldsToUpdate];

        const optionIndex = newOptions.indexOf(baseFieldName);

        if (index === 0) {
          newOptions.splice(optionIndex, 1, currentField);
        } else if (index > 0) {
          currentField = `${baseFieldName}_${index}`;
          newOptions.splice(optionIndex, 1, currentField);
        }
        acc.fields.push({
          ...field,
          field: currentField,
        });
        if (field.id === defaultValue.id) {
          acc.options = newOptions;
        }
        return acc;
      },
      {
        fields: [] as FieldSchema[],
        options: [] as string[],
      },
    );
    setFields(updatedOptionFields.options);

    setFormSchema(
      newFormSchema.map((section) => {
        const newFields = section.fields.map((f) => {
          const current = updatedOptionFields.fields.find((field) => field.id === f.id);
          if (current) {
            return { ...f, field: current.field };
          }
          return f;
        });
        return { ...section, fields: newFields };
      }),
    );
  };
  React.useEffect(() => {
    updateFieldsWithIndices(options, formSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (options.length === 0) return null;

  const formatLabel = (field: string): string => {
    const words = field?.match(/[A-Z]?[a-z]+|[0-9]+/g);
    return (
      words
        ?.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
        .join(' ') ?? ''
    );
  };

  const handleOnChange = (field: string) => {
    const updatedFormSchema = [...formSchema];
    updatedFormSchema[sectionIndex].fields[fieldIndex].field = field;
    updateFieldsWithIndices(options, updatedFormSchema, field);
  };

  return (
    <Select
      variant="outline"
      size="xs"
      fontSize="xs"
      width="fit-content"
      value={defaultValue.field}
      onChange={(e) => handleOnChange(e.target.value)}
      sx={{
        '&': {
          width: 'auto',
          padding: '0.25rem .5rem',
          paddingRight: '1.8rem',
          borderRadius: theme.radii.md,
        },
      }}
    >
      <option disabled>Select a field</option>
      {fields?.map((option) => (
        <option key={option} value={option}>
          {formatLabel(option)}
        </option>
      ))}
    </Select>
  );
}
