import { Editable, EditablePreview, Input, EditableInput, FormControl, FormErrorMessage } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../../styles/customTheme';
import { SectionSchema } from '../formBuilderSchema';

interface SectionNameInputProps {
  name: string;
  sectionIndex: number;
  isAdvancedSection: boolean;
  setFormSchema: (value: React.SetStateAction<SectionSchema[]>) => void;
}

const SectionNameInput = React.memo(({ name, sectionIndex, isAdvancedSection, setFormSchema }: SectionNameInputProps) => {
  const [sectionName, setSectionName] = React.useState(name);
  const [isSectionNameAlreadyAvailable, setIsSectionNameAlreadyAvailable] = React.useState(false);

  React.useEffect(() => {
    setSectionName(name);
  }, [name]);

  function handleSectionNameChange(newSectionName: string) {
    setSectionName(newSectionName);

    setFormSchema((prev) => {
      const isDuplicateSectionName = prev?.some((section, index) => section.section === newSectionName && index !== sectionIndex);
      setIsSectionNameAlreadyAvailable(isDuplicateSectionName);

      return prev;
    });
  }

  function handleSectionNameBlur() {
    setFormSchema((prev) => {
      if (isSectionNameAlreadyAvailable) return prev;

      return [...prev]?.map((section, index) => {
        if (sectionIndex === index) {
          return {
            ...section,
            fields: section.fields?.map((field) => ({ ...field, section: sectionName })),
            section: sectionName,
          };
        }
        return section;
      });
    });
  }

  const formatLabel = (field: string): string => {
    const words = field?.match(/[A-Z]?[a-z]+|[0-9]+/g);
    return (
      words
        ?.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
        .join(' ') ?? ''
    );
  };

  return (
    <FormControl isInvalid={isSectionNameAlreadyAvailable}>
      <Editable
        backgroundColor={theme.colors.white}
        value={formatLabel(sectionName)}
        size="sm"
        fontSize={isAdvancedSection ? 'lg' : 'sm'}
        padding={0}
        background={'none'}
        isDisabled={isAdvancedSection}
        color={isSectionNameAlreadyAvailable ? theme.colors.red[500] : '#4A5568'}
        fontWeight="bold"
        onChange={handleSectionNameChange}
        onBlur={handleSectionNameBlur}
        width="100%"
        isPreviewFocusable={!isAdvancedSection}
      >
        <EditablePreview width="100%" padding={isSectionNameAlreadyAvailable ? 0 : 'revert'} />
        <Input
          value={sectionName}
          as={EditableInput}
          size="sm"
          fontWeight="normal"
          variant="outline"
          borderRadius={theme.radii.md}
          paddingX={1}
          paddingY={0}
        />
      </Editable>
      <FormErrorMessage marginTop={0} fontSize="xs">
        Section name already exists
      </FormErrorMessage>
    </FormControl>
  );
});

export default SectionNameInput;
