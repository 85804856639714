/* istanbul ignore file */
import React from 'react';
import isBoolean from 'lodash/isBoolean';

import { AvatarBadge, Box, Grid, Heading } from '@chakra-ui/core';
import { TreeMenuItem } from 'react-simple-tree-menu';
import { NodeType } from '../treeGroup';
import { EngagedCandidate } from '../../../../../firebase/firestore/documents/candidate';
import { ProfilePicture } from '../../../../common';
import colors from '../../../../../styles/colors';
import { useMessagingViewContext } from '../../../MessagingViewContext';

type Props = {
  item: TreeMenuItem;
  selectedCallback: (candidate: EngagedCandidate) => void;
  isActive?: boolean;
};

const addBoxClass = (item: TreeMenuItem) => {
  switch (item.type) {
    case NodeType.PARENT:
      return 'parent-block';
    case NodeType.CHILD:
      return 'child-block';
    default:
      return 'candidate-block';
  }
};

const NewCandidateItem: React.FC<Props> = ({ item, selectedCallback, isActive }: Props) => {
  const { selectedCandidateId } = useMessagingViewContext();
  const selectedSeekerId = selectedCandidateId?.split('_')[1];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const itemSeekerId = (item.candidate?.id as string)?.split('_')[1];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isSelected = isBoolean(isActive) ? isActive : selectedSeekerId === itemSeekerId;
  const candidateBlockClass = `inner-candidate-block ${isSelected ? 'active-list' : ''}`;

  return (
    <Box
      className={addBoxClass(item)}
      data-testid="SeekerListItem"
      onClick={(e) => {
        e.stopPropagation();
        item.toggleNode?.();
      }}
    >
      <Box
        d="flex"
        alignItems="flex-start"
        className={candidateBlockClass}
        cursor="pointer"
        data-testid="CandidateItem"
        onClick={() => selectedCallback(item.candidate as EngagedCandidate)}
      >
        <Grid
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          className={selectedCandidateId === item.candidate?.id ? 'active-list' : ''}
          templateColumns={{ base: '48px auto', md: '50px auto', lg: '60px auto' }}
          width="100%"
          minH="48px"
        >
          <ProfilePicture style={{ backgroundColor: 'orange', color: 'white' }} name={item.label} bg="transparent">
            <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />
          </ProfilePicture>

          <Box pl="10px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Heading as="h4" size="sm" data-testid="FullName" display="inline-flex">
                {item.label}
              </Heading>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

NewCandidateItem.defaultProps = {
  isActive: undefined,
};

export default NewCandidateItem;
