import React from 'react';
import { Button } from '@chakra-ui/core';
import { FieldSchema } from '../formBuilderSchema';

interface AddFollowUpProps {
  followUpOptions: string[];
  setFollowUpOptions: (followUps: string[]) => void;
  parentDefaultValue: FieldSchema;
}
export default function AddFollowUp({ followUpOptions, setFollowUpOptions, parentDefaultValue }: AddFollowUpProps) {
  if (followUpOptions.length >= parentDefaultValue?.optionList?.length!) return null;

  const { optionList: options } = parentDefaultValue;

  function handleAddFollowUp() {
    if (!options?.length) return;
    if (followUpOptions?.length) {
      const nextOption = options.find((option) => followUpOptions.find((id) => id !== option.id));
      setFollowUpOptions([...followUpOptions, nextOption?.id!]);
    } else {
      setFollowUpOptions([options[0].id]);
    }
  }
  return (
    <Button variant="link" fontSize={12} fontWeight={'normal'} onClick={handleAddFollowUp}>
      Add Follow Up
    </Button>
  );
}
