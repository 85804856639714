import { IconType } from 'react-icons';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { MultipleChoiceImage } from '../../positionManagement/PositionType';
import { v4 as uuidv4 } from 'uuid';

export const languages = ['en', 'fr'] as const;
export type Language = typeof languages[number];

export const MAX_FOLLOW_UP_DEPTH = 1;

export enum formBuilderType {
  // basic elements
  text = 'text',
  info = 'info',
  number = 'number',
  date = 'date',
  time = 'time',
  email = 'email',
  address = 'address',
  phone = 'phone',
  yesNo = 'yesNo',
  checkbox = 'checkbox',
  radio = 'radio',
  image = 'image',
  file = 'file',
  link = 'link',
  button = 'button',
  singleCheckbox = 'singleCheckbox',
  gender = 'gender',
  documentReview = 'documentReview',
  bankAccountNumber = 'bankAccountNumber',
  routingNumber = 'routingNumber',
  branchTransitNumber = 'branchTransitNumber',
  financialInstitutionNumber = 'financialInstitutionNumber',
  checkboxDisable = 'checkboxDisable',
  correspondenceLanguage = 'correspondenceLanguage',
  // page elements
  section = 'section',
  // advanced elements
  sin = 'sin',
  documentPreview = 'documentPreview',
  americanBank = 'americanBank',
  canadianBank = 'canadianBank',
  earliestStartDate = 'earliestStartDate',
  addressValidator = 'addressValidator',
  confirmation = 'confirmation',
  signature = 'signature',
  emergencyContact = 'emergencyContact',
  miscellaneous = 'miscellaneous',
}

export type FormBuilderTypes = keyof typeof formBuilderType;
export type BasicFormBuilderTypes = Extract<
  FormBuilderTypes,
  | 'text'
  | 'info'
  | 'number'
  | 'date'
  | 'time'
  | 'email'
  | 'address'
  | 'phone'
  | 'yesNo'
  | 'checkbox'
  | 'radio'
  | 'image'
  | 'file'
  | 'link'
  | 'documentReview'
  | 'singleCheckbox'
  | 'button'
  | 'gender'
  | 'bankAccountNumber'
  | 'routingNumber'
  | 'branchTransitNumber'
  | 'financialInstitutionNumber'
  // custom elements
  | 'checkboxDisable'
  | 'correspondenceLanguage'
>;

export enum Sections {
  canadianBank = 'canadianBank',
  americanBank = 'americanBank',
  emergencyContact = 'emergencyContact',
  documentPreview = 'documentPreview',
  miscellaneous = 'miscellaneous',
}

export type AdvancedFormBuilderTypes = Extract<
  FormBuilderTypes,
  | 'sin'
  | 'documentPreview'
  | 'canadianBank'
  | 'americanBank'
  | 'earliestStartDate'
  | 'addressValidator'
  | 'confirmation'
  | 'signature'
  | 'emergencyContact'
  | 'miscellaneous'
>;

export type LabelSchema = (Required<Pick<Record<Language, string>, 'en'>> | Required<Pick<Record<Language, string>, 'fr'>>) &
  Partial<Record<Language, string>>;
export interface OptionSchema {
  key: string;
  id: string;
  text: LabelSchema;
  isSelected?: boolean;
}

export interface DocumentReview {
  description: LabelSchema;
  url: LabelSchema;
  acknowledgeContent: LabelSchema;
  confirmText: LabelSchema;
}
export interface FieldSchema {
  timestamp: number;
  id: string;
  inputType: FormBuilderTypes;
  order: number;
  section?: string;
  label?: LabelSchema;
  required?: boolean;
  text?: LabelSchema;
  validations?: Record<string, any>;
  optionList?: OptionSchema[];
  isFollowUp: boolean;
  followUpQuestionList?: FieldSchema[];
  hintText?: Partial<Record<Language, string>>;
  parent?: string | null;
  optionId?: string | null;
  isInfo?: boolean;
  link?: LabelSchema;
  image?: MultipleChoiceImage;
  previewPlaceholders?: { placeholder: string };
  field: string;
  documentReview?: DocumentReview;
  disabled?: boolean;
  disabledId?: string;
  isFrontEndOnly?: boolean;
  hideFieldSelector?: boolean;
  min?: number;
  max?: number;
}

export interface SchemaBuilderProps {
  defaultValue: FieldSchema;
  onChange: (schema: FieldSchema) => void;
  language: Language;
  path: (string | number)[];
  sectionIndex: number;
  fieldIndex: number;
  followUpDragOver?: () => void;
  isAdvanced?: boolean;
  followUpLabel: number;
  labelRightRenderer?: React.ReactNode;
}

export type ComponentPanels = 'basic' | 'advanced' | 'tags' | 'page-element';
export interface FormBuilderSchema {
  type: FormBuilderTypes;
  component: (({ defaultValue, onChange }: SchemaBuilderProps) => JSX.Element) | null;
  props: any;
  defaultValue: FieldSchema | FieldSchema[];
  icon: IconType;
  label: string;
  panel: ComponentPanels;
  section?: string;
  hidden?: boolean;
}

export interface PageElementSchema {
  key: string;
  text: { en: string; fr: string };
  icon: IconType;
  label: string;
}

export interface HrFromTemplate {
  id: string;
  account: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface HrFormTemplateGetAllResponse {
  templates: HrFromTemplate[];
}

export interface HrFromCreateTemplateResponse {
  data: {
    templateId: string;
  };
  message: string;
}
export interface SectionSchema {
  id: string;
  section: string;
  fields: FieldSchema[];
  isAdvanced?: boolean;
}

export function generateFormSchema(sectionSchema: SectionSchema[], language: Language) {
  const questionsOmitKeys = [
    'timestamp',
    'hintText',
    'validations',
    'required',
    'previewPlaceholders',
    'disabledId',
    'disabled',
    'isFrontEndOnly',
    'hideFieldSelector',
  ];

  function processFollowUpQuestions(questions: FieldSchema[] | undefined): any[] | null {
    if (!questions) return null;
    return questions
      .filter((question) => !(question?.disabled || question?.isFrontEndOnly))
      .map((question) => {
        // Recursively handle both follow-up questions and nested option lists
        return {
          ...omit(question, questionsOmitKeys),
          isInfo: question?.isInfo ?? false,
          optionList: question.optionList?.map((option) => {
            return omit(option, ['key']);
          }), // Handle optionList for follow-up
          followUpQuestionList: processFollowUpQuestions(question?.followUpQuestionList), // Recursive call for nested follow-ups
        };
      });
  }

  return sectionSchema
    .map((section) => {
      return section.fields
        ?.filter((item) => !(item?.disabled || item?.isFrontEndOnly))
        ?.map((field) => {
          const newField = {
            ...field,
            optionList: field?.optionList?.map((option) => {
              return omit(option, ['key']);
            }),
            followUpQuestionList: processFollowUpQuestions(field?.followUpQuestionList),
          };
          return {
            ...omit(newField, questionsOmitKeys),
            section: section.section,
          };
        });
    })
    .flat()
    .map((item, index) => {
      const temp = { ...omitBy(item, isNil) };
      return {
        ...temp,
        order: index + 1,
      } as FieldSchema;
    });
}

export function mapFormSchema({ currentTemplate }: { currentTemplate: FieldSchema[] }) {
  const grouped = currentTemplate.reduce<SectionSchema[]>((acc, field, index) => {
    const sectionName = field.section ?? `section_${index + 1}`;
    const existingSection = acc.find((group) => group.section === sectionName);
    const newField = {
      ...field,
      optionList: field?.optionList?.map((option, index) => ({
        ...option,
        key: field.inputType === 'yesNo' ? option.text.en! : String.fromCharCode(65 + index).toUpperCase(),
      })),
    };
    if (existingSection) {
      existingSection.fields.push(newField);
    } else {
      acc.push({ section: sectionName, fields: [newField], id: uuidv4() });
    }

    return acc;
  }, []);
  return grouped;
}
