import { Box, Stack, theme, Divider, HStack, Text, Button, useToast } from '@chakra-ui/core';
import React from 'react';
import { IoMoveSharp } from 'react-icons/io5';
import { EmptyComponent } from './EmptyComponent';
import FormBuilderElement from './FormBuilderElement';
import { FieldSchema, formBuilderType, generateFormSchema, SectionSchema } from './formBuilderSchema';
import { FormBuilderProps } from '../formBuilder/FormBuilder';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { swap } from './OptionSchemaBuilder';
import { MdDragIndicator } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { isArray } from 'lodash';
import { HiTrash } from 'react-icons/hi';
import DeletePopover from './advancedElements/DeletePopover';
import SectionNameInput from './section/SectionNameInput';
import { useHireFormContext } from '../HireForm.context';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { formBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';

interface FormBuilderSectionsProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {}

export default function FormBuilderSections(props: FormBuilderSectionsProps) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const toast = useToast();

  const handleDropSection = (event: React.DragEvent<HTMLDivElement>) => {
    const type = event.dataTransfer.getData('type');
    const schemas = [...formSchema];
    const isSignature = type === formBuilderType.signature;
    const hasSignature = formSchema
      .flatMap((section) => section.fields)
      .some((field) => field.inputType === formBuilderType.signature);

    if (type === 'section') {
      const newSection = { section: 'section_' + (formSchema.length + 1), fields: [], id: uuidv4() };
      if (hasSignature) {
        schemas.splice(formSchema.length - 1, 0, newSection);
      } else {
        schemas.push(newSection);
      }
      setFormSchema(schemas);
    } else {
      const schema = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
      if (schema.panel === 'advanced' && isArray(schema.defaultValue)) {
        const newSchema = {
          section: schema.section!,
          fields: schema.defaultValue?.map((field) => ({
            ...field,
            timestamp: Date.now(),
            // id: uuidv4(),
          })),
          isAdvanced: true,
          id: uuidv4(),
        };
        if (hasSignature) {
          schemas.splice(schemas.length - 1, 0, newSchema);
        } else if (isSignature || (schemas.length === 1 && schemas[0].fields.length === 0)) {
          schemas.push(newSchema);
        } else {
          schemas.push(newSchema);
        }
        setFormSchema(schemas);
      } else {
        const field = { ...schema.defaultValue, id: uuidv4(), timestamp: Date.now() } as FieldSchema;
        const newSection: SectionSchema = { section: 'section_' + (schemas.length + 1), fields: [field], id: uuidv4() };

        if (hasSignature) {
          schemas.splice(schemas.length - 1, 0, newSection);
        } else {
          schemas.push(newSection);
        }
        setFormSchema(schemas);
      }
    }
  };
  return (
    <Box flexGrow={1} onDrop={handleDropSection} onDragOver={(e) => e.preventDefault()}>
      <SortableSectionContainer
        {...props}
        onSortEnd={({ oldIndex, newIndex }) => {
          const signatureIndex = formSchema.findIndex((section) =>
            section.fields.some((field) => field.inputType === formBuilderType.signature),
          );
          if (newIndex === signatureIndex) {
            toast({
              status: 'warning',
              description: 'You cannot swap with the signature section',
            });
            return;
          }
          setFormSchema(swap(formSchema, oldIndex, newIndex));
        }}
        distance={20}
        axis="y"
        lockAxis="y"
        hideSortableGhost={true}
        lockToContainerEdges={true}
        useDragHandle
      />
    </Box>
  );
}

const SortableSectionContainer = SortableContainer(function ({ ...props }: FormBuilderSectionsProps) {
  const { formSchema } = useHireFormContext();

  return (
    <Stack spacing={8} flexGrow={1}>
      {formSchema.map((section, sectionIndex) => {
        if (!section) return null;
        const isSignature = section.fields.some((field) => field.inputType === formBuilderType.signature);
        return (
          <SortableSectionElement
            section={section}
            sectionIndex={sectionIndex}
            index={sectionIndex}
            {...props}
            disabled={isSignature}
          />
        );
      })}
    </Stack>
  );
});

interface SortableSectionElementProps extends FormBuilderSectionsProps {
  section: SectionSchema;
  sectionIndex: number;
}

const SortableSectionElement = SortableElement(function ({ section, sectionIndex, language }: SortableSectionElementProps) {
  const { formSchema, setFormSchema } = useHireFormContext();

  return (
    <SectionElementContainer
      section={section}
      sectionIndex={sectionIndex}
      language={language}
      key={sectionIndex}
      onSortEnd={({ oldIndex, newIndex }) => {
        const swappedFields = swap(section.fields, oldIndex, newIndex);
        const newSection = { ...section, fields: swappedFields };
        setFormSchema(
          formSchema.map((item, index) => {
            if (index === sectionIndex) {
              return newSection;
            }
            return item;
          }),
        );
      }}
      distance={20}
      axis="y"
      lockAxis="y"
      hideSortableGhost={true}
      useDragHandle
      lockToContainerEdges
    />
  );
});

interface SectionElementContainerProps extends Pick<FormBuilderSectionsProps, 'language'> {
  section: SectionSchema;
  sectionIndex: number;
}

const SectionElementContainer = SortableContainer(function ({ section, language, sectionIndex }: SectionElementContainerProps) {
  const { formSchema, setFormSchema } = useHireFormContext();

  const { errors } = useStoreState((state) => state.hrFormTemplate);
  const { setErrors } = useStoreActions((state) => state.hrFormTemplate);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const type = event.dataTransfer.getData('type');
    if (type?.length > 0) {
      if (type !== 'section') {
        const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
        if (isArray(defaultValue)) return;
        const newSchema = formSchema?.map<SectionSchema>((item, index) => {
          if (index === sectionIndex) {
            return {
              ...item,
              fields: [...item.fields, { ...defaultValue, timestamp: Date.now(), id: uuidv4(), section: item.section }],
            };
          }
          return item;
        });

        setFormSchema(newSchema);
      }
    }
  };

  const SortableAnchor = SortableHandle(() => (
    <Box
      borderRadius="50%"
      cursor="grab"
      color={theme.colors.gray[300]}
      style={{ aspectRatio: '1/1' }}
      padding={1}
      size="sm"
      _groupHover={{ color: theme.colors.gray[500] }}
      data-index={sectionIndex}
    >
      <MdDragIndicator />
    </Box>
  ));

  const currentSection = formSchema.find((s) => s.id === section.id);

  const isAdvancedSection = section?.isAdvanced ?? false;

  return (
    <Box role="group" position="relative">
      <Box width="100%">
        <HStack justify="space-between">
          <SectionNameInput name={section.section} {...{ sectionIndex, setFormSchema, isAdvancedSection }} />
          <HStack>
            <DeletePopover
              popoverTrigger={
                <Button colorScheme="red" style={{ aspectRatio: '1/1' }} padding={1} size="sm" variant="ghost">
                  <HiTrash />
                </Button>
              }
              onYes={() => {
                const newFormSchema = [...formSchema];
                const fields = generateFormSchema(newFormSchema, language).filter((field) => field.section === section.section);

                newFormSchema.splice(sectionIndex, 1);

                if (newFormSchema.length === 0) {
                  newFormSchema.push({ section: 'section_1', fields: [], id: uuidv4() });
                }

                setErrors([...errors]?.filter((e) => !fields?.find((f) => f.order - 1 === e.path[1])));
                setFormSchema(newFormSchema);
                setFormSchema(newFormSchema);
              }}
            />
            {section?.fields.some((field) => field.inputType !== formBuilderType.signature) && <SortableAnchor />}
          </HStack>
        </HStack>
      </Box>
      <Box
        backgroundColor={theme.colors.white}
        borderRadius={theme.radii.md}
        p={theme.space[4]}
        outline={`2px solid ${currentSection?.isAdvanced ? theme.colors.blue[200] : 'transparent'}`}
      >
        <Stack spacing={currentSection?.isAdvanced ? 2 : 4} divider={<Divider />}>
          {currentSection?.fields?.length === 0 && (
            <EmptyComponent
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onDrop={(e) => {
                handleDrop(e);
              }}
            >
              <HStack color="#586073" align="center" justify="center" flexGrow={1}>
                <IoMoveSharp size={24} />
                <Text fontSize="sm" fontWeight="bold">
                  Drag or Click on the items on your left to start building your form.
                </Text>
              </HStack>
            </EmptyComponent>
          )}
          {currentSection?.fields
            ?.filter((item) => !item?.disabled)
            ?.map((item, index) => {
              return (
                <FormBuilderElement
                  key={item.id}
                  language={language}
                  item={item}
                  elementIndex={index}
                  index={index}
                  sectionIndex={sectionIndex}
                  isAdvancedSection={isAdvancedSection}
                />
              );
            })}
        </Stack>
      </Box>
    </Box>
  );
});
