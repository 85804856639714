import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { GroupEventConfiguration } from '../../firebase/firestore/documents/groupEvent';
import { Collections } from '../../firebase/firestore/collections';
import { BUNDLE_ID_GLOBAL, CONTACT_EMAIL_FOR_ACCOUNT_AUTHORISATION } from '../../firebase/firestore/constants';
import { Query } from '../../firebase/firestore/query/query';

export const useContactEmailForAccountAuthorisation = () => {
  const contactEmail = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(CONTACT_EMAIL_FOR_ACCOUNT_AUTHORISATION),
  ]);
  if (contactEmail) {
    return contactEmail[0]?.value;
  }
  return 'account-validation@appyhere.com';
};

export default useContactEmailForAccountAuthorisation;
