import React, { useContext } from 'react';
import { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Language, languages, mapFormSchema, SectionSchema } from './formElements/formBuilderSchema';
import { useParams } from 'react-router-dom';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { useStoreActions, useStoreState } from '../../../models/hooks';

interface FormBuilderContext {
  formSchema: SectionSchema[];
  setFormSchema: React.Dispatch<React.SetStateAction<SectionSchema[]>>;
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  templateName: string;
  setTemplateName: React.Dispatch<React.SetStateAction<string>>;
}

const HireFormContext = createContext<FormBuilderContext>({
  formSchema: [],
  setFormSchema: (o) => o,
  language: 'en',
  setLanguage: (o) => o,
  templateName: '',
  setTemplateName: (o) => o,
});

const HireFormProvider: React.FC = ({ children }) => {
  const { getTemplateById, setCurrentTemplate } = useStoreActions((state) => state.hrFormTemplate);
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);
  const [formSchema, setFormSchema] = useState<SectionSchema[]>([{ section: 'section_1', fields: [], id: uuidv4() }]);
  const [language, setLanguage] = useState<Language>(languages[0]);
  const [templateName, setTemplateName] = useState<string>('');
  let templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;

  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  React.useEffect(() => {
    if (templateId) {
      getTemplateById({ account: accountId, id: templateId });
    } else {
      setCurrentTemplate(undefined);
      setFormSchema([{ section: 'section_1', fields: [], id: uuidv4() }]);
    }
    // eslint-disable-next-line
  }, [templateId]);

  React.useEffect(() => {
    if (!currentTemplate?.questions?.length || !templateId) {
      setFormSchema([{ section: 'section_1', fields: [], id: uuidv4() }]);
      return;
    }

    const grouped = mapFormSchema({
      currentTemplate: currentTemplate.questions,
    });

    setFormSchema(grouped);
  }, [currentTemplate, templateId]);

  return (
    <HireFormContext.Provider
      value={{
        formSchema,
        setFormSchema,
        language,
        setLanguage,
        templateName,
        setTemplateName,
      }}
    >
      {children}
    </HireFormContext.Provider>
  );
};

export { HireFormProvider, HireFormContext };

export const useHireFormContext = () => useContext(HireFormContext);
