import { Box, theme } from '@chakra-ui/core';
import React from 'react';

interface EmptyComponentProps extends React.ComponentProps<typeof Box> {}
export function EmptyComponent({ children, style, onDrop, onDragOver }: EmptyComponentProps) {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        backgroundColor: '#F3F6F9',
        border: '2px dashed',
        borderColor: '#A8B1E3',
        borderRadius: theme.radii.md,
        padding: theme.space[8],
        ...style,
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onDrop?.(e);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onDragOver?.(e);
      }}
    >
      {children}
    </Box>
  );
}
