import { action, Action, Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { BusinessManagementController } from '../../../controllers/businessManagementController';
import {
  Business,
  BusinessListQueryParams,
  BusinessListResponse,
  BusinessListV4Response,
  BusinessResponse,
} from '../positionManagement/BusinessType';
import { CandidateListType, SearchAndSortTypes } from '../../messaging/sidebar/filters/seekersFiltersContextValueType';

export type BusinessManagementStoreModel = {
  businessManagement: BusinessManagementModel;
};
export interface BusinessManagementModel {
  paginatedBusinesses: BusinessListV4Response & { isFetching: boolean; cacheKey: string };
  cachedBusinesses: Map<string, BusinessListV4Response & { isFetching: boolean }>;
  createBusiness: Thunk<
    BusinessManagementModel,
    { accountId: string; businessData: Business },
    void,
    HttpStoreModel,
    Promise<BusinessResponse>
  >;
  editBusiness: Thunk<
    BusinessManagementModel,
    { accountId: string; businessData: Business; businessId: string },
    void,
    HttpStoreModel,
    Promise<BusinessResponse>
  >;
  getBusinessList: Thunk<
    BusinessManagementModel,
    { params: BusinessListQueryParams },
    void,
    HttpStoreModel,
    Promise<BusinessListResponse>
  >;
  setBusinessList: Action<BusinessManagementModel, BusinessListV4Response & { cacheKey: string; isFetching: boolean }>;
  setCachedBusinessList: Action<BusinessManagementModel, BusinessListV4Response & { cacheKey: string; isFetching: boolean }>;
  getBusinessWithPositionAndCandidate: Thunk<
    BusinessManagementModel,
    {
      params: BusinessListQueryParams & {
        listType: CandidateListType;
        advancedFilters: SearchAndSortTypes;
      };
    },
    void,
    HttpStoreModel,
    Promise<BusinessListV4Response>
  >;
  setBusinessFetching: Action<BusinessManagementModel, boolean>;
  resetBusinessWithPositionAndCandidate: Action<BusinessManagementModel, { resetFetching?: boolean }>;
}
/* istanbul ignore next */
function cacheForAdvancedFilters(params: SearchAndSortTypes): string {
  return JSON.stringify(params);
}
/* istanbul ignore next */
function generateApiCacheKey(
  params: BusinessListQueryParams & {
    listType: CandidateListType;
    advancedFilters: SearchAndSortTypes;
  },
): string {
  return `
    sortBy=${params.sortBy}
    &sortOrder=${params.sortOrder}
    &onlyFollowedBusiness=${params.onlyFollowedBusiness}
    &searchQuery=${params.searchQuery}
    &listType=${params.listType}
    &advancedFilters=${cacheForAdvancedFilters(params.advancedFilters)}
  `;
}
export const businessManagementStoreModel: BusinessManagementModel = {
  cachedBusinesses: new Map<string, BusinessListV4Response & { isFetching: boolean }>(),
  paginatedBusinesses: {
    size: 0,
    data: [],
    from: 0,
    page: 0,
    to: 0,
    total: 0,
    isFetching: false,
    cacheKey: '',
  },
  createBusiness: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.createBusiness(payload.accountId, payload.businessData);
    return response?.data as BusinessResponse;
  }),
  editBusiness: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.editBusiness(payload.accountId, payload.businessData, payload.businessId);
    return response?.data as BusinessResponse;
  }),
  getBusinessList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getBusinessList(payload.params);
    return response?.data as BusinessListResponse;
  }),
  setBusinessList: /* istanbul ignore next */ action(
    /* istanbul ignore next */
    (state, payload) => {
      const updatedPayload = { ...payload, isFetching: false };
      state.paginatedBusinesses = updatedPayload;
    },
  ),
  setCachedBusinessList: /* istanbul ignore next */ action(
    /* istanbul ignore next */
    (state, payload) => {
      state.cachedBusinesses.set(payload.cacheKey, payload);
    },
  ),
  setBusinessFetching: /* istanbul ignore next */ action(
    /* istanbul ignore next */ (state, payload) => {
      state.paginatedBusinesses.isFetching = payload;
    },
  ),
  /* istanbul ignore next */
  resetBusinessWithPositionAndCandidate: /* istanbul ignore next */ action(
    /* istanbul ignore next */ (state, payload) => {
      const { resetFetching = false } = payload;
      state.paginatedBusinesses = {
        size: 0,
        data: [],
        from: 0,
        page: 0,
        to: 0,
        total: 0,
        // isFetching: false,
        isFetching: resetFetching ? false : state.paginatedBusinesses.isFetching,
        cacheKey: '',
      };
    },
  ),
  /* istanbul ignore next */
  getBusinessWithPositionAndCandidate: /* istanbul ignore next */ thunk(
    /* istanbul ignore next */
    async (_actions, payload, { getStoreState, getState }) => {
      try {
        _actions.setBusinessFetching(true);
        const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
        const cacheKey = generateApiCacheKey(payload.params);
        const cachedData = getState().cachedBusinesses.get(cacheKey);
        if (cachedData) {
          if (cachedData.page >= payload.params.page) {
            _actions.setBusinessList({ ...cachedData, cacheKey });
            return cachedData;
          }
          const response = await controller.getBusinessWithOpenPositions(payload.params);
          const data = response?.data as BusinessListV4Response;
          const paginatedBusinesses = {
            size: cachedData.size + data.size,
            data: [...cachedData.data, ...data.data],
            from: cachedData.from + 100,
            page: data.page,
            to: data.to,
            total: data.total,
            isFetching: false,
          };
          _actions.setBusinessList({ ...paginatedBusinesses, cacheKey });
          _actions.setCachedBusinessList({ ...paginatedBusinesses, cacheKey });
          return data;
        }
        _actions.resetBusinessWithPositionAndCandidate({ resetFetching: false });
        const response = await controller.getBusinessWithOpenPositions(payload.params);
        const data = response?.data as BusinessListV4Response;
        _actions.setBusinessList({ ...data, cacheKey: generateApiCacheKey(payload.params), isFetching: false });
        _actions.setCachedBusinessList({ ...data, cacheKey: generateApiCacheKey(payload.params), isFetching: false });
        return data;
      } finally {
        _actions.setBusinessFetching(false);
      }
    },
  ),
};
