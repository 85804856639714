import { Button, HStack } from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';

export default function ButtonSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <HStack align="flex-start">
      <Button variant="outline" size="sm" colorScheme="blue" borderRadius={20}>
        Confirm
      </Button>
    </HStack>
  );
}
