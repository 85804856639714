/* istanbul ignore file */
import React, { Fragment, ChangeEvent, useCallback, useEffect, useState, useRef } from 'react';
import { Flex, Box, Button, useToast, Heading, Text, Spacer, Center } from '@chakra-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import { RiCheckFill, RiCloseFill } from 'react-icons/ri';
import _includes from 'lodash/includes';
import _findIndex from 'lodash/findIndex';
import { createJobPositionStyle, RCStepsStyles } from './CreateJobPositionStyle';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import {
  FreeFormQuestion,
  IndustryType,
  Interview,
  JobPosition,
  JobType,
  JopPositionResponse,
  MultipleChoiceQuestion,
  OccupationType,
  Question,
  Schedule,
  SkillForPosition,
} from '../PositionType';
import { clearErrorMessage, errorFormat, FormattedError, updateObject } from '../../../../utils/FormErrorUtils';
import useBusinessLocations from '../useBusinessLocations';
import { JobPositionDetailCard } from './JobPositionDetailCard';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { AdvanceFieldsCard } from './AdvanceFieldsCard';
import { WorkScheduleCard } from './WorkScheduleCard';
import { AutomationCard } from './AutomationCard';
import { SpecificQuestionsCard } from './SpecificQuestionsCard';
import { InterviewCard } from './InterviewCard';
import { FreeFormQuestionsCard } from './FreeFormQuestionsCard';
import { AutomationTypes } from './AutomationTypes';
import { VideoDetailCard } from './VideoDetailCard';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { usePositionManagementContext } from '../PositionManagementContext';
import { Business } from '../BusinessType';
import { CertnBackgroundCheckCard } from './CertnBackgroundCheckCard';
import { BackgroundChecks } from '../../../../firebase/firestore/documents/candidate';
import { LanguageEvaluationPhrases } from '../../../../firebase/firestore/documents/languageEvaluationPhrases';

const initialQuestion = {
  text: '',
  preferredAnswer: true,
  knockout: false,
};
const initialFreeFormQuestion = {
  text: '',
  isActive: true,
};
const correspondingValue = {
  day: 'AM_PM',
  evening: '6_TO_11',
  night: 'NIGHT',
} as { [index: string]: any };

export type CreateJobsViewProps = {
  positionDataToEdit: JopPositionResponse | null;
  redirectToPositionAfterCreated: (jobPositionData: JobPosition) => void;
  selectedBusiness: Business | null;
  setCreateNewPosition: (createNewPosition: boolean) => void;
  updatePositionAutomation: (
    givenPositionId: string,
    givenAutomationType: string | null,
    screeningTarget: number | undefined,
    shouldUpdateAutomationApiCalled?: boolean,
    schedulingVrEnabled?: boolean,
  ) => void;
};

export const CreateJobsView = ({
  positionDataToEdit,
  redirectToPositionAfterCreated,
  selectedBusiness,
  setCreateNewPosition,
  updatePositionAutomation,
}: CreateJobsViewProps): JSX.Element => {
  const initialSalaryData = {
    customUnit: '',
    maxValue: '',
    minValue: '',
    unit: 'hourly',
  };

  const initialInterviewData = {
    interviewType: 'video',
    interviewDuration: 30,
    interviewMessage: '',
    interviewLocation: '',
  };

  const initialWorkSchedule = {
    sun: { day: '', evening: '', night: '' },
    mon: { day: '', evening: '', night: '' },
    tue: { day: '', evening: '', night: '' },
    wed: { day: '', evening: '', night: '' },
    thu: { day: '', evening: '', night: '' },
    fri: { day: '', evening: '', night: '' },
    sat: { day: '', evening: '', night: '' },
  } as { [index: string]: Schedule };

  const DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD = 2;

  const initialJobPositionData = {
    automated: false,
    automationType: null,
    industryName: '',
    industry: '',
    occupation: '',
    job: '',
    jobCustomName: '',
    business: '',
    businessName: '',
    businessAddress: '',
    noOfOpenings: null,
    proposedSalary: initialSalaryData,
    experienceFilterType: null,
    requiredExperience: 0,
    jobDescription: '',
    fullTime: true,
    interview: initialInterviewData,
    specificQuestions: ([] as unknown) as Question[],
    freeFormQuestions: ([] as unknown) as FreeFormQuestion[],
    multipleChoiceQuestions: ([] as unknown) as MultipleChoiceQuestion[],
    schedule: initialWorkSchedule,
    enableScreeningOptions: false,
    skills: [],
    enableHireFormSolution: false,
    modernHireEnable: false,
    modernHirePassThreshold: 0,
    modernHireTemplateId: '',
    languageEvaluationPassThreshold: DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD,
  };
  const [activeStep, setActiveStep] = useState(0);
  const [stepsTaken, setStepsTaken] = useState([0]);
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [jobList, setJobList] = useState<JobType[]>([]);
  const [occupationList, setOccupationList] = useState<OccupationType[]>([]);
  const [jobPositionData, setJobPositionData] = useState<JobPosition>(initialJobPositionData);
  const [isInterviewAndBusinessLocationSame, setIsInterviewAndBusinessLocationSame] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFreeFormQuestion, setFreeFormQuestion] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [salaryError, setSalaryError] = useState<FormattedError[]>([]);
  const jobPositionRef = useRef<null | HTMLDivElement>(null);
  const [overviewVideoFile, setOverviewVideoFile] = useState<File | null>(null);
  const [overviewThumbnailFile, setOverviewThumbnailFile] = useState<File | null>(null);

  const { t, i18n } = useTranslation();
  const toast = useToast();
  const {
    getIndustries,
    getJobList,
    getOccupationList,
    createJobPosition,
    updateJobPosition,
    uploadOverviewVideo,
    uploadOverviewThumbnail,
    uploadOverviewVideoConfirmCall,
  } = useStoreActions((actions) => actions.positionManagement);
  const { user, accounts } = useStoreState((s) => s.app);
  const isCertnBackgroundEnabled = useStoreState((s) => s.app.accounts?.configuration?.certnEnabled);
  const businessLocations = useBusinessLocations();
  const enableToggleOfBackgroundCheckOnPositionLevel: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL];
  });

  const { businessData, setPositionQueryParams, positionQueryParams } = usePositionManagementContext();
  const loadIndustriesJobsOccupation = useCallback(async () => {
    const industriesResponse = await getIndustries(null);
    const jobListResponse = await getJobList(null);
    const occupationListResponse = await getOccupationList(null);

    setIndustries(industriesResponse.industries);
    setOccupationList(occupationListResponse.occupations);
    setJobList(jobListResponse.jobs);
  }, [getIndustries, getJobList, getOccupationList]);

  const getIndustryName = (value: any) => {
    // eslint-disable-next-line radix
    return getTranslatedValue(industries.filter((industry) => industry.id === parseInt(value))[0].name, i18n.language);
  };
  const isRequisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);

  const interviewSchema = Joi.object().keys({
    interviewType: Joi.string()
      .required()
      .messages({
        'string.empty': t('createJobPosition:interviewTypeRequired'),
      }),
    interviewDuration: Joi.number()
      .required()
      .messages({
        'number.base': t('createJobPosition:interviewDurationRequired'),
      }),
    interviewMessage: Joi.string().trim(true).required().allow('').optional(),
    interviewLocation:
      jobPositionData.interview.interviewType === 'inPerson'
        ? Joi.string()
            .trim(true)
            .required()
            .messages({
              'string.empty': t('createJobPosition:interviewLocationRequired'),
            })
        : Joi.string().allow('').optional(),
  });

  const automationConfigurationSchema = Joi.object().keys({
    target: !_isNil(jobPositionData.automationConfiguration)
      ? Joi.number()
          .min(1)
          .required()
          .messages({
            'number.base': t('createJobPosition:automationConfigurationRequired'),
            'number.min': t('createJobPosition:automationConfigurationMinRequired'),
          })
      : Joi.number().allow(null).optional(),
  });

  const proposedSalarySchema = Joi.object()
    .optional()
    .keys({
      unit: Joi.string().optional(),
      minValue: Joi.string().allow(null).allow('').optional(),
      maxValue: Joi.string().allow(null).allow('').optional(),
      customUnit:
        jobPositionData.proposedSalary.unit === 'custom'
          ? Joi.string()
              .required()
              .messages({
                'string.empty': t('createJobPosition:unitRequired'),
              })
          : Joi.string().allow('').allow(null).optional(),
    });

  const jobDetailSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      industry: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:industryRequired'),
        }),
      occupation: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:occupationRequired'),
        }),
      job: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobRequired'),
        }),
      jobCustomName: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobTitleRequired'),
        }),

      noOfOpenings: isRequisitionEnabled
        ? Joi.number().allow(null).optional()
        : Joi.number()
            .required()
            .messages({
              'number.base': t('createJobPosition:noOfOpeningsRequired'),
            }),
      experienceFilterType: Joi.string().allow('').allow(null).optional(),
      requiredExperience: Joi.number().allow('').allow(null).optional(),
      jobDescription: Joi.string().allow('').optional(),
      modernHirePassThreshold: Joi.number().when('modernHireEnable', {
        is: Joi.boolean().valid(true),
        then: Joi.number()
          .min(1)
          .positive()
          .required()
          .messages({
            'string.empty': t('createJobPosition:modernHireThresholdValueNotEmptyError'),
            'number.min': t('createJobPosition:modernHireThresholdValueNotZeorError'),
            'any.required': t('createJobPosition:modernHireThresholdValueNotEmptyError'),
          }),
        otherwise: Joi.allow('').allow(null),
      }),
      languageEvaluationPassThreshold: Joi.number()
        .required()
        .messages({
          'any.required': t('createJobPosition:languageThresholdValueEmptyError'),
        }),
      modernHireTemplateId: Joi.string().when('modernHireEnable', {
        is: Joi.boolean().valid(true),
        then: Joi.string()
          .required()
          .messages({
            'string.empty': t('createJobPosition:modernHireTemplateIdError'),
            'any.required': t('createJobPosition:modernHireTemplateIdError'),
          }),
        otherwise: Joi.allow('').allow(null),
      }),
      proposedSalary: proposedSalarySchema,
    })
    .unknown(true);

  const additionalDetailSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      interview: interviewSchema,
    })
    .unknown(true);

  const automationDetailSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      automationConfiguration: automationConfigurationSchema,
    })
    .unknown(true);

  const defaultSchema = Joi.object().unknown(true);

  const formValidation = (formData: any, scheme: Joi.ObjectSchema) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = scheme.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }
    setFormErrors([]);
    return true;
  };

  const handleChange = (value: string | Date | number | boolean | null, fieldName: string, dataType = false) => {
    updateObject(jobPositionData, fieldName, value, dataType);

    if (fieldName === 'industry') {
      jobPositionData.occupation = '';
      jobPositionData.job = '';
      jobPositionData.industryName = getIndustryName(value);
    }

    if (fieldName === 'occupation') {
      jobPositionData.job = '';
    }

    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setJobPositionData({ ...jobPositionData });
  };

  const handleSalaryChange = (value: string, fieldName: string) => {
    const translated = !/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(value);
    let allowInput = true;

    const spliterator = value.includes('.') ? value.split('.') : value.split(',');
    if (spliterator[1]) {
      if (spliterator[1].length > 2) {
        allowInput = false;
      }
    }
    if (allowInput) {
      setJobPositionData((prevData) => ({
        ...prevData,
        proposedSalary: {
          ...prevData.proposedSalary,
          [fieldName]: value.toString().replace(',', '.'),
        },
      }));

      if (value === '' || !translated) {
        setSalaryError([]);
      } else {
        const hasAlreadyError = salaryError.filter((err) => err.label === fieldName);
        if (hasAlreadyError.length < 1) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:invalidNumberFormat'),
            },
          ]);
        }
      }

      if (fieldName === 'maxValue') {
        if (jobPositionData.proposedSalary.minValue !== '' && jobPositionData.proposedSalary.minValue > parseFloat(value)) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:maxValueShouldBeGreaterThanMinValue', {
                minValue: jobPositionData.proposedSalary.minValue,
              }),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.minValue === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: 'minValue',
              message: t('createJobPosition:minValueRequired'),
            },
          ]);
        }

        if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(jobPositionData.proposedSalary.minValue.toString())) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: 'minValue',
              message: t('createJobPosition:invalidNumberFormat'),
            },
          ]);
        }
        if (!_isNil(jobPositionData.proposedSalary.minValue) && value === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:maxValueRequired'),
            },
          ]);
        }
        if (value === '') {
          setSalaryError([]);
        }
        if (jobPositionData.proposedSalary.minValue === '' && value === '') {
          setSalaryError([]);
        }
      }

      if (fieldName === 'minValue') {
        if (jobPositionData.proposedSalary.maxValue !== '' && jobPositionData.proposedSalary.maxValue < parseFloat(value)) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:minValueShouldBeLowerThanMaxValue', {
                maxValue: jobPositionData.proposedSalary.maxValue,
              }),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.maxValue !== '') {
          if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(jobPositionData.proposedSalary.maxValue.toString())) {
            setSalaryError((prevData) => [
              ...prevData,
              {
                label: 'maxValue',
                message: t('createJobPosition:invalidNumberFormat'),
              },
            ]);
          }
        }

        if (!_isNil(jobPositionData.proposedSalary.maxValue) && value === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:minValueRequired'),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.maxValue === '' && value === '') {
          setSalaryError([]);
        }
      }
    }
  };

  const handleAutomationChange = (value: string | null, fieldName: string, dataType = false) => {
    updateObject(jobPositionData, fieldName, value, dataType);
    if (value === AutomationTypes.SCREENING) {
      jobPositionData.automationType = AutomationTypes.SCREENING;
      jobPositionData.automated = true;
      jobPositionData.automationConfiguration = { target: null };
    } else if (value === AutomationTypes.SCHEDULING) {
      jobPositionData.automationType = AutomationTypes.SCHEDULING;
      jobPositionData.automated = true;
      delete jobPositionData.automationConfiguration;
    } else {
      jobPositionData.automationType = null;
      jobPositionData.automated = false;
      delete jobPositionData.automationConfiguration;
    }
    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setJobPositionData({ ...jobPositionData });
  };

  const handleJobChange = (value: string, fieldName: string) => {
    let skillList: SkillForPosition[] = [];
    /* istanbul ignore else */
    if (!_isEmpty(value)) {
      // eslint-disable-next-line radix
      const filterSkills = jobList.filter((job) => job.id === parseInt(value));
      skillList = filterSkills[0].skills.map((skill) => ({
        id: skill.id,
        value: 0.5,
      }));
    }

    setJobPositionData((prevData) => ({
      ...prevData,
      [fieldName]: value,
      skills: skillList,
    }));
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const isOverviewVideoFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_OVERVIEW_VIDEO];
  });

  const handleScheduleChange = (event: ChangeEvent<HTMLInputElement>, value: string, fieldName: string) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      schedule: {
        ...prevData.schedule,
        [fieldName]: {
          ...prevData.schedule[fieldName],
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [value]: event.target.checked ? correspondingValue[value] : '',
        },
      },
    }));
  };

  const handleSkillChange = (value: number, fieldName: number) => {
    Object.assign(
      jobPositionData.skills.find((skill) => skill.id === fieldName),
      { value },
    );

    setJobPositionData((prevData) => ({
      ...prevData,
      skills: jobPositionData.skills,
    }));
  };

  const handleLanguageEvaluationPhrase = (checked: boolean, language: string, phrase: LanguageEvaluationPhrases) => {
    const formatPhrase = {
      language,
      phrase: phrase.description || '',
      phraseId: phrase.id || '',
    };

    if (checked) {
      jobPositionData.languageEvaluationTemplates?.push(formatPhrase);
    } else {
      const indexOfItem = _findIndex(jobPositionData.languageEvaluationTemplates, { phraseId: phrase.id });
      if (indexOfItem > -1) {
        jobPositionData.languageEvaluationTemplates?.splice(indexOfItem, 1);
      }
    }

    setJobPositionData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: jobPositionData.languageEvaluationTemplates,
    }));
  };

  const handleLanguageEvaluation = (checked: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: checked ? [] : undefined,
    }));
  };

  const handleLanguageChange = () => {
    setJobPositionData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: [],
    }));
  };

  const handleChangeInterviewLocation = (isSameLocation: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      interview: {
        ...prevData.interview,
        interviewLocation: isSameLocation ? jobPositionData.businessAddress : '',
      },
    }));
    setIsInterviewAndBusinessLocationSame(isSameLocation);
    setFormErrors(clearErrorMessage(formErrors, 'interview.interviewLocation'));
  };

  const deleteQuestion = (questionId: number | undefined) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      specificQuestions: jobPositionData.specificQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  // eslint-disable-next-line consistent-return
  const addQuestion = (question: Question, questionEditMode: number | undefined) => {
    if (_isNil(questionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        specificQuestions: [...prevData.specificQuestions, { ...question }],
      }));
    } else {
      Object.assign(
        jobPositionData.specificQuestions.find((ques, index) => index === questionEditMode),
        { ...question },
      );
      setJobPositionData((prevData) => ({
        ...prevData,
        specificQuestions: jobPositionData.specificQuestions,
      }));
    }
  };

  const deleteFreeFormQuestion = (questionId: number | undefined) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      freeFormQuestions: jobPositionData.freeFormQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  // eslint-disable-next-line consistent-return
  const addFreeFormQuestion = (freeFormQuestion: FreeFormQuestion, freeFormQuestionEditMode: number | undefined) => {
    if (_isNil(freeFormQuestionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        freeFormQuestions: [...prevData.freeFormQuestions, { ...freeFormQuestion }],
      }));
    } else {
      Object.assign(
        jobPositionData.freeFormQuestions.find((ques, index) => index === freeFormQuestionEditMode),
        { ...freeFormQuestion },
      );
      setJobPositionData((prevData) => ({
        ...prevData,
        freeFormQuestions: jobPositionData.freeFormQuestions,
      }));
    }
  };
  const handleEnableCertnBackgroundCheck = (checked: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      enableScreeningOptions: checked,
    }));
  };

  const setBackgroundCheckValue = (checked: boolean, field: string) => {
    if (!_isNil(jobPositionData.screeningOptions)) {
      let selectedValues: BackgroundChecks = { ...jobPositionData.screeningOptions };

      if (field === 'request_criminal_record_check') {
        selectedValues = {
          ...selectedValues,
          request_enhanced_identity_verification: true,
          request_enhanced_criminal_record_check: false,
        };
      } else if (field === 'request_enhanced_criminal_record_check') {
        selectedValues = {
          ...selectedValues,
          request_enhanced_identity_verification: true,
          request_criminal_record_check: false,
        };
      } else if (field === 'request_enhanced_identity_verification') {
        selectedValues = {
          ...selectedValues,
          request_criminal_record_check: false,
          request_enhanced_criminal_record_check: false,
        };
      } else if (field === 'request_us_criminal_record_check_tier_1') {
        selectedValues = {
          ...selectedValues,
          request_us_criminal_record_check_tier_2: false,
        };
      } else if (field === 'request_us_criminal_record_check_tier_2') {
        selectedValues = {
          ...selectedValues,
          request_us_criminal_record_check_tier_1: false,
        };
      }

      selectedValues = {
        ...selectedValues,
        [field]: checked,
      };

      setJobPositionData((prevData) => ({
        ...prevData,
        screeningOptions: selectedValues,
      }));
    }
  };

  const handleOverviewVideoUpload = async ({
    userId,
    positionId,
    overviewVideo,
    overviewThumbnail,
  }: {
    userId: string;
    positionId: string;
    overviewVideo: File;
    overviewThumbnail: File;
  }) => {
    await uploadOverviewVideo({ userId, positionId, overviewVideo });
    await uploadOverviewThumbnail({ userId, positionId, overviewThumbnail });
    await uploadOverviewVideoConfirmCall({ positionId });
  };

  const saveJobPosition = async () => {
    if (user) {
      setIsLoading(true);
      if (_isNil(accounts?.configuration?.certnEnabled) || !accounts?.configuration?.certnEnabled) {
        jobPositionData.screeningOptions = undefined;
      }

      if (!enableToggleOfBackgroundCheckOnPositionLevel) {
        jobPositionData.enableScreeningOptions = undefined;
      }

      if (_isNil(positionDataToEdit)) {
        if (selectedBusiness) {
          jobPositionData.business = selectedBusiness.id;
          jobPositionData.businessAddress = selectedBusiness.completeAddress;
          jobPositionData.businessName = selectedBusiness.name;
        }
        if (!accounts?.subscription?.automation) {
          jobPositionData.automationType = null;
          jobPositionData.automated = false;
        }
        const newPostition = await createJobPosition({
          accountId: user.account,
          jobPositionData,
        }).finally(() => {
          setIsLoading(false);
        });
        if (overviewVideoFile && overviewThumbnailFile && isOverviewVideoFeatureEnabled) {
          await handleOverviewVideoUpload({
            userId: user.id,
            positionId: newPostition.positionId,
            overviewVideo: overviewVideoFile,
            overviewThumbnail: overviewThumbnailFile,
          });
        }
        setIsLoading(false);
        setJobPositionData(initialJobPositionData);
        toast({
          title: t('createJobPosition:jobCreatedSuccessMessage'),
          status: 'info',
          duration: 6000,
          isClosable: true,
        });
        const currentBusinessLocation = businessData.find((business) => business.id === jobPositionData.business);
        if (currentBusinessLocation) {
          const newpositionQueryParams = positionQueryParams;
          newpositionQueryParams.positionStatus = 'closed';
          setPositionQueryParams({ ...newpositionQueryParams });
        }
      } else {
        await updateJobPosition({
          accountId: user.account,
          jobPositionData,
          positionId: positionDataToEdit?.id,
        })
          .then(async () => {
            updatePositionAutomation(
              positionDataToEdit?.id,
              positionDataToEdit?.automationType,
              positionDataToEdit?.automationConfiguration?.target
                ? positionDataToEdit?.automationConfiguration?.target
                : undefined,
              false,
              positionDataToEdit.virtualRecruiterEnabled,
            );
            if (overviewVideoFile && overviewThumbnailFile && isOverviewVideoFeatureEnabled) {
              // Put video upload logic here
              await handleOverviewVideoUpload({
                userId: user.id,
                positionId: positionDataToEdit.id,
                overviewVideo: overviewVideoFile,
                overviewThumbnail: overviewThumbnailFile,
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
        setTimeout(() => {
          setJobPositionData(initialJobPositionData);
          setActiveStep(0);
        }, 2000);
        toast({
          title: t('createJobPosition:jobUpdatedSuccessMessage'),
          status: 'info',
          duration: 6000,
          isClosable: true,
        });
      }
      redirectToPositionAfterCreated(jobPositionData);
    }
  };

  const jobDetail: JSX.Element = (
    <Fragment>
      <JobPositionDetailCard
        jobPositionData={jobPositionData}
        industries={industries}
        occupationList={occupationList}
        jobList={jobList}
        formErrors={formErrors}
        salaryErrors={salaryError}
        positionDataToEdit={!!positionDataToEdit}
        handleJobChange={handleJobChange}
        handleChange={handleChange}
        handleSalaryChange={handleSalaryChange}
        handleLanguageEvaluationPhrase={handleLanguageEvaluationPhrase}
        handleLanguageEvaluation={handleLanguageEvaluation}
        handleLanguageChange={handleLanguageChange}
      />
    </Fragment>
  );

  const questionsDetail: JSX.Element = (
    <Fragment>
      <Box mb={showFreeFormQuestion ? 0 : 6}>
        <SpecificQuestionsCard
          jobPositionData={jobPositionData}
          deleteQuestion={deleteQuestion}
          addQuestion={addQuestion}
          showQuestionForm
          questionToEdit={initialQuestion}
          questionToEditMode={undefined}
          onRefreshState={() => {}}
        />
      </Box>
      {showFreeFormQuestion && (
        <FreeFormQuestionsCard
          jobPositionData={jobPositionData}
          deleteFreeFormQuestion={deleteFreeFormQuestion}
          addFreeFormQuestion={addFreeFormQuestion}
          showQuestionForm
          questionToEdit={initialFreeFormQuestion}
          questionToEditMode={undefined}
          onRefreshState={() => {}}
        />
      )}
    </Fragment>
  );

  const additionalDetail: JSX.Element = (
    <Fragment>
      <InterviewCard
        jobPositionData={jobPositionData}
        formErrors={formErrors}
        isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
        handleChange={handleChange}
        handleChangeInterviewLocation={handleChangeInterviewLocation}
      />
      <AdvanceFieldsCard jobPositionData={jobPositionData} jobList={jobList} handleSkillChange={handleSkillChange} />
      {isCertnBackgroundEnabled && (
        <Fragment>
          <CertnBackgroundCheckCard
            jobPositionData={jobPositionData}
            formErrors={formErrors}
            setBackgroundCheckValue={setBackgroundCheckValue}
            setEnableCertnBackgroundCheck={handleEnableCertnBackgroundCheck}
          />
        </Fragment>
      )}
    </Fragment>
  );

  const automationDetail: JSX.Element = (
    <Fragment>
      <AutomationCard
        jobPositionData={jobPositionData}
        formErrors={formErrors}
        isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
        handleChange={handleChange}
        handleAutomationChange={handleAutomationChange}
        handleChangeInterviewLocation={handleChangeInterviewLocation}
        removePadding={false}
      />
    </Fragment>
  );

  const videoDetail: JSX.Element = (
    <Fragment>
      <VideoDetailCard
        positionId={_isNil(positionDataToEdit) ? null : positionDataToEdit.id}
        changeOverviewVideo={(fileToUpload: File | null, thumbnail?: File) => {
          setOverviewVideoFile(fileToUpload);
          if (thumbnail) setOverviewThumbnailFile(thumbnail);
        }}
        saveVideoUrl={() => {}}
        onAddVideoToPosition={() => {}}
        jobPositionData={jobPositionData}
      />
    </Fragment>
  );

  const availabilityDetail: JSX.Element = (
    <WorkScheduleCard jobPositionData={jobPositionData} handleScheduleChange={handleScheduleChange} handleChange={handleChange} />
  );

  let steps = [
    {
      title: t('createJobPosition:steps.jobDetail'),
      content: jobDetail,
    },
    {
      title: t('createJobPosition:steps.additionalDetail'),
      content: additionalDetail,
    },
    {
      title: t('createJobPosition:steps.videoDetails'),
      content: videoDetail,
    },
    {
      title: t('createJobPosition:steps.questions'),
      content: questionsDetail,
    },
    {
      title: t('createJobPosition:steps.automation'),
      content: automationDetail,
    },
    {
      title: t('createJobPosition:steps.availability'),
      content: availabilityDetail,
    },
  ];

  const getSchemaForStep = (step: number) => {
    switch (step) {
      case 0:
        return jobDetailSchema;
      case 1:
        return additionalDetailSchema;
      case 3:
      case 4:
        return automationDetailSchema;
      default:
        return defaultSchema;
    }
  };

  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);

  // eslint-disable-next-line consistent-return
  const handleNext = () => {
    if (activeStep === 0 || activeStep === 1 || activeStep === 3 || activeStep === 4) {
      const validate = formValidation(jobPositionData, getSchemaForStep(activeStep));

      if (!validate || salaryError.length > 0) {
        return false;
      }
    }

    if (activeStep < 5) {
      setStepsTaken((currentSteps) => [...currentSteps, activeStep + 1]);
      setActiveStep((currenStep) => currenStep + 1);
    }
    jobPositionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleBack = () => {
    setActiveStep((currenStep) => currenStep - 1);
  };

  const getInterviewData = (interview: Interview) => {
    return {
      interviewType: interview.interviewType ? interview.interviewType : 'video',
      interviewDuration: interview.interviewDuration ? interview.interviewDuration : 30,
      interviewMessage: interview.interviewMessage ? interview.interviewMessage : '',
      interviewLocation: interview.interviewLocation ? interview.interviewLocation : '',
    } as Interview;
  };

  const icons = {
    finish: <RiCheckFill style={{ height: '22px', width: '22px', marginTop: '2px' }} />,
    error: <RiCloseFill style={{ height: '22px', width: '22px', marginTop: '2px' }} />,
  };

  const loadSkillsOfJobType = (jobId: string): SkillForPosition[] => {
    const { skills } = jobList.filter((job) => job.id.toString() === jobId)[0];
    if (skills.length > 0) {
      return skills.map((skill) => ({
        id: skill.id,
        value: skill.value,
      }));
    }
    return [];
  };

  const getBusinessAddress = (businessId: string) => {
    const selectedBusinessLocation = businessLocations.find((businessLocation) => businessLocation.id === businessId);

    if (selectedBusinessLocation) {
      return selectedBusinessLocation.label;
    }
    return '';
  };

  useEffect(() => {
    const isFreeFormQuestionEnabled = accounts?.configuration?.freeFormQuestionEnabled === true;
    setFreeFormQuestion(isFreeFormQuestionEnabled);
  }, [accounts]);

  useEffect(() => {
    jobPositionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    loadIndustriesJobsOccupation().catch(/* istanbul ignore next */ (err) => console.log(err));
  }, [loadIndustriesJobsOccupation]);

  useEffect(() => {
    if (!_isNil(positionDataToEdit) && jobList.length > 0) {
      const jobTypeId = positionDataToEdit?.job;
      const industryId = jobList.filter((jobItem) => jobItem.id.toString() === jobTypeId)[0].industry;
      const occupationId = jobList.filter((jobItem) => jobItem.id.toString() === jobTypeId)[0].occupation;
      const proposedSalary = positionDataToEdit?.proposedSalary
        ? {
            ...positionDataToEdit?.proposedSalary,
            minValue: positionDataToEdit?.proposedSalary.minValue
              ? (positionDataToEdit?.proposedSalary.minValue / 100).toString()
              : '',
            maxValue: positionDataToEdit?.proposedSalary.maxValue
              ? (positionDataToEdit?.proposedSalary.maxValue / 100).toString()
              : '',
          }
        : initialSalaryData;

      setJobPositionData((prevData) => ({
        ...prevData,
        industryName: getTranslatedValue(industries.filter((industry) => industry?.id === industryId)[0]?.name, i18n.language),
        industry: industryId.toString(),
        occupation: occupationId.toString(),
        job: positionDataToEdit?.job,
        jobCustomName: positionDataToEdit?.customName ? positionDataToEdit?.customName : positionDataToEdit?.jobCustomName,
        business: positionDataToEdit?.business,
        businessName: positionDataToEdit?.businessName,
        businessAddress: positionDataToEdit.business ? getBusinessAddress(positionDataToEdit?.business) : '',
        noOfOpenings: positionDataToEdit.noOfOpenings ? positionDataToEdit.noOfOpenings : null,
        proposedSalary,
        experienceFilterType: positionDataToEdit.experienceFilterType ? positionDataToEdit.experienceFilterType : null,
        requiredExperience: positionDataToEdit.requiredExperience ? positionDataToEdit.requiredExperience : null,
        jobDescription: positionDataToEdit.description ? positionDataToEdit.description : '',
        fullTime: positionDataToEdit.fullTime ? positionDataToEdit?.fullTime : false,
        interview: positionDataToEdit.interview ? getInterviewData(positionDataToEdit.interview) : initialInterviewData,
        specificQuestions: positionDataToEdit?.specificQuestions ? positionDataToEdit.specificQuestions : [],
        freeFormQuestions: positionDataToEdit?.freeFormQuestions ? positionDataToEdit.freeFormQuestions : [],
        schedule: positionDataToEdit?.schedule ? positionDataToEdit?.schedule : initialWorkSchedule,
        skills: positionDataToEdit?.skills ? positionDataToEdit?.skills : loadSkillsOfJobType(positionDataToEdit?.job),
        enableHireFormSolution: _includes(hireFormEnabledPositions, positionDataToEdit.id),
        modernHireEnable: positionDataToEdit?.modernHireEnable,
        modernHireTemplateId: positionDataToEdit?.modernHireTemplateId,
        automationType: positionDataToEdit.automationType ? positionDataToEdit.automationType : null,
        automated: positionDataToEdit.automated ? positionDataToEdit.automated : false,
        automationConfiguration:
          positionDataToEdit.automationType === 'screening' && positionDataToEdit.automationConfiguration
            ? { target: positionDataToEdit.automationConfiguration.target }
            : undefined,
        languageEvaluationTemplates: positionDataToEdit?.languageEvaluationTemplates,
      }));
    } else if (accounts?.automated) {
      jobPositionData.automationType = AutomationTypes.SCHEDULING;
      jobPositionData.automated = true;
      setJobPositionData(jobPositionData);
    } else {
      setJobPositionData(initialJobPositionData);
    }
    setActiveStep(0);
    setFormErrors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobList, positionDataToEdit]);

  const filteredSteps = () => {
    if (!accounts?.subscription?.automation) {
      steps = steps.filter((s) => s.title !== t('createJobPosition:steps.automation'));
    }
    if (!isOverviewVideoFeatureEnabled) {
      steps = steps.filter((s) => s.title !== t('createJobPosition:steps.videoDetails'));
    }
    return steps;
  };

  const loadSubmitLabel = () => {
    if (_isNil(positionDataToEdit)) {
      return t('createJobPosition:stepsNav.submit');
    }
    return t('createJobPosition:stepsNav.update');
  };

  return (
    <Box css={createJobPositionStyle} ref={jobPositionRef}>
      <Box background="#ffffff" px={6} py={4} marginBottom="12px">
        <Box marginBottom="12px">
          <Flex>
            <Box>
              <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
                {_isNil(positionDataToEdit) ? t('jobPosition:createNewPosition') : t('jobPosition:editPosition')}
              </Heading>
              {selectedBusiness && (
                <Text fontSize="xs" color="gray.500" fontWeight={900}>
                  {_isNil(positionDataToEdit) ? t('jobPosition:newJobFor') : `${t('jobPosition:updateJobPosition')}`}
                  {` ${selectedBusiness.name}, ${selectedBusiness.completeAddress}`}
                </Text>
              )}
            </Box>
            <Spacer />
            <Center>
              <Button
                colorScheme="blue"
                data-testid="createBusinessCloseBtn"
                className="closeIcon"
                onClick={() => setCreateNewPosition(false)}
                // onClick={() => redirectToPositionAfterCreated(false)}
              >
                {t('jobPosition:viewPositionList')}
              </Button>
            </Center>
          </Flex>
        </Box>

        <Box css={RCStepsStyles} marginTop="10px">
          <Steps
            current={activeStep}
            icons={icons}
            onChange={(val) =>
              !_isNil(positionDataToEdit) || activeStep > val || stepsTaken.includes(val) ? setActiveStep(val) : ''
            }
          >
            {filteredSteps().map((s, index) => (
              <Step
                key={s.title}
                title={s.title}
                className={!_isNil(positionDataToEdit) || stepsTaken.includes(index) ? 'cursorPointer' : 'cursorNotAllowed'}
              />
            ))}
          </Steps>
        </Box>
      </Box>

      {filteredSteps().map((s, index) => (
        <Box key={s.title}>{index === activeStep ? s.content : ''}</Box>
      ))}

      <Flex justifyContent="flex-end">
        {activeStep !== 0 && (
          <Button data-testid="BackBtn" onClick={handleBack}>
            {t('createJobPosition:stepsNav.back')}
          </Button>
        )}
        <Button
          isLoading={isLoading}
          data-testid="NextBtn"
          colorScheme="blue"
          onClick={activeStep === steps.length - 1 ? saveJobPosition : handleNext}
          ml={3}
        >
          {activeStep === steps.length - 1 ? loadSubmitLabel() : t('createJobPosition:stepsNav.next')}
        </Button>
      </Flex>
    </Box>
  );
};
