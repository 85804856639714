import React, { useEffect, useRef, useState } from 'react';
import { Button, Center, Divider, Image, ImageProps, Skeleton, Stack, theme, Tooltip } from '@chakra-ui/core';
import { IoExpandOutline, IoRemove, IoImageOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';
import isNumber from 'lodash/isNumber';
import colors from '../../styles/colors';

enum ImageShape {
  circle = 'circle',
  rounded = 'rounded',
  square = 'square',
}
const IMAGE_SHAPE_VALUE = {
  [ImageShape.circle]: '50%',
  [ImageShape.rounded]: '10px',
  [ImageShape.square]: '0',
} as const;

type ImageShapeType = 'circle' | 'rounded' | 'square';
type ButtonProps = React.ComponentProps<typeof Button>;
type CenterProps = React.ComponentProps<typeof Center>;

interface ImageViewProps extends Omit<ImageProps, 'onClick'> {
  shape?: ImageShapeType;
  onRemove?: () => void;
  onExpand?: () => void;
  wrapperProps?: CenterProps;
  showAddImageIcon?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  iconSize?: number;
  placeholderImage?: React.ReactNode;
  imagePreviewStyles?: React.CSSProperties;
}

/**
 * ImageView component that displays an image with optional expand and remove actions.
 *
 * @param {ImageViewProps} props - The props for the component.
 * @param {string} props.src - The source URL of the image to display.
 * @param {string} props.alt - The alt text for the image.
 * @param {number} [props.height=100] - The height of the image container.
 * @param {number} [props.iconSize=20] - The size of the icons used for actions.
 * @param {ImageShapeType} [props.shape=IMAGE_SHAPE.rounded] - The shape of the image (circle, rounded, square).
 * @param {Function} [props.onRemove] - Callback function invoked when the remove action is triggered.
 * @param {Function} [props.onExpand] - Callback function invoked when the expand action is triggered.
 * @returns {JSX.Element} The rendered ImageView component.
 */
export default function ImageView({
  src,
  alt,
  onClick,
  objectFit = 'cover',
  height,
  iconSize,
  shape,
  onRemove,
  onExpand,
  onError,
  onLoad,
  wrapperProps,
  showAddImageIcon,
  dataTestId,
  placeholderImage,
  imagePreviewStyles,
}: ImageViewProps) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const imageRef = useRef<HTMLDivElement>(null);
  const borderRadius = IMAGE_SHAPE_VALUE?.[shape ?? ImageShape.rounded];

  const showAddImage = !src && showAddImageIcon;

  useEffect(() => {
    const { current } = imageRef;
    /* istanbul ignore next */
    if (!current) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        /* istanbul ignore next */
        if (entries.length > 0 && entries[0].isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entries[0].target);
        }
      },
      {
        rootMargin: '50px',
        threshold: 0,
      },
    );

    observer.observe(current);

    return () => {
      /* istanbul ignore next */
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [imageRef]);
  const containerHeight = isNumber(height) ? height : imageRef.current?.offsetHeight ?? 0;

  return (
    <Center
      ref={imageRef}
      h={height}
      w={height}
      {...omit(wrapperProps, 'style')}
      style={{ aspectRatio: '1/1', ...wrapperProps?.style, cursor: 'pointer' }}
      data-testid="intersection-observer-container"
    >
      {isVisible && (
        <Center
          h="100%"
          w="100%"
          borderRadius={borderRadius}
          style={{ position: 'relative', ...imagePreviewStyles }}
          role="group"
          border={theme.borders['1px']}
          borderColor={colors.blue[100]}
          onClick={() => {
            if (showAddImage) {
              onClick?.();
            }
          }}
          data-testid="image-preview-container"
        >
          {placeholderImage || (showAddImage && <IoImageOutline size={iconSize} data-testid="add-image-icon" />)}
          {!showAddImage && (
            <>
              <Tooltip isDisabled={!error} label={t('administration:imageGalleryModal:errorLoadingImages')} hasArrow>
                <Image
                  src={src}
                  alt={alt}
                  width="100%"
                  height="100%"
                  objectFit={objectFit}
                  data-testid={dataTestId}
                  borderRadius={borderRadius}
                  onError={(e) => {
                    setError(true);
                    onError?.(e);
                  }}
                  onLoad={(e) => {
                    setError(false);
                    onLoad?.(e);
                  }}
                  border="none"
                  role="presentation"
                  loading="lazy"
                  fallback={<Skeleton width="100%" height="100%" borderRadius={borderRadius} />}
                  onClick={onClick}
                />
              </Tooltip>
              {(onExpand || onRemove) && (
                <Stack
                  position="absolute"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor={colors.blue[50]}
                  padding={1}
                  borderRadius={theme.radii.md}
                  spacing={1}
                  border={theme.borders['1px']}
                  borderColor={colors.blue[100]}
                  zIndex={-1}
                  left={containerHeight > 100 ? 'unset' : '100%'}
                  right={containerHeight > 100 ? '10px' : 'unset'}
                  top={containerHeight <= 100 ? 0 : '10px'}
                  transform="translateX(-100%)"
                  opacity={0}
                  transitionTimingFunction={theme.transition.easing['ease-in-out']}
                  transitionDuration={theme.transition.duration.normal}
                  transitionProperty="[transform, z-index, opacity]"
                  _groupHover={{ transform: 'translateX(0)', zIndex: 2, opacity: 1 }}
                >
                  {onExpand && (
                    <ActionButton
                      translation={t('common:expand')}
                      onClick={(e) => {
                        e.stopPropagation();
                        onExpand();
                      }}
                      dataTestId="expand-button"
                    >
                      <IoExpandOutline size={iconSize} />
                    </ActionButton>
                  )}
                  {onRemove && onExpand && <Divider backgroundColor={colors.blue[100]} data-testid="divider" />}
                  {onRemove && (
                    <ActionButton
                      translation={t('common:remove')}
                      colorScheme="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemove();
                      }}
                      dataTestId="remove-button"
                    >
                      <IoRemove size={iconSize} />
                    </ActionButton>
                  )}
                </Stack>
              )}
            </>
          )}
        </Center>
      )}
    </Center>
  );
}

ImageView.defaultProps = {
  // eslint-disable-next-line
  height: 100,
  shape: ImageShape.rounded,
  wrapperProps: {},
  showAddImageIcon: true,
  dataTestId: 'image-preview',
  iconSize: 20,
  onRemove: undefined,
  onExpand: undefined,
  onClick: undefined,
};

interface ActionButtonProps extends ButtonProps {
  translation: string;
  dataTestId?: string;
}

export function ActionButton({ translation, children, onClick, colorScheme = 'gray', dataTestId }: ActionButtonProps) {
  return (
    <Center _hover={{ cursor: 'pointer' }}>
      <Tooltip label={translation} aria-label={translation} placement="right" borderRadius={theme.radii.md} closeOnClick>
        <Button
          onClick={onClick}
          data-testid={dataTestId}
          size="sm"
          variant="ghost"
          colorScheme={colorScheme}
          pl="0 !important"
          pr="0 !important"
        >
          {children}
        </Button>
      </Tooltip>
    </Center>
  );
}

ActionButton.defaultProps = {
  dataTestId: 'action-button',
};

ImageView.defaultProps = {
  dataTestId: 'action-button',
  placeholderImage: undefined,
  imagePreviewStyles: undefined,
};
