// eslint-disable-next-line import/prefer-default-export
export type CandidateAuditActionNames = CandidateMessagingActionName | CandidateHistoryActionName;

export enum CandidateHistoryActionName {
  APPOINTMENT_ASSESSMENT_POSITIVE = 'APPOINTMENT_ASSESSMENT_POSITIVE',
  APPOINTMENT_ASSESSMENT_NEGATIVE = 'APPOINTMENT_ASSESSMENT_NEGATIVE',
  SEEKER_APPOINTMENT_REMINDER_SENT = 'SEEKER_APPOINTMENT_REMINDER_SENT',
  CANDIDATE_BOOKMARKED = 'CANDIDATE_BOOKMARKED',
  CANDIDATE_CALLED = 'CANDIDATE_CALLED',
  CANDIDATE_DISMISSED = 'CANDIDATE_DISMISSED',
  CANDIDATE_HIRED = 'CANDIDATE_HIRED',
  OFFER_MADE = 'OFFER_MADE',
  OFFER_REJECTED = 'OFFER_REJECTED',
  CANDIDATE_UNHIRED = 'CANDIDATE_UNHIRED',
  CANDIDATE_UNLOCKED = 'CANDIDATE_UNLOCKED',
  CONTACT_REQUEST_SENT = 'CONTACT_REQUEST_SENT',
  CONTACT_REQUEST_ACCEPTED = 'CONTACT_REQUEST_ACCEPTED',
  CONTACT_REQUEST_DECLINED = 'CONTACT_REQUEST_DECLINED',
  POSITION_LIKED = 'POSITION_LIKED',
  POSITION_UNLIKED = 'POSITION_UNLIKED',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  INTEREST_REVOKED = 'INTEREST_REVOKED',
  SAVED_FROM_AUTO_DISMISSAL = 'SAVED_FROM_AUTO_DISMISSAL',
  INVITED_FOR_SCREENING = 'INVITED_FOR_SCREENING',
  SCREENING_COMPLETED = 'SCREENING_COMPLETED',
  // VIDEO_INTERVIEW_JOIN_FAILED = 'VIDEO_INTERVIEW_JOIN_FAILED',
  // VIDEO_INTERVIEW_JOINED = 'VIDEO_INTERVIEW_JOINED',
  FREE_FORM_QUESTION_ANSWERED = 'FREE_FORM_QUESTION_ANSWERED',
  APPOINTMENT_REQUEST_ACCEPTED = 'APPOINTMENT_REQUEST_ACCEPTED',
  APPOINTMENT_REQUEST_CANCELLED = 'APPOINTMENT_REQUEST_CANCELLED',
  APPOINTMENT_REQUEST_CREATED = 'APPOINTMENT_REQUEST_CREATED',
  APPOINTMENT_PERIOD_CANCELLED = 'APPOINTMENT_PERIOD_CANCELLED',
  OFFER_ACCEPETED_BY_SEEKER = 'OFFER_ACCEPETED_BY_SEEKER',
  OFFER_REJECTED_BY_SEEKER = 'OFFER_REJECTED_BY_SEEKER',
  OFFER_REJECTED_BY_RECRUITER = 'OFFER_REJECTED',
  HIRE_FORM_REQUESTED = 'HIRE_FORM_REQUESTED',
  HIRE_FORM_COMPLETED = 'HIRE_FORM_COMPLETED',
  HIRE_FORM_RESUBMISSION_REQUESTED = 'HIRE_FORM_RESUBMISSION_REQUESTED',
  CANDIDATE_FORM_HELP_REQUESTED = 'CANDIDATE_FORM_HELP_REQUESTED',
  MODERN_HIRE_ASSESSMENT_STARTED = 'MODERN_HIRE_ASSESSMENT_STARTED',
  MODERN_HIRE_ASSESSMENT_IN_PROGRESS = 'MODERN_HIRE_ASSESSMENT_IN_PROGRESS',
  MODERN_HIRE_ASSESSMENT_PASSED = 'MODERN_HIRE_ASSESSMENT_PASSED',
  MODERN_HIRE_ASSESSMENT_FAILED = 'MODERN_HIRE_ASSESSMENT_FAILED',
  CANDIDATE_APPROVED = 'CANDIDATE_APPROVED',
  CANDIDATE_REJECTED = 'CANDIDATE_REJECTED',
  CANDIDATE_RECORDING_UPLOADED = 'CANDIDATE_RECORDING_UPLOADED',
  CANDIDATE_LANGUAGE_EVALUATION_EXPERT = 'CANDIDATE_LANGUAGE_EVALUATION_EXPERT',
  CANDIDATE_LANGUAGE_EVALUATION_COMPETENT = 'CANDIDATE_LANGUAGE_EVALUATION_COMPETENT',
  CANDIDATE_LANGUAGE_EVALUATION_LIMITED = 'CANDIDATE_LANGUAGE_EVALUATION_LIMITED',
  CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL = 'CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT = 'CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT = 'CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED = 'CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL = 'CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW = 'CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW',
  CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW = 'CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW',
  CANDIDATE_LANGUAGE_EVALUATION_REQUESTED = 'CANDIDATE_LANGUAGE_EVALUATION_REQUESTED',
  OFFER_REVOKE = 'OFFER_REVOKE',
  HIRE_FORM_IN_PROGRESS = 'HIRE_FORM_IN_PROGRESS',
  HIRE_FORM_RESUBMISSION_IN_PROGRESS = 'HIRE_FORM_RESUBMISSION_IN_PROGRESS',
  COLLABORATOR_INTERVIEW_REVIEW = 'COLLABORATOR_INTERVIEW_REVIEW',
  CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED = 'CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED',
  CANDIDATE_APPOINTMENT_EXPIRED = 'CANDIDATE_APPOINTMENT_EXPIRED',
  NOT_LOOKING_FOR_WORK = 'NOT_LOOKING_FOR_WORK',
  DEACTIVATED = 'DEACTIVATED',
  OFFBOARDED = 'OFFBOARDED',
}

export enum CandidateMessagingActionName {
  APPOINTMENT_REQUEST_CANCELLED = 'APPOINTMENT_REQUEST_CANCELLED',
  APPOINTMENT_REQUEST_ACCEPTED = 'APPOINTMENT_REQUEST_ACCEPTED',
  SEEKER_MESSAGE = 'SEEKER_MESSAGE',
  APPOINTMENT_REQUEST_CREATED = 'APPOINTMENT_REQUEST_CREATED',
  APPOINTMENT_PERIOD_CANCELLED = 'APPOINTMENT_PERIOD_CANCELLED',
  EMPLOYER_MESSAGE = 'EMPLOYER_MESSAGE',
}
