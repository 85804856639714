import {
  Button,
  HStack,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Text,
  Stack,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Link,
  Checkbox,
  Spacer,
  Box,
  VStack,
} from '@chakra-ui/core';
import React from 'react';
import { MdClose, MdSettings } from 'react-icons/md';
import { useForm, SubmitHandler } from 'react-hook-form';
import MobilePreviewView from '../mobilePreview/MobilePreviewView';
import { MdBusiness } from 'react-icons/md';
import DragAndDropImageUpload from './DragAndDropImageUpload';
import { ImagePreviewModal } from '../../../../app/components/ImagePreviewModal';
import ImageView from '../../../../app/components/ImageView';
import theme from '../../../../styles/customTheme';

export default function TemplateSettings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false);
  const [logoPreview, setLogoPreview] = React.useState<string | null>(null);

  const { handleSubmit, setValue } = useForm();

  const onSubmit: SubmitHandler<any> = (data) => console.log(data);

  return (
    <>
      <Button size="md" variant="square" onClick={onOpen}>
        <MdSettings size={24} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={isPreviewVisible ? '6xl' : '3xl'} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent minHeight={0} height="100%">
          <HStack minHeight={0} height="100%">
            <Stack flexGrow={1} paddingY={theme.space[5]} minHeight={0} height="100%">
              <HStack justify="space-between" flexShring={0} paddingX={theme.space[5]}>
                <Stack spacing={0}>
                  <Text fontSize="xl" fontWeight="600" lineHeight={theme.fontSizes['2xl']}>
                    Template Settings
                  </Text>
                  <Text fontSize="sm">Set your organization logo and name to use it in your forms.</Text>
                </Stack>
                <Button
                  variant="link"
                  size="sm"
                  colorScheme="blue"
                  textDecoration="underline"
                  fontWeight="400"
                  onClick={() => {
                    setIsPreviewVisible((prev) => !prev);
                  }}
                >
                  Preview
                </Button>
              </HStack>
              <Divider />
              <Stack as="form" onSubmit={handleSubmit(onSubmit)} minHeight={0} height="100%">
                <Stack spacing={3} minHeight={0} height="100%" overflowY="auto" overflowX="hidden" paddingX={theme.space[5]}>
                  <FormControl>
                    <FormLabel fontWeight={700} color="#1A202C" margin={0}>
                      Upload Business Logo
                    </FormLabel>
                    <DragAndDropImageUpload
                      onUpload={(file) => {
                        setValue('logo', file);
                      }}
                      onError={(err) => {
                        setValue('logo', null);
                      }}
                      onPreview={setLogoPreview}
                      renderChildren={(dragging, inputId) => {
                        return (
                          <Box width="100%" background="#EDF2F7" padding={theme.space[4]} borderRadius={theme.radii.md}>
                            <HStack spacing={3} width="100%">
                              <ImagePreviewModal
                                images={[logoPreview]}
                                getImageKey={(image) => image!}
                                getImageSrc={(image) => image!}
                                setTrigger={
                                  <ImageView
                                    src={logoPreview!}
                                    height="56px"
                                    placeholderImage={<MdBusiness size={24} />}
                                    imagePreviewStyles={{ background: '#455A641A', border: 'none !important' }}
                                  />
                                }
                              />
                              <VStack spacing={0} align="flex-start">
                                <Text color="#A0AEC0" fontSize="sm" fontWeight={700}>
                                  Drag logo image file or{' '}
                                  <Button
                                    as="label"
                                    htmlFor={inputId}
                                    variant="link"
                                    color="blue.500"
                                    fontWeight="700"
                                    cursor="pointer"
                                  >
                                    click here
                                  </Button>{' '}
                                  to upload
                                </Text>
                                <Text color="#A0AEC0" fontSize="sm" fontWeight={400}>
                                  Max. file size 2 MB (.jpg, .png, .svg)
                                </Text>
                              </VStack>
                            </HStack>
                          </Box>
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={700} color="#1A202C" margin={0}>
                      Business Name
                    </FormLabel>
                    <Input type="text" variant="filled" />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight={700} color="#1A202C" margin={0}>
                      Disclaimer Text
                    </FormLabel>
                    <Stack spacing={2} align="center" background="#EDF2F7" padding={theme.space[4]} borderRadius={theme.radii.md}>
                      <Text fontSize="sm" lineHeight={theme.fontSizes.lg} color="#718096">
                        <Text>
                          By completing the information in the AppyHere application, I consent to have AppyHere collecting the
                          following personal information for the purpose of reviewing my eployment application with Dollarama
                          L.P.: name, phone number, email, date of birth, address, current employment status information, void
                          cheque, social insurance number, work permit (if applicable)
                        </Text>
                        <Spacer height={theme.space[2]} />
                        <Text>
                          I understand that my information will be used by AppyHere and Dollarama L.P. in the context of
                          recruiting for a position at Dollarama L.P. and will be shared with their staff, members of its group or
                          agents only in the aforementioned context.
                        </Text>
                        <Spacer height={theme.space[2]} />
                        <Text>I agree to disclose if I have family members who work at the same store I am applying for.</Text>
                        <Spacer height={theme.space[2]} />
                        <Text>
                          To avoid conflicts or the appearance of conflicts of interest, Dollarama L.P. prohibits spouses and
                          family members from working in the same store if there is a relationship of subordinates between the two
                          employees. If this is the case, I will make Dollarama L.P. aware so that opportunities at another store
                          can be explored.
                        </Text>
                        <Spacer height={theme.space[2]} />
                        <Text>
                          It is my duty to disclose any conflict of interest or Dollarama L.P. reserves the right to terminate
                          your employment without further notice.
                        </Text>
                        <Spacer height={theme.space[2]} />
                        <Text>
                          If I am living in the province of Québec and am under 16 years of age and do not have my Secondary 5
                          diploma OR if I am 16 years old and in the middle of the school year, I must notify my Store Manager and
                          comply with the CNESST’s “Loi sur l’encadrement du travail des enfants”.
                        </Text>
                        <Spacer height={theme.space[2]} />
                        <Text>
                          There fore it is on a voluntary basis that I agree to complete the information and entirely assume
                          responsibility for my answers. It is also my understanding that my participation can be interrupted at
                          any time. For any information regarding the Privacny Policy of Dollarama, please visit the Legal Matters
                          section at{' '}
                          <Link color="blue.500" href="https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy" isExternal>
                            https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy
                          </Link>
                          .
                        </Text>
                        <Spacer height={theme.space[2]} />
                        <Text>If you are ready to start, click on the button below</Text>
                        <Spacer height={theme.space[2]} />
                      </Text>
                      <Button colorScheme="orange">I consent</Button>
                    </Stack>
                  </FormControl>
                </Stack>

                <ModalFooter paddingY={0} paddingX={theme.space[5]}>
                  <HStack justify="space-between" width="100%">
                    <FormControl>
                      <Checkbox>
                        <Text fontSize={theme.fontSizes['sm']}>Use this text and logo for new templates</Text>
                      </Checkbox>
                    </FormControl>
                    <HStack>
                      <Button onClick={onClose} mr={3}>
                        Cancel
                      </Button>
                      <Button colorScheme="blue">Start Template</Button>
                    </HStack>
                  </HStack>
                </ModalFooter>
              </Stack>
            </Stack>
            {isPreviewVisible && (
              <>
                <Divider orientation="vertical" flexShrink={0} />
                <Stack spacing={3} flexBasis="350px" flexShrink={0} height="100%" padding={theme.space[5]}>
                  <HStack justify="space-between" flexShrink={0}>
                    <Text fontSize="xl" fontWeight="600" lineHeight={theme.fontSizes['2xl']}>
                      Device Preview
                    </Text>
                    <Button
                      variant="square"
                      size="sm"
                      onClick={() => {
                        setIsPreviewVisible(false);
                      }}
                    >
                      <MdClose size={theme.sizes[5]} />
                    </Button>
                  </HStack>
                  <MobilePreviewView hideProgress height="100%">
                    <Stack>
                      <Stack spacing={1} align="center" padding={theme.space[5]}>
                        <Text fontSize="sm" lineHeight={theme.fontSizes.lg} color="#718096">
                          <Text>
                            By completing the information in the AppyHere application, I consent to have AppyHere collecting the
                            following personal information for the purpose of reviewing my eployment application with Dollarama
                            L.P.: name, phone number, email, date of birth, address, current employment status information, void
                            cheque, social insurance number, work permit (if applicable)
                          </Text>
                          <Spacer height={theme.space[2]} />
                          <Text>
                            I understand that my information will be used by AppyHere and Dollarama L.P. in the context of
                            recruiting for a position at Dollarama L.P. and will be shared with their staff, members of its group
                            or agents only in the aforementioned context.
                          </Text>
                          <Spacer height={theme.space[2]} />
                          <Text>I agree to disclose if I have family members who work at the same store I am applying for.</Text>
                          <Spacer height={theme.space[2]} />
                          <Text>
                            To avoid conflicts or the appearance of conflicts of interest, Dollarama L.P. prohibits spouses and
                            family members from working in the same store if there is a relationship of subordinates between the
                            two employees. If this is the case, I will make Dollarama L.P. aware so that opportunities at another
                            store can be explored.
                          </Text>
                          <Spacer height={theme.space[2]} />
                          <Text>
                            It is my duty to disclose any conflict of interest or Dollarama L.P. reserves the right to terminate
                            your employment without further notice.
                          </Text>
                          <Spacer height={theme.space[2]} />
                          <Text>
                            If I am living in the province of Québec and am under 16 years of age and do not have my Secondary 5
                            diploma OR if I am 16 years old and in the middle of the school year, I must notify my Store Manager
                            and comply with the CNESST’s “Loi sur l’encadrement du travail des enfants”.
                          </Text>
                          <Spacer height={theme.space[2]} />
                          <Text>
                            There fore it is on a voluntary basis that I agree to complete the information and entirely assume
                            responsibility for my answers. It is also my understanding that my participation can be interrupted at
                            any time. For any information regarding the Privacny Policy of Dollarama, please visit the Legal
                            Matters section at{' '}
                            <Link color="blue.500" href="https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy" isExternal>
                              https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy
                            </Link>
                            .
                          </Text>
                          <Spacer height={theme.space[2]} />
                          <Text>If you are ready to start, click on the button below</Text>
                          <Spacer height={theme.space[2]} />
                        </Text>
                        <Button colorScheme="orange" isFullWidth>
                          I consent
                        </Button>
                      </Stack>
                    </Stack>
                  </MobilePreviewView>
                </Stack>
              </>
            )}
          </HStack>
        </ModalContent>
      </Modal>
    </>
  );
}
