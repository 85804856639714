export const BUNDLE_ID_GLOBAL = 'com.appyhere.global';
export const BUNDLE_ID_SERVICES = 'com.appyhere.services';
export const BUNDLE_ID_APP_BASED = 'com.appyhere.recruiterweb';
export const MAINTENANCE_NAME = 'isUnderMaintenance';
export const ANALYTICS_NAME = 'analyticsUrl';
export const HOURS_BEFORE_EVENT_TO_ALLOW_DELETION = 'hoursBeforeGroupEventToAllowDeletion';
export const MINUTES_BEFORE_EVENT_TO_ALLOW_DELETION = 'minutesBeforeGroupEventToAllowDeletion';
export const MINUTES_BEFORE_GROUP_EVENT_WHEN_USER_CAN_JOIN = 'minutesBeforeGroupeventWhenUsersCanJoin';
export const MINUTES_AFTER_GROUP_EVENT_WHEN_USER_CAN_JOIN = 'minutesAfterGroupeventWhenUsersCanJoin';
export const BACKGROUND_CHECK = 'background_check';
export const CERTN_URL = 'certnBaseUrl';
export const KNOCKED_OUT_AUTOMATIC_DISMISSAL_HOURS_DELAY = 'knockedOutAutomaticDismissalHoursDelay';
export const VIDEO_INTERVIEW_END_GRACE_PERIOD_IN_MINUTES = 'videoInterviewEndGracePeriodInMinutes';
export const EDUCATION_LEVEL = 'educationLevel';
export const DISMISSAL_REASONS = 'dismissalReasons';
export const INTERVIEW_CANCEL_REASONS = 'interviewCancelReasons';
export const AUDIO_EVALUATION_OPTIONS = 'audioEvaluationOptions';
export const MODERN_HIRE_TEMPLATE_ID_LIST = 'modernHireTemplate';
export const LANGUAGE_EVALUATION_PHRASE = 'language_evaluation_phrase';
export const LANGUAGE_EVALUATION_PHRASE_FRENCH = 'languageEvaluationPhraseFrench';
export const LANGUAGE_EVALUATION_PHRASE_ENGLISH = 'languageEvaluationPhraseEnglish';
export const DAYS_UPTO_CANDIDATE_INTEACT_AFTER_HIRE = 'daysUptoCandidateInteractAfterHire';
export const MAX_CANDIDATE_LIMIT = 'maxCandidateLimit';
export const CONTACT_EMAIL_FOR_ACCOUNT_AUTHORISATION = 'contactEmailForAccountAuthorisation';
export const TOKEN_THRESHOLD_FOR_AUTOMATION = 'tokenThresholdForAutomation';
