import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { ConfirmMessageType, PositionFromPositionList } from '../PositionType';
import { useStoreState } from '../../../../models/hooks';
import useContactEmailForAccountAuthorisation from '../../../../app/hooks/useContactEmailForAccountAuthorisation';

export type UpdateStatusConfirmationProps = {
  isOpen: boolean;
  onClose: () => void;
  message: ConfirmMessageType;
  updatePositionByStatus: (position: PositionFromPositionList) => void;
  selectedPosition: PositionFromPositionList;
  isPositionOpen?: boolean;
  isPositonArchived?: boolean;
  isArchivingPosition?: boolean;
};

export const UpdateStatusConfirmation = ({
  isOpen,
  onClose,
  message,
  selectedPosition,
  updatePositionByStatus,
  isPositionOpen,
  isPositonArchived,
  isArchivingPosition,
}: UpdateStatusConfirmationProps): JSX.Element => {
  const { t } = useTranslation();
  const authorization = useStoreState((s) => s.app.accounts?.authorization);
  const disablePositionCreation = !isNil(authorization) && !authorization;
  const contactEmailForAuthorization = useContactEmailForAccountAuthorisation();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{message.headerMessage}</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            {disablePositionCreation && !isPositionOpen && !isPositonArchived && !isArchivingPosition && (
              <Alert status="error" borderRadius="8px" data-testid="authorizationAlert">
                <AlertIcon height={20} width="auto" />
                <Text>
                  <span>{t('jobPosition:userRestrictedByAuthorizationMessageFront')}</span>
                  <span> </span>
                  <a
                    href={`mailto:${contactEmailForAuthorization || t('jobPosition:contactEmailForAccountAuthorisation')}`}
                    style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                  >
                    {contactEmailForAuthorization || t('jobPosition:contactEmailForAccountAuthorisation')}
                  </a>
                  <span> </span>
                  <span>{t('jobPosition:userRestrictedByAuthorizationMessageBack')}</span>
                </Text>
              </Alert>
            )}
            {message.bodyMessage}
            <Flex paddingTop=" 10px">
              {isPositionOpen && (
                <Flex justifyContent="space-between" alignItems="flex-start">
                  {/* <Box paddingTop="5px" pr={3}>
                    {checkBoxComponent}
                  </Box> */}
                  <span data-testid="close-confirmation-text">{t('jobPosition:closePositionModal.confirmationText')}</span>
                </Flex>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter mt={2}>
            {((!isPositionOpen && !disablePositionCreation) || isPositionOpen || isPositonArchived || isArchivingPosition) && (
              <Button data-testid="ConfirmBtn" colorScheme="blue" mr={3} onClick={() => updatePositionByStatus(selectedPosition)}>
                {message.confirmText}
              </Button>
            )}
            <Button
              data-testid="CloseBtn"
              colorScheme={
                disablePositionCreation && !isPositionOpen && !isPositonArchived && !isArchivingPosition ? 'blue' : 'red'
              }
              onClick={onClose}
            >
              {disablePositionCreation && !isPositionOpen && !isPositonArchived && !isArchivingPosition
                ? t('jobPosition:updateStatusConfirmation.okayText')
                : message.cancelText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

UpdateStatusConfirmation.defaultProps = {
  isPositionOpen: false,
};
