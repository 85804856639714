import { AdministrationMenuItemEnum } from '../modules/administration/AdministrationMenuItemEnum';

export const PATH_ROOT = '/';
export const PATH_LOGIN = '/login';
export const PATH_SMB_LOGIN = `${PATH_LOGIN}/welcome`;
export const PATH_DASHBOARD = '/dashboard';
export const PATH_DASHBOARD_REQUISITION = '/dashboard/requisition';
export const PATH_MANAGEMENT_OVERVIEW = '/managementOverview';
export const PATH_OFFER_HIRE_PROCESS = '/dashboard/offerAndHireProcess/:status';
export const PATH_GUEST_MEET = '/guestMeet/:candidateId';
export const PATH_MEET = '/meet/:token';
export const PATH_MAIN = '/messaging';
export const PATH_MAIN_NEW = '/newMessaging';
export const PATH_CANDIDATE = '/candidate/:candidateId/:tab';
export const PATH_POSITION_CANDIDATE = '/positionCandidates/:positionId/:tab';
export const PATH_ANALYTICS = '/analytics';
export const PATH_PROTOTYPE = '/prototype';
export const PATH_CALENDAR = '/calendar';
export const PATH_POSITIONS = '/positions';
export const PATH_TEAM = '/team';
export const PATH_SUPPORT = '/support';
export const PATH_ADMINISTRATION = '/administration';
export const PATH_POSITION = '/position/:positionId/:tab';
export const PATH_RECRUITER_CALENDAR = '/administrations/calendar';
export const PATH_MESSAGING_TABS = '/candidates/:candidateId/:tab';
export const PATH_MESSAGING_TABS_NEW = '/newCandidates/:candidateId/:tab';
export const PATH_INTERVIEW_MESSAGING_TABS = '/interview/:candidateId/:tab';
export const PATH_ADMINISTRATION_TABS = '/administration/:tab';
export const PATH_EMAIL_INVITATION = '/groupEvent/invitation/:type';
export const PATH_UNSUBSCRIBE = '/unsubscribe/:userId';
export const PATH_SUCCESSFUL = '/paymentSuccess';
export const PATH_SMB_BUSINESS_MANAGEMENT = '/positionManagement';
export const PATH_SMB_POSITION_MANAGEMENT = '/positionManagement/:businessId';

export const MESSAGING_TABS = (candidateId: string, tab: string): string => `/candidates/${candidateId}/${tab}`;
// istanbul ignore next
export const MESSAGING_TABS_NEW = (candidateId: string, tab: string): string => `/newCandidates/${candidateId}/${tab}`;
export const MESSAGING_CANDIDATE_TABS = (candidateId: string, tab: string): string => `/candidate/${candidateId}/${tab}`;
// istanbul ignore next
export const POSITION_CANDIDATE_TABS = (positionId: string, tab: string): string => `/positionCandidates/${positionId}/${tab}`;
export const INTERVIEW_MESSAGING_TABS = (candidateId: string, tab: string): string => `/interview/${candidateId}/${tab}`;
// istanbul ignore next
export const PATH_POSITION_TABS = (positionId: string, tab: string): string => `/position/${positionId}/${tab}`;

// istanbul ignore next
export const MEET_PAGE = (token: string): string => `/meet/${token}`;

export type MessagingTabsParams = {
  candidateId: string;
  tab: string;
};

export type PositionTabParams = {
  positionId: string;
  tab: string;
};

export type AdministrationTabsParams = {
  tab: AdministrationMenuItemEnum;
};

export type RecruiterManagementTabsParams = {
  tab: string;
};

export enum MeetTypeEnum {
  INTERVIEW = 'interview',
  EVENT = 'event',
}

export type GroupEventMeetParams = {
  token?: string;
};

export type GuestMeetParams = {
  candidateId?: string;
};

export type MeetTabParams = {
  token?: string;
  type?: MeetTypeEnum;
};

export type TopCandidateParams = {
  positionId?: string;
};

export type EmailInvitationTabParams = {
  type?: string;
};

export type OfferAndHireStatus = {
  status?: string;
};

export const ANIMATED_VARIANTS = {
  initial: {
    opacity: 0,
    x: 0,
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 0,
  },
};
