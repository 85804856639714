import { Box, HStack, Progress, Stack, Text } from '@chakra-ui/core';
import { IoCellular, IoWifi, IoBatteryHalf, IoChevronBack, IoBusinessSharp } from 'react-icons/io5';
import { useDraggable } from 'react-use-draggable-scroll';

import React, { useRef } from 'react';
import moment from 'moment';
import { SectionSchema, generateFormSchema } from '../formElements/formBuilderSchema';
import PreviewElementWrapper from './PreviewElements';

interface FormPreviewProps {
  schema?: SectionSchema[];
  hideProgress?: boolean;
  children?: React.ReactNode;
  height?: string;
}

export default function MobilePreviewView({ schema, hideProgress = false, children, height = '700px' }: FormPreviewProps) {
  const formSchema = generateFormSchema(schema ?? [], 'en');
  const scrollRef = useRef<HTMLElement>(null);
  const { events } = useDraggable(scrollRef as React.MutableRefObject<HTMLDivElement>);
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="375px"
      height={height}
      style={{ aspectRatio: '9/19' }}
      borderRadius={'20px'}
      overflow="hidden"
      boxShadow={'0 0 43.31px #00000026'}
    >
      <MobileHeader />
      <Box flexGrow={1} backgroundColor="#fff" minHeight={0} height="100%">
        {!hideProgress && (
          <Box paddingTop="5px">
            <Progress value={20} size="xs" colorScheme="blue" borderRadius="10px" />
          </Box>
        )}

        <Box
          overflowY="auto"
          overflowX="hidden"
          minHeight={0}
          height="100%"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          ref={scrollRef as any}
          {...events}
          style={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {children || (
            <Stack paddingX="10px" paddingY="5px" color="#4D4D4D" spacing={1} height="100%">
              <Stack align="center" justify="center" spacing={1}>
                <IoBusinessSharp size="72px" />
                <Text fontSize="15.2px" fontWeight="700">
                  Burger King
                </Text>
                <Text fontSize="12.35px" fontWeight="400">
                  Small Descriptions about the workplace.
                </Text>
              </Stack>
              <PreviewElementWrapper answer="Get Started" />
              {formSchema.map((schema) => {
                return <PreviewElementWrapper question={schema} key={schema.id} />;
              })}
            </Stack>
          )}
        </Box>
      </Box>
      <MobileFooter />
    </Box>
  );
}

const MobileHeader = () => (
  <>
    <HStack
      height="50px"
      justify="space-between"
      align="center"
      color="#090A0A"
      backgroundColor="#fff"
      paddingX="28px"
      flexShrink={0}
    >
      <Text fontSize={'15.2px'} fontWeight={'500'}>
        {moment().format('h:mm')}
      </Text>
      <HStack spacing={1}>
        <IoCellular size="18px" />
        <IoWifi size="18px" />
        <IoBatteryHalf size="18px" />
      </HStack>
    </HStack>
    <HStack
      backgroundColor="#455A64"
      color="#fff"
      height="53px"
      justify="space-between"
      align="center"
      paddingX="28px"
      flexShrink={0}
    >
      <HStack>
        <IoChevronBack />
        <Text fontSize="15.2px" fontWeight="600">
          New Hire
        </Text>
      </HStack>
      <Text fontSize="15.2px" fontWeight="600">
        Help
      </Text>
    </HStack>
  </>
);

const MobileFooter = () => (
  <HStack
    height="32.3px"
    justify="center"
    align="center"
    jsutify="center"
    color="#090A0A"
    backgroundColor="#fff"
    paddingX="28px"
    flexShrink={0}
    data-testid="mobile-preview-footer"
  >
    <svg width="142" height="5" viewBox="0 0 142 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.800781 2.4248C0.800781 1.11313 1.86411 0.0498047 3.17578 0.0498047H139.026C140.337 0.0498047 141.401 1.11313 141.401 2.4248C141.401 3.73648 140.337 4.79981 139.026 4.79981H3.17578C1.86411 4.79981 0.800781 3.73648 0.800781 2.4248Z"
        fill="#090A0A"
      />
    </svg>
  </HStack>
);
