import { ValidationErrorItem } from 'joi';
import _isNaN from 'lodash/isNaN';
import { CalendarParticipantType } from '../modules/calendar/events/CalendarParticipantType';

export type FormattedError = {
  label: string | undefined;
  message: string;
  type?: string;
};

export const errorFormat = (errors: ValidationErrorItem[] | null): FormattedError[] => {
  if (!errors) return [];
  return errors.map((err: ValidationErrorItem) => {
    return { label: err.context?.label, message: err.message };
  });
};

export const getErrorMessage = (formErrors: FormattedError[], fieldName: string): string | undefined => {
  return formErrors?.find((err) => err?.label?.split('[')[0] === fieldName)?.message;
};

export const hasErrorMessage = (formErrors: FormattedError[], fieldName: string): boolean => {
  return !!formErrors?.find((err) => err?.label?.split('[')[0] === fieldName);
};

export const clearErrorMessage = (formErrors: FormattedError[], fieldName: string): FormattedError[] => {
  return formErrors?.filter((err) => err?.label?.split('[')[0] !== fieldName);
};

export const convertStringToNumber = (value: any) => {
  // eslint-disable-next-line radix
  return _isNaN(parseFloat(value)) ? null : parseFloat(value);
};

/**
 * Updates Object with given value
 * @param {Object} Object Current Object
 * @param {String} key Dot seperated string
 * @param {String} value Value to be set
 *
 * @return {Object}
 */
export const updateObject = (
  Object: any,
  key: string,
  value: string | Date | CalendarParticipantType[] | number | null | boolean,
  dataType = false,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let currentState: any = Object;
  const propChain = key.split('.') || /* istanbul ignore next */ [];
  // eslint-disable-next-line no-plusplus
  for (let p = 0; p < propChain.length; p++) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,no-prototype-builtins
    /* istanbul ignore next */ if (currentState && currentState.hasOwnProperty(propChain[p])) {
      if (p !== propChain.length - 1) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        currentState = currentState[propChain[p]];
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        currentState[propChain[p]] = dataType ? convertStringToNumber(value) : value;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return currentState;
};
