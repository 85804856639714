import { AppointmentType, CandidateAudit, OffboardingDetails } from '../../../../../firebase/firestore/documents/candidateAudit';
import {
  CandidateAuditActionNames,
  CandidateMessagingActionName,
} from '../../../../../firebase/firestore/documents/candidateAuditActionNames';

export class MessagePresenter {
  readonly id: string = this.message.id;

  readonly userId: string = this.message.userId;

  readonly content: string = this.message.message;

  readonly action: CandidateAuditActionNames = this.message.action;

  readonly timestamp: Date = this.message.timestamp;

  readonly appointmentType?: AppointmentType = this.message.appointment?.appointmentType;

  readonly appointmentDuration?: number = this.message.appointment?.duration && Math.abs(this.message.appointment.duration);

  readonly appointmentDate?: Date = this.message.appointment?.date;

  readonly recruiterFirstName?: string = this.message.recruiterFirstName;

  readonly recruiterFullName?: string = this.message.userFullName;

  readonly candidateFullName?: string = this.message.candidateFullName;

  readonly dialogflow?: boolean = this.message.dialogflow;

  readonly automation?: boolean = this.message.automation;

  readonly reasonId?: string = this.message.reasonId;

  readonly reasonComment?: string = this.message.reasonComment;

  readonly offboardingDetails?: OffboardingDetails = this.message.offboardingDetails;

  constructor(
    private readonly message: CandidateAudit,
    readonly type: 'seeker' | 'recruiter',
    readonly showPicture: boolean,
    readonly authorName: string | undefined,
    readonly dateString: string | undefined,
  ) {}

  get isFromSeeker(): boolean {
    return this.type === 'seeker';
  }

  get isChatMessage(): boolean {
    return (
      this.action === CandidateMessagingActionName.EMPLOYER_MESSAGE || this.action === CandidateMessagingActionName.SEEKER_MESSAGE
    );
  }

  get isAppointmentMessage(): boolean {
    return (
      this.action === CandidateMessagingActionName.APPOINTMENT_PERIOD_CANCELLED ||
      this.action === CandidateMessagingActionName.APPOINTMENT_REQUEST_ACCEPTED ||
      this.action === CandidateMessagingActionName.APPOINTMENT_REQUEST_CANCELLED ||
      this.action === CandidateMessagingActionName.APPOINTMENT_REQUEST_CREATED
    );
  }

  get hasMetaContent(): boolean {
    if (this.showPicture || this.authorName) {
      return true;
    }
    return !!this.dateString;
  }

  hasBeenSentAfter(message: MessagePresenter): boolean {
    return this.timestamp >= message.timestamp;
  }
}
