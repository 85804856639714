import { Stack, Button, Text, HStack, Box, Flex } from '@chakra-ui/core';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdDragIndicator } from 'react-icons/md';
import theme from '../../../../styles/customTheme';
import { Language, OptionSchema } from './formBuilderSchema';
import { v4 as uuidv4 } from 'uuid';
import TextSchemaBuilder from './TextSchemaBuilder';

const MAX_OPTIONS_COUNT = 20;

export function swap<T>(array: T[], indexA: number, indexB: number): T[] {
  const newArray = [...array];
  [newArray[indexA], newArray[indexB]] = [newArray[indexB], newArray[indexA]];
  return newArray;
}

interface OptionSchemaBuilderProps {
  options: OptionSchema[];
  handleChange: (options: OptionSchema[]) => void;
  language: Language;
  hideSelection?: boolean;
}

const OptionSchemaBuilder: React.FC<OptionSchemaBuilderProps> = ({ options, handleChange, language, hideSelection }) => {
  const handleOptionChange = (value: string, index: number) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? { ...option, text: { ...option.text, [language]: value } } : option,
    );
    handleChange(updatedOptions);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    handleChange(updatedOptions);
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    handleChange(swap(options, oldIndex, newIndex));
  };

  return (
    <Stack align="flex-start" spacing={4}>
      <OptionList
        options={options}
        language={language}
        onSortEnd={onSortEnd}
        onOptionChange={handleOptionChange}
        onRemoveOption={handleRemoveOption}
        hideSelection={hideSelection}
      />
      {options.length < MAX_OPTIONS_COUNT && <AddOptionButton onClick={() => handleChange([...options, createNewOption()])} />}
    </Stack>
  );
};

const createNewOption = (): OptionSchema => ({
  id: uuidv4(),
  text: { en: '', fr: '' },
  key: 'a',
});

interface OptionListProps {
  options: OptionSchema[];
  language: Language;
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  onOptionChange: (value: string, index: number) => void;
  onRemoveOption: (index: number) => void;
  hideSelection?: boolean;
}

const OptionList = SortableContainer(({ options, onOptionChange, language, onRemoveOption, hideSelection }: OptionListProps) => (
  <Stack spacing={2} w="100%">
    {options.map((option, index) => (
      <SortableOption
        key={option.id}
        option={option}
        index={index}
        optionIndex={index}
        language={language}
        onOptionChange={onOptionChange}
        onRemoveOption={onRemoveOption}
        hideSelection={hideSelection}
      />
    ))}
  </Stack>
));

interface SortableOptionProps {
  option: OptionSchema;
  optionIndex: number;
  language: Language;
  onOptionChange: (value: string, index: number) => void;
  onRemoveOption: (index: number) => void;
  hideSelection?: boolean;
}

const SortableOption = SortableElement(
  ({ option, optionIndex: index, language, onOptionChange, onRemoveOption, hideSelection }: SortableOptionProps) => {
    const { text } = option;
    const getOptionType = (priority: number): string => String.fromCharCode(65 + priority).toUpperCase();

    const SortableHandleIcon = SortableHandle(() => <MdDragIndicator cursor="grab" color={theme.colors.gray[300]} />);

    return (
      <HStack
        w="100%"
        backgroundColor={theme.colors.white}
        sx={{ '&.is-dragging': { opacity: 0.5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' } }}
      >
        <Flex h="inherit" alignItems="center">
          <SortableHandleIcon />
        </Flex>
        <HStack spacing={2} backgroundColor={theme.colors.blue[30]} borderRadius={theme.radii.md} paddingX={2} paddingY={1}>
          <Text fontSize={theme.fontSizes.xs} fontWeight="bold">
            {getOptionType(index)}
          </Text>
        </HStack>

        <TextSchemaBuilder
          flex={1}
          label={text[language] || ''}
          onLabelChange={(val) => onOptionChange(val, index)}
          startWithEditView={false}
          language={language}
        />

        {!hideSelection && index >= 1 && (
          <Box flexBasis="10%">
            <Button px={1} onClick={() => onRemoveOption(index)} variant="ghost" colorScheme="red" size="sm">
              <IoClose />
            </Button>
          </Box>
        )}
      </HStack>
    );
  },
);

interface AddOptionButtonProps {
  onClick: () => void;
}

const AddOptionButton: React.FC<AddOptionButtonProps> = ({ onClick }) => (
  <Button variant="link" fontSize={12} fontWeight="normal" onClick={onClick}>
    Add Option
  </Button>
);

export default OptionSchemaBuilder;
