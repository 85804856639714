/* istanbul ignore file */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import TreeMenu, { TreeMenuItem } from 'react-simple-tree-menu';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, Flex, Spacer, Spinner } from '@chakra-ui/core';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import isNumber from 'lodash/isNumber';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { NodeType, ParentItemNode } from './treeGroup';
import { Business } from '../../../administration/positionManagement/BusinessType';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { EngagedCandidate } from '../../../../firebase/firestore/documents/candidate';
import { MESSAGING_TABS_NEW } from '../../../../routes/constants';
import { SeekerContainerTabItems } from '../../seeker/SeekerContainerContext';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import SeekerListSortType from '../filters/seekerListSortType';
import NewCandidateItem from './candidateItem/NewCandidateItem';
import { Position } from '../../../../firebase/firestore/documents/position';
import { CandidateSearchResponse } from '../../../../controllers/candidateController';

type Props = Record<string, unknown>;

const addBoxClass = (item: TreeMenuItem) => {
  switch (item.type) {
    case NodeType.PARENT:
      return 'parent-block';
    case NodeType.CHILD:
      return 'child-block';
    default:
      return 'candidate-block';
  }
};

type ToggleIconProp = {
  on: boolean;
};

const ToggleIcon = ({ on }: ToggleIconProp) => (
  <span>{on ? <ChevronUpIcon w={6} h={6} /> : <ChevronDownIcon w={6} h={6} />}</span>
);

const Loading = () => (
  <span>
    <Spinner size="sm" />
  </span>
);

const PAGE_LIMIT = 100;

const BusinessCandidateList: React.FC = () => {
  const {
    setSelectedEngagedCandidate,
    setSelectedEngagedSeeker,
    setSelectedCandidateId,
    selectedCandidateId,
  } = useMessagingViewContext();

  const getBusinessWithPositionAndCandidate = useStoreActions((a) => a.businessManagement.getBusinessWithPositionAndCandidate);
  const paginatedBusinesses = useStoreState((state) => state.businessManagement.paginatedBusinesses);
  const getPositionsWithCandidates = useStoreActions((actions) => actions.positionManagement.getPositionsWithCandidates);

  const scrollableDivRef = React.useRef<HTMLDivElement>(null);

  const businesses = paginatedBusinesses.data;

  const { searchQuery, sort, searchAndSortTypes, listType } = useSeekersFilters();

  const [businessPresenter, setBusinessPresenter] = useState<{
    [key: string]: Business & {
      key: string;
      label: string;
      type: NodeType;
      nodes?: Partial<ParentItemNode>[];
    };
  }>({});

  useEffect(() => {
    let paginatedBusinessLength = 0;
    const firstPage = 0;
    const getBusinesses = (prevPage?: number) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBusinessWithPositionAndCandidate({
        params: {
          limit: PAGE_LIMIT,
          page: (isNumber(prevPage) ? prevPage : paginatedBusinesses.page) + 1,
          onlyFollowedBusiness: true,
          searchQuery: searchQuery || '',
          sortBy: sort === SeekerListSortType.ALPHABETICAL ? 'name' : 'createdAt',
          sortOrder: 'asc',
          listType,
          advancedFilters: searchAndSortTypes,
        },
      }).then((data) => {
        paginatedBusinessLength += data.data.length;
        const hasNext = data.page * PAGE_LIMIT < data.total;
        if (hasNext && paginatedBusinessLength < 16) {
          getBusinesses(data.page);
        }
      });
    };
    getBusinesses(firstPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchAndSortTypes, sort, listType]);

  useEffect(() => {
    const businessPresenterToSet: {
      [key: string]: Business & {
        key: string;
        label: string;
        type: NodeType;
        nodes?: Partial<ParentItemNode>[];
      };
    } = {};
    businesses.forEach((business: Business) => {
      const businessData = {
        ...business,
        key: business.id,
        label: business.name,
        type: NodeType.PARENT,
        isFetching: false,
        nodes: [
          {
            key: Math.random(),
            label: '',
          },
        ],
      };
      businessPresenterToSet[business.id] = businessData;
    });
    setBusinessPresenter(businessPresenterToSet);
  }, [businesses]);

  const selectedCallback = useCallback(
    (candidate?: Readonly<EngagedCandidate>) => {
      if (candidate) {
        setSelectedCandidateId(candidate.id);
        setSelectedEngagedSeeker(candidate && { seekerId: candidate.seeker, candidateId: candidate.id });
        window.history.pushState(null, '', MESSAGING_TABS_NEW(candidate.id, SeekerContainerTabItems.Messaging));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedEngagedCandidate, setSelectedEngagedSeeker],
  );

  const getBusinesses = (prevPage?: number) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getBusinessWithPositionAndCandidate({
      params: {
        limit: PAGE_LIMIT,
        page: (prevPage || paginatedBusinesses.page) + 1,
        onlyFollowedBusiness: true,
        searchQuery: searchQuery || '',
        sortBy: sort === SeekerListSortType.ALPHABETICAL ? 'name' : 'createdAt',
        sortOrder: 'asc',
        listType,
        advancedFilters: searchAndSortTypes,
      },
    }).then((data) => {
      const hasNext = data.page * PAGE_LIMIT < data.total;
      if (hasNext && !data.data.length) {
        getBusinesses(data.page);
      }
    });
  };

  const treeBusiness = Object.values(businessPresenter);

  const hasNext = paginatedBusinesses.page * PAGE_LIMIT < paginatedBusinesses.total;

  const maxHeightOfTheContainer = useMemo(() => {
    return scrollableDivRef.current?.offsetTop ? window.innerHeight - (scrollableDivRef.current?.offsetTop || 0) : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableDivRef.current?.offsetTop]);

  const isCandidateActive = useCallback(
    (item: TreeMenuItem) => {
      const parents = (item.parent as string).split('/');
      const parentPositionId = parents[parents.length - 1];
      const [, seekerId] = (item.candidate as CandidateSearchResponse['data'][0]).id.split('_');
      const selectedPositionId = selectedCandidateId?.split('_')[0];
      const selectedSeekerId = selectedCandidateId?.split('_')[1];

      return parentPositionId === selectedPositionId && selectedSeekerId === seekerId;
    },
    [selectedCandidateId],
  );

  return (
    <div
      id="scrollableDiv"
      style={{
        height: maxHeightOfTheContainer,
        overflow: 'auto',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
      }}
      ref={scrollableDivRef}
    >
      <InfiniteScroll
        dataLength={treeBusiness.length}
        next={getBusinesses}
        hasMore={hasNext}
        loader={
          <Box marginY="auto" width="100%" textAlign="center">
            <Spinner color="#1F3CBA" data-testid="Spinner" />
          </Box>
        }
        scrollableTarget="scrollableDiv"
        scrollThreshold={0.8}
      >
        <TreeMenu
          data={treeBusiness as never}
          onClickItem={() => {}}
          debounceTime={5}
          resetOpenNodesOnDataUpdate
          disableKeyboard
          cacheSearch={false}
        >
          {({ items }) => {
            return (
              <AnimatePresence initial={false}>
                {items &&
                  items.length &&
                  items.map((item) => {
                    return (
                      <motion.div
                        layout
                        key={item.key}
                        id={item.key}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1 }}
                        transition={{ duration: 0.2, ease: 'linear' }}
                      >
                        <Box className={addBoxClass(item)} data-testid="SeekerListItem">
                          <Box
                            data-testid="SeekerToggleIcon"
                            className="candidate-bar"
                            id="test"
                            onClick={(e) => {
                              item.toggleNode?.();
                              if (!item.isOpen && item.type === NodeType.PARENT) {
                                setBusinessPresenter((prevValue) => ({
                                  ...prevValue,
                                  [item.key]: {
                                    ...prevValue[item.key],
                                    isFetching: true,
                                  },
                                }));
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                getPositionsWithCandidates({
                                  businessId: item.key,
                                  filters: {
                                    listType,
                                    searchQuery: '',
                                    sortBy: sort === SeekerListSortType.ALPHABETICAL ? 'name' : 'createdAt',
                                    sortOrder: 'asc',
                                    advancedFilters: searchAndSortTypes,
                                  },
                                }).then((res) => {
                                  setBusinessPresenter((prevValue) => ({
                                    ...prevValue,
                                    [item.key]: {
                                      ...prevValue[item.key],
                                      isFetching: false,
                                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                                      nodes: res.data.map(
                                        (
                                          position: Position & { candidates: CandidateSearchResponse['data']; total: number },
                                        ) => ({
                                          key: position.id,
                                          label: position.customName,
                                          type: NodeType.CHILD,
                                          nodes: position.candidates.map((candidate: CandidateSearchResponse['data'][0]) => ({
                                            key: candidate.id,
                                            label: candidate.fullName,
                                            type: NodeType.CANDIDATE,
                                            candidate,
                                          })),
                                        }),
                                      ),
                                    },
                                  }));
                                  e.stopPropagation();
                                });
                              } else {
                                e.stopPropagation();
                              }
                            }}
                          >
                            {item.isFetching ? <Loading /> : item.type !== NodeType.CANDIDATE && <ToggleIcon on={item.isOpen} />}
                            {item.type === NodeType.PARENT && (
                              <Flex fontWeight={700}>
                                {item.label}
                                <Spacer />
                              </Flex>
                            )}
                            {item.type === NodeType.CHILD && <Box>{item.label}</Box>}
                            {item.type === NodeType.CANDIDATE && (
                              <NewCandidateItem
                                selectedCallback={selectedCallback}
                                item={item}
                                isActive={isCandidateActive(item)}
                              />
                            )}
                          </Box>
                        </Box>
                      </motion.div>
                    );
                  })}
              </AnimatePresence>
            );
          }}
        </TreeMenu>
      </InfiniteScroll>
      {paginatedBusinesses.isFetching && (
        <Box marginY="auto" width="100%" textAlign="center">
          <Spinner color="#1F3CBA" data-testid="Spinner" />
        </Box>
      )}
    </div>
  );
};

export default BusinessCandidateList;
