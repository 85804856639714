// istanbul ignore file
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { Box, Container, useDisclosure } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import { Header } from '../../../app/topBar/Header';
import { ANIMATED_VARIANTS } from '../../../routes/constants';
import { CreateBusinessView } from '../../administration/positionManagement/createBusiness/CreateBusinessView';
import { Business } from '../../administration/positionManagement/BusinessType';
import useCheckIfSmbScreen from './useCheckIfSmbScreen';
import { AppLoadingSpinner } from '../../../app/components';
import SmbBusinessOnBoardingModal from './SmbBusinessOnBoardingModal';

const MotionBox = motion.custom(Box);

const defaultBusinessData: Business = {
  id: '0',
  name: '',
  locationId: '',
  cityName: '',
  address: '',
  country: 'Canada',
  latitude: 0,
  longitude: 0,
  zip: '',
  state: '',
  phoneNumber: '',
  webSite: '',
  completeAddress: '',
  placeId: '',
  positions: 0,
};

export const SmbBusinessManagementView = (): JSX.Element => {
  const history = useHistory();
  const [currentBusinessId, setCurrentBusinessId] = useState('');

  const {
    isOpen: isSmbBusinessOnBoardingModalOpen,
    onOpen: onSmbBusinessOnBoardingModalOpen,
    onClose: onSmbBusinessOnBoardingModalClose,
  } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 2;

  const onSuccessCallBack = () => {
    history.push(`/positionManagement/${currentBusinessId}`);
  };
  const onCloseCallBack = () => {
    history.push(`/administration/positionManagement`);
  };

  const onSuccessFullBusinessCreation = (business: Business) => {
    setCurrentBusinessId(business.id);
    setCurrentPage(2);
    onSmbBusinessOnBoardingModalOpen();
  };

  const { loading } = useCheckIfSmbScreen();

  useEffect(() => {
    onSmbBusinessOnBoardingModalOpen();
  }, [onSmbBusinessOnBoardingModalOpen]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      onSmbBusinessOnBoardingModalClose();
    } else {
      onSmbBusinessOnBoardingModalClose();
      setCurrentPage(1);
      onSuccessCallBack();
    }
  };

  const handleSkip = () => {
    onCloseCallBack();
  };

  return (
    <MotionBox data-testid="SMBBusinessMgmtView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
      <Header data-testid="Header" showMenu={false} />
      {!loading ? (
        <Box py={8} backgroundColor="#F3F3F3" height="calc(100vh - 64px)" overflowY="auto">
          <Container maxW="1200px" position="relative">
            <CreateBusinessView
              successCallBack={onSuccessFullBusinessCreation}
              closeCallBack={onCloseCallBack}
              businessData={defaultBusinessData}
              createNewBusiness
            />
          </Container>
          <SmbBusinessOnBoardingModal
            isOpen={isSmbBusinessOnBoardingModalOpen}
            onClose={() => {
              onSmbBusinessOnBoardingModalClose();
              setCurrentPage(1);
            }}
            currentPage={currentPage}
            onNext={handleNext}
            onSkip={handleSkip}
            totalPages={totalPages}
          />
        </Box>
      ) : (
        <AppLoadingSpinner />
      )}
    </MotionBox>
  );
};
