/* istanbul ignore file */
import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/colors';

interface SmbBusinessOnBoardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPage: number;
  onNext: () => void;
  onSkip: () => void;
  totalPages: number;
}

const SmbBusinessOnBoardingModal: React.FC<SmbBusinessOnBoardingModalProps> = ({
  isOpen,
  onClose,
  currentPage,
  onNext,
  onSkip,
  totalPages,
}) => {
  const { t } = useTranslation('businessManagement');

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={colors.darkBg}>{t('createBusiness.smbBusinessOnBoardingModal.businessTitle')}</ModalHeader>
        <ModalBody>
          {currentPage === 1 && (
            <Box>
              <Text fontWeight="bold" color={colors.gray[450]} mb={3}>
                {t('createBusiness.smbBusinessOnBoardingModal.businessHeading')}
              </Text>
              <Text color={colors.gray[500]}>{t('createBusiness.smbBusinessOnBoardingModal.businessDesc')}</Text>
            </Box>
          )}
          {currentPage === 2 && (
            <Box>
              <Text fontWeight="bold" color={colors.gray[450]} mb={3}>
                {t('createBusiness.smbBusinessOnBoardingModal.positionHeading')}
              </Text>
              <Text color={colors.gray[500]}>{t('createBusiness.smbBusinessOnBoardingModal.positionDesc')}</Text>
            </Box>
          )}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Text color={colors.gray[500]}>
            {`${currentPage} ${t('createBusiness.smbBusinessOnBoardingModal.of')} ${totalPages}`}
          </Text>
          <Box display="flex" flexDirection="row-reverse">
            <Button colorScheme="blue" onClick={onNext}>
              {t('createBusiness.smbBusinessOnBoardingModal.next')}
            </Button>
            <Button variant="ghost" onClick={onSkip} mr={3} border={`1px solid ${colors.lavenderMist}`}>
              {t('createBusiness.smbBusinessOnBoardingModal.skip')}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SmbBusinessOnBoardingModal;
