import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AvatarGroup,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
  Tooltip,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useClipboard,
  useToast,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import {
  RiArrowLeftFill,
  RiBookmarkLine,
  RiEditBoxLine,
  RiFileUserLine,
  RiListOrdered,
  RiUserStarLine,
  RiInboxUnarchiveLine,
  RiShareFill,
  RiBriefcase4Line,
} from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdRefresh } from 'react-icons/io';
import { SearchIcon } from '@chakra-ui/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/core';
import i18next from 'i18next';
import isNil from 'lodash/isNil';
import { Business } from '../BusinessType';
import {
  ConfirmMessageType,
  FollowedRecruiters,
  JobPosition,
  JopPositionResponse,
  PositionFilterEnum,
  PositionFromPositionList,
  PositionListResponse,
} from '../PositionType';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { ProfilePicture } from '../../../common';
import { PositionFollowButton } from './PositionFollowButton';
import colors from '../../../../styles/colors';
import { UpdateStatusConfirmation } from './UpdateStatusConfirmation';
import { defaultPositionListParams, usePositionManagementContext } from '../PositionManagementContext';
import Colors from '../../../../styles/colors';
import { CreateJobsView } from '../createJobs/CreateJobsView';
import { CopyPositionButton } from './CopyPositionButton';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { NewCreateJobsView } from '../createJobs/NewCreateJobsView';
import { AutomationTypes } from '../createJobs/AutomationTypes';
import { PositionAutomationInfoView } from './PositionAutomationInfoView';
import { PositionRequisitionMenuButton } from './PositionRequisitionMenuButton';
import PositionAutomationSelect from './PositionAutomationSelect';
import useContactEmailForAccountAuthorisation from '../../../../app/hooks/useContactEmailForAccountAuthorisation';
import { PATH_POSITION_TABS } from '../../../../routes/constants';
import { SelectedTab } from '../../topCandidates/TopCandidatesContext';

const selectStyle = css`
  margin-right: 1.2rem;
  .chakra-select__icon-wrapper {
    border-left: 1px solid rgb(255 255 255 / 31%);
  }
`;

export type JobsListProps = {
  activeBusiness: Business | null;
  isAdministrationAccount: boolean;
  changeActiveBusiness: (business: Business | null) => void;
  setScrollToBusiness: (business: Business) => void;
};

export enum InfoType {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  ARCHIVE = 'ARCHIVE',
  UNARCHIVE = 'UNARCHIVE',
}

export const PositionList = ({
  activeBusiness,
  changeActiveBusiness,
  isAdministrationAccount,
  setScrollToBusiness,
}: JobsListProps): JSX.Element => {
  const defaultJobListResponse: PositionListResponse = {
    count: 0,
    lastPage: 1,
    to: 0,
    from: 0,
    data: [],
  };
  const match = useRouteMatch();
  const isCurrentPage = (path: string): boolean => match.url.includes(path);
  const toast = useToast();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isArchivingPosition, setIsArchivingPosition] = useState<boolean>(false);
  const [isUnArchivingPosition, setIsUnArchivingPosition] = useState<boolean>(false);
  const [isUpdatingStatusId, setUpdatingStatusId] = useState({} as { [key: string]: boolean });
  const [isArchivingStatusId, setArchivingStatusId] = useState({} as { [key: string]: boolean });
  const [isUnArchivingStatusId, setUnArchivingStatusId] = useState({} as { [key: string]: boolean });
  const [isUpdatingFollow, setIsUpdatingFollow] = useState({} as { [key: string]: boolean });
  const [positionData, setPositionData] = useState<PositionListResponse>(defaultJobListResponse);
  const [searchText, setSearchText] = useState<string>('');
  const [assignedRecruiters, setAssignedRecruiters] = useState<FollowedRecruiters[] | undefined>(undefined);
  const [recuriterImages, setRecuritersImages] = useState<Map<string, string>>(new Map<string, string>());
  const [selectedPosition, setSelectedPosition] = useState<PositionFromPositionList | null>(null);
  const [confirmUpdateStatusMessage, setConfirmUpdateStatusMessage] = useState<ConfirmMessageType | null>(null);
  const [noOfUpdatedPosition, setNoOfUpdatedPosition] = useState<number>(0);
  const [openPositionCountOfAllPosition, setOpenPositionCountOfAllPosition] = useState<number>(0);
  const [createNewPosition, setCreateNewPosition] = useState<boolean>(false);
  const [filterByRequisition, setFilterByRequisition] = useState<boolean>(false);

  const [popOverMessage, setPopOverMessage] = useState<string>('');
  const positionRef = useRef<null | HTMLDivElement>(null);
  const [positionToEdit, setPositionToEdit] = useState<JopPositionResponse | null>(null);
  const [deepLink, setDeepLink] = React.useState<string>('');
  const { onCopy } = useClipboard(deepLink);
  const [positionToCopy, setPositionToCopy] = useState<PositionFromPositionList>();
  const enableCopyPosition: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_COPY_POSITION];
  });

  const enableNewUiForCreatePosition: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION];
  });

  const getIsUpdatingFollowFromChild = (status: boolean, positionId: string) => {
    setIsUpdatingFollow((ids) => ({
      ...ids,
      [positionId]: status,
    }));
  };

  const contactEmailForAccountAuthorizaton = useContactEmailForAccountAuthorisation();

  const { t } = useTranslation();

  const {
    getPositionList,
    getRecruiterProfilePicture,
    updatePositionStatus,
    updateArchivePosition,
    updateUnArchivePosition,
    getPositionDetail,
    getDeepLinkOfPosition,
    setPositionAutomation,
  } = useStoreActions((actions) => actions.positionManagement);
  const accountId = useStoreState((s) => s.app.user?.account);
  const isRequisitionEnabled = useStoreState((s) => s.app.accounts?.configuration?.requisitionEnabled);
  const authorization = useStoreState((s) => s.app.accounts?.authorization);
  const userId = useStoreState((s) => s.app.user?.id);
  const automationEnabled = useStoreState((s) => s.app.accounts?.subscription?.automation);
  const isAccountVirtualRecruiterEnabled = useStoreState((s) => s.app.accounts?.configuration?.virtualRecruiterEnabled);
  const { positionQueryParams, setPositionQueryParams } = usePositionManagementContext();

  // const [isCheckedInterviewCancel, setIsCheckedInterviewCancel] = React.useState<boolean>(false);

  const { isOpen: copyPosition, onOpen: setShowCopyPosition, onClose: setCloseCopyPosition } = useDisclosure();
  const { isOpen: isUpdateStatusModalOpen, onOpen: onUpdateStatusModalOpen, onClose: onUpdateStatusModalClose } = useDisclosure();
  const {
    isOpen: showPositionOpenInfo,
    onOpen: setShowPositionOpenInfo,
    onClose: setShowPositionOpenInfoClose,
  } = useDisclosure();

  const {
    isOpen: showPositionCloseInfo,
    onOpen: setShowPositionCloseInfo,
    onClose: setShowPositionCloseInfoClose,
  } = useDisclosure();

  const {
    isOpen: showPositionArchievedInfo,
    onOpen: setShowPositionArchievedInfo,
    onClose: setShowPositionArchievedInfoClose,
  } = useDisclosure();

  const handleInfoBox = (infoType: InfoType) => {
    positionRef?.current?.scrollIntoView();
    // istanbul ignore else
    if (infoType === InfoType.ARCHIVE) {
      setShowPositionArchievedInfo();
    } else if (infoType === InfoType.OPEN) {
      setPopOverMessage(t('jobPosition:info.open'));
      setShowPositionOpenInfo();
    } else if (infoType === InfoType.UNARCHIVE) {
      setPopOverMessage(t('jobPosition:info.unArchive'));
      setShowPositionCloseInfo();
    } else {
      setPopOverMessage(t('jobPosition:info.close'));
      setShowPositionCloseInfo();
    }
    setTimeout(() => {
      setShowPositionCloseInfoClose();
      setShowPositionOpenInfoClose();
      setShowPositionArchievedInfoClose();
    }, 3000);
  };

  const onCompleteCopy = (business: Business | null) => {
    setCloseCopyPosition();
    if (business) {
      setPopOverMessage(t('jobPosition:info.copy'));
      setShowPositionCloseInfo();

      changeActiveBusiness(business);
    }
    /* istanbul ignore next */
    setTimeout(() => {
      setShowPositionCloseInfoClose();
    }, 3000);
  };

  const getRecuriterImage = async (jopPositionResponse: PositionFromPositionList[]) => {
    const myMap = new Map<string, string>();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < jopPositionResponse.length; i++) {
      const value = jopPositionResponse[i];
      if (value.assignedRecruiters) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < value.assignedRecruiters.length; j++) {
          // eslint-disable-next-line no-await-in-loop
          const picture = await getRecruiterProfilePicture({ accountId: value.assignedRecruiters[j].id });
          myMap.set(value.assignedRecruiters[j].id, picture);
        }
      }
    }
    setRecuritersImages(myMap);
  };

  /* istanbul ignore next */
  const getQueryParamsPage = (pageNumber: number, positionListCount: number, updatedPositionsCount: number) => {
    if (positionListCount - updatedPositionsCount <= defaultPositionListParams.limit) {
      return pageNumber - 1;
    }
    return pageNumber;
  };

  /* istanbul ignore next */
  const getUpdatedPositionList = (
    existingPositionList: PositionListResponse,
    responseData: PositionListResponse,
    updatedPositionsCount: number,
  ) => {
    if (existingPositionList.count - updatedPositionsCount <= defaultPositionListParams.limit) {
      return responseData.data;
    }
    return existingPositionList.data.concat(responseData.data);
  };

  // function to check the interveiw cancellation
  /* istanbul ignore next */
  // const getInterviewCancelCheckBox = () => {
  //   return (
  //     <Checkbox
  //       mb={3}
  //       isChecked={isCheckedInterviewCancel}
  //       onChange={(e) => setIsCheckedInterviewCancel(e.target.checked)}
  //       data-testid="interviewCancelCheck"
  //     />
  //   );
  // };

  const loadPositionList = useCallback(
    async (
      business: Business,
      existingPositionList: PositionListResponse,
      searchTerms: string,
      noOfUpdatedPositionCount: number,
      loadMore = false,
    ) => {
      setLoading(true);
      /* istanbul ignore else */
      if (accountId && business.id) {
        positionQueryParams.page = !loadMore
          ? 1
          : getQueryParamsPage(positionQueryParams.page, existingPositionList.count, noOfUpdatedPositionCount);
        positionQueryParams.searchTerm = searchTerms;
        setPositionQueryParams(positionQueryParams);
        const data: PositionListResponse = await getPositionList({
          accountId,
          businessId: business?.id,
          params: positionQueryParams,
        });
        setLoading(false);
        setNoOfUpdatedPosition(0);
        /* istanbul ignore else */
        if (data) {
          positionData.data = loadMore ? getUpdatedPositionList(existingPositionList, data, noOfUpdatedPositionCount) : data.data;
          positionData.lastPage = data.lastPage;
          positionData.count = data.count;
          setPositionData({ ...positionData });
          if (positionQueryParams.positionStatus === 'open' && positionQueryParams.followedOnly === false) {
            setOpenPositionCountOfAllPosition(data.count);
          }
          await getRecuriterImage(positionData.data);
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, getPositionList, positionQueryParams],
  );

  const loadMorePosition = () => {
    positionQueryParams.page += 1;
    setPositionQueryParams({ ...positionQueryParams });
    /* istanbul ignore else */
    if (!_isNil(activeBusiness)) {
      loadPositionList(activeBusiness, positionData, searchText, noOfUpdatedPosition, true).catch(
        /* istanbul ignore next */ (err) => console.log(err),
      );
    }
  };

  const filterJobPositionList = (value: PositionFilterEnum) => {
    if (value === PositionFilterEnum.FOLLOWED) {
      setPositionQueryParams({ ...positionQueryParams, followedOnly: true, page: 1 });
    } else if (value === PositionFilterEnum.ALL) {
      setPositionQueryParams({ ...positionQueryParams, followedOnly: false, page: 1 });
    } else if (value === PositionFilterEnum.OPEN) {
      setPositionQueryParams({ ...positionQueryParams, positionStatus: 'open', archive: false, page: 1 });
    } else if (value === PositionFilterEnum.ARCHIVED) {
      setPositionQueryParams({ ...positionQueryParams, archive: true, positionStatus: 'closed', page: 1 });
    } else {
      setPositionQueryParams({ ...positionQueryParams, positionStatus: 'closed', archive: false, page: 1 });
    }
    setNoOfUpdatedPosition(0);
  };

  const updatePositionByStatus = async (position: PositionFromPositionList) => {
    onUpdateStatusModalClose();
    /* istanbul ignore else */
    if (accountId && userId) {
      setUpdatingStatusId((ids) => ({
        ...ids,
        [position.id]: true,
      }));
      await updatePositionStatus({
        accountId,
        businessId: position.business,
        positionId: position.id,
        positionStatus: {
          open: !position.open,
        },
        // eslint-disable-next-line no-unneeded-ternary
        doCancellAppointmentHireOrOffer: position.open ? /* istanbul ignore next */ true : false,
      });

      const positionIndex = positionData.data.findIndex((posi) => posi.id === position.id);
      positionData.data[positionIndex].open = !position.open;

      /* istanbul ignore else */
      if (position.open) {
        positionData.data[positionIndex].assignedRecruiters = [{ id: userId, name: '' }];
        handleInfoBox(InfoType.OPEN);
      } else {
        handleInfoBox(InfoType.CLOSE);
      }

      positionData.data = positionData.data.filter((posi) => posi.id !== position.id);
      setNoOfUpdatedPosition(noOfUpdatedPosition + 1);

      /* istanbul ignore else */
      if (positionQueryParams.positionStatus === 'open') {
        setOpenPositionCountOfAllPosition(openPositionCountOfAllPosition - 1);
      } else {
        setOpenPositionCountOfAllPosition(openPositionCountOfAllPosition + 1);
      }

      setPositionData({ ...positionData });
      setUpdatingStatusId((ids) => ({
        ...ids,
        [position.id]: false,
      }));
    }
  };

  const updateArchiveStatus = async (position: PositionFromPositionList) => {
    onUpdateStatusModalClose();
    /* istanbul ignore else */
    if (accountId && userId) {
      setArchivingStatusId((ids) => ({
        ...ids,
        [position.id]: true,
      }));
      await updateArchivePosition({
        positionId: position.id,
      }).finally(() => setIsArchivingPosition(false));

      positionData.data = positionData.data.filter((posi) => posi.id !== position.id);
      setNoOfUpdatedPosition(noOfUpdatedPosition + 1);
      setPositionData({ ...positionData });
      setArchivingStatusId((ids) => ({
        ...ids,
        [position.id]: false,
      }));
    }

    handleInfoBox(InfoType.ARCHIVE);
    setIsArchivingPosition(false);
  };

  const updateUnArchiveStatus = async (position: PositionFromPositionList) => {
    onUpdateStatusModalClose();
    /* istanbul ignore else */
    if (accountId && userId) {
      setUnArchivingStatusId((ids) => ({
        ...ids,
        [position.id]: true,
      }));
      await updateUnArchivePosition({
        positionId: position.id,
      }).finally(() => setIsUnArchivingPosition(false));

      positionData.data = positionData.data.filter((posi) => posi.id !== position.id);
      setNoOfUpdatedPosition(noOfUpdatedPosition + 1);
      setPositionData({ ...positionData });
      setUnArchivingStatusId((ids) => ({
        ...ids,
        [position.id]: false,
      }));
    }
    handleInfoBox(InfoType.UNARCHIVE);
    setIsUnArchivingPosition(false);
  };

  const callArchiveHandler = async (position: PositionFromPositionList) => {
    if (isArchivingPosition) {
      await updateArchiveStatus(position);
    } else {
      await updateUnArchiveStatus(position);
    }
  };

  const callbackAssignedRecruiters = (recruiters: FollowedRecruiters[], positionId: string) => {
    const positionIndex = positionData.data.findIndex((position) => position.id === positionId);
    positionData.data[positionIndex].assignedRecruiters = recruiters;

    /* istanbul ignore next */
    if (recruiters.length === 0) {
      positionData.data[positionIndex].open = false;
    }

    /* istanbul ignore next */
    if (positionQueryParams.followedOnly || recruiters.length === 0) {
      positionData.data = positionData.data.filter((position) => position.id !== positionId);
      setNoOfUpdatedPosition(noOfUpdatedPosition + 1);
    }

    setPositionData({ ...positionData });
  };

  // istanbul ignore next
  const updatePositionAutomation = (
    givenPositionId: string,
    givenAutomationType: string | null,
    screeningTarget: number | undefined,
    shouldUpdateAutomationApiCalled?: boolean,
    schedulingVrEnabled?: boolean,
  ): Promise<boolean> => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises,no-async-promise-executor
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        if (shouldUpdateAutomationApiCalled) {
          await setPositionAutomation({
            positionId: givenPositionId,
            automationType: givenAutomationType as AutomationTypes,
            virtualRecruiterEnabled: schedulingVrEnabled,
            candidateScreenTarget: screeningTarget,
          });
        }

        const updatedPositionListData = positionData.data.map((positionItem) =>
          givenPositionId === positionItem.id
            ? {
                ...positionItem,
                automationType: givenAutomationType,
                ...(givenAutomationType === AutomationTypes.SCREENING &&
                  screeningTarget !== undefined && {
                    automationConfiguration: {
                      target: screeningTarget,
                    },
                  }),
                virtualRecruiterEnabled: givenAutomationType === AutomationTypes.SCHEDULING && schedulingVrEnabled,
              }
            : positionItem,
        );

        setPositionData({ ...positionData, data: updatedPositionListData });

        // Manually resolve the promise if successful
        resolve(true);
      } catch (error) {
        // Manually reject the promise if an error occurs
        reject(error);
      }
    });
  };

  const onChangeSearchText = (value: string) => {
    setSearchText(value);
  };

  const disablePositionCreation = !_isNil(authorization) && !authorization;

  const loadRecruiterImage = (recruiter: FollowedRecruiters) => {
    return (
      <Tooltip label={recruiter.name} shouldWrapChildren placement="top" key={recruiter.id}>
        <ProfilePicture
          src={recuriterImages.get(recruiter.id)}
          style={{ backgroundColor: '#263DBA', fontWeight: 600, color: '#fff', marginRight: '-10px' }}
          name={recruiter.name}
          bg="transparent"
          size="sm"
        />
      </Tooltip>
    );
  };

  const loadFollowedRecruiterListItems = (recruiter: FollowedRecruiters) => {
    return (
      <Flex alignItems="center" py={3}>
        <ProfilePicture
          src={recuriterImages.get(recruiter.id)}
          style={{ backgroundColor: 'blue', color: 'white' }}
          name={recruiter.name}
          bg="transparent"
          size="sm"
        />
        <Box px={3}>
          <Text>{recruiter.name}</Text>
        </Box>
      </Flex>
    );
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  const messageForOpenPosition = (position: PositionFromPositionList) => {
    if (!_isNil(automationEnabled) && automationEnabled && position.automated) {
      switch (position.automationType) {
        case 'screening':
          return (
            <Box>
              <Text>{t('jobPosition:openPositionModal.subtitle')}</Text>
              <Heading as="h4" fontSize="lg" mt={1} mb={1}>
                {t('jobPosition:openPositionModal.screening.title')}
              </Heading>
              <Text>
                {position.automationConfiguration?.target === 1
                  ? t('jobPosition:openPositionModal.screening.description.one', { count: 1 })
                  : t('jobPosition:openPositionModal.screening.description.other', {
                      count: position.automationConfiguration?.target,
                    })}
              </Text>
            </Box>
          );
        case 'scheduling':
          return (
            <Box>
              <Text>{t('jobPosition:openPositionModal.subtitle')}</Text>
              <Heading as="h4" fontSize="lg" mt={1} mb={1}>
                {t('jobPosition:openPositionModal.scheduling.title')}
              </Heading>
              <Text>{t('jobPosition:openPositionModal.scheduling.description')}</Text>
            </Box>
          );
        default:
          return (
            <Box>
              <Text>{t('jobPosition:openPositionModal.subtitle')}</Text>
              <Heading as="h4" fontSize="lg" mt={1} mb={1}>
                {t('jobPosition:openPositionModal.disabled.title')}
              </Heading>
              <Text>{t('jobPosition:openPositionModal.disabled.description')}</Text>
            </Box>
          );
      }
    } else {
      //  istanbul ignore next
      return <Text>{disablePositionCreation ? '' : t('jobPosition:openPositionModal.noAutomation.description')}</Text>;
    }
  };

  const messageForClosePosition = (noOfRecruiter: number | undefined) => {
    if (!_isNil(noOfRecruiter)) {
      if (noOfRecruiter - 1 < 1) {
        return t('jobPosition:closePositionModal.subtitle.zero');
      }
      if (noOfRecruiter - 1 === 1) {
        return t('jobPosition:closePositionModal.subtitle.one', { count: noOfRecruiter - 1 });
      }
      return t('jobPosition:closePositionModal.subtitle.other', { count: noOfRecruiter - 1 });
    }
    return '';
  };

  const confirmUpdateByStatusHeaderMessage = (isPositionOpen: boolean) => {
    if (isRequisitionEnabled) {
      return t('jobPosition:openPositionModal.requisition.title');
    }
    return isPositionOpen ? t('jobPosition:closePositionModal.title') : t('jobPosition:openPositionModal.title');
  };

  const confirmUpdateByStatusBodyMessage = (position: PositionFromPositionList) => {
    if (isRequisitionEnabled) {
      return !position.open
        ? t('jobPosition:closePositionModal.requisition.subtitle')
        : `${t('jobPosition:openPositionModal.requisition.subtitle')}`;
    }

    return position.open ? `${messageForClosePosition(position.assignedRecruiters?.length)}` : messageForOpenPosition(position);
  };

  const confirmUpdateByStatus = (position: PositionFromPositionList) => {
    const confirmMessage = {
      headerMessage: confirmUpdateByStatusHeaderMessage(position.open),
      bodyMessage: confirmUpdateByStatusBodyMessage(position),
      confirmText: position.open
        ? t('jobPosition:closePositionModal.closePositionButton.title')
        : t('jobPosition:openPositionModal.openPositionButton'),
      cancelText: t('jobPosition:openPositionModal.cancelModalButton'),
    } as ConfirmMessageType;
    setConfirmUpdateStatusMessage(confirmMessage);
    setSelectedPosition(position);
    onUpdateStatusModalOpen();
  };

  const confirmArchivePosition = (position: PositionFromPositionList) => {
    setIsArchivingPosition(true);
    const confirmMessage = {
      headerMessage: t('jobPosition:archivePositionModal.title'),
      bodyMessage: t('jobPosition:archivePositionModal.bodyMessage'),
      confirmText: t('jobPosition:archivePositionModal.confirmText'),
      cancelText: t('jobPosition:archivePositionModal.cancelText'),
    } as ConfirmMessageType;
    setConfirmUpdateStatusMessage(confirmMessage);
    setSelectedPosition(position);
    onUpdateStatusModalOpen();
  };

  const confirmUnArchivePosition = (position: PositionFromPositionList) => {
    setIsUnArchivingPosition(true);
    const confirmMessage = {
      headerMessage: t('jobPosition:unarchivePositionModal.title'),
      bodyMessage: t('jobPosition:unarchivePositionModal.bodyMessage'),
      confirmText: t('jobPosition:unarchivePositionModal.confirmText'),
      cancelText: t('jobPosition:unarchivePositionModal.cancelText'),
    } as ConfirmMessageType;
    setConfirmUpdateStatusMessage(confirmMessage);
    setSelectedPosition(position);
    onUpdateStatusModalOpen();
  };

  const handlePositionToEdit = async (res: PositionFromPositionList) => {
    const response = await getPositionDetail({
      accountId: res.account,
      businessId: res.business,
      positionId: res.id,
    });
    setPositionToEdit(response);
    setCreateNewPosition(true);
  };

  const copyDeepLinkOfPosition = async (positionId: string) => {
    const response = await getDeepLinkOfPosition({
      positionId,
      locale: i18next.language,
    });
    setDeepLink(response.link);
    await navigator.clipboard.writeText(response.link);
  };

  useEffect(() => {
    if (deepLink !== '') {
      onCopy();
      /* istanbul ignore next */
      toast({
        title: t('jobPosition:copiedMessage'),
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deepLink]);

  /* istanbul ignore next */
  const getHiredAndOpenignsData = (position: PositionFromPositionList) => {
    let hiredCount = 0;
    let openings: string | number = '-';
    if (position?.noOfHiredCandidates) {
      hiredCount = position.noOfHiredCandidates;
    }
    if (position?.noOfOpenings) {
      openings = position.noOfOpenings;
    }
    return `${hiredCount} / ${openings}`;
  };

  const loadAutomationInfo = (positionInformation: PositionFromPositionList) => {
    // istanbul ignore next
    if (positionInformation.automationType === AutomationTypes.SCHEDULING && positionInformation?.virtualRecruiterEnabled) {
      return (
        <PositionAutomationInfoView
          toolTipMessage={t('jobPosition:automation.schedulingVrEnabled.toolTipMessage')}
          automationLabel={t('jobPosition:automation.schedulingVrEnabled.label')}
        />
      );
    }
    switch (positionInformation.automationType) {
      case AutomationTypes.SCREENING:
        return (
          <PositionAutomationInfoView
            toolTipMessage={t('jobPosition:automation.screening.toolTipMessage', {
              count: positionInformation.automationConfiguration?.target,
            })}
            automationLabel={t('jobPosition:automation.screening.label', {
              count: positionInformation.automationConfiguration?.target,
            })}
          />
        );
      case AutomationTypes.SCHEDULING:
        return (
          <PositionAutomationInfoView
            toolTipMessage={t('jobPosition:automation.scheduling.toolTipMessage')}
            automationLabel={t('jobPosition:automation.scheduling.label')}
          />
        );
      default:
        return (
          <PositionAutomationInfoView
            toolTipMessage={t('jobPosition:automation.findMeCandidate.toolTipMessage')}
            automationLabel={t('jobPosition:automation.findMeCandidate.label')}
          />
        );
    }
  };

  const loadPositionItem = (position: PositionFromPositionList, index: number) => {
    const isPositionFollowed =
      position.assignedRecruiters &&
      position?.assignedRecruiters?.filter(function (value) {
        return value.id === userId;
      }).length > 0;

    const currentAutomationValue = isNil(position?.automationType) ? 'null' : position.automationType;

    return (
      <Flex
        px={6}
        py={5}
        justifyContent="space-between"
        key={position.id}
        bg={
          // eslint-disable-next-line no-nested-ternary
          isRequisitionEnabled && position.open
            ? !_isNil(position.isPaused) && !position.isPaused
              ? 'white'
              : 'yellow.50'
            : 'white'
        }
      >
        <Box pr={3} w="100%">
          <Heading as="h5" fontSize="md" lineHeight="1" mb={3} display="flex">
            {`${index + 1}. `}

            {isPositionFollowed && position.open ? (
              <Link
                to={{ pathname: `/positionCandidates/${position.id}/messaging`, state: 'PositionList' }}
                style={{ color: colors.linkBlue, textDecoration: 'underline' }}
              >
                {position.customName ? position.customName : /* istanbul ignore next */ position.jobTitle}
              </Link>
            ) : (
              <span>{position.customName ? position.customName : position.jobTitle}</span>
            )}

            {isPositionFollowed && position.open && (
              <Link to={PATH_POSITION_TABS(position.id, SelectedTab.BEST_MATCH)}>
                <RiBriefcase4Line size={20} color={colors.blue[500]} style={{ marginLeft: '12px', marginBottom: '5px' }} />
              </Link>
            )}
          </Heading>

          <Flex mb={4} alignItems="flex-start">
            <Flex alignItems="center" mr={8}>
              <RiBookmarkLine style={{ width: '24px', height: '24px', marginRight: '.6rem', color: '#b0bec5' }} />
              <Box>
                <Heading as="h6" fontSize="sm" fontWeight="normal" lineHeight="1" color="gray.500" whiteSpace="nowrap">
                  {t('jobPosition:time')}
                </Heading>
                <Text fontSize=".9rem" fontWeight="bold">
                  {position.fullTime ? t('jobPosition:fullTime') : t('jobPosition:partTime')}
                </Text>
              </Box>
            </Flex>
            <Flex alignItems="center" mr={8}>
              <RiFileUserLine style={{ width: '24px', height: '24px', marginRight: '.6rem', color: '#b0bec5' }} />
              <Box>
                <Heading as="h6" fontSize="sm" fontWeight="normal" lineHeight="1" color="gray.500" whiteSpace="nowrap">
                  {t('jobPosition:applicants')}
                </Heading>
                <Text fontSize=".9rem" fontWeight="bold">
                  {/* istanbul ignore next */ position.applicants ? position.applicants : ' -'}
                </Text>
              </Box>
            </Flex>
            <Flex alignItems="center" mr={8}>
              <RiListOrdered style={{ width: '24px', height: '24px', marginRight: '.6rem', color: '#b0bec5' }} />
              <Box>
                <Heading as="h6" fontSize="sm" fontWeight="normal" lineHeight="1" color="gray.500" whiteSpace="nowrap">
                  {t('jobPosition:noOfHires')}
                </Heading>
                <Text fontSize=".9rem" fontWeight="bold">
                  {getHiredAndOpenignsData(position)}
                  {/* {`${position.noOfHiredCandidates ? position.noOfHiredCandidates : '0 '}/${
                    position.noOfOpenings ? position.noOfOpenings : ' -'
                  }`} */}
                </Text>
              </Box>
            </Flex>
          </Flex>

          <Flex alignItems="normal">
            {position.assignedRecruiters && position.assignedRecruiters.length > 0 ? (
              <Flex alignItems="center" mr={8}>
                <RiUserStarLine style={{ width: '24px', height: '24px', marginRight: '.6rem', color: '#b0bec5' }} />
                <Box height="100%" display="flex" flexDirection="column">
                  <Heading as="h6" fontSize="sm" fontWeight="normal" lineHeight="1" color="gray.500" mb={2}>
                    {t('jobPosition:recruiterFollowing')}
                  </Heading>

                  <Box
                    onClick={() => {
                      setAssignedRecruiters(position.assignedRecruiters);
                      onOpen();
                    }}
                    style={{ cursor: 'pointer' }}
                    data-testid="showAllRecuriter"
                  >
                    <AvatarGroup size="xs" max={5}>
                      {position.assignedRecruiters.map((item: FollowedRecruiters) => loadRecruiterImage(item))}
                    </AvatarGroup>
                  </Box>
                </Box>
              </Flex>
            ) : (
              ''
            )}

            <Flex alignItems="center" mr={8} height="100%" width="35%">
              <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Heading as="h6" fontSize="sm" fontWeight="normal" lineHeight="1" color="gray.500" whiteSpace="nowrap">
                  {t('jobPosition:automation.title')}
                </Heading>
                <Text fontSize=".9rem" fontWeight="bold" mt={2}>
                  {loadAutomationInfo(position)}
                </Text>
              </Box>
            </Flex>

            {isPositionFollowed && !position.archived && (
              <Flex direction="column">
                <Heading as="h6" fontSize="sm" fontWeight="normal" lineHeight="1" color="gray.500" mb={1}>
                  {t('jobPosition:automationTypes.selectAutomationLevel')}
                </Heading>

                <PositionAutomationSelect
                  positionId={position.id}
                  currentAutomationValue={currentAutomationValue as AutomationTypes}
                  isAccountVirtualRecruiterEnabled={isAccountVirtualRecruiterEnabled}
                  updatePositionAutomation={updatePositionAutomation}
                  automationConfiguration={position.automationConfiguration}
                  isPositionVirtualRecruiterEnabled={position.virtualRecruiterEnabled}
                />
              </Flex>
            )}
          </Flex>
        </Box>

        <Flex>
          {isAdministrationAccount && !isCurrentPage('/positions') && !positionQueryParams.archive && (
            <Tooltip
              label={`${t('jobPosition:userRestrictedByAuthorizationMessageFront')} ${contactEmailForAccountAuthorizaton} ${t(
                'jobPosition:userRestrictedByAuthorizationMessageBack',
              )}`}
              shouldWrapChildren
              isDisabled={!disablePositionCreation}
            >
              <Box height="fit-content">
                <IconButton
                  size="sm"
                  fontSize="1.3rem"
                  colorScheme="blue"
                  aria-label={t('jobPosition:edit')}
                  icon={<RiEditBoxLine />}
                  mr={3}
                  data-testid={`PositionEditBtn-${position.id}`}
                  onClick={() => {
                    handlePositionToEdit(position).finally(() => console.log(''));
                  }}
                  disabled={disablePositionCreation}
                />
              </Box>
            </Tooltip>
          )}

          {isRequisitionEnabled && position.open && (
            <PositionRequisitionMenuButton position={position} isLastItem={positionData.data.length - 1 === index} />
          )}
          <Button
            leftIcon={<RiShareFill />}
            size="sm"
            background="#edf2f7"
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="1px"
            border="none"
            mr={2}
            data-testid={`copyDeepLinkButton-${position.id}`}
            _hover={{ bg: '#e2e8f0' }}
            _expanded={{ bg: '#1F3CBA', color: '#fff' }}
            onClick={() => copyDeepLinkOfPosition(position.id)}
          >
            {t('jobPosition:share')}
          </Button>
          {!position.archived && (
            <Menu>
              <MenuButton
                height="32px"
                px={4}
                py={2}
                background="#edf2f7"
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="1px"
                border="none"
                _hover={{ bg: '#e2e8f0' }}
                _expanded={{ bg: '#1F3CBA', color: '#fff' }}
                data-testid={`MenuButton-${position.id}`}
              >
                <Box display="flex" alignItems="center" fontWeight="600">
                  {isUpdatingFollow[position.id] ||
                  isUpdatingStatusId[position.id] ||
                  isArchivingStatusId[position.id] ||
                  isUnArchivingStatusId[position.id] ? (
                    <Spinner size="sm" display="flex" />
                  ) : (
                    <BsThreeDotsVertical />
                  )}
                </Box>
              </MenuButton>
              <MenuList minWidth="8rem">
                {userId && !positionQueryParams.archive && (
                  <>
                    <MenuItem p={0}>
                      <PositionFollowButton
                        recruiterId={userId}
                        assignedRecruiters={position.assignedRecruiters ? position.assignedRecruiters : []}
                        positionId={position.id}
                        callbackAssignedRecruiters={callbackAssignedRecruiters}
                        notifyUpdatingStateToParent={getIsUpdatingFollowFromChild}
                      />
                    </MenuItem>
                  </>
                )}

                <>
                  <MenuItem p={0}>
                    <Button
                      size="sm"
                      variant="ghost"
                      width="100%"
                      borderRadius="unset"
                      isLoading={isUpdatingStatusId[position.id]}
                      data-testid={`UpdateStatusBtn-${position.id}`}
                      onClick={() => confirmUpdateByStatus(position)}
                      // onClick={() => updatePositionByStatus(position)}
                    >
                      {position.open ? t('jobPosition:close') : t('jobPosition:open')}
                    </Button>
                  </MenuItem>
                </>

                {!position.open && !position.archived && isAdministrationAccount && (
                  <>
                    <MenuItem p={0}>
                      <Button
                        size="sm"
                        variant="ghost"
                        width="100%"
                        borderRadius="unset"
                        isLoading={isArchivingStatusId[position.id]}
                        data-testid={`ArchiveBtn-${position.id}`}
                        onClick={() => confirmArchivePosition(position)}
                        // onClick={() => updatePositionByStatus(position)}
                      >
                        {t('jobPosition:archive')}
                      </Button>
                    </MenuItem>
                  </>
                )}
                {enableCopyPosition && isAdministrationAccount && (
                  <>
                    <Tooltip
                      label={`${t(
                        'jobPosition:userRestrictedByAuthorizationMessageFront',
                      )} ${contactEmailForAccountAuthorizaton} ${t('jobPosition:userRestrictedByAuthorizationMessageBack')}`}
                      shouldWrapChildren
                      isDisabled={!disablePositionCreation}
                    >
                      <MenuItem p={0}>
                        <Button
                          size="sm"
                          variant="ghost"
                          width="100%"
                          style={{ borderRadius: 'unset', background: 'unset' }}
                          onClick={() => {
                            setPositionToCopy(position);
                            setShowCopyPosition();
                          }}
                          data-testid={`copyPositionBtn-${position.id}`}
                          disabled={disablePositionCreation}
                        >
                          {t('jobPosition:copy')}
                        </Button>
                      </MenuItem>
                    </Tooltip>
                  </>
                )}
              </MenuList>
            </Menu>
          )}

          {position.archived && !position.open && isAdministrationAccount && (
            <Box>
              <Button
                leftIcon={<RiInboxUnarchiveLine />}
                ml={3}
                size="sm"
                width="115px"
                isLoading={isUnArchivingStatusId[position.id]}
                colorScheme="blue"
                data-testid={`unArchiveBtn-${position.id}`}
                onClick={() => confirmUnArchivePosition(position)}
              >
                {t('jobPosition:unArchive')}
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (activeBusiness) {
      loadPositionList(activeBusiness, positionData, searchText, noOfUpdatedPosition).catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBusiness, loadPositionList]);

  const handlePositionForm = () => {
    setCreateNewPosition(true);
  };

  const redirectToPositionAfterCreated = (jobPositionData: JobPosition) => {
    const updatedPositionListData = positionData.data.map((positionItem) =>
      /* istanbul ignore next */ positionItem.id === positionToEdit?.id
        ? {
            ...positionItem,
            jobCustomName: jobPositionData.jobCustomName,
            customName: jobPositionData.jobCustomName ?? '',
            fullTime: jobPositionData.fullTime,
          }
        : positionItem,
    );

    setPositionData({ ...positionData, data: updatedPositionListData });

    setCreateNewPosition(false);
    setPositionToEdit(null);
  };

  if (createNewPosition && !enableNewUiForCreatePosition) {
    return (
      <Box ref={positionRef}>
        <CreateJobsView
          positionDataToEdit={positionToEdit}
          redirectToPositionAfterCreated={redirectToPositionAfterCreated}
          selectedBusiness={activeBusiness}
          setCreateNewPosition={(createPosition) => setCreateNewPosition(createPosition)}
          updatePositionAutomation={updatePositionAutomation}
        />
      </Box>
    );
  }

  return (
    <Box ref={positionRef}>
      {!_isNil(activeBusiness) && (
        <Fragment>
          <Box backgroundColor="white" borderRadius={5} mb={6} px={6} py={4}>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  data-testid="searchInput"
                  type="text"
                  value={searchText}
                  placeholder={t('jobPosition:searchPlaceholder')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearchText(e.target.value)}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    e.key === 'Enter' && loadPositionList(activeBusiness, positionData, searchText, noOfUpdatedPosition, false)
                  }
                />
              </InputGroup>
            </Stack>
          </Box>
          <Flex
            bg="#fff"
            px={4}
            py={3}
            alignItems="center"
            color="gray.500"
            borderBottom="1px solid #eee"
            borderRadius="5px 5px 0 0"
          >
            <Flex alignItems="center" w="100%">
              <Button
                onClick={() => {
                  setScrollToBusiness({ ...activeBusiness, positions: openPositionCountOfAllPosition });
                  changeActiveBusiness(null);
                }}
                variant="ghost"
                colorScheme="blue"
                style={{ color: colors.blue[500] }}
                data-testid="backToBusiness"
                leftIcon={<RiArrowLeftFill />}
              >
                {t('createJobPosition:stepsNav.back')}
              </Button>

              <Spacer />
              <Flex alignItems="center">
                <Button
                  onClick={() =>
                    filterJobPositionList(
                      positionQueryParams.archive
                        ? PositionFilterEnum.ARCHIVED
                        : (positionQueryParams.positionStatus as PositionFilterEnum),
                    )
                  }
                  size="md"
                  fontSize="sm"
                  colorScheme="gray"
                  leftIcon={<IoMdRefresh size={20} />}
                  data-testid="refreshBtn"
                  mr={4}
                  variant="outline"
                >
                  {t('jobPosition:refresh')}
                </Button>
                <Box css={selectStyle}>
                  <Select
                    onChange={(e) => filterJobPositionList(e.target.value as PositionFilterEnum)}
                    bg="blue.500"
                    color="#fff"
                    borderColor="blue.500"
                    maxW="200px"
                    mr={5}
                    fontSize="sm"
                    fontWeight="bold"
                    data-testid="filterPositionBtn"
                    value={positionQueryParams.followedOnly ? PositionFilterEnum.FOLLOWED : PositionFilterEnum.ALL}
                  >
                    <option value={PositionFilterEnum.FOLLOWED} style={{ color: '#000' }}>
                      {t('jobPosition:followedPositions')}
                    </option>
                    <option value={PositionFilterEnum.ALL} style={{ color: '#000' }}>
                      {t('jobPosition:allPositions')}
                    </option>
                  </Select>
                </Box>
                <Box>
                  <ButtonGroup isAttached backgroundColor="white" borderRadius={5}>
                    <Popover
                      isOpen={showPositionOpenInfo}
                      onClose={setShowPositionOpenInfoClose}
                      placement="top"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <Button
                          onClick={() => filterJobPositionList(PositionFilterEnum.OPEN)}
                          size="md"
                          fontSize="sm"
                          colorScheme="blue"
                          data-testid="openPositionBtn"
                          variant={positionQueryParams.positionStatus === 'open' ? 'solid' : 'outline'}
                        >
                          {t('jobPosition:openPositions')}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow background={Colors.blue.default} />
                        <PopoverCloseButton color={Colors.white} />
                        <PopoverBody background={Colors.blue.default} color={Colors.white}>
                          {popOverMessage}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                    <Popover
                      isOpen={showPositionCloseInfo}
                      onClose={setShowPositionCloseInfoClose}
                      placement="top"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <Button
                          onClick={() => filterJobPositionList(PositionFilterEnum.CLOSED)}
                          size="md"
                          fontSize="sm"
                          colorScheme="blue"
                          data-testid="closePositionBtn"
                          variant={
                            positionQueryParams.positionStatus === 'closed' && !positionQueryParams.archive ? 'solid' : 'outline'
                          }
                        >
                          {t('jobPosition:closedPositions')}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow background={Colors.blue.default} />
                        <PopoverCloseButton color={Colors.white} />
                        <PopoverBody background={Colors.blue.default} color={Colors.white}>
                          {popOverMessage}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>

                    <Popover
                      isOpen={showPositionArchievedInfo}
                      onClose={setShowPositionArchievedInfoClose}
                      placement="top"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <Button
                          onClick={() => filterJobPositionList(PositionFilterEnum.ARCHIVED)}
                          size="md"
                          fontSize="sm"
                          colorScheme="blue"
                          data-testid="archivedPositionBtn"
                          variant={positionQueryParams.archive ? 'solid' : 'outline'}
                        >
                          {t('jobPosition:archivedPositions')}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow background={Colors.blue.default} />
                        <PopoverCloseButton color={Colors.white} />
                        <PopoverBody background={Colors.blue.default} color={Colors.white}>
                          {t('jobPosition:info.archived')}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </ButtonGroup>
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Accordion defaultIndex={[0]}>
            <AccordionItem backgroundColor="#fff" mb={4} border={0}>
              <h2>
                <AccordionButton as="div" px={6} py={4} borderBottom="1px solid #eceff1">
                  <Flex flex="1" textAlign="left" justifyContent="space-between" alignItems="center">
                    <Heading as="h4" fontSize="lg">
                      {activeBusiness?.name}
                    </Heading>
                    <Flex alignItems="center">
                      {isRequisitionEnabled && (
                        <FormControl w="auto" display="flex" alignItems="center" mr={3}>
                          <FormLabel fontSize="sm" htmlFor="filterByRequisitions" mb="0">
                            {t('jobPosition:filterByRequisitions')}
                          </FormLabel>
                          <Switch
                            size="sm"
                            id="filterByRequisitions"
                            data-testid="filterByRequisitions"
                            onChange={(e) => setFilterByRequisition(e.target.checked)}
                          />
                        </FormControl>
                      )}
                      <Button
                        color="blue.500"
                        fontWeight="bold"
                        isLoading={isLoading}
                        loadingText={t('jobPosition:counting')}
                        border="0"
                        backgroundColor="#F3F3F3"
                        _hover={{ bg: '#F3F3F3' }}
                        fontSize=".9rem"
                        borderRadius="4px"
                        px={4}
                        py={2}
                        size="sm"
                      >
                        {`${positionData.count - noOfUpdatedPosition} ${
                          positionData.count - noOfUpdatedPosition !== 1 ? t('jobPosition:positions') : t('jobPosition:position')
                        }`}
                      </Button>
                      {isAdministrationAccount && (
                        <Button
                          colorScheme="blue"
                          data-testid="createPositionBtn"
                          style={{ float: 'right' }}
                          fontWeight="bold"
                          fontSize=".9rem"
                          borderRadius="4px"
                          px={4}
                          py={2}
                          size="sm"
                          marginLeft="12px"
                          onClick={() => handlePositionForm()}
                        >
                          {t('jobPosition:createNewPosition')}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel px={0} pt={0} pb={6}>
                <VStack
                  divider={<StackDivider borderColor="#ECEFF1" />}
                  spacing={0}
                  align="stretch"
                  data-testid="positionListWrapper"
                >
                  {!isLoading && positionData.data.length <= 0 && (
                    <Box px={6}>
                      <Heading as="h5" fontSize="md" lineHeight="1" my={4}>
                        {t('jobPosition:noJobs')}
                      </Heading>
                    </Box>
                  )}
                  {(filterByRequisition
                    ? positionData.data.filter((pos) => !pos.isPaused)
                    : positionData.data
                  ).map((position, index) => loadPositionItem(position, index))}
                </VStack>
                {isLoading && (
                  <Center py={6}>
                    <Spinner color="blue.500" />
                  </Center>
                )}
              </AccordionPanel>
            </AccordionItem>
            {positionData.lastPage === positionQueryParams.page || positionData.data.length <= 0 ? (
              ''
            ) : (
              <Center>
                <Button onClick={loadMorePosition} data-testid="loadMoreBtn" colorScheme="blue">
                  {t('jobPosition:loadMore')}
                </Button>
              </Center>
            )}
          </Accordion>
          <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside" isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('jobPosition:recruiterFollowing')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {assignedRecruiters ? (
                  <Box>
                    {_sortBy(assignedRecruiters, 'name').map((item: FollowedRecruiters) => loadFollowedRecruiterListItems(item))}
                  </Box>
                ) : (
                  ''
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {!_isNil(selectedPosition) && !_isNil(confirmUpdateStatusMessage) && (
            <UpdateStatusConfirmation
              isOpen={isUpdateStatusModalOpen}
              onClose={onUpdateStatusModalClose}
              selectedPosition={selectedPosition}
              updatePositionByStatus={isArchivingPosition || isUnArchivingPosition ? callArchiveHandler : updatePositionByStatus}
              message={confirmUpdateStatusMessage}
              // checkBoxComponent={getInterviewCancelCheckBox()}
              isPositionOpen={/* istanbul ignore next */ selectedPosition.open}
              isPositonArchived={selectedPosition.archived}
              isArchivingPosition={isArchivingPosition}
            />
          )}
        </Fragment>
      )}
      {createNewPosition && (
        <Box ref={positionRef}>
          <NewCreateJobsView
            positionDataToEdit={positionToEdit}
            redirectToPositionAfterCreated={redirectToPositionAfterCreated}
            selectedBusiness={activeBusiness}
            onClose={() => {
              setPositionToEdit(null);
              setCreateNewPosition(false);
            }}
            createNewPosition={createNewPosition}
            updatePositionAutomation={updatePositionAutomation}
          />
        </Box>
      )}

      {positionToCopy && (
        <CopyPositionButton positionFromPositionList={positionToCopy} isOpen={copyPosition} onClose={onCompleteCopy} />
      )}
    </Box>
  );
};
