import { Button, Box, theme, Stack, HStack, keyframes } from '@chakra-ui/core';
import React from 'react';
import { HiTrash } from 'react-icons/hi';
import { MdDragIndicator } from 'react-icons/md';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { EmptyComponent } from './EmptyComponent';
import { FieldSchema, generateFormSchema } from './formBuilderSchema';
import { FormBuilderProps } from '../formBuilder/FormBuilder';
import { v4 as uuidv4 } from 'uuid';
import isArray from 'lodash/isArray';
import DeletePopover from './advancedElements/DeletePopover';
import { useHireFormContext } from '../HireForm.context';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { formBuilderSchemas, schemaFieldsByType } from '../formBuilderSchemas/formBuilder.schema';
import ElementSelectField from './ElementSelctField';

const animation = keyframes`
  0% {
    background-color: ${theme.colors.blue[100]};
    outline: 2px solid ${theme.colors.blue[100]};
    outline-offset: 2px;
    padding: 6px;
    z-index: 2;
  }
  60% {
    outline: 2px solid ${theme.colors.blue[50]};
    outline-offset: 7px;
  }
  100% {
    background-color: transparent;
    outline: 0px solid transparent;
    outline-offset: 10px;
    padding: 12px;
    z-index: -1;
  }
`;

interface FormBuilderElementProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {
  item: FieldSchema;
  elementIndex: number;
  sectionIndex: number;
  isAdvancedSection: boolean;
}

const FormBuilderElement = SortableElement(function ({
  item,
  elementIndex: index,
  sectionIndex,
  language,
  isAdvancedSection,
}: FormBuilderElementProps) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const { setErrors } = useStoreActions((s) => s.hrFormTemplate);
  const { errors } = useStoreState((s) => s.hrFormTemplate);

  const elementIndex = generateFormSchema(formSchema, language)?.findIndex(
    (schema) => schema.id === formSchema[sectionIndex].fields[index]?.id,
  );
  const path = ['questions', elementIndex];

  const field = formSchema[sectionIndex]?.fields?.find((f) => f.id === item.id)!;

  const [dropTarget, setDropTarget] = React.useState<{ index: number; position: 'top' | 'bottom' } | null>(null);

  const { timestamp, inputType, hideFieldSelector } = field;
  const { component: Component } = formBuilderSchemas[inputType];
  if (!Component) return null;

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (isAdvancedSection) return;
    event.preventDefault();
    event.stopPropagation();

    const rect = event.currentTarget.getBoundingClientRect();
    const cursorY = event.clientY;
    const midpointY = rect.top + rect.height / 2;

    setDropTarget({
      index,
      position: cursorY < midpointY ? 'top' : 'bottom',
    });
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    if (isAdvancedSection) return;

    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const cursorX = event.clientX;
    const cursorY = event.clientY;

    const tolerance = 1;

    const isCursorInside =
      cursorX >= rect.left - tolerance &&
      cursorX <= rect.right + tolerance &&
      cursorY >= rect.top - tolerance &&
      cursorY <= rect.bottom - tolerance;

    if (!isCursorInside) {
      setDropTarget(null);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
    if (isAdvancedSection) return;
    event.preventDefault();
    event.stopPropagation();
    const type = event.dataTransfer.getData('type');
    if (type !== 'section') {
      const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
      const newFormSchema = [...formSchema];
      // const section = 'section_' + (sectionIndex + 1);
      const currentSection = formSchema[sectionIndex];
      let spliceIndex = index;

      if (index === formSchema.length - 1 && dropTarget?.position === 'bottom') {
        spliceIndex = index + 1;
      } else {
        if (dropTarget?.position === 'top') {
          spliceIndex = index;
        } else {
          spliceIndex = index;
        }
      }
      if (isArray(defaultValue)) return;
      currentSection.fields.splice(spliceIndex, 0, { ...defaultValue, timestamp: Date.now(), id: uuidv4() });
      formSchema.map((section) => {
        if (section.section === currentSection.section) {
          return currentSection;
        }
        return section;
      });
      setFormSchema(newFormSchema);
    }

    setDropTarget(null);
  };

  const SortableAnchor = SortableHandle(() => (
    <Button as="div" borderRadius="50%" cursor="grab" backgroundColor="gray" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
      <MdDragIndicator />
    </Button>
  ));

  const elementFields =
    schemaFieldsByType?.[field.field as keyof typeof schemaFieldsByType] || schemaFieldsByType?.[field.inputType];

  return (
    <Box
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      backgroundColor={theme.colors.white}
      role="group"
    >
      <Stack spacing={4}>
        {dropTarget?.position === 'top' && (
          <Box
            style={{
              borderBottomWidth: 1,
              borderColor: '#E5E7EB',
              paddingBottom: theme.space[4],
            }}
          >
            <EmptyComponent onDrop={handleDrop} />
          </Box>
        )}
        <Box
          sx={{
            '&:hover .hire-form-element-actions': {
              width: 'auto',
            },
          }}
        >
          <HStack
            justify="space-between"
            align="flex-start"
            position="relative"
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '100%',
              boxSizing: 'content-box',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              animation: timestamp > Date.now() - 1000 ? `${animation} 500ms ease-out` : '',
              zIndex: -10,
              borderRadius: theme.radii.md,
            }}
          >
            <Box flexGrow={1} transition={'flex-grow 0.2s ease-in-out'}>
              <Component
                defaultValue={field}
                onChange={(value) => {
                  const newFormSchema = [...formSchema];
                  newFormSchema[sectionIndex].fields[index] = value;
                  setFormSchema(newFormSchema);
                }}
                language={language}
                followUpDragOver={() => {
                  setDropTarget(null);
                }}
                path={path}
                isAdvanced={isAdvancedSection}
                sectionIndex={sectionIndex}
                fieldIndex={index}
                followUpLabel={0}
                labelRightRenderer={
                  ((elementFields && elementFields.length > 0) || !hideFieldSelector) && (
                    <ElementSelectField
                      defaultValue={field}
                      sectionIndex={sectionIndex}
                      fieldIndex={index}
                      options={elementFields ?? []}
                    />
                  )
                }
              />
            </Box>

            {!isAdvancedSection && (
              <HStack>
                {/* {((elementFields && elementFields.length > 0) || !hideFieldSelector) && (
                  <ElementSelectField
                    defaultValue={field}
                    sectionIndex={sectionIndex}
                    fieldIndex={index}
                    options={elementFields ?? []}
                  />
                )} */}

                <HStack flexShrink={0} marginTop="0 !important" width="0" overflow="hidden" className="hire-form-element-actions">
                  <SortableAnchor />
                  <DeletePopover
                    popoverTrigger={
                      <Button borderRadius="50%" colorScheme="red" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
                        <HiTrash />
                      </Button>
                    }
                    onYes={() => {
                      const newFormSchema = [...formSchema];
                      newFormSchema[sectionIndex].fields.splice(index, 1);
                      setErrors([...errors]?.filter((e) => e.path[1] !== elementIndex));
                      setFormSchema(newFormSchema);
                    }}
                  />
                </HStack>
              </HStack>
            )}
          </HStack>
        </Box>
        {dropTarget?.position === 'bottom' && (
          <Box
            style={{
              borderTopWidth: 1,
              borderColor: '#E5E7EB',
              paddingTop: theme.space[4],
            }}
          >
            <EmptyComponent onDrop={handleDrop} />
          </Box>
        )}
      </Stack>
    </Box>
  );
});

export default FormBuilderElement;
