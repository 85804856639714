import React from 'react';
import { FieldSchema, formBuilderType } from '../formElements/formBuilderSchema';
import { Box, Center, HStack, Stack, Text } from '@chakra-ui/core';
import mobilePreviewSchema from './mobilePreview.schema';
import ButtonPreview from '../formElements/ButtonPreview';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCircle } from 'react-icons/md';
import isEqual from 'lodash/isEqual';

interface PreviewElementProps {
  question?: FieldSchema;
  answer?: string;
}

const PreviewElementWrapper = React.memo(
  ({ question, answer }: PreviewElementProps) => {
    let seekerAnswer = mobilePreviewSchema?.[question?.inputType!]?.answer ?? answer;

    if (question?.isInfo) {
      seekerAnswer = undefined;
    }
    if (question?.inputType === formBuilderType.radio && question?.optionList?.length) {
      seekerAnswer = question?.optionList?.[0]?.text?.en;
    } else if (question?.inputType === formBuilderType.checkbox && question?.optionList?.length) {
      seekerAnswer = question?.optionList?.[0]?.text?.en;
    }
    const showQuestion = !!question;
    return (
      <Box>
        <Stack>
          {showQuestion && (
            <Box display="flex" flexDirection="column">
              <Box
                backgroundColor="#455A641A"
                color="#36464E"
                paddingX={'7.6px'}
                paddingY={'7.6px'}
                borderRadius="15.2px"
                width="fit-content"
                maxW="80%"
              >
                <Text
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="400"
                  dangerouslySetInnerHTML={{ __html: question?.text?.en ?? '' }}
                />
              </Box>
              {question?.optionList?.length && (
                <Stack
                  flexDirection={formBuilderType.yesNo === question.inputType ? 'row' : 'column'}
                  marginTop="5px"
                  spacing={1}
                  align={formBuilderType.yesNo === question.inputType ? 'center' : 'flex-start'}
                >
                  {question?.optionList?.map((option, index) => (
                    <HStack key={index}>
                      {formBuilderType.checkbox === question.inputType && (
                        <MdOutlineCheckBoxOutlineBlank color="blue" fontSize="sm" style={{ flexShrink: 0 }} />
                      )}
                      {formBuilderType.radio === question.inputType && (
                        <MdOutlineCircle color="blue" fontSize="sm" style={{ flexShrink: 0 }} />
                      )}
                      {formBuilderType.yesNo !== question.inputType && (
                        <Text
                          fontSize="14px"
                          lineHeight="21px"
                          fontWeight="400"
                          dangerouslySetInnerHTML={{ __html: option?.text?.['en'] ?? '' }}
                        />
                      )}
                      {formBuilderType.yesNo === question.inputType && <ButtonPreview label={option.text?.['en']!} />}
                    </HStack>
                  ))}
                </Stack>
              )}
            </Box>
          )}
          {seekerAnswer && (
            <Box display="flex" justifyContent="flex-end">
              <Box
                backgroundColor="#CFCFCF"
                color="#4D4D4D"
                paddingX={'7.6px'}
                paddingY={'7.6px'}
                borderRadius="15.2px"
                width="fit-content"
                maxW="80%"
              >
                <Text
                  fontSize="14px"
                  lineHeight="15px"
                  fontWeight="400"
                  dangerouslySetInnerHTML={{ __html: seekerAnswer ?? '' }}
                />
              </Box>
              <Box marginTop="10px" marginLeft="5px">
                <Center backgroundColor="#FF6600" color="white" borderRadius="50%" height="15px" width="15px">
                  <Text fontSize="9px" fontW="400" lineHeight="9px">
                    JD
                  </Text>
                </Center>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    );
  },
  (prev, next) => isEqual(prev.question, next.question),
);

export default PreviewElementWrapper;
