import { useColorModeValue, VStack, Box, Input, Button, Text } from '@chakra-ui/core';
import React, { useState } from 'react';

interface DragAndDropImageUploadProps {
  onUpload: (file: File) => void;
  onError?: (error: string) => void;
  acceptedFormats?: string[];
  maxFileSize?: number; // In bytes
  renderChildren?: (dragging: boolean, inputId: string) => React.ReactNode;
  onPreview?: (previewUrl: string) => void;
}

const DragAndDropImageUpload: React.FC<DragAndDropImageUploadProps> = ({
  onUpload,
  onError,
  acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'],
  maxFileSize = 2 * 1024 * 1024,
  renderChildren,
  onPreview,
}) => {
  const inputId = 'image-upload';
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);
  //   const [preview, setPreview] = useState<string | null>(null);

  const handleFile = (file: File) => {
    if (!acceptedFormats.includes(file.type)) {
      const errorMessage = `Invalid file type. Accepted formats are: ${acceptedFormats.join(', ')}`;
      setError(errorMessage);
      onError?.(errorMessage);
      return;
    }

    if (file.size > maxFileSize) {
      const errorMessage = `File size exceeds the maximum of ${maxFileSize / 1024 / 1024}MB`;
      setError(errorMessage);
      onError?.(errorMessage);
      return;
    }

    setError(null);
    onUpload(file);

    // Generate and return the preview URL
    const previewUrl = URL.createObjectURL(file);
    onPreview?.(previewUrl);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) handleFile(file);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) handleFile(file);
  };

  const borderColor = useColorModeValue(dragging ? 'blue.400' : 'gray.200', dragging ? 'blue.300' : 'gray.600');
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  return (
    <VStack>
      <Input type="file" accept={acceptedFormats.join(',')} display="none" onChange={handleInputChange} id={inputId} />
      <Box onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} cursor="pointer" width="100%">
        {renderChildren ? (
          renderChildren(dragging, inputId)
        ) : (
          <Box
            border="2px dashed"
            borderColor={borderColor}
            borderRadius="md"
            p={6}
            textAlign="center"
            bg={bgColor}
            w="full"
            maxW="sm"
          >
            <Text fontSize="lg" fontWeight="bold">
              {dragging ? 'Drop the image here' : 'Drag and drop an image or click to upload'}
            </Text>
            <Button as="label" htmlFor={inputId} mt={4} colorScheme="blue">
              Click to Upload
            </Button>
          </Box>
        )}
      </Box>
      {error && (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      )}
    </VStack>
  );
};

export default DragAndDropImageUpload;
