/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback, Fragment, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { AnimatePresence, motion } from 'framer-motion';
import { Box } from '@chakra-ui/core';
import moment from 'moment-timezone';

import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ANIMATED_VARIANTS, MESSAGING_TABS, MessagingTabsParams, PATH_MAIN } from '../../routes/constants';
import {
  SeekersFiltersContextProvider,
  SESSION_KEY_CANDIDATE_LIST_TYPE,
  useSeekersFilters,
} from './sidebar/filters/SeekersFiltersContext';
import { SeekerListContainer } from './sidebar/seekerList/SeekerListContainer';
import { MessagingViewContextProvider, useMessagingViewContext } from './MessagingViewContext';
import { SeekerContainer } from './seeker/SeekerContainer';
import { messagingGridCSS, messagingGridCSSIfSearchSortOpens, messagingGridCSSWhenInterview } from './MessagingView.styles';
import { Header } from '../../app/topBar/Header';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { RemoteConfigKey } from '../../firebase/remoteConfig';
import { AvailableBookingWithPosition, Booking } from '../../firebase/firestore/documents/appointmentAvaiability';
import { filtersHeight, headerHeight, filtersHeightWhenCertn } from '../../styles/sizes';
import { InterviewRating } from './sidebar/meetInterview/InterviewRating';
import { SearchAndSortView } from './sidebar/searchAndSort/SearchAndSortView';
import { CandidateCategory, EngagedCandidate } from '../../firebase/firestore/documents/candidate';
import { SeekerContainerTabItems, useSeekerContainerContext } from './seeker/SeekerContainerContext';
import { SeekersPresenter } from './sidebar/seekerList/seekersPresenter';
import { CandidateListType } from './sidebar/filters/seekersFiltersContextValueType';
import useCandidateBooking from '../../app/components/useCandidateBooking';
import { isPositionCandidatesPage } from '../../utils/candidatesUtils';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';

const MotionBox = motion.custom(Box);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const MessagingView = (): JSX.Element => {
  const isCertnFeatureEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_CERTN_FEATURE];
  });

  const [isCertnEnabled, setCertnEnabled] = useState<boolean>(false);
  const [showSearchSort, setShowSearchSort] = useState<boolean>(false);
  const [enableGoogleCalendarVideoInterview, setEnableGoogleCalendarVideoInterview] = useState<boolean>(true);
  const [seekerList, setSeekerList] = useState<SeekersPresenter | undefined>(undefined);

  const accountStore = useStoreState((s) => s.app.accounts);

  const gracePeriod: number = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE];
  });

  const isMeetEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MEET_FEATURE];
  });

  const interViewDetail: AvailableBookingWithPosition | undefined = useStoreState((state) => {
    return state.interview.eventDetail;
  });

  const ratingFor = useStoreState((state) => state.interview.ratingFor);

  const isUnresponsiveCandidatesEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_UNRESPONSIVE_CANDIDATES];
  });

  const isArchiveListEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.SHOW_ARCHIVE_LIST];
  });

  const isDismissedCandidateEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_DISMISSED_CANDIDATE_LIST];
  });

  const isUndismissedeEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE];
  });
  const history = useHistory();

  const { setListType, setChangeCandidate, changeCandidate } = useSeekersFilters();

  const isAbleToJoinVideoInterview = useCallback(
    (booking: Booking | undefined) => {
      if (_isNil(booking)) return false;
      return moment().isBetween(
        moment(booking.date).subtract(5, 'minutes'),
        moment(booking.date).add(booking.duration, 'minutes').add(gracePeriod, 'minute'),
      );
    },
    [gracePeriod],
  );

  const isInterviewEnabled = (): boolean => {
    if (_isNil(interViewDetail)) {
      return false;
    }
    if (showSearchSort) setShowSearchSort(false);
    if (changeCandidate) setChangeCandidate(false);
    return isMeetEnabled && isAbleToJoinVideoInterview(interViewDetail?.booking);
  };

  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();

  const showRatingDialogue = (): boolean => {
    if (_isNil(ratingFor) || _isEmpty(ratingFor)) return false;
    // if (showSearchSort) setShowSearchSort(false);
    if (changeCandidate) setChangeCandidate(false);
    return true;
  };

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { seekerIdFromTopCandidate } = location.state || {};

  const allSeekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);
  const allPositionSeekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allPositionCandidates);
  const { positionId } = useParams<{ positionId: string }>();

  const { candidateId } = useParams<MessagingTabsParams>();
  const candidateAndBookingDetails = useCandidateBooking(candidateId);
  const setInterviewEvent = useStoreActions((state) => state.interview.setInterviewEvent);
  const googleCalendarEvent = useQuery().get('from');

  const { currentUserProfile } = useCurrentUserProfile();

  const getPositionCandidates = useStoreActions((s) => s.candidate.getPositionCandidates);

  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);
  const setAllPositionCandidates = useStoreActions((a) => a.candidate.setAllPositionCandidates);

  const { setSelectedCandidate } = useSeekerContainerContext();

  const loadAllCandidatesFromPosition = useCallback(async () => {
    setAllPositionCandidates(undefined);

    const candidates = await getPositionCandidates({ positionId });
    const monetizedCandidate = candidates.filter((c) => c.monetized);
    const dismissedcandidates = monetizedCandidate?.filter((c) => c.dismissed);

    const seekerPresenter = new SeekersPresenter(
      monetizedCandidate,
      currentUserProfile?.id ?? '',
      minutesBeforeInactivity,
      dismissedcandidates,
    );
    setAllPositionCandidates(seekerPresenter);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const candidateIdFromParams = `${positionId}_${seekerIdFromTopCandidate?.id}`;
    // istanbul ignore next
    const candidate = seekerPresenter?.getFirstEngagedCandidate(seekerIdFromTopCandidate ? candidateIdFromParams : candidateId);

    setSelectedEngagedCandidate(candidate);
    // istanbul ignore next
    setSelectedEngagedSeeker(candidate && { seekerId: candidate.seeker, candidateId: candidate.id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedEngagedCandidate, setSelectedEngagedSeeker, setSelectedCandidate]);

  useEffect(() => {
    if (positionId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadAllCandidatesFromPosition();
    }
  }, [positionId, loadAllCandidatesFromPosition, setAllPositionCandidates]);

  useEffect(() => {
    if (!_isNil(accountStore) && accountStore.configuration && accountStore.configuration.certnEnabled) {
      setCertnEnabled(accountStore.configuration.certnEnabled);
    } else {
      setCertnEnabled(false);
    }
  }, [accountStore]);

  useEffect(() => {
    if (isPositionCandidatesPage() && currentUserProfile) {
      setSeekerList(allPositionSeekerList);
    } else {
      setSeekerList(allSeekerList);
    }
  }, [allSeekerList, allPositionSeekerList, currentUserProfile]);

  useEffect(() => {
    // istanbul ignore next
    if (googleCalendarEvent && candidateAndBookingDetails && enableGoogleCalendarVideoInterview) {
      setInterviewEvent(candidateAndBookingDetails as AvailableBookingWithPosition);
      setEnableGoogleCalendarVideoInterview(!enableGoogleCalendarVideoInterview);
    }
  }, [candidateAndBookingDetails, googleCalendarEvent, setInterviewEvent, enableGoogleCalendarVideoInterview]);

  const onSelectNextCandidateAfterRating = useCallback(
    () => {
      const newSeeker = seekerList?.engagedCandidates.filter(
        (seeker: EngagedCandidate) =>
          seeker.computedStatus?.category !== CandidateCategory.Other &&
          seeker.ghostingStatus?.status !== 'GHOSTED' &&
          seeker.id !== window.location.pathname.split('/')[2],
      );

      if (newSeeker && newSeeker.length > 0) {
        sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.UNKNOWN);
        setSelectedEngagedCandidate(newSeeker[0]);
        setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });

        history.push(MESSAGING_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging));
      } else {
        setSelectedEngagedCandidate(undefined);
        setSelectedEngagedSeeker(undefined);
        history.push(PATH_MAIN);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedEngagedCandidate, setSelectedEngagedSeeker, seekerList],
  );

  const handleRatingViewChange = () => {
    setListType(CandidateListType.UNKNOWN);
    setChangeCandidate(true);
  };

  const getCssForGrid = () => {
    if (showSearchSort) return messagingGridCSSIfSearchSortOpens;
    if (isInterviewEnabled()) return messagingGridCSSWhenInterview;
    return messagingGridCSS;
  };

  return (
    <AnimatePresence>
      <Box data-testid="MessagingContainer">
        <Fragment>
          <MessagingViewContextProvider>
            <SeekersFiltersContextProvider>
              <MotionBox
                data-testid="MessagingView"
                css={getCssForGrid}
                initial="initial"
                animate="in"
                exit="out"
                variants={ANIMATED_VARIANTS}
                style={{
                  gridTemplateRows: `[top] ${headerHeight} [header] ${
                    isCertnEnabled && isCertnFeatureEnabled ? filtersHeightWhenCertn : filtersHeight
                  } [sub1] 12px [sub2] 1fr [bottom]`,
                  overflow: showSearchSort ? 'hidden' : 'auto',
                }}
              >
                {showRatingDialogue() && (
                  <InterviewRating
                    onSelectNextCandidateAfterRating={onSelectNextCandidateAfterRating}
                    onClose={() => handleRatingViewChange()}
                  />
                )}
                <Header data-testid="Header" showMenu showSearchSort={showSearchSort} />
                {showSearchSort ? (
                  <SearchAndSortView
                    onCloseSearch={() => setShowSearchSort(false)}
                    isCertnFeatureEnabled={isCertnFeatureEnabled}
                    isCertnEnabled={isCertnEnabled}
                  />
                ) : (
                  ''
                )}
                <SeekerListContainer
                  data-testid="SeekersListContainer"
                  isCertnFeatureEnabled={isCertnFeatureEnabled}
                  isArchiveListEnabled={isArchiveListEnabled}
                  isDismissedCandidateEnabled={isDismissedCandidateEnabled}
                  isCertnEnabled={isCertnEnabled}
                  isUnresponsiveCandidatesEnabled={isUnresponsiveCandidatesEnabled}
                  onOpenSearch={() => {
                    setShowSearchSort(true);
                  }}
                  showSidebar
                  showSearchSort={showSearchSort}
                  shouldRedirect
                  seekerListFromProps={seekerList}
                />
                <SeekerContainer
                  data-testid="SeekerContainer"
                  isInterviewEnabled={isInterviewEnabled()}
                  isCertnFeatureEnabled={isCertnFeatureEnabled}
                  isCertnEnabled={isCertnEnabled}
                  isUndismissedeEnabled={isUndismissedeEnabled}
                  isMessagingView
                />
              </MotionBox>
            </SeekersFiltersContextProvider>
          </MessagingViewContextProvider>
        </Fragment>
      </Box>
    </AnimatePresence>
  );
};
