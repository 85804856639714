import { Box, Text } from '@chakra-ui/core';
import React from 'react';
import { ErrorWrapperProps } from '../useErrorHandling';
import theme from '../../../../styles/customTheme';

const ErrorWrapper = React.memo(
  React.forwardRef<HTMLInputElement, ErrorWrapperProps>(({ children, error, hasError }, ref) => (
    <Box
      style={{
        position: 'relative',
        borderRadius: theme.radii.sm,
        width: '100%',
        outline: `2px solid`,
        outlineColor: 'transparent',
        outlineOffset: '2px',
        transition: 'all 100ms ease-in-out',
        ...(hasError ? { outlineColor: theme.colors.red[500] } : {}),
      }}
      _focusWithin={{
        ...{ paddingX: 1 },
        ...(hasError ? {} : { outlineColor: `${theme.colors.blue[300]} !important` }),
      }}
    >
      {React.cloneElement(children as React.ReactElement, { ref })}
      {hasError && (
        <Box style={{ position: 'absolute', left: 0, top: '100%', paddingTop: '5px' }}>
          <Text color={theme.colors.red[500]} fontSize="xs">
            {error}
          </Text>
        </Box>
      )}
    </Box>
  )),
);

export default ErrorWrapper;
