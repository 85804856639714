/* istanbul ignore file */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import TreeMenu from 'react-simple-tree-menu';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, Spinner } from '@chakra-ui/core';
import isNumber from 'lodash/isNumber';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { NodeType } from './treeGroup';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { Candidate, EngagedCandidate } from '../../../../firebase/firestore/documents/candidate';
import { MESSAGING_TABS_NEW } from '../../../../routes/constants';
import { SeekerContainerTabItems } from '../../seeker/SeekerContainerContext';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { CandidateSearchResponse } from '../../../../controllers/candidateController';
import SeekerListSortType from '../filters/seekerListSortType';
import NewCandidateItem from './candidateItem/NewCandidateItem';

type Props = Record<string, unknown>;

type ToggleIconProp = {
  on: boolean;
};

const PAGE_LIMIT = 100;

const NewSeekerList: React.FC = () => {
  const {
    setSelectedEngagedCandidate,
    setSelectedEngagedSeeker,
    setSelectedCandidateId,
    selectedCandidateId,
  } = useMessagingViewContext();
  const { searchQuery, searchAndSortTypes, sort, listType } = useSeekersFilters();
  const accountId = useStoreState((s) => s.app.user?.account);

  const searchCandidates = useStoreActions((a) => a.candidate.searchCandidates);
  const resetPaginatedCandidates = useStoreActions((a) => a.candidate.resetPaginatedCandidates);

  const paginatedCandidates = useStoreState((state) => state.candidate.paginatedCandidates);

  const scrollableDivRef = React.useRef<HTMLDivElement>(null);

  const candidates = Array.from(paginatedCandidates.data.values());

  const [candidatePresenterArray, setCandidatePresenterArray] = useState<
    {
      key: string;
      label: string;
      type: NodeType;
      candidate: Candidate;
    }[]
  >([]);

  const [seekerMap, setSeekerMap] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    let paginatedCandidateLength = 0;
    setCandidatePresenterArray([]);
    setSeekerMap(new Map());
    resetPaginatedCandidates({ resetFetching: false });
    const firstPage = 0;
    const getCandidates = (prevPage?: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      searchCandidates({
        accountId: accountId!,
        filters: {
          listType,
          limit: PAGE_LIMIT,
          page: (isNumber(prevPage) ? prevPage : paginatedCandidates.page) + 1,
          searchQuery: searchQuery || '',
          sortBy: sort === SeekerListSortType.ALPHABETICAL ? 'name' : 'createdAt',
          sortOrder: 'desc',
          advancedFilters: searchAndSortTypes,
        },
      }).then((data: CandidateSearchResponse) => {
        paginatedCandidateLength += data.data.length;
        const hasNext = data.page * PAGE_LIMIT < data.total;
        if (hasNext && paginatedCandidateLength < PAGE_LIMIT) {
          getCandidates(data.page);
        }
      });
    };
    getCandidates(firstPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchAndSortTypes, sort, listType]);

  useEffect(() => {
    const candidatesAfterLastCandidateSeeker = candidates.filter((candidate) => !seekerMap.get(candidate.seeker));
    const newCandidates = candidatesAfterLastCandidateSeeker.map((candidate: CandidateSearchResponse['data'][0]) => {
      setSeekerMap((prev) => prev.set(candidate.seeker, true));
      return {
        candidate,
        key: candidate.id,
        label: candidate.fullName ?? `${candidate.firstName} ${candidate.lastName}`,
        type: NodeType.CANDIDATE,
        isFetching: false,
      };
    });
    setCandidatePresenterArray((prevState) => [...prevState, ...newCandidates]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedCandidates.lastSetDate]);

  const selectedCallback = useCallback(
    (candidate?: Readonly<EngagedCandidate>) => {
      if (candidate) {
        setSelectedCandidateId(candidate.id);
        setSelectedEngagedSeeker({ seekerId: candidate.seeker, candidateId: candidate.id });
        window.history.pushState(null, '', MESSAGING_TABS_NEW(candidate.id, SeekerContainerTabItems.Messaging));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedEngagedCandidate, setSelectedEngagedSeeker],
  );

  const getCandidate = (prevPage?: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    searchCandidates({
      accountId: accountId!,
      filters: {
        listType,
        limit: PAGE_LIMIT,
        page: (prevPage || paginatedCandidates.page) + 1,
        searchQuery,
        sortBy: sort === SeekerListSortType.ALPHABETICAL ? 'name' : 'createdAt',
        sortOrder: 'desc',
        advancedFilters: searchAndSortTypes,
      },
    }).then((data: CandidateSearchResponse) => {
      const hasNext = data.page * PAGE_LIMIT < data.total;
      if (hasNext && !data.data.length) {
        getCandidate(data.page);
      }
    });
  };

  useEffect(() => {
    if (!selectedCandidateId && candidatePresenterArray?.[0]?.key) {
      selectedCallback(candidatePresenterArray?.[0]?.candidate as Readonly<EngagedCandidate>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatePresenterArray?.[0]?.key, selectedCandidateId]);

  const treeCandidates = candidatePresenterArray;

  const hasNext = paginatedCandidates.page * PAGE_LIMIT < paginatedCandidates.total;

  const maxHeightOfTheContainer = useMemo(() => {
    return scrollableDivRef.current?.offsetTop ? window.innerHeight - (scrollableDivRef.current?.offsetTop || 0) : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableDivRef.current?.offsetTop]);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: maxHeightOfTheContainer,
        // height: '200px',
        // backgroundColor: 'gray',
        overflow: 'auto',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
      }}
      ref={scrollableDivRef}
    >
      <InfiniteScroll
        dataLength={treeCandidates.length}
        next={getCandidate}
        hasMore={hasNext}
        loader={
          <Box marginY="auto" width="100%" textAlign="center">
            <Spinner color="#1F3CBA" data-testid="Spinner" />
          </Box>
        }
        scrollableTarget="scrollableDiv"
        scrollThreshold={0.8}
      >
        <TreeMenu
          data={treeCandidates}
          onClickItem={() => {}}
          debounceTime={5}
          resetOpenNodesOnDataUpdate={false}
          disableKeyboard
          cacheSearch={false}
        >
          {({ items }) => {
            return (
              <AnimatePresence initial={false}>
                {items &&
                  items.length &&
                  items.map((item) => {
                    return (
                      <motion.div
                        layout
                        key={item.key}
                        id={item.key}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1 }}
                        transition={{ duration: 0.2, ease: 'linear' }}
                      >
                        <NewCandidateItem selectedCallback={selectedCallback} item={item} />
                      </motion.div>
                    );
                  })}
              </AnimatePresence>
            );
          }}
        </TreeMenu>
      </InfiniteScroll>
      {/* Only show loading for initial fetch as after that loading will be handled by infinite scroll */}
      {paginatedCandidates.isFetching && (
        <Box marginY="auto" width="100%" textAlign="center">
          <Spinner color="#1F3CBA" data-testid="Spinner" />
        </Box>
      )}
    </div>
  );
};

export default NewSeekerList;
