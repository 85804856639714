import {
  Box,
  Button,
  Stack,
  theme,
  Text,
  HStack,
  Divider,
  Input,
  Editable,
  EditablePreview,
  EditableInput,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
} from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from '../formBuilderSchema';
import LabelSchemaBuilder from '../LabelSchemaBuilder';
import FormElementTextBuilder from '../FormElementTextBuilder';
import { MdClose, MdOutlineCheckBoxOutlineBlank, MdOutlineRemoveRedEye, MdOutlineUploadFile } from 'react-icons/md';
import omit from 'lodash/omit';
import TextSchemaBuilder from '../TextSchemaBuilder';
import useErrorHandling from '../../useErrorHandling';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';

export default function DocumentPreview({ ...props }: SchemaBuilderProps) {
  const { defaultValue, language } = props;
  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder="Enter text here..." />
      <Divider variant="dashed" marginY={3} marginTop={3} />
      <TextSchemaBuilder
        label={defaultValue?.documentReview?.description?.[language]!}
        onLabelChange={(value) => {
          const changedValue = {
            ...omit(props.defaultValue, 'documentReview'),
            documentReview: {
              ...defaultValue?.documentReview!,
              description: { ...defaultValue?.documentReview?.description!, [language]: value },
            },
          };
          props.onChange(changedValue);
        }}
        language={props.language}
        placeholder="Enter description here...."
        path={[...props.path, 'documentPreview', 'description']}
      />

      <DocumentUpload {...props} />
      <HStack>
        <MdOutlineCheckBoxOutlineBlank />
        <TextSchemaBuilder
          label={defaultValue?.documentReview?.acknowledgeContent?.[language]!}
          onLabelChange={(value) => {
            const changedValue = {
              ...omit(props.defaultValue, 'documentReview'),
              documentReview: {
                ...defaultValue?.documentReview!,
                acknowledgeContent: { ...defaultValue?.documentReview?.acknowledgeContent!, [language]: value },
              },
            };
            props.onChange(changedValue);
          }}
          language={props.language}
          placeholder="Enter acknowledgement text here...."
          path={[...props.path, 'documentReview', 'acknowledgeContent']}
        />
      </HStack>
      <HStack>
        <DocumentPreviewButton {...props} />
      </HStack>
    </Stack>
  );
}

function DocumentUpload({ path, onChange, defaultValue, language }: SchemaBuilderProps) {
  const inputId = 'document-upload';
  const [file, setFile] = React.useState<File | null>(null);
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(defaultValue?.documentReview?.url?.[language] ?? null);

  React.useEffect(() => {
    setPdfUrl(file ? URL.createObjectURL(file) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path: [...path, 'documentReview', 'url'],
    valdationFor: 'document_preview_file',
    onValidation: (val) => val,
  });
  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (file) {
      const validateFile = { mimetype: file.type, size: file.size };
      validateError({ file: validateFile }, () => {
        setFile(file ?? null);
      });
    }
  }
  return (
    <FormControl isInvalid={hasError}>
      <Box
        style={{
          backgroundColor: error ? '#FED7D7' : '#F0F8FE',
          borderRadius: '4px',
          padding: '24px',
          border: '2px dashed',
          borderColor: error ? 'red' : '#E2E8F0',
        }}
      >
        <Input ref={inputRef} accept="application/pdf" type="file" display="none" onChange={handleFileChange} id={inputId} />

        <Stack align="center" style={{ rowGap: theme.space[3] }}>
          <Box
            style={{
              backgroundColor: '#2196F326',
              padding: 16,
              borderRadius: '50%',
              aspectRatio: '1/1',
            }}
          >
            <MdOutlineUploadFile size={24} fill="#2196F3" />
          </Box>
          <Text
            color="#A0AEC0"
            style={{
              fontSize: 12,
              fontWeight: 400,
              textAlign: 'center',
              lineHeight: '18px',
            }}
          >
            <Text> Click the button below to upload your PDF document.</Text>Please ensure the file is in PDF format, with a size
            no larger than 2MB and an ideal resolution suitable for viewing.
          </Text>
          {(file || defaultValue?.documentReview?.url?.[language]?.length! > 0) && (
            <HStack spacing={2} align="center" backgroundColor="#F0F8FE" borderRadius={20} padding={2}>
              <Text color="#A0AEC0" isTruncated fontSize="sm" maxW={'300px'}>
                {file?.name || defaultValue?.documentReview?.url?.[language]}
              </Text>
              <HStack divider={<Divider />} spacing={0}>
                <DocumentPdfViewer
                  url={pdfUrl!}
                  file={file}
                  onUpload={(url) => {
                    onChange({
                      ...omit(defaultValue, 'documentReview'),
                      documentReview: {
                        ...defaultValue?.documentReview!,
                        url: { ...defaultValue?.documentReview?.url!, [language]: url },
                      },
                    });
                    setPdfUrl(url);
                    setFile(null);
                  }}
                  onChange={onChange}
                  defaultValue={defaultValue}
                  onCancel={() => setFile(null)}
                />

                <Button variant="square" size="xs" colorScheme="gray" onClick={() => setFile(null)}>
                  <MdClose color="#A0AEC0" />
                </Button>
              </HStack>
            </HStack>
          )}
          {!file && (
            <Button as="label" htmlFor={inputId} colorScheme="blue" size="sm" cursor="pointer">
              Choose Files
            </Button>
          )}
        </Stack>
      </Box>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

function DocumentPdfViewer({
  url,
  file,
  onCancel = () => {},
  onUpload = () => {},
}: { url: string; file: File | null; onUpload: (url: string) => void; onCancel: () => void } & Pick<
  SchemaBuilderProps,
  'onChange' | 'defaultValue'
>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { uploadDocument } = useStoreActions((state) => state.hrFormTemplate);
  const { uploadingDocument } = useStoreState((state) => state.hrFormTemplate);

  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  React.useEffect(() => {
    if (file) onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  async function handleUpload() {
    const payload = new FormData();
    payload.append('file', file!);
    const response = await uploadDocument({ account: accountId, payload: payload });

    onUpload(response);
    onClose();
  }

  function handleCancel() {
    onCancel();
    onClose();
  }

  return (
    <>
      <Button variant="square" size="xs" colorScheme="gray" onClick={onOpen} title="Preview">
        <MdOutlineRemoveRedEye />
      </Button>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        isCentered
        closeOnOverlayClick={!uploadingDocument}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent padding={0}>
          <ModalBody padding={0} height="100%" flexGrow={1} borderRadius={theme.radii.md}>
            <iframe src={url!} width="100%" height="600px" style={{ border: 'none' }} title="PDF Preview"></iframe>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" variant="ghost" mr={3} onClick={handleCancel} disabled={uploadingDocument}>
              Close
            </Button>
            {file && (
              <Button variant="ghost" colorScheme="blue" isLoading={uploadingDocument} onClick={handleUpload}>
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function DocumentPreviewButton({ defaultValue, language, onChange, path }: SchemaBuilderProps) {
  const [value, setValue] = React.useState(defaultValue?.documentReview?.confirmText?.[language]);

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path: [...path, 'documentReview', 'confirmText'],
    valdationFor: 'document_preview_confirm_text',
    onValidation: (val) => val,
  });

  React.useEffect(() => {
    setValue(defaultValue?.documentReview?.confirmText?.[language]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  function handleChange(value: string) {
    validateError(value);
    setValue(value);
  }

  return (
    <FormControl isInvalid={hasError}>
      <Button variant="outline" size="sm" colorScheme="blue" borderRadius={20}>
        <Editable
          edi
          value={value}
          submitOnBlur
          onChange={handleChange}
          onSubmit={(value) => {
            onChange({
              ...omit(defaultValue, 'documentReview'),
              documentReview: {
                ...defaultValue?.documentReview!,
                confirmText: { ...defaultValue?.documentReview?.confirmText!, [language]: value },
              },
            });
          }}
          size="sm"
          ref={inputRef}
          placeholder="Enter text here..."
        >
          <EditablePreview />
          <Input as={EditableInput} variant="unstyled" />
        </Editable>
      </Button>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
