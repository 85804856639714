export default {
  jobSearchPlaceholder: 'Search by business location',
  createDate: 'Create Date',
  modifyDate: 'Modify Date',
  allPositions: 'All Positions',
  openPositions: 'Open Positions',
  openedPositions: 'Opened Positions',
  closedPositions: 'Closed Positions',
  refresh: 'Refresh',
  archivedPositions: 'Archived Positions',
  followedPositions: 'Followed Positions',
  archivedPosition: 'Archived Positions',
  position: 'Position',
  positions: 'Positions',
  counting: 'Counting',
  filterByRequisitions: 'Hide positions without open requisitions',
  time: 'Position Type',
  partTime: 'Part Time',
  fullTime: 'Full Time',
  applicants: 'Active Applicants',
  noOfHires: 'No. of hires',
  open: 'Open',
  close: 'Close',
  archive: 'Archive',
  unArchive: 'Unarchive',
  follow: 'Follow',
  unFollow: 'Unfollow',
  copy: 'Copy',
  edit: 'Edit',
  share: 'Share',
  copiedMessage: 'You copied the link to this position.',
  recruiterFollowing: 'Recruiters following this position',
  noJobs: "Sorry, we haven't found any results matching this search.",
  loadMore: 'Load More',
  searchPlaceholder: 'Search by position name',
  unfollowModal: {
    header: 'Last recruiter to follow',
    body: 'You are the last recruiter to follow this position. If you unfollow, it will be closed.',
  },
  unfollowWarningModal: {
    header: 'Are you sure you want to unfollow this position?',
    body:
      'Make sure you do not have any booked interviews for this position before unfollowing. It will become much more difficult to keep track of these candidates once you no longer follow the position.',
  },
  positionImpactedModal: {
    emptyAutomatedPositions: 'No affected positions found.',
  },
  openPositionModal: {
    title: 'Reminder before opening',
    subtitle: 'This position is set to be automated with the following settings:',
    disabled: {
      title: 'Find me candidates',
      description: 'I will find you the best candidates.',
    },
    screening: {
      title: 'Screen the candidates',
      description: {
        zero: 'Screen for {{count}} candidates',
        one: 'Screen for {{count}} candidate',
        other: 'Screen for {{count}} candidates',
      },
    },
    scheduling: {
      title: 'Take care of everything',
      description: 'I will organize interviews with the candidates',
    },
    noAutomation: {
      description: 'Are you sure you want to open this position?',
    },
    openPositionButton: 'Confirm',
    cancelModalButton: 'Cancel',
    requisition: {
      title: 'Requisition',
      subtitle: 'This position is controlled by requisition management. Do you still want to close this position?',
    },
  },
  closePositionModal: {
    title: 'Closing this position',
    subtitle: {
      zero: 'Do you want to close it?',
      one: '{{count}} other recruiter is following this position.\nDo you still want to close it?',
      other: '{{count}} other recruiters are following this position.\nDo you still want to close it?',
    },
    confirmationText: 'Closing this position will cancel any future interviews or outstanding requests for candidates.',
    closePositionButton: {
      title: 'Close the position',
    },
    requisition: {
      title: 'Requisition',
      subtitle: 'This position will be automatically opened by requisition management. Do you still want to open this position?',
    },
  },
  updateStatusConfirmation: {
    okayText: 'I understand',
  },
  archivePositionModal: {
    title: 'Archiving a position',
    bodyMessage: 'Are you sure you want to archive this position ?',
    confirmText: 'Yes',
    cancelText: 'Cancel',
  },
  unarchivePositionModal: {
    title: 'Unarchiving a position',
    bodyMessage: 'Are you sure you want to unarchive this position ?',
    confirmText: 'Yes',
    cancelText: 'Cancel',
  },
  info: {
    archived: 'The position you archived is here.',
    open: 'The position you opened is here.',
    unArchive: 'The position you unarchived is here.',
    close: 'The position you closed is here.',
    copy: 'The position you just copied is here.',
  },
  createNewPosition: 'Create New Position',
  editPosition: 'Update Position',
  viewPositionList: 'View Position List',
  newJobFor: 'Located at',
  updateJobPosition: 'Located at',
  automationTypes: {
    selectAutomationLevel: 'Select Automation Level',
    none: 'No Automation',
    screen: 'Screen',
    screenAndScheduleInterviews: 'Screen & Schedule Interviews',
    screenAndSendOffers: 'Screen & Send Offers',
  },
  screenAndSendOffersModal: {
    warningText: `In order for Virtual Recruiter to function correctly, you must make sure that all necessary configurations are set up for this position. If the position hasn't been set up, consult a team administrator to configure the position correctly.`,
    submitText: 'Set Automation Level',
    cancelText: 'Cancel',
  },
  screeningInputModal: {
    submitText: 'Submit',
    cancelText: 'Cancel',
  },
  positionAutomationSelect: {
    errorInUpdating: 'An Error Occured While Updating The Position.',
    errorUpdatingDescription: 'Could not update automation level. Please try updating it again!',
    successInUpdating: `Successfully set this position's automation level to {{automationLevel}}.`,
  },
  automation: {
    title: 'Automation Level',
    findMeCandidate: {
      toolTipMessage: 'AppyHere will find ideal candidates for you. All you have to do is screen those you want to interview!',
      label: 'AppyHere will find ideal candidates',
    },
    screening: {
      toolTipMessage:
        'AppyHere will screen {{count}} candidates for you. All you have to do is send interview requests to the candidates you want to interview!',
      label: 'AppyHere will screen {{count}} candidates',
    },
    scheduling: {
      toolTipMessage:
        'AppyHere will screen and send interview requests to candidates for you. All you have to do is set your availability and let the interviews book themselves!',
      label: 'AppyHere will screen and send interview requests to candidates',
    },
    schedulingVrEnabled: {
      toolTipMessage: 'AppyHere will do it all for you, even send job offers!',
      label: 'AppyHere will screen candidates and send them job offers.',
    },
  },
  days: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  },
  availabilityHint:
    'Set the days and hours candidates should be available to work. Select the days, then use the slider to adjust shift times.',
  userRestrictedByAuthorizationMessageFront: 'In order to perform this action, you must contact',
  contactEmailForAccountAuthorisation: 'account-validation@appyhere.com',
  userRestrictedByAuthorizationMessageBack: 'to verify your account.',
  contactToEnableAuthorization: 'In order to create a position, you must contact {{configurableEmail}} to verify your account.',
  startRecruitingImmediatelyModal: {
    heading: 'Do you want to open this position immediately?',
    bodyText: 'Opening this position immediately will make AppyHere begin recruiting automatically.',
    yes: 'Yes',
    no: 'No',
  },
};
