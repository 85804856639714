import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JobPosition } from '../PositionType';

export type StartRecruitingImmediatelyModalProps = {
  isOpen: boolean;
  updatePositionStatusTo: (position: JobPosition) => void;
  onCancelClick: () => void;
  selectedPosition: JobPosition | null;
};

export const StartRecruitingImmediatelyModal = ({
  isOpen,
  selectedPosition,
  updatePositionStatusTo,
  onCancelClick,
}: StartRecruitingImmediatelyModalProps): JSX.Element => {
  const { t } = useTranslation('jobPosition');
  return (
    <Modal
      isOpen={isOpen}
      onClose={
        // istanbul ignore next line
        () => ''
      }
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('startRecruitingImmediatelyModal.heading')}</ModalHeader>
        <ModalBody>{t('startRecruitingImmediatelyModal.bodyText')}</ModalBody>
        <ModalFooter mt={2}>
          <Button
            data-testid="ConfirmBtn"
            colorScheme="blue"
            mr={3}
            onClick={() => selectedPosition && updatePositionStatusTo(selectedPosition)}
          >
            {t('startRecruitingImmediatelyModal.yes')}
          </Button>
          <Button data-testid="CloseBtn" colorScheme="red" onClick={onCancelClick}>
            {t('startRecruitingImmediatelyModal.no')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

StartRecruitingImmediatelyModal.defaultProps = {
  isPositionOpen: false,
};
