import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/core';
import React from 'react';
import FormBuilderTags from '../formElements/FormBuilderTags';
import ComponentGrid from './ComponentGrid';
import theme from '../../../../styles/customTheme';

interface ComponentTabsProps {
  search: string;
}

const ComponentTabs = React.memo(({ search }: ComponentTabsProps) => {
  return (
    <Tabs isFitted size="sm">
      <TabList mb="1em">
        <Tab panelId="basic">Basic</Tab>
        <Tab panelId="advanced">Advanced</Tab>
        <Tab panelId="tags">Tags</Tab>
      </TabList>
      <TabPanels>
        <TabPanel id="basic" px={0}>
          <Stack spacing={theme.space[4]}>
            <ComponentGrid currentPanel="basic" search={search} />
            <Stack>
              <Text fontSize="sm">Page Elements</Text>
              <ComponentGrid currentPanel="page-element" search={search} />
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel id="advanced" px={0}>
          <ComponentGrid currentPanel="advanced" search={search} />
        </TabPanel>
        <TabPanel id="tags" px={0}>
          <FormBuilderTags search={search} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
});

export default ComponentTabs;
