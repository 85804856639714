import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { GroupEventConfiguration } from '../../firebase/firestore/documents/groupEvent';
import { Collections } from '../../firebase/firestore/collections';
import { BUNDLE_ID_SERVICES, TOKEN_THRESHOLD_FOR_AUTOMATION } from '../../firebase/firestore/constants';
import { Query } from '../../firebase/firestore/query/query';

export const useTokenThresholdForAutomation = () => {
  const tokenThresholdForAutomation = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_SERVICES),
    Query.field('name').equals(TOKEN_THRESHOLD_FOR_AUTOMATION),
  ]);
  if (tokenThresholdForAutomation) {
    return tokenThresholdForAutomation[0]?.value;
  }
  return undefined;
};

export default useTokenThresholdForAutomation;
