import _includes from 'lodash/includes';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { LocalizedString } from '../firebase/firestore/documents/localizedString';

const DEFAULT_LOCALE = 'en';

function getTranslatedValue(localizedString: LocalizedString, locale: string): string {
  // istanbul ignore else
  if (localizedString) {
    const providedLocale = Object.keys(localizedString);
    const bestLocale = _find([locale, DEFAULT_LOCALE], (value) => _includes(providedLocale, value));

    if (bestLocale) {
      return _get(localizedString, bestLocale) as string;
    }
  }
  return '';
}

// eslint-disable-next-line import/prefer-default-export
export { getTranslatedValue };
