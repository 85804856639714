import ProfilePresenter from './profilePresenter';
import { useMessagingViewContext, useSelectedEngagedCandidate } from '../../../MessagingViewContext';
import useSingleCandidate from '../../../../../app/hooks/useSingleCandidate';
import { useSeekerContainerContext } from '../../SeekerContainerContext';

const useProfile = (): { profile: ProfilePresenter; initialized: boolean } => {
  const { selectedSeeker } = useMessagingViewContext();

  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const c = selectedCandidate || /* istanbul ignore next */ selectedEngagedCandidate;

  const candidate = useSingleCandidate(c?.id);

  return {
    profile: new ProfilePresenter(selectedSeeker, candidate),
    initialized: Boolean(selectedSeeker && candidate),
  };
};

export default useProfile;
