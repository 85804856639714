export default {
  header: {
    date: 'Date',
    status: 'Status',
  },
  createNotes: {
    saveNote: 'Save Note',
    deleteNote: 'Delete Note',
    cancel: 'Cancel',
  },
  noteList: {
    compose: 'Compose',
    status: 'Status',
    salaryOffered: 'Salary Offered',
    unit: 'Unit',
    recruiterName: 'Recruiter Name',
    updatedDate: 'Updated Date',
    noteType: 'Note Type',
    interveiwRating: 'Interview Rating',
    salary: 'Salary and its frequency',
    dateOfJoining: 'Date of joining',
  },
  noteDetail: {
    editNote: 'Edit Note',
    back: 'Back',
    status: 'Status',
    offerMade: 'Offer Made',
  },
  deleteNote: {
    headerText: 'Delete Note',
    description: 'This note will be permanently deleted. This action cannot be undone.',
  },
  deleteSuccessMsg: {
    title: 'Success !',
    description: 'Note deleted successfully',
  },
  noteType: {
    NOTE: 'Note',
    FEEDBACK: 'Feedback',
    OFFER_DETAILS: 'Offer Details',
    OFFER_REJECTED_DETAILS: 'Offer Rejected Details',
    HIRE_DETAILS: 'Hire Details',
    UNHIRE_DETAILS: 'Unhire Details',
  },
  noNotes: 'No Notes!',
  noNotesMessage:
    'You can add notes about this candidate here, they will be visible if they apply for another position in the future.',
  noteSuccessMsg: {
    title: 'Success !',
    description: 'Note saved successfully.',
  },
  manual: 'Set By Recruiter',
};
