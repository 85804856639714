import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Box, Heading, Text, Spinner, Image } from '@chakra-ui/core';
// import { CgArrowRight } from 'react-icons/cg';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Business } from '../administration/positionManagement/BusinessType';
import { PositionFromPositionList, PositionListResponse } from '../administration/positionManagement/PositionType';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { usePositionManagementContext } from '../administration/positionManagement/PositionManagementContext';
import { PATH_POSITION_TABS } from '../../routes/constants';
import { SelectedTab } from '../administration/topCandidates/TopCandidatesContext';
import colors from '../../styles/colors';

type PositionListForTopCandidateProps = {
  activeBusiness: Business;
  backToBusinessList: () => void;
  setScrollToBusiness: (business: Business) => void;
};

const PositionListForTopCandidate = ({
  activeBusiness,
  backToBusinessList,
  setScrollToBusiness,
}: PositionListForTopCandidateProps) => {
  const { t } = useTranslation('businessManagement');
  const defaultJobListResponse: PositionListResponse = {
    count: 0,
    lastPage: 1,
    to: 0,
    from: 0,
    data: [],
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const [positionData, setPositionData] = useState<PositionListResponse>(defaultJobListResponse);
  const { positionQueryParams } = usePositionManagementContext();
  const { getPositionList } = useStoreActions((actions) => actions.positionManagement);
  const accountId = useStoreState((s) => s.app.user?.account);
  const userId = useStoreState((s) => s.app.user?.id);

  const isPositionFollowed = (position: PositionFromPositionList) =>
    position.assignedRecruiters &&
    position?.assignedRecruiters?.filter(function (value) {
      return value.id === userId;
    }).length > 0;

  const loadPositionList = useCallback(
    async (business: Business) => {
      setLoading(true);
      // istanbul ignore next
      if (accountId && business.id) {
        const data: PositionListResponse = await getPositionList({
          accountId,
          businessId: business?.id,
          params: { ...positionQueryParams, limit: activeBusiness.positions <= 0 ? 1 : activeBusiness.positions },
        });
        setLoading(false);
        setPositionData(data);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, getPositionList, positionQueryParams],
  );

  useEffect(() => {
    loadPositionList(activeBusiness).catch((err) => console.log(err));
  }, [activeBusiness, loadPositionList]);

  return (
    <Box>
      <Box paddingLeft={0} paddingRight={0}>
        <Box borderBottom="0.5px solid #ECEFF1" px={8} py="1rem">
          <Flex
            data-testid="back-btn"
            onClick={() => {
              backToBusinessList();
              setScrollToBusiness(activeBusiness);
            }}
            color="blue.500"
            fontSize="18px"
            fontWeight="bold"
            alignItems="center"
          >
            <BiArrowBack style={{ marginRight: '5px' }} />
            {t('businessList.business')}
          </Flex>
        </Box>
      </Box>
      <Flex py={2} px={8} w="100%" alignItems="center" justifyContent="space-between">
        <Box maxW="70%">
          {activeBusiness.locationId && (
            <Text color="#607D8B" fontSize="14px" lineHeight={1.5}>{`LocationId: ${activeBusiness.locationId}`}</Text>
          )}
          <Heading as="h3" fontSize="16px" lineHeight={1.5}>
            {activeBusiness.name}
          </Heading>
          <Text color="#607D8B" fontSize="12px" lineHeight={1.5}>
            {activeBusiness.completeAddress}
          </Text>
        </Box>
        <Box>
          <Box bg="#F1F5F8" color="blue.500" py={2} px={8} fontSize="14px" fontWeight="bold">
            {`${activeBusiness.positions} ${activeBusiness.positions !== 1 ? t('businessList.jobs') : t('businessList.job')}`}
          </Box>
        </Box>
      </Flex>
      <Box py={4} px={8}>
        {isLoading && (
          <Flex h="120px" alignItems="center" justifyContent="center">
            <Spinner size="md" color="blue.500" />
          </Flex>
        )}
        {positionData.data.length > 0 && (
          <Flex flexWrap="wrap">
            {positionData.data.map((position, index) => (
              <Box key={position.id} w="calc(50% - 8px)" mr={index % 2 === 0 ? '16px' : 0} mb={4}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex justifyContent="flex-start" alignItems="center">
                    {isPositionFollowed(position) ? (
                      // istanbul ignore next
                      <Link
                        to={PATH_POSITION_TABS(position.id, SelectedTab.BEST_MATCH)}
                        style={{ color: colors.linkBlue, textDecoration: 'none' }}
                      >
                        {` ${position.customName}`}
                      </Link>
                    ) : (
                      <Text>{` ${position.customName}`}</Text>
                    )}

                    {isPositionFollowed(position) && (
                      // istanbul ignore next
                      <Link to={{ pathname: `/positionCandidates/${position.id}/messaging`, state: 'PositionList' }}>
                        <Image src="/forum.png" alt="no_icon" style={{ marginLeft: '12px' }} />
                      </Link>
                    )}
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Flex>
        )}
        {!isLoading && positionData.data.length <= 0 && (
          <Flex p={3} alignItems="center" justifyContent="center">
            <Text fontSize="sm">No Positions !</Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default PositionListForTopCandidate;
