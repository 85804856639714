/* istanbul ignore file */
import { Box, Container, Flex, Text, Heading, Alert, AlertIcon, useDisclosure, useToast } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Header } from '../../../app/topBar/Header';
import { NewCreateJobsView } from '../../administration/positionManagement/createJobs/NewCreateJobsView';
import colors from '../../../styles/colors';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { ANIMATED_VARIANTS, PATH_POSITION_TABS } from '../../../routes/constants';
import useContactEmailForAccountAuthorisation from '../../../app/hooks/useContactEmailForAccountAuthorisation';
import { StartRecruitingImmediatelyModal } from '../../administration/positionManagement/createJobs/StartRecruitingImmediatelyModal';
import { JobPosition } from '../../administration/positionManagement/PositionType';
import { SelectedTab } from '../../administration/topCandidates/TopCandidatesContext';
import useCheckIfSmbScreen from './useCheckIfSmbScreen';
import { AppLoadingSpinner } from '../../../app/components';

const MotionBox = motion.custom(Box);

export const SmbPositionManagementView = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const authorization = useStoreState((s) => s.app.accounts?.authorization);
  const showAuthorizationWarning = !_isNil(authorization) && !authorization;
  const contactEmailForAuthorization = useContactEmailForAccountAuthorisation();
  const [currentSelectedPosition, setCurrentSelectedPosition] = useState<JobPosition | null>(null);
  const { user } = useStoreState((s) => s.app);

  const {
    isOpen: isStartRecruitingModalOpen,
    onOpen: onStartRecruitingModalOpen,
    onClose: onStartRecruitingModalClose,
  } = useDisclosure();

  const toast = useToast();

  const { updatePositionStatus } = useStoreActions((actions) => actions.positionManagement);

  const openPosition = async () => {
    if (currentSelectedPosition?.business && currentSelectedPosition?.positionId && user?.account) {
      await updatePositionStatus({
        accountId: user?.account,
        businessId: currentSelectedPosition?.business,
        positionId: currentSelectedPosition?.positionId,
        positionStatus: {
          open: true,
        },
        // eslint-disable-next-line no-unneeded-ternary
        doCancellAppointmentHireOrOffer: false,
      });
    }
  };

  const redirectToTopCandidatesPage = () => {
    if (currentSelectedPosition?.positionId) {
      history.push(PATH_POSITION_TABS(currentSelectedPosition?.positionId, SelectedTab.BEST_MATCH));
    }
  };

  const redirectToPositionManagement = () => {
    history.push('/administration/positionManagement');
  };

  const onSuccessfulPositionCreation = (jobPositionData: JobPosition) => {
    setCurrentSelectedPosition(jobPositionData);
    onStartRecruitingModalOpen();
  };

  const onStartRecruitingImmediately = async () => {
    try {
      await openPosition();
      redirectToTopCandidatesPage();
    } catch (error) {
      // show toast error
      toast({
        title: 'Failed to open position',
        status: 'error',
        duration: 3000,
      });
    } finally {
      onStartRecruitingModalClose();
    }
  };

  const { loading } = useCheckIfSmbScreen();

  return (
    <MotionBox data-testid="SMBBusinessMgmtView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
      <Header data-testid="Header" showMenu />
      {!loading ? (
        <Box py={8} backgroundColor="#F3F3F3" height="calc(100vh - 64px)" overflowY="auto">
          <Container maxW="1200px" position="relative" background={colors.white}>
            <Flex py={3} borderBottom="1px solid #eee">
              <Box>
                <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
                  {t('createJobPosition:positionCreation')}
                </Heading>
                <Text fontSize="xs" color="gray.500">
                  {t('createJobPosition:createBusinessPosition')}
                </Text>
              </Box>
            </Flex>

            {showAuthorizationWarning && (
              <Alert status="error" borderRadius="8px" marginTop={4}>
                <AlertIcon />
                {t('jobPosition:userRestrictedByAuthorizationMessageFront')}
                &nbsp;
                <a
                  href={`mailto:${contactEmailForAuthorization || t('jobPosition:contactEmailForAccountAuthorisation')}`}
                  style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                >
                  {contactEmailForAuthorization || t('jobPosition:contactEmailForAccountAuthorisation')}
                </a>
                &nbsp;
                {t('jobPosition:userRestrictedByAuthorizationMessageBack')}
              </Alert>
            )}

            <NewCreateJobsView
              positionDataToEdit={null}
              redirectToPositionAfterCreated={onSuccessfulPositionCreation}
              selectedBusiness={null}
              onClose={() => {
                ('');
              }}
              createNewPosition
              disablePositionCreation={showAuthorizationWarning}
              showPositionCreationOnPage
            />

            {currentSelectedPosition && (
              <StartRecruitingImmediatelyModal
                isOpen={isStartRecruitingModalOpen}
                onCancelClick={redirectToPositionManagement}
                updatePositionStatusTo={onStartRecruitingImmediately}
                selectedPosition={currentSelectedPosition}
              />
            )}
          </Container>
        </Box>
      ) : (
        <AppLoadingSpinner />
      )}
    </MotionBox>
  );
};
