/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Center, Image, Text } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMessagingViewContext, useSelectedEngagedCandidate } from '../MessagingViewContext';
import SeekerHeader from './header/SeekerHeader';
import { SeekerContainerContextProvider } from './SeekerContainerContext';
import CandidateContainer from './candidate/CandidateContainer';
import { seekerPanel } from './SeekerContainer.styles';
import { useStoreState } from '../../../models/hooks';
import { SeekersPresenter } from '../sidebar/seekerList/seekersPresenter';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { MessagingTabsParams } from '../../../routes/constants';
import useCandidate from '../../../app/components/useCandidate';

export type SeekerContainerProps = {
  isInterviewEnabled: boolean;
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isUndismissedeEnabled: boolean;
  isMessagingView: boolean;
  givenCandidateId?: string;
  messagingModal?: boolean;
};
export const SeekerContainer = ({
  isInterviewEnabled,
  isCertnFeatureEnabled,
  isCertnEnabled,
  isUndismissedeEnabled,
  isMessagingView,
  givenCandidateId,
  messagingModal,
}: SeekerContainerProps): JSX.Element => {
  const { t } = useTranslation('noSeekerMsg');
  const seekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const { candidateId: candidateIdFromParams } = useParams<MessagingTabsParams>();
  const {
    setSelectedEngagedCandidate,
    setSelectedEngagedSeeker,
    selectedCandidateId,
    setSelectedCandidateId,
  } = useMessagingViewContext();
  const candidateId = selectedCandidateId || givenCandidateId || candidateIdFromParams;
  const interviewCandidateSetter = useCallback(() => {
    const candidate = seekerList?.getFirstEngagedCandidate(candidateId);
    setSelectedEngagedCandidate(candidate);
    setSelectedEngagedSeeker(candidate && { seekerId: candidate.seeker, candidateId: candidate.id });
  }, [setSelectedEngagedCandidate, setSelectedEngagedSeeker, candidateId, seekerList]);
  useEffect(() => {
    if (isInterviewEnabled) {
      interviewCandidateSetter();
    }
  }, [isInterviewEnabled, interviewCandidateSetter]);
  const currentCandidate = useCandidate(candidateId);
  useEffect(() => {
    /**
     * We are setting the selected candidate only if the current candidate is the same as the candidateId
     * This is to solve the disparity between the two can happen when the user navigates to a different candidate while the previous candidate is still loading
     */
    if (currentCandidate && currentCandidate.id === candidateId) {
      setSelectedCandidateId(currentCandidate.id);
      setSelectedEngagedCandidate(currentCandidate);
      setSelectedEngagedSeeker(currentCandidate && { seekerId: currentCandidate.seeker, candidateId: currentCandidate.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId, currentCandidate?.id]);
  const isUserDeactivated = Boolean(selectedEngagedCandidate?.deactivated || selectedEngagedCandidate?.scheduledDeletion);
  const isShowFavoriteStatusEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.SHOW_FAVORITE_STATUS];
  });
  return (
    <Box css={seekerPanel} data-testid="SeekerViewPanel" marginLeft="4vw">
      {(() => {
        // if (
        //   !(isCandidatePage() && seekerList?.engagedCandidates[0].dismissed) &&
        //   seekerList?.getSeekersList(i18n.language).length === 0 &&
        //   seekerList?.getGhostedSeekersList(i18n.language).length === 0 &&
        //   seekerList?.getArchivedSeekersList(i18n.language).length === 0 &&
        //   seekerList?.getDismissedSeekersList(i18n.language).length === 0
        // ) {
        if (_isNil(selectedEngagedCandidate)) {
          return (
            <Center>
              <Box
                align="center"
                pos="absolute"
                height={{
                  base: '100%', // 0-48em
                  md: '50%', // 48em-80em,
                  xl: '25%', // 80em+
                }}
                top="35%"
              >
                <Image src="/noseekermsg.png" alt="no_seeker_img" pb={5} />
                <Text>{t('message')}</Text>
              </Box>
            </Center>
          );
        }
        if (!_isNil(selectedEngagedCandidate)) {
          return (
            <SeekerContainerContextProvider>
              {!messagingModal && (
                <SeekerHeader
                  isUndismissedeEnabled={isUndismissedeEnabled}
                  isInterviewEnabled={isInterviewEnabled}
                  isCertnFeatureEnabled={isCertnFeatureEnabled}
                  isCertnEnabled={isCertnEnabled}
                  isShowFavoriteStatusEnabled={isShowFavoriteStatusEnabled}
                  isUserDeactivated={isUserDeactivated}
                  isMessagingView={isMessagingView}
                />
              )}
              <CandidateContainer
                isCertnFeatureEnabled={isCertnFeatureEnabled}
                isCertnEnabled={isCertnEnabled}
                isMessagingModal={messagingModal}
              />
            </SeekerContainerContextProvider>
          );
        }
        return <Box gridRow="1 / 3" className="super-centered" />;
      })()}
    </Box>
  );
};
