import React from 'react';
import { HStack, Stack } from '@chakra-ui/core';
import { SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import InputPreview from './InputPreview';

export default function TimePickerSchemaBuilder(props: SchemaBuilderProps) {
  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder="Enter text here for time input..." />
      <InputPreview value={props?.defaultValue?.previewPlaceholders?.placeholder ?? 'HH:MM AM'} />
    </Stack>
  );
}
