/* eslint-disable jsx-a11y/control-has-associated-label */
// istanbul ignore file
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  Divider,
  FormControl,
  FormLabel,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Tooltip,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

import _find from 'lodash/find';
import moment from 'moment-timezone';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import _sortBy from 'lodash/sortBy';
import { RiArrowDownSLine } from 'react-icons/ri';
import _isUndefined from 'lodash/isUndefined';
import isUndefined from 'lodash/isUndefined';
import { Requisition, SalaryUnits, ShiftDetail, Shifts } from '../../../../firebase/firestore/documents/requisition';
import usePositionByRequisition from './actionsDropdown/usePositionByRequisition';
import { tableStyle } from './actionsDropdown/HireWithRequisitionStyle';
import colors from '../../../../styles/colors';
import {
  ReactCustomSelectStyles,
  ReactCustomSelectStylesWhenDisabled,
} from '../../../administration/positionManagement/createJobs/CreateJobPositionStyle';
import { MultiSelectDropdown } from '../../../common/MultiSelectDropDown/MultiSelectDropDown';
import useBusinessLocations from '../../../administration/positionManagement/useBusinessLocations';
import {
  BusinessSelectType,
  PositionListResponse,
  PositionSelectType,
  RequisitionSelectType,
} from '../../../administration/positionManagement/PositionType';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { usePositionDocumentSnapshot } from './actionsDropdown/usePositionDocumentSnapshot';
import { useSeekerContainerContext } from '../SeekerContainerContext';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { isRequisitionAvailable } from '../../../../utils/RequisitionEffectiveComparisionWithThresholdValue';
import { getTranslatedStringValue } from '../../../../utils/localizedNumber';
import { CandidateRequisitionOverlap } from './CandidateRequisitionOverlap';

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
const weekEnds = ['sat', 'sun'];

export type SelectRequisitionProps = {
  onRequisitionChanged: (requisition: Requisition | undefined) => void;
  positionId: string;
  requisitionId?: string;
  handleJobLocationChange: (businessLocationItem: BusinessSelectType | null) => void;
  handleJobChange: (jobPositionItem: PositionSelectType | null) => void;
  isOpenConformationModal: boolean;
  onHandleConformationModal: (conformation: boolean) => void;
  showIcon?: boolean;
  selectedRequisitionID?: string;
  availabilities?: { [key: string]: { start_1: string; end_1: string } };
  updatedWageOfRequisition?: string;
  setUpdatedWageOfRequisition?: (wage: string) => void;
};

export const SelectRequisition = ({
  onRequisitionChanged,
  requisitionId,
  handleJobLocationChange,
  handleJobChange,
  isOpenConformationModal,
  onHandleConformationModal,
  showIcon,
  selectedRequisitionID,
  updatedWageOfRequisition,
  setUpdatedWageOfRequisition,
}: SelectRequisitionProps): JSX.Element => {
  const { t, i18n } = useTranslation('requisition');
  // const [prevData, setPrevData] = React.useState<string>('');
  const { selectedCandidate: selectedCandidateFromContext } = useSeekerContainerContext();
  const { selectedEngagedCandidate } = useMessagingViewContext();
  const selectedCandidate = selectedCandidateFromContext || selectedEngagedCandidate;
  const { selectedSeeker } = useMessagingViewContext();
  const [selectedRequisition, setSelectedRequisition] = useState<Requisition | undefined>();
  const [isFristCall, setIsFristCall] = useState<boolean>(false);
  const [positions, setPositions] = useState<BusinessSelectType[]>([]);
  const [requisitions, setRequisitions] = useState<RequisitionSelectType[]>([]);
  const [businessLocation, setBusinessLocation] = React.useState<BusinessSelectType | null>(null);
  const [jobPosition, setJobPosition] = React.useState<PositionSelectType | null>(null);
  const [reqFromDropDown, setReqFromDropDown] = React.useState<RequisitionSelectType | null>(null);
  const businessLocations = useBusinessLocations();
  const accountId = useStoreState((s) => s.app.user?.account);
  const getPositionList = useStoreActions((actions) => actions.positionManagement.getPositionList);
  const [positionFirstLoad, setPositionFirstLoad] = useState<boolean>(false);
  const positionDocument = usePositionDocumentSnapshot(
    selectedCandidate?.newHireData ? selectedCandidate?.newHireData?.positionId : selectedCandidate?.position,
  );
  const [showComparision, setShowComparision] = useState<boolean>(false);
  const [loadReq, setLoadReq] = useState<boolean>(true);

  const requisition = usePositionByRequisition(jobPosition?.id || '', requisitionId);

  const selectedBusinessLocation = businessLocations.find((location) => location.id === positionDocument?.business);

  const requisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  const isShiftIntersectableInAccount = useStoreState((state) => state.app.accounts?.configuration?.isShiftIntersectable);
  const candidateAvailabilities = selectedSeeker?.newAvailabilities ?? /* istanbul ignore next */ undefined;
  const candidateNightAvailabilities = selectedSeeker?.nightAvailabilities ?? /* istanbul ignore next */ undefined;
  const getShiftIntersection = useStoreActions((actions) => actions.positionManagement.getShiftIntersection);
  const [intersectionShift, setInterSectionShift] = useState<Shifts | undefined>(undefined);

  const getTopCandidateList = useCallback(
    // eslint-disable-next-line no-shadow
    async (positionId: string, seekerId: string, requisitionId: string) => {
      // istanbul ignore else
      if (jobPosition?.id && selectedSeeker?.id && selectedRequisition?.requisitionId) {
        const reqResponse = await getShiftIntersection({
          positionId,
          seekerId,
          requisitionId,
        });
        setInterSectionShift(reqResponse);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobPosition?.id, selectedSeeker?.id, selectedRequisition?.requisitionId],
  );

  useEffect(() => {
    if (jobPosition?.id && selectedSeeker?.id && selectedRequisition?.requisitionId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getTopCandidateList(jobPosition?.id, selectedSeeker?.id, selectedRequisition?.requisitionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPosition?.id, selectedSeeker?.id, selectedRequisition?.requisitionId]);

  const requisitionActiveThresholdDays = useStoreState(
    (state) => state.app.accounts?.configuration?.requisitionActiveThresholdDays,
  );

  const allowOnlyBusinessAndPositionwithRequisition: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION];
  });

  /* istanbul ignore next */
  function anyDayHasValues() {
    if (intersectionShift) {
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      for (let i = 0; i < daysOfWeek.length; i += 1) {
        const day = daysOfWeek[i];
        if (intersectionShift[day] && Object.keys(intersectionShift[day]).length > 0) {
          return true; // Found a day with values, return true
        }
      }
      return false;
    }
    return false; // No day has values, return false
  }

  /* istanbul ignore next */
  const shiftIntersection = () => {
    if (!_isNil(jobPosition?.isShiftIntersectable)) {
      return jobPosition && jobPosition.isShiftIntersectable && anyDayHasValues();
    }

    return isShiftIntersectableInAccount && anyDayHasValues();
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  // const formattedSalary = (salary: string) => getTranslatedNumber(Number(salary as any).toFixed(2), i18n.language) as any;

  const setRequisition = () => {
    /* istanbul ignore else */
    if ((selectedRequisitionID || requisitionId) && selectedRequisition) {
      const req = requisition?.filter((r) => r.requisitionId === (selectedRequisitionID || requisitionId));

      /* istanbul ignore else */
      if (req && req.length > 0) {
        setSelectedRequisition(req[0]);
        onRequisitionChanged(req[0]);

        setReqFromDropDown({
          id: req[0].requisitionId,
          label: req[0].requisitionId,
          value: req[0].requisitionId,
          requisitoin: req[0],
          color: isRequisitionAvailable(
            req[0].effectiveDate,
            requisitionActiveThresholdDays,
            jobPosition?.requisitionActiveThresholdDays,
          )
            ? colors.black
            : /* istanbul ignore next */ colors.gray[400],
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setUpdatedWageOfRequisition && setUpdatedWageOfRequisition('');
        // setPrevData(req[0].wage.value.toString());
        // setInterSectionShift(
        //   getShiftIntersectionWithCandidate(req[0]?.shifts, candidateAvailabilities, candidateNightAvailabilities),
        // );
      }
    }
  };

  useEffect(() => {
    const req: RequisitionSelectType[] = [];

    if (requisition && requisition.length > 0 && loadReq) {
      _sortBy(requisition, ['effectiveDate'], ['asc'])?.forEach((value) => {
        const data: RequisitionSelectType = {
          id: value.requisitionId,
          value: value.requisitionId,
          label: value.requisitionId,
          color: isRequisitionAvailable(
            value.effectiveDate,
            requisitionActiveThresholdDays,
            jobPosition?.requisitionActiveThresholdDays,
          )
            ? colors.black
            : /* istanbul ignore next */ colors.gray[400],
          requisitoin: value,
        };
        req.push(data);
      });
      setLoadReq(false);
      setRequisitions(req);
      setRequisition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisition, loadReq]);

  const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const dx = lat2 - lat1;
    const dy = lon2 - lon1;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const onGetPositionsFormBusiness = useCallback(
    async (businessID: string) => {
      if (accountId) {
        const data: PositionListResponse = await getPositionList({
          accountId,
          businessId: businessID,
          params: {
            positionStatus: 'open',
            followedOnly: false,
            sortBy: 'customName',
            sortOrder: 'asc',
            page: 1,
            limit: 100,
            searchTerm: '',
            archive: false,
          },
        });
        const positionListData: PositionSelectType[] = [];
        data.data.forEach((value) => {
          if (allowOnlyBusinessAndPositionwithRequisition && requisitionEnabled) {
            if (!_isNil(value.isPaused) && !value.isPaused) {
              positionListData.push({
                id: value.id,
                label: value.customName || '',
                value: value.customName || '',
                proposedSalary: value.proposedSalary,
                requisitionActiveThresholdDays: value.requisitionActiveThresholdDays,
                isShiftIntersectable: value.isShiftIntersectable,
              });
            }
          } else {
            positionListData.push({
              id: value.id,
              label: value.customName || '',
              value: value.customName || '',
              proposedSalary: value.proposedSalary,
              requisitionActiveThresholdDays: value.requisitionActiveThresholdDays,
              isShiftIntersectable: value.isShiftIntersectable,
            });
          }
        });

        setPositions(positionListData);
        setPositionFirstLoad(true);
      }
    },
    [accountId, getPositionList, requisitionEnabled, allowOnlyBusinessAndPositionwithRequisition],
  );

  const { setReq } = useMessagingViewContext();

  useEffect(() => {
    // istanbul ignore else
    if (requisitionId && !isFristCall && requisition) {
      const prevRequisition = _find(requisition, (req: Requisition) => req?.requisitionId === requisitionId);
      if (prevRequisition) {
        setReqFromDropDown({
          id: prevRequisition.requisitionId,
          label: prevRequisition.requisitionId,
          value: prevRequisition.requisitionId,
          requisitoin: prevRequisition,
          color: isRequisitionAvailable(
            prevRequisition.effectiveDate,
            requisitionActiveThresholdDays,
            jobPosition?.requisitionActiveThresholdDays,
          )
            ? colors.black
            : /* istanbul ignore next */ colors.gray[400],
        });
      }
      setSelectedRequisition(prevRequisition);
      onRequisitionChanged(prevRequisition);
      setIsFristCall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisition, requisitionId]);

  React.useEffect(() => {
    if (
      _isNil(businessLocation) &&
      selectedCandidate &&
      !_isNil(selectedBusinessLocation) &&
      !_isNil(selectedBusinessLocation.id)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onGetPositionsFormBusiness(selectedBusinessLocation.id);
      setBusinessLocation(selectedBusinessLocation);
      if (selectedCandidate?.newHireData) {
        setJobPosition({
          value: selectedCandidate?.newHireData?.positionId || '',
          label: selectedCandidate?.newHireData?.jobTitle
            ? getTranslatedValue(selectedCandidate?.newHireData?.jobTitle, i18n.language)
            : '',
          id: selectedCandidate?.newHireData?.positionId || '',
        });
      } else {
        setJobPosition({
          value: selectedCandidate?.position,
          label: selectedCandidate?.jobTitle
            ? /* istanbul ignore next */ getTranslatedValue(selectedCandidate?.jobTitle, i18n.language)
            : '',
          id: selectedCandidate?.position,
        });
      }
    }
  }, [
    positionFirstLoad,
    businessLocation,
    onGetPositionsFormBusiness,
    selectedBusinessLocation,
    i18n.language,
    selectedCandidate,
  ]);
  /* istanbul ignore next */
  const onHandleJobLocationChange = (businessLocationItem: BusinessSelectType | null) => {
    if (!_isNil(businessLocationItem) && businessLocationItem?.id !== businessLocation?.id) {
      setBusinessLocation(businessLocationItem);
      setLoadReq(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onGetPositionsFormBusiness(businessLocationItem.id);
      handleJobLocationChange(businessLocationItem);
      setJobPosition(null);
      setReqFromDropDown(null);
      setSelectedRequisition(undefined);
    }
  };

  /* istanbul ignore next */
  const onHandleJobChange = (jobPositionItem: PositionSelectType | null) => {
    if (!_isNil(jobPositionItem) && jobPositionItem?.id !== jobPosition?.id) {
      setRequisitions([]);
      setJobPosition(jobPositionItem);
      setSelectedRequisition(undefined);
      setReqFromDropDown(null);
      handleJobChange(jobPositionItem);
      setTimeout(function () {
        setLoadReq(true);
      }, 1000);
    }
  };
  /* istanbul ignore next */
  const onHandleChangeRequisition = (requisitionItem: RequisitionSelectType | null) => {
    if (!_isNil(requisitionItem) && requisitionItem?.id !== reqFromDropDown?.id) {
      if (requisitionItem.requisitoin) {
        setReqFromDropDown(requisitionItem);
        setSelectedRequisition(requisitionItem.requisitoin);
        setReq(requisitionItem.id);
        onRequisitionChanged(requisitionItem.requisitoin);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setUpdatedWageOfRequisition && setUpdatedWageOfRequisition('');
      }
    }
  };

  /* istanbul ignore next */
  const isAvailabilitiesMatchedToRequisition = (reqAvailabilities?: ShiftDetail, day?: string) => {
    if (!reqAvailabilities) {
      return true;
    }

    if (reqAvailabilities.start_1 < reqAvailabilities.end_1) {
      if (candidateAvailabilities && day && candidateAvailabilities[day] && reqAvailabilities) {
        const startTime1 = new Date(`2000-01-01T${candidateAvailabilities[day].start_1}:00`);
        const endTime1 = new Date(`2000-01-01T${candidateAvailabilities[day].end_1}:00`);
        const startTime2 = new Date(`2000-01-01T${reqAvailabilities.start_1}:00`);
        const endTime2 = new Date(`2000-01-01T${reqAvailabilities.end_1}:00`);
        return startTime1 <= startTime2 && endTime1 >= endTime2;
      }
    } else if (reqAvailabilities.start_1 > reqAvailabilities.end_1) {
      if (candidateNightAvailabilities && day && candidateNightAvailabilities[day] && reqAvailabilities) {
        const startTime1 = new Date(`2000-01-01T${candidateNightAvailabilities[day].start_1}:00`);
        const endTime1 = new Date(`2000-01-01T${candidateNightAvailabilities[day].end_1}:00`);
        const startTime2 = new Date(`2000-01-01T${reqAvailabilities.start_1}:00`);
        const endTime2 = new Date(`2000-01-01T${reqAvailabilities.end_1}:00`);
        return startTime1 <= startTime2 && endTime1 >= endTime2;
      }
    }
    return false;
  };

  /* istanbul ignore next */
  const onGetColorByIndex = (index: number) => {
    if (index % 2) return '#edf2f7';
    return '#ffffff';
  };

  // helper function
  const getDayRange = (day: string) => {
    const daysOfWeek = [...weekDays, ...weekEnds];
    const currentIndex = daysOfWeek.indexOf(day);
    const nextDay = currentIndex < daysOfWeek.length - 1 ? daysOfWeek[currentIndex + 1] : daysOfWeek[0];
    return `${t(`common:days.${day}`)} - ${t(`common:days.${nextDay}`)}`;
  };

  const checkLengthOfStringSeparatedByDotOrComma = (givenString: string, stringLength: number) => {
    let allowInput = true;

    const spliterator = givenString.includes('.') ? givenString.split('.') : givenString.split(',');
    if (spliterator[1]) {
      if (spliterator[1].length > stringLength) {
        allowInput = false;
      }
    }

    return allowInput;
  };

  const handleSalaryChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkLengthOfStringSeparatedByDotOrComma(e.target.value, 2)) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setUpdatedWageOfRequisition && setUpdatedWageOfRequisition(e.target.value);
  };

  const handleValueOnBlur = () => {
    if (updatedWageOfRequisition === '') {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (setUpdatedWageOfRequisition && selectedRequisition && selectedRequisition.wage && selectedRequisition.wage.maxValue) {
      if (Number(updatedWageOfRequisition?.replace(',', '.')) > selectedRequisition?.wage?.maxValue) {
        setUpdatedWageOfRequisition(selectedRequisition?.wage?.maxValue.toFixed(2).toString());
      } else if (Number(updatedWageOfRequisition?.replace(',', '.')) < selectedRequisition?.wage?.value) {
        setUpdatedWageOfRequisition(selectedRequisition?.wage?.value.toFixed(2).toString());
      } else {
        setUpdatedWageOfRequisition(Number(updatedWageOfRequisition?.replace(',', '.')).toFixed(2).toString());
      }
    }
  };

  return (
    <Box>
      <Flex>
        <Box w="49%">
          <FormControl isRequired>
            <FormLabel htmlFor="jobLocation" fontWeight="bold">
              {t('createJobPosition:businessLocation')}
            </FormLabel>
            <Box css={ReactCustomSelectStyles} data-testid="JobLocationChangeBtn">
              <MultiSelectDropdown
                placeholder={t('createJobPosition:businessLocationPlaceholder')}
                id="jobLocation"
                handleChange={onHandleJobLocationChange}
                options={businessLocations
                  .filter((businessItem) =>
                    allowOnlyBusinessAndPositionwithRequisition && requisitionEnabled
                      ? businessItem.hasRequisition === true && businessItem.positions && businessItem.positions >= 1
                      : businessItem.positions && businessItem.positions >= 1,
                  )
                  .sort((a, b) => {
                    const distA = distance(
                      selectedBusinessLocation?.latitude || 0,
                      selectedBusinessLocation?.longitude || 0,
                      a?.latitude || 0,
                      a?.longitude || 0,
                    );
                    const distB = distance(
                      selectedBusinessLocation?.latitude || 0,
                      selectedBusinessLocation?.longitude || 0,
                      b?.latitude || 0,
                      b?.longitude || 0,
                    );
                    return distA - distB;
                  })}
                value={businessLocation}
                data-testid="JobLocationField"
              />
            </Box>
          </FormControl>
        </Box>
        <Box w="2%" />
        <Box w="49%">
          <FormControl isRequired>
            <FormLabel htmlFor="jobPosition" fontWeight="bold">
              {t('createJobPosition:positionNamePlaceholder')}
            </FormLabel>
            <Box css={ReactCustomSelectStyles} data-testid="PositionNameChangeBtn" data-attr={`data-attr-${jobPosition?.id}`}>
              <MultiSelectDropdown
                placeholder={t('createJobPosition:positionNamePlaceholder')}
                id="PositionName"
                handleChange={onHandleJobChange}
                options={positions}
                value={jobPosition}
                data-testid="JobPositionField"
                showIcon={showIcon}
              />
            </Box>
          </FormControl>
        </Box>
      </Flex>
      {requisitionEnabled && (
        <Box mt="12px" w="100%">
          <FormControl isRequired>
            <FormLabel htmlFor="jobLocation" fontWeight="bold">
              {t('requisitionID')}
            </FormLabel>
            <Box
              css={
                isRequisitionAvailable(
                  selectedRequisition?.effectiveDate,
                  requisitionActiveThresholdDays,
                  jobPosition?.requisitionActiveThresholdDays,
                )
                  ? /* istanbul ignore next */ ReactCustomSelectStyles
                  : /* istanbul ignore next */ ReactCustomSelectStylesWhenDisabled
              }
              data-testid="JobLocationChangeBtn"
            >
              <MultiSelectDropdown
                placeholder={t('selectRequisition')}
                id="PositionName"
                handleChange={onHandleChangeRequisition}
                options={requisitions}
                value={reqFromDropDown}
                data-testid="selectRequisition"
                showIcon={showIcon}
              />
            </Box>
          </FormControl>
        </Box>
      )}
      {requisitionEnabled && (
        <Box>
          {selectedRequisition && (
            <Box>
              <Flex mt="16px">
                <Text fontSize={16} fontWeight={700} mr="12px">
                  {t('requisitionDetails')}
                </Text>
                {selectedRequisition.seasonal && (
                  <Text fontSize="xs" fontWeight="bold" d="inline-flex" alignItems="center">
                    <Badge
                      variant="subtle"
                      colorScheme="blue"
                      mr="12px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color="#263DBA"
                      background="#263DBA1A"
                    >
                      {t('seasonal')}
                    </Badge>
                  </Text>
                )}
                <Text fontSize="xs" fontWeight="bold" d="inline-flex" alignItems="center">
                  <Badge
                    variant="subtle"
                    colorScheme="blue"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr="4px"
                    color="#263DBA"
                    background="#263DBA1A"
                  >
                    {selectedRequisition.fullTime ? t('fullTime') : t('partTime')}
                  </Badge>
                  {`${
                    selectedRequisition.fullTime
                      ? ''
                      : `: ${selectedRequisition.partTimeHours?.toFixed(2)} ${t('partTimeHoursPerWeek')}`
                  } `}
                </Text>
              </Flex>
              <Divider mt="16px" />
              <Flex mt="16px" alignItems="center">
                {selectedRequisition?.effectiveDate && (
                  <Flex w="50%" alignItems="center">
                    <Text fontSize="14px" fontWeight="700">
                      {t('effectiveTime')}
                    </Text>

                    <Text pl="6px">{moment(selectedRequisition?.effectiveDate).locale(i18n.language).format('ll')}</Text>
                    {
                      /* istanbul ignore next */
                      !isRequisitionAvailable(
                        selectedRequisition?.effectiveDate,
                        requisitionActiveThresholdDays,
                        jobPosition?.requisitionActiveThresholdDays,
                      ) && (
                        <Tooltip
                          placement="top"
                          size="xl"
                          label={t('effectiveTimeInfo', {
                            requisitionActiveThresholdDays: moment(selectedRequisition.effectiveDate)
                              .subtract(
                                jobPosition?.requisitionActiveThresholdDays
                                  ? /* istanbul ignore next */ jobPosition?.requisitionActiveThresholdDays
                                  : /* istanbul ignore next */ requisitionActiveThresholdDays,
                                'days',
                              )
                              .locale(i18n.language)
                              .format('ll'),
                          })}
                          aria-label="value?.label"
                          shouldWrapChildren
                        >
                          <IoMdInformationCircleOutline
                            color={colors.red[500]}
                            style={{ cursor: 'pointer', marginLeft: '6px' }}
                          />
                        </Tooltip>
                      )
                    }
                  </Flex>
                )}
                {selectedRequisition && selectedRequisition?.wage && (
                  <Flex w="50%" alignItems="center" ml={selectedRequisition?.effectiveDate ? '12px' : '0px'}>
                    <Text fontSize="14px" fontWeight="700" mr={1}>
                      {t('wages')}
                      <Box as="span" color="red.500">
                        *
                      </Box>
                    </Text>
                    <Box w="130px">
                      <NumberInput
                        max={
                          // istanbul ignore next
                          (i18n.language === 'en' && selectedRequisition?.wage?.maxValue) || undefined
                        }
                        min={
                          // istanbul ignore next
                          (i18n.language === 'en' && selectedRequisition?.wage?.value) || undefined
                        }
                        precision={2}
                        value={getTranslatedStringValue(updatedWageOfRequisition || '', i18n.language)}
                      >
                        <NumberInputField
                          data-testid="requisitionWageField"
                          type="text"
                          value={getTranslatedStringValue(updatedWageOfRequisition || '', i18n.language)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSalaryChange(e)}
                          placeholder={t('messaging:actions.hire.confirmationModal.salaryValue')}
                          onBlur={() => handleValueOnBlur()}
                        />
                      </NumberInput>
                    </Box>
                    <Text pl="6px">
                      {`${`$${
                        selectedRequisition?.wage?.value
                          ? selectedRequisition?.wage?.value?.toLocaleString(i18n.language, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : /* istanbul ignore next */ ''
                      }`} ${`${
                        selectedRequisition?.wage.maxValue
                          ? ` - $${selectedRequisition?.wage.maxValue?.toLocaleString(i18n.language, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : ''
                      }/`} ${
                        selectedRequisition?.wage.unit === SalaryUnits.CUSTOM
                          ? selectedRequisition?.wage?.customUnit
                          : t(selectedRequisition?.wage.unit.toLowerCase())
                      }`}
                    </Text>
                  </Flex>
                )}
              </Flex>
              {selectedRequisition.seasonal && selectedRequisition?.endDate && (
                <Flex mt="12px">
                  <Text fontSize="14px" fontWeight="700">
                    {t('seasonalEndDate')}
                  </Text>
                  <Text pl="6px">{moment(selectedRequisition?.endDate).locale(i18n.language).format('ll')}</Text>
                </Flex>
              )}
              {
                /* istanbul ignore next */
                !shiftIntersection() ? (
                  <Box>
                    <Text fontSize="14px" fontWeight="700" mt="12px">
                      {t('shifts')}
                    </Text>
                    {selectedRequisition?.shifts && !_isEmpty(selectedRequisition?.shifts) && (
                      <Flex mt="16px" alignItems="baseline">
                        <Box w="50%" css={tableStyle}>
                          <table>
                            {weekDays.map((day, index) => {
                              const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                              const isAnotherRequisitionOnSameDayAvailable =
                                reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 < reqShift.end_2;

                              const isAnotherRequisitionOnDifferentDayAvailable =
                                reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 >= reqShift.end_2;

                              const isBothRequisitionOnDifferentDay =
                                reqShift &&
                                reqShift.start_2 &&
                                reqShift.end_2 &&
                                reqShift.start_2 > reqShift.end_2 &&
                                reqShift.start_1 > reqShift.end_1;

                              const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                reqShift &&
                                reqShift.start_2 &&
                                reqShift.end_2 &&
                                reqShift.start_2 < reqShift.end_2 &&
                                reqShift.start_1 >= reqShift.end_1;

                              return (
                                <Popover autoFocus={false} key={day}>
                                  <PopoverTrigger>
                                    <Flex direction="column">
                                      {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                        <tr
                                          style={{
                                            background: isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? /* istanbul ignore next */ onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <td>{t(`common:days.${day}`)}</td>

                                          <td style={{ float: 'right' }}>
                                            <Flex>
                                              <Text fontSize={16}>
                                                {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(
                                                  reqShift?.end_2,
                                                  ['HH:mm'],
                                                ).format('LT')}`}
                                              </Text>
                                            </Flex>
                                          </td>
                                        </tr>
                                      )}
                                      <tr
                                        style={{
                                          background: isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>
                                          {reqShift && (reqShift.start_1 > reqShift.end_1 || reqShift.isOvernightShift)
                                            ? getDayRange(day)
                                            : t(`common:days.${day}`)}
                                        </td>

                                        <td style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                          <td style={{ float: 'right', padding: 0 }}>
                                            {(isAnotherRequisitionOnSameDayAvailable ||
                                              isAnotherRequisitionOnSameDayAvailable ||
                                              isBothRequisitionOnDifferentDay) &&
                                            !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                              <Badge
                                                background={
                                                  isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  {reqShift ? (
                                                    <Text fontSize={15} fontWeight="normal">
                                                      {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                        'LT',
                                                      )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                    </Text>
                                                  ) : (
                                                    <Text>---</Text>
                                                  )}
                                                </Flex>
                                              </Badge>
                                            ) : (
                                              <Flex paddingRight={2}>
                                                {reqShift ? (
                                                  <Text>
                                                    {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            )}
                                          </td>

                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                            !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                              <td style={{ float: 'right' }}>
                                                <Badge
                                                  background={
                                                    isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                      ? '#e9f0ff'
                                                      : '#ffe9e9'
                                                  }
                                                >
                                                  <Flex>
                                                    <Text fontSize={15} fontWeight="normal">
                                                      {`${moment(reqShift?.start_2, ['HH:mm']).format(
                                                        'LT',
                                                      )} - ${moment(reqShift?.end_2, ['HH:mm']).format('LT')}`}
                                                    </Text>
                                                  </Flex>
                                                </Badge>
                                              </td>
                                            )}
                                        </td>
                                      </tr>

                                      {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                        <tr
                                          style={{
                                            background: isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? /* istanbul ignore next */ onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <td>{getDayRange(day)}</td>

                                          <td style={{ float: 'right' }}>
                                            <Flex>
                                              <Text fontSize={16}>
                                                {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(
                                                  reqShift?.end_2,
                                                  ['HH:mm'],
                                                ).format('LT')}`}
                                              </Text>
                                            </Flex>
                                          </td>
                                        </tr>
                                      )}
                                    </Flex>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                      <Flex>
                                        <Text fontSize="16px" fontWeight={700} w="50%">
                                          {t('common:requisitions')}
                                        </Text>
                                        <Text fontSize="16px" fontWeight={700} w="50%">
                                          {t('common:candidatesHours')}
                                        </Text>
                                      </Flex>

                                      <Flex
                                        justifyContent="space-between"
                                        backgroundColor="#edf2f7"
                                        p="10px"
                                        borderRadius="4px"
                                        mt="6px"
                                      >
                                        <Box w="50%">
                                          {reqShift ? (
                                            <Fragment>
                                              <Text fontSize="14px" fontWeight={500}>
                                                {`${moment(reqShift.start_1, ['HH:mm']).format('LT')} - ${moment(reqShift.end_1, [
                                                  'HH:mm',
                                                ]).format('LT')}`}
                                              </Text>
                                              {reqShift.start_2 && reqShift.end_2 && (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  {`${moment(reqShift.start_2, ['HH:mm']).format('LT')} - ${moment(
                                                    reqShift.end_2,
                                                    ['HH:mm'],
                                                  ).format('LT')}`}
                                                </Text>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <Text fontSize="14px" fontWeight={500}>
                                              ---
                                            </Text>
                                          )}
                                        </Box>
                                        {reqShift &&
                                        reqShift.start_1 < reqShift.end_1 &&
                                        _isUndefined(reqShift.isOvernightShift) ? (
                                          <Box w="50%">
                                            {
                                              /* istanbul ignore next */
                                              candidateAvailabilities && candidateAvailabilities[day] ? (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  {`${moment(candidateAvailabilities[day].start_1, ['HH:mm']).format(
                                                    'LT',
                                                  )} - ${moment(candidateAvailabilities[day].end_1, ['HH:mm']).format('LT')}`}
                                                </Text>
                                              ) : (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  ---
                                                </Text>
                                              )
                                            }
                                          </Box>
                                        ) : (
                                          <Box w="50%">
                                            {
                                              /* istanbul ignore next */
                                              candidateNightAvailabilities && candidateNightAvailabilities[day] ? (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  {`${moment(candidateNightAvailabilities[day].start_1, ['HH:mm']).format(
                                                    'LT',
                                                  )} - ${moment(candidateNightAvailabilities[day].end_1, ['HH:mm']).format(
                                                    'LT',
                                                  )}`}
                                                </Text>
                                              ) : (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  ---
                                                </Text>
                                              )
                                            }
                                          </Box>
                                        )}
                                      </Flex>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              );
                            })}
                          </table>
                        </Box>

                        <Box w="50%" ml="12px" css={tableStyle}>
                          <table>
                            {weekEnds.map((day, index) => {
                              const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                              const isAnotherRequisitionOnSameDayAvailable =
                                reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 < reqShift.end_2;

                              const isAnotherRequisitionOnDifferentDayAvailable =
                                reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 >= reqShift.end_2;

                              const isBothRequisitionOnDifferentDay =
                                reqShift &&
                                reqShift.start_2 &&
                                reqShift.end_2 &&
                                reqShift.start_2 > reqShift.end_2 &&
                                reqShift.start_1 > reqShift.end_1;

                              const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                reqShift &&
                                reqShift.start_2 &&
                                reqShift.end_2 &&
                                reqShift.start_2 < reqShift.end_2 &&
                                reqShift.start_1 >= reqShift.end_1;
                              return (
                                <Popover autoFocus={false} key={day}>
                                  <PopoverTrigger>
                                    <Flex direction="column">
                                      {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                        <tr
                                          style={{
                                            background: isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? /* istanbul ignore next */ onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <td>{t(`common:days.${day}`)}</td>

                                          <td style={{ float: 'right' }}>
                                            <Flex>
                                              <Text fontSize={16}>
                                                {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(
                                                  reqShift?.end_2,
                                                  ['HH:mm'],
                                                ).format('LT')}`}
                                              </Text>
                                            </Flex>
                                          </td>
                                        </tr>
                                      )}
                                      <tr
                                        style={{
                                          background: isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>
                                          {reqShift && (reqShift.start_1 > reqShift.end_1 || reqShift.isOvernightShift)
                                            ? getDayRange(day)
                                            : t(`common:days.${day}`)}
                                        </td>

                                        <td style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                          <td style={{ float: 'right', padding: 0 }}>
                                            {(isAnotherRequisitionOnSameDayAvailable ||
                                              isAnotherRequisitionOnSameDayAvailable ||
                                              isBothRequisitionOnDifferentDay) &&
                                            !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                              <Badge
                                                background={
                                                  isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  {reqShift ? (
                                                    <Text fontSize={15} fontWeight="normal">
                                                      {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                        'LT',
                                                      )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                    </Text>
                                                  ) : (
                                                    <Text>---</Text>
                                                  )}
                                                </Flex>
                                              </Badge>
                                            ) : (
                                              <Flex paddingRight={2}>
                                                {reqShift ? (
                                                  <Text>
                                                    {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            )}
                                          </td>

                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                            !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                              <td style={{ float: 'right' }}>
                                                <Badge
                                                  background={
                                                    isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                      ? '#e9f0ff'
                                                      : '#ffe9e9'
                                                  }
                                                >
                                                  <Flex>
                                                    <Text fontSize={15} fontWeight="normal">
                                                      {`${moment(reqShift?.start_2, ['HH:mm']).format(
                                                        'LT',
                                                      )} - ${moment(reqShift?.end_2, ['HH:mm']).format('LT')}`}
                                                    </Text>
                                                  </Flex>
                                                </Badge>
                                              </td>
                                            )}
                                        </td>
                                      </tr>

                                      {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                        <tr
                                          style={{
                                            background: isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? /* istanbul ignore next */ onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <td>{getDayRange(day)}</td>

                                          <td style={{ float: 'right' }}>
                                            <Flex>
                                              <Text fontSize={16}>
                                                {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(
                                                  reqShift?.end_2,
                                                  ['HH:mm'],
                                                ).format('LT')}`}
                                              </Text>
                                            </Flex>
                                          </td>
                                        </tr>
                                      )}
                                    </Flex>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                      <Flex>
                                        <Text fontSize="16px" fontWeight={700} w="50%">
                                          {t('common:requisitions')}
                                        </Text>
                                        <Text fontSize="16px" fontWeight={700} w="50%">
                                          {t('common:candidatesHours')}
                                        </Text>
                                      </Flex>

                                      <Flex
                                        justifyContent="space-between"
                                        backgroundColor="#edf2f7"
                                        p="10px"
                                        borderRadius="4px"
                                        mt="6px"
                                      >
                                        <Box w="50%">
                                          {reqShift ? (
                                            <Fragment>
                                              <Text fontSize="14px" fontWeight={500}>
                                                {`${moment(reqShift.start_1, ['HH:mm']).format('LT')} - ${moment(reqShift.end_1, [
                                                  'HH:mm',
                                                ]).format('LT')}`}
                                              </Text>
                                              {reqShift.start_2 && reqShift.end_2 && (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  {`${moment(reqShift.start_2, ['HH:mm']).format('LT')} - ${moment(
                                                    reqShift.end_2,
                                                    ['HH:mm'],
                                                  ).format('LT')}`}
                                                </Text>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <Text fontSize="14px" fontWeight={500}>
                                              ---
                                            </Text>
                                          )}
                                        </Box>
                                        {reqShift &&
                                        reqShift.start_1 < reqShift.end_1 &&
                                        _isUndefined(reqShift.isOvernightShift) ? (
                                          <Box w="50%">
                                            {
                                              /* istanbul ignore next */
                                              candidateAvailabilities && candidateAvailabilities[day] ? (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  {`${moment(candidateAvailabilities[day].start_1, ['HH:mm']).format(
                                                    'LT',
                                                  )} - ${moment(candidateAvailabilities[day].end_1, ['HH:mm']).format('LT')}`}
                                                </Text>
                                              ) : (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  ---
                                                </Text>
                                              )
                                            }
                                          </Box>
                                        ) : (
                                          <Box w="50%">
                                            {
                                              /* istanbul ignore next */
                                              candidateNightAvailabilities && candidateNightAvailabilities[day] ? (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  {`${moment(candidateNightAvailabilities[day].start_1, ['HH:mm']).format(
                                                    'LT',
                                                  )} - ${moment(candidateNightAvailabilities[day].end_1, ['HH:mm']).format(
                                                    'LT',
                                                  )}`}
                                                </Text>
                                              ) : (
                                                <Text fontSize="14px" fontWeight={500}>
                                                  ---
                                                </Text>
                                              )
                                            }
                                          </Box>
                                        )}
                                      </Flex>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              );
                            })}
                          </table>
                        </Box>
                      </Flex>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <CandidateRequisitionOverlap
                      intersectionShift={intersectionShift}
                      selectedRequisition={selectedRequisition}
                      candidateAvailabilities={candidateAvailabilities}
                      candidateNightAvailabilities={candidateNightAvailabilities}
                    />
                  </Box>
                )
              }

              <Box mt="16px">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  cursor="pointer"
                  data-testid="showReqAndCandidateAvabilityBtn"
                  onClick={() => setShowComparision(!showComparision)}
                  _hover={{ background: colors.gray[100] }}
                  p="6px 0px"
                >
                  <Text fontSize="14px" fontWeight="700" mb="6px">
                    {t('compare')}
                  </Text>
                  <Box>
                    <RiArrowDownSLine />
                  </Box>
                </Flex>

                {showComparision && (
                  <Box mt="16px" css={tableStyle}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th style={{ width: '20%', textAlign: 'left', padding: '8px', fontWeight: 700 }} />

                          <th style={{ width: '40%', textAlign: 'left', padding: '8px', fontWeight: 700 }}>
                            {t('candidateAvailability')}
                          </th>

                          <th style={{ width: '40%', textAlign: 'left', padding: '8px', fontWeight: 700 }}>
                            {t('requisitionShifts')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {weekDays.concat(weekEnds).map((day, index) => {
                          const reqShift = selectedRequisition?.shifts?.[day];
                          const candidateDay = candidateAvailabilities?.[day] || {};
                          const candidateNight = candidateNightAvailabilities?.[day] || {};

                          const newIsMatched = (
                            reqStartTime: string,
                            reqEndTime: string,
                            intersectionShifts: ShiftDetail | undefined,
                            reqShiftIndex: number,
                          ) => {
                            if (isUndefined(intersectionShifts)) return false;
                            if (reqShiftIndex === 1) {
                              return (
                                (reqStartTime === intersectionShifts?.start_1 && reqEndTime === intersectionShifts.end_1) ||
                                (reqStartTime === intersectionShifts?.start_3 && reqEndTime === intersectionShifts.end_3)
                              );
                            }

                            if (reqShiftIndex === 2) {
                              return (
                                (reqStartTime === intersectionShifts?.start_2 && reqEndTime === intersectionShifts.end_2) ||
                                (reqStartTime === intersectionShifts?.start_4 && reqEndTime === intersectionShifts.end_4)
                              );
                            }

                            return false;
                          };

                          return (
                            <Fragment key={day}>
                              {day === 'sat' && (
                                <tr style={{ height: '12px', borderLeft: '1px solid white', borderRight: '1px solid white' }} />
                              )}
                              <tr key={day} style={{ backgroundColor: index % 2 === 0 ? '#edf2f7' : '#ffffff' }}>
                                {/* Day Column */}
                                <td align="center">
                                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                    <div style={{ padding: '0px', fontWeight: 600 }}>{t(`common:days.${day}`)}</div>
                                  </div>
                                </td>

                                {/* Candidate Availability Column */}
                                <td style={{ padding: '8px' }}>
                                  <Flex direction="column">
                                    {[1].map((shiftIndex) => {
                                      const startKey = `start_${shiftIndex}` as keyof typeof candidateDay;
                                      const endKey = `end_${shiftIndex}` as keyof typeof candidateDay;

                                      return (
                                        <Fragment key={shiftIndex}>
                                          {candidateDay[startKey] && candidateDay[endKey] ? (
                                            <Text fontSize={15} fontWeight="normal" mb="2px">
                                              {`${moment(candidateDay[startKey], ['HH:mm']).format('LT')} - ${moment(
                                                candidateDay[endKey],
                                                ['HH:mm'],
                                              ).format('LT')}`}
                                            </Text>
                                          ) : (
                                            <Text>--</Text>
                                          )}
                                          {candidateNight[startKey] && candidateNight[endKey] ? (
                                            <Text fontSize={15} fontWeight="normal" mb="2px">
                                              {`${moment(candidateNight[startKey], ['HH:mm']).format(
                                                'LT',
                                              )} - ${moment(candidateNight[endKey], ['HH:mm']).format('LT')}`}
                                            </Text>
                                          ) : (
                                            <Text>--</Text>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </Flex>
                                </td>

                                {/* Desired Availability (Requisition Shifts) Column */}
                                <td style={{ padding: '8px' }}>
                                  <Flex direction="column">
                                    {[1, 2].map((shiftIndex) => {
                                      const startKey = `start_${shiftIndex}` as keyof typeof reqShift;
                                      const endKey = `end_${shiftIndex}` as keyof typeof reqShift;

                                      const reqStart = reqShift?.[startKey];
                                      const reqEnd = reqShift?.[endKey];

                                      return reqStart && reqEnd ? (
                                        <Text
                                          key={shiftIndex}
                                          fontSize={15}
                                          fontWeight="normal"
                                          mb="2px"
                                          style={{
                                            backgroundColor: newIsMatched(reqStart, reqEnd, intersectionShift?.[day], shiftIndex)
                                              ? colors.aeroBlue
                                              : '#ffe9e9',
                                            display: 'inline-block',
                                            padding: '2px 4px',
                                            borderRadius: '4px',
                                          }}
                                        >
                                          {`${moment(reqStart, ['HH:mm']).format('LT')} - ${moment(reqEnd, ['HH:mm']).format(
                                            'LT',
                                          )}`}
                                        </Text>
                                      ) : (
                                        <Text>--</Text>
                                      );
                                    })}
                                  </Flex>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </Box>
                )}
              </Box>

              <Box mt="12px" css={tableStyle} w="50%">
                {(selectedRequisition?.minHours ||
                  selectedRequisition?.maxHours ||
                  selectedRequisition?.guaranteedHours ||
                  selectedRequisition?.guaranteedHours === 0 ||
                  selectedRequisition?.minHours === 0 ||
                  selectedRequisition?.maxHours === 0) && (
                  <Text fontSize="14px" fontWeight="700" mb="12px">
                    {t('contractedHours')}
                  </Text>
                )}
                <table>
                  {(selectedRequisition?.minHours || selectedRequisition?.minHours === 0) && (
                    <tr>
                      <td>{t('minimum')}</td>
                      <td style={{ float: 'right' }}>
                        {selectedRequisition?.minHours.toLocaleString(i18n.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  )}
                  {(selectedRequisition?.maxHours || selectedRequisition?.maxHours === 0) && (
                    <tr>
                      <td>{t('maximum')}</td>
                      <td style={{ float: 'right' }}>
                        {selectedRequisition?.maxHours.toLocaleString(i18n.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  )}
                  {(selectedRequisition?.guaranteedHours || selectedRequisition?.guaranteedHours === 0) && (
                    <tr>
                      <td>{t('guaranteedHours')}</td>
                      <td style={{ float: 'right' }}>
                        {selectedRequisition?.guaranteedHours.toLocaleString(i18n.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  )}
                </table>
              </Box>

              <Box mt="14px">
                <Text fontSize="14px" fontWeight="700" mb="6px">
                  {t('recruiterNote')}
                </Text>
                <Text fontSize="14px">{selectedRequisition?.note}</Text>
              </Box>
            </Box>
          )}
          {requisition && requisition.length <= 0 && <Text mt="16px">{t('noRequisition')}</Text>}
        </Box>
      )}
      <Modal
        isOpen={isOpenConformationModal}
        onClose={/* istanbul ignore next */ () => onHandleConformationModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('conformAlert.title')}</ModalHeader>
          <ModalCloseButton data-testid="modal-close-button" />
          <ModalBody>
            <Text>{t('conformAlert.subtitle')}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => onHandleConformationModal(false)} data-testid="alertCloseButton">
              {t('conformAlert.close')}
            </Button>
            <Button colorScheme="blue" onClick={() => onHandleConformationModal(true)} data-testid="alertSuccessButton">
              {t('conformAlert.continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
