/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Flex, Grid, Text, jsx } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiHandCoinLine } from 'react-icons/ri';

import { BsCheckCircleFill } from 'react-icons/bs';
import { IoMdCalendar } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import AppyHereIcon from '../../../../../../assets/img/appy_logo.svg';
import Offer_Made_Icon from '../../../../../../assets/img/automated_icon/offer-made.svg';
import { CandidateHistoryActionName } from '../../../../../../firebase/firestore/documents/candidateAuditActionNames';
import colors from '../../../../../../styles/colors';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import useContactRequestDeclineReasons from '../../history/useContactRequestDeclinedReasons';
import { appointmentMessageCSS } from '../appointmentMessage/AppointmentMessage.style';
import { MessagePresenter } from '../messagePresenter';
import useLookupsByType from '../../../../../../app/hooks/useLookupsByType';
import { CandidateAuditStatusIcon, CandidateAuditStatusIconAutomated } from '../../history/CandidateAuditStatusIcon';
import { historyViewWrapper } from '../../history/History.styles';
import { messageVariants } from '../chatMessage/MessageVariants';
import { OffboardingReason } from '../../../../../../firebase/firestore/documents/candidate';

const colorsByAction = {
  info: colors.blue.default,
  success: colors.green[500],
  danger: colors.red[500],
  seekerIcon: '#ff6600',
} as const;
const colorsByStatus: Record<Partial<CandidateHistoryActionName[number]>, string> = {
  [CandidateHistoryActionName.HIRE_FORM_REQUESTED]: colorsByAction.seekerIcon,
  [CandidateHistoryActionName.HIRE_FORM_COMPLETED]: colorsByAction.success,
  [CandidateHistoryActionName.CANDIDATE_APPROVED]: colorsByAction.info,
  [CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED]: colorsByAction.info,
  [CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS]: colorsByAction.info,
  [CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS]: colorsByAction.info,
  [CandidateHistoryActionName.CANDIDATE_REJECTED]: colorsByAction.danger,
  [CandidateHistoryActionName.CANDIDATE_UNHIRED]: colorsByAction.danger,
  [CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED]: colorsByAction.info,
  [CandidateHistoryActionName.OFFBOARDED]: colorsByAction.danger,
};

export type AppointmentMessageProps = {
  message: MessagePresenter;
};

export const OfferMadeMessages = ({ message, ...rest }: AppointmentMessageProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const declinedReasonsTypes = useContactRequestDeclineReasons();
  const offerRejectedReasons = useLookupsByType('offerRejectedReason');
  const offboardedReasons = useLookupsByType('offboardReason');
  const offboardedTypes = useLookupsByType('offboardingType');

  const revokeReason = (reasonValue: string | undefined) => {
    let reasonText;
    reasonText = declinedReasonsTypes.find((reason) => reason?.reasonType === reasonValue)?.text;
    if (isEmpty(reasonText)) {
      reasonText = offerRejectedReasons.find((reason) => reason.id === reasonValue)?.label;
    }
    return reasonText && getTranslatedValue(reasonText, i18n.language);
  };

  // istanbul ignore next
  const offboardingReason = (reasonValue: string | undefined) => {
    let reasonText;
    if (isEmpty(reasonText)) {
      reasonText = offboardedReasons.find((reason) => reason.id === reasonValue)?.label;
    }
    return reasonText && getTranslatedValue(reasonText, i18n.language);
  };

  // istanbul ignore next
  const offboardingType = (typeValue: string | undefined) => {
    let typeReason;
    if (isEmpty(typeReason)) {
      typeReason = offboardedTypes.find((type) => type.id === typeValue)?.label;
    }
    return typeReason && getTranslatedValue(typeReason, i18n.language);
  };

  const getIconByAction = () => {
    if (message.action === CandidateHistoryActionName.OFFER_MADE) {
      return message.automation ? (
        <img src={Offer_Made_Icon} style={{ width: 40 }} alt="" />
      ) : (
        <RiHandCoinLine size={24} color={colors.blue.default} />
      );
    }
    if (message.action === CandidateHistoryActionName.OFFER_REVOKE) {
      return <img src={AppyHereIcon} style={{ height: 35, width: 'auto' }} alt="" />;
    }
    if (message.action === CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER) {
      return <BsCheckCircleFill size={24} color={colors.green[500]} />;
    }
    if (message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER) {
      return <MdCancel size={24} color={colors.red[500]} />;
    }
    if (message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_RECRUITER) {
      return <MdCancel size={24} color={colors.red[500]} />;
    }
    if (message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED) {
      return <IoMdCalendar size={24} color={colors.red[500]} />;
    }
    if (
      message.action === CandidateHistoryActionName.HIRE_FORM_REQUESTED ||
      message.action === CandidateHistoryActionName.HIRE_FORM_COMPLETED ||
      message.action === CandidateHistoryActionName.CANDIDATE_APPROVED ||
      message.action === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED ||
      message.action === CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS ||
      message.action === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS ||
      message.action === CandidateHistoryActionName.CANDIDATE_REJECTED ||
      message.action === CandidateHistoryActionName.CANDIDATE_UNHIRED ||
      message.action === CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED ||
      message.action === CandidateHistoryActionName.OFFBOARDED
    ) {
      return (
        <Box
          css={historyViewWrapper}
          style={{
            overflowY: 'unset',
            backgroundColor: 'transparent',
          }}
        >
          {message.automation ? (
            <CandidateAuditStatusIconAutomated status={message.action} />
          ) : (
            <CandidateAuditStatusIcon status={message.action} />
          )}
        </Box>
      );
    }

    return <Box />;
  };

  const getReason = () => {
    if (
      message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER ||
      message.action === CandidateHistoryActionName.OFFER_REVOKE
    ) {
      return (
        <Text fontSize="sm" color="gray.400" fontWeight="bold">
          {message.reasonId === 'OTHER'
            ? `${revokeReason(message.reasonId)} - ${message.reasonComment}`
            : revokeReason(message.reasonId)}
        </Text>
      );
    }
    // istanbul ignore next
    if (message.action === CandidateHistoryActionName.OFFBOARDED && !isUndefined(message.offboardingDetails)) {
      return (
        <Text fontSize="sm" color="gray.400" fontWeight="bold">
          {message?.offboardingDetails?.reason === OffboardingReason.OTHER
            ? message?.offboardingDetails?.customReason
            : `${offboardingType(message?.offboardingDetails?.type)} - ${offboardingReason(message?.offboardingDetails?.reason)}`}
        </Text>
      );
    }
    return false;
  };

  return (
    <Box
      css={appointmentMessageCSS}
      data-testid="AppointmentMessageBox"
      backgroundColor="#ffffff"
      className={messageVariants(message).className}
      borderColor={`${
        message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED ? colors.red[500] : colors.blue[500]
      } !important`}
      {...rest}
    >
      <Flex paddingLeft="10px" paddingTop="10px" paddingBottom="10px">
        {getIconByAction()}
        <Grid width="100%">
          <Text
            paddingLeft={3}
            paddingRight={3}
            color={
              message.action === CandidateHistoryActionName.OFFER_MADE
                ? colors.blue.default
                : (message.automation && colorsByStatus[message.action]) || colors.gray[700]
            }
            lineHeight={message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED ? 'inherit' : 'shorter'}
          >
            {t(`candidateHistory:${message.action}`)}
            {getReason()}
          </Text>
          {message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_RECRUITER && message.content && (
            <Text paddingLeft={3} paddingRight={3} color="gray.400" fontWeight="bold">
              {message.content}
            </Text>
          )}
          {message.action !== CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED && (
            <Text paddingLeft={3} paddingRight={3} className="appointmentDate" css={appointmentMessageCSS}>
              {message.timestamp && t('appointmentMessages:APPOINTMENT_MESSAGE.APPOINTMENT_DATE', { date: message.timestamp })}
            </Text>
          )}
        </Grid>
      </Flex>
    </Box>
  );
};
