import {
  ButtonGroup,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  useDisclosure,
  Text,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { generateFormSchema, HrFromCreateTemplateResponse, LabelSchema, Language } from '../formElements/formBuilderSchema';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useHireFormContext } from '../HireForm.context';
import { v4 as uuidv4 } from 'uuid';

interface TemplateActionsProps {
  language: Language;
  templateName: LabelSchema;
}

const TemplateActions = React.memo((props: TemplateActionsProps) => {
  return (
    <ButtonGroup variant="outline" size="sm">
      <ClearAllTemplate />
      <SaveTemplate {...props} />
    </ButtonGroup>
  );
});

const SaveTemplate = React.memo(({ language, templateName }: Pick<TemplateActionsProps, 'language' | 'templateName'>) => {
  const toast = useToast();
  const { formSchema } = useHireFormContext();

  const { isUpdating, errors } = useStoreState((state) => state.hrFormTemplate);
  const { createTemplate, updateTemplate } = useStoreActions((state) => state.hrFormTemplate);
  const { currentUserProfile } = useCurrentUserProfile();

  const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isDuplicate = useRouteMatch('/hire-form/:templateId/duplicate');
  const accountId = currentUserProfile?.account ?? '';
  const history = useHistory();

  async function handleSaveTemplate() {
    try {
      const questions = generateFormSchema(formSchema, language);
      let response: HrFromCreateTemplateResponse | undefined = undefined;
      if (templateId && !isDuplicate) {
        await updateTemplate({
          account: accountId,
          id: templateId,
          payload: { name: templateName?.[language]!, questions: questions },
        });
      } else {
        response = await createTemplate({
          account: accountId,
          payload: { name: templateName?.[language]!, questions: questions },
        });
      }

      toast({
        title: `Template ${templateId ? 'updated' : 'created'}`,
        status: 'success',
        isClosable: true,
      });

      if (isDuplicate || !templateId) {
        history.replace(`/hire-form/${templateId ?? response?.data.templateId}/edit`);
      }
    } catch {
      toast({
        title: `Error ${templateId ? 'updating' : 'creating'} template`,
        status: 'error',
        isClosable: true,
      });
    }
  }
  return (
    <Button
      colorScheme="blue"
      isDisabled={
        formSchema.length === 0 ||
        (formSchema.length === 1 && formSchema?.[0]?.fields?.length === 0) ||
        isUpdating ||
        errors.length > 0
      }
      isLoading={isUpdating}
      onClick={handleSaveTemplate}
    >
      Save
    </Button>
  );
});

const ClearAllTemplate = React.memo(() => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { isUpdating } = useStoreState((state) => state.hrFormTemplate);
  const { setErrors } = useStoreActions((state) => state.hrFormTemplate);
  const { formSchema, setFormSchema } = useHireFormContext();

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur closeOnEsc>
      <PopoverTrigger>
        <Button
          colorScheme="red"
          isDisabled={formSchema.length === 0 || (formSchema.length === 1 && formSchema?.[0]?.fields?.length === 0) || isUpdating}
        >
          Clear All
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text>Are you sure you want to clear all form elements?</Text>
          </PopoverBody>
          <PopoverFooter display="flex" alignItems="center" justifyContent="flex-end">
            <ButtonGroup size="sm" variant="outline">
              <Button
                colorScheme="red"
                onClick={() => {
                  setFormSchema([{ section: 'section_1', fields: [], id: uuidv4() }]);
                  setErrors([]);
                  onClose();
                }}
              >
                Yes
              </Button>
              <Button onClick={onClose}>No</Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
});

export default TemplateActions;
