import { Stack, HStack, Text } from '@chakra-ui/core';
import React from 'react';
import { LabelSchema, Language } from '../formElements/formBuilderSchema';
import theme from '../../../../styles/customTheme';
import FormBuilderSections from '../formElements/FormBuilderSections';
import TemplateActions from './TemplateActions';
import TemplateNameInput from './TemplateNameInput';

export interface FormBuilderProps {
  language: Language;
}

export default function FormBuilder({ language }: FormBuilderProps) {
  const [templateName, setTemplateName] = React.useState<LabelSchema>({ en: '', fr: '' });

  return (
    <Stack
      spacing={6}
      flex="1 0 50%"
      px={theme.space[4]}
      py={theme.space[6]}
      overflowY="auto"
      minHeight="0"
      height="100%"
      overflowX="hidden"
      marginLeft="0 !important"
    >
      <Text fontSize="xl" fontWeight="bold">
        Form Builder
      </Text>
      <HStack justify="space-between" align="center">
        <TemplateNameInput {...{ language, templateName, setTemplateName }} />
        <TemplateActions
          {...{
            language,
            templateName,
          }}
        />
      </HStack>

      <FormBuilderSections language={language} />
    </Stack>
  );
}
